import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Stepping into the shoes of an Interviewer',
      content: (
        <div>
          <p className="task-mvp">Stepping into the shoes of an Interviewer</p>
         
                  
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>
                    Giving Interviews is one type of an experience , but taking interview of other candidates is a very different experience all together.
<br/>
You get to understand what goes inside the mind of an interviewer.

<br/><br/>

Before you start taking interviews
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Watch this video to understand about the benefits of being an interviewer - <a href="https://youtu.be/01SFvQliiu4?si=A7ojKO6PJZ6U3utl" target='_blank'>
                            Click here&nbsp;
                          </a>

<br/><br/>


If you have any questions, please let your student counsellor know.

<br/><br/>

(If the interviewee you have chosen is not picking up your call - Please send him the following message a
    <br/>
	&nbsp;&nbsp;&nbsp;"This is your mock interviewer from EduYog, Please call me as soon as possible to get your mock interview scheduled. Thank you!!!"
    <br/>

                    </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Join the Web Dev whatsapp group',
        content: (
          <div>
            <p className="task-mvp">Join the Web Dev whatsapp group</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Hi Guys
<br/>
Please join the whatsapp group via this link <a href="" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/>
This group is only for doubts discussions and helping each other.

Please be humble and motivate each other to achieve more
<br/><br/>


Any misconduct would lead to strict actions.
<br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Web Development Basics',
        content: (
          <div>
            <p className="task-mvp">Web Development Basics</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      So NICE to SEE you here ....
<br/>
Web Development is going to be a lot of fun together.
<br/>
We have live classes everyday , feel free to join whenever you get stuck xD!!!
<br/>
For class timings check dashboard.
<br/><br/>


What is HTML CSS and JS. Watch this video.
<a href="https://www.youtube.com/embed/gT0Lh1eYk78" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/><br/>

Please go through these topics to understand HTML , Start from the first topic on the left and go uptill HTML images topic.
<br/>
 DONT DO MORE THAN THAT !!!!!
 <br/>
 <a href="https://www.w3schools.com/html/default.asp" target='_blank'>
                            Click here&nbsp;
                          </a>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Installing Dependencies',
        content: (
          <div>
            <p className="task-mvp">Installing Dependencies</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Install VScode (Editor ) into your laptop. Always use VScode to right code. Online editors never work as expected.Dont use them.
<br/>
(Check it out on youtube how to install for your OS)
<br/>
<br/>



Install node
<br/>

(Check it out on youtube how to install for your OS)

<br/>
<br/>


What is Node ? Why do we even care about it
<br/>


<a href="https://www.youtube.com/embed/yEHCfRWz-EI" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Let, const and var',
        content: (
          <div>
            <p className="task-mvp">Let, const and var</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch the video from the 12 th min uptill the 24 th minute
<br/>
<a href="https://www.youtube.com/embed/W6NZfCO5SIk" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/>
<br/>

Deliverable (Write the code in JS only. Other languages are not supported)

<br/><br/>

Write a program to Swap two Variables a and b (Swapping basically means interchanging)
<br/>
Input
<br/>
a=3 , b=4
<br/>
Output
<br/>
a=4, b=3
<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Strings in JS',
        content: (
          <div>
            <p className="task-mvp">Strings in JS</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch this video from here uptill the 24th min and answer the following
<br/>
<a href="https://www.youtube.com/embed/hdI2bqOjy3c" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/>Question 1) Template Strings

<br/><br/>

Take variable like name , age , address and fill them with your personal details.
<br/>
Now make a new variable called personDetails and make a string which states that 'My name is "name var" , age "age var", address is "address var".
<br/><br/>


Example

<br/><br/>

name = 'Yash Prasad'
<br/>
age = 25
<br/>
address = "Prestige Falcon City, Bangalore"
<br/><br/>


OUTPUT
<br/>
personal details = My name is Yash Prasad age is 25 address is Prestige Falcon City, Bangalore
<br/><br/>


Question 2)Split the String
<br/><br/>


Take the string above and split it into an array using the comma delimeter
<br/><br/>


Ex Input
<br/>
var str = My name is Yash Prasad, age is 25, address is Prestige Falcon City, Bangalore

<br/><br/>

Output Array
<br/>
[
    <br/>
"My name is Yash Prasad",
<br/>
"age is 25",
<br/>
"address is Prestige Falcon City",
<br/>
"Bangalore"
<br/>
]<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Reverse a String',
        content: (
          <div>
            <p className="task-mvp">Reverse a String</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Solve the following in Javascript only 
<br/>

<br/>
Given a string , reverse the string and return the reverse string.

<br/><br/>

Example.1
<br/>
Input
<br/>
ram
<br/><br/>


Output
<br/>
mar
<br/><br/>


Example 2
<br/>
Input
<br/>
mango
<br/><br/>


Output
<br/>
ognam
<br/><br/>






Write the code in Javascript and do not use special functions.
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'ARRAYS',
        content: (
          <div>
            <p className="task-mvp">ARRAYS</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Follow the youtuber and experiment with all the APIs available with arrays
<br/>
(unshift, push,pop, Array, indexOf)

<br/><br/>

Watch from the 23rd min to 30th min. Dont watch entire video as we will cover them in next tasks

<br/><br/>
<a href="https://www.youtube.com/embed/hdI2bqOjy3c?t=1380s"  target='_blank'>
                            Click here&nbsp;
                          </a>
<br/><br/>

Deliverable


<br/><br/>
Find the max element present in an array
<br/><br/>


Example 1
<br/>
Input
<br/>
1,2,4,1,5,6

<br/><br/>

Output
<br/>
6

<br/><br/>

Example 2
<br/>
Input
<br/>
-1,-2,-3,-2,-4

<br/><br/>

Output
<br/>
-1
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Find the minimum element in an array',
        content: (
          <div>
            <p className="task-mvp">Find the minimum element in an array</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    In an array of elements find the number with the minimum value
<br/>
<br/>

Example 1
<br/>
Input
<br/>
1,2,4,1,5,6
<br/><br/>


Output
<br/>
1

<br/><br/>

Example 2
<br/>
Input
<br/>
1,-2,-3,6,3,7
<br/><br/>


Output
<br/>
-3

<br/><br/>



Write the code in Javascript and paste it here
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Find the second maximum number in an array',
        content: (
          <div>
            <p className="task-mvp">Find the second maximum number in an array</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Given an array , find the second maximum number present in the array.
<br/><br/>


Example 1
<br/>
Input
<br/>
1,2,4,1,5,6
<br/><br/>


Output
<br/>
5

<br/><br/>

Example 2
<br/>
Input
<br/>
1,2,3,6,3,7

<br/><br/>

Output
<br/>
6

<br/>

(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Balanced Parenthesis problem',
        content: (
          <div>
            <p className="task-mvp">Balanced Parenthesis problem</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/valid-parentheses/description/" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/>
<br/>


(Write your code in javascript only)
<br/>

(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Objects',
        content: (
          <div>
            <p className="task-mvp">Objects</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch the video from 30th min to 46th min . Dont watch entire video as we will cover them in the next tasks.
<br/>
<a href="https://www.youtube.com/embed/hdI2bqOjy3c?t=1809s" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/><br/>

Watch what the youtuber is doing and implement the following
<br/><br/>


&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. In the person object, print "movies" from the hobbies<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. In the person object, print the "state" value from address key<br/>

<br/><br/>


Destructuring the object

<br/><br/>

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Take out the age from the person object using destructuring and console log<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Take out state from the person object using destructuring and console log<br/>
<br/>

(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Function',
        content: (
          <div>
            <p className="task-mvp">Function</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Start watching the video from 46th to 59th min . Dont watch the entire video as we will finish them in the next tasks<br/>
                    <a href="https://www.youtube.com/embed/hdI2bqOjy3c?t=3203s" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/><br/>
Functions
<br/>
Write a function to take temperature and conversion unit such that it converts the temperature to the respective conversion unit from either Celcius or Kelvin.
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Assume temperature is in Celcius if conversion unit is Kelvin.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Assume temperature is in Kelvin if conversion unit is Celcius.<br/>
<br/><br/>

SAMPLE INPUT OUTPUT 1
<br/>
Input
<br/>
35, K
<br/><br/>


Output
<br/>
273+35 = 308
<br/><br/>


Reason
<br/>
To convert from Celcius to Kelvin we add 273
<br/><br/>


SAMPLE INPUT OUTPUT 2

<br/><br/>

Input
<br/>
308, C

<br/><br/>

Output
<br/>
308-273 = 35
<br/><br/>


Reason
<br/>
To convert from Kelvin to Celcius we subtract 273
<br/><br/>


[Bonus Task ]
<br/><br/>


Use ternary operator instead of IF condition
<br/>
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Functional Constructors',
        content: (
          <div>
            <p className="task-mvp">Functional Constructors</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Start watching the video from 59th to 1hr 5th min . Dont watch the entire video as we will finish them in the next tasks<br/>
                    <a href="https://www.youtube.com/embed/hdI2bqOjy3c?t=3203s" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/><br/>
Deliverables
<br/>

<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Create a constructor function Person which takes in first name, last name , Sex and age of person<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Inside the constructor function , create a function called printFullName which prints full name (first name + last name) of the student<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Initialize 2 objects with the following values<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. "yash", "prasad", 10, "M"<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. "vaibhav", "prasad", 20, "M"<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Minimum age to be egligible for going to disco is 18. Write a function outside the constructor function by which it can be checked whether the given person is allowed to enter the disco or not.
<br/>
<br/>
Complete the puzzle. Try it by yourself. It is very easy.
<br/>
If stuck for more than 1 hour check the hints<br/>

<br/>

Expected Output (case sensitive and space sensitive)
<br/>
Full name = yash prasad
<br/>
10
<br/>
yash not egligible
<br/>
Full name = vaibhav prasad
<br/>
20
<br/>
vaibhav egligible
<br/><br/>


Small hack to get the correct output
<br/><br/>


A lot of students get the right output but for some reason it doesnt match the expected output because of case sensitivity or format issues.
<br/>
A good way to check what is the difference between your output and expected output is through this <a href="https://www.diffchecker.com/">
                            Tool&nbsp;
                          </a>.

<br/>
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'ES6 CLASSES',
        content: (
          <div>
            <p className="task-mvp">ES6 CLASSES</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch the video from 1 hour 8th min here till the end<br/>
                    <a href="https://www.youtube.com/embed/hdI2bqOjy3c?t=4041s" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/><br/>
Convert all the features present in the functional contructor of Student into Student class . Best Design pattern to follow

<br/><br/>



Complete the Puzzle
<br/><br/>


&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Create a constructor function Student which takes in first name, last name , roll number and Student's age to initialize the object<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Complete the function printFullName() so that it prints the full name of the student object which invokes it.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Complete the function minorOrmajor() so that it<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. prints major if the student's age is above or equal to 18<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. prints minor if the student's age is below 18<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Make 2 students objects.Ram and Yash to test everything<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. Yash's details: ('yash', 'prasad', 12, 27)<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. Ram's details: ('ram', 'prasad', 14, 15)<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. Invoke prinFullName and minorOrmajor to print Yash's and Ram's details.<br/>

<br/><br/>
If stuck for more than 1 hour check the hints.

<br/><br/>

Output should look like the following.(Output is case sensitive )
<br/>
yashprasad
<br/>
Major
<br/>
ramprasad
<br/>
Minor
<br/><br/>


Small hack to get the correct output
<br/><br/>


A lot of students get the right output but for some reason it doesnt match the expected output because of case sensitivity or format issues.
<br/>
A good way to check what is the difference between your output and expected output is through this <a href="https://www.diffchecker.com/" target='_blank'>
                            Tool&nbsp;
                          </a>.

<br/>
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'ES6 CLASSES',
        content: (
          <div>
            <p className="task-mvp">ES6 CLASSES</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch this video to code the following deliverables as per the timelines given below. DONT watch everything in one shot!!!
                    
                    <br/><a href="https://www.youtube.com/embed/RBLIm5LMrmc" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/><br/>
Deliverables
<br/><br/>


&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Watch uptill the first 4:30 min - Implement the class User as per the youtuber.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Every time a new user registers, keep a count of number of registered users.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. At any given point of time if we invoke printNumberOfUsers ,the number of users should get printed .<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Complete the coding puzzle. It is very easy.<br/>
<br/>
Hint - You just have to use static variables
<br/>
Check Hint 1 if you are stuck for more than 30 minutes.

<br/>
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Inheritance',
        content: (
          <div>
            <p className="task-mvp">Inheritance</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch this video to code the following deliverables as per the timelines given below. DONT watch everything in one shot!!!
                    
                    <br/><a href="https://www.youtube.com/embed/RBLIm5LMrmc" target='_blank'>
                            Click here&nbsp;
                          </a>
<br/><br/>
Deliverables
<br/><br/>

1)Watch the remaining video from 4:30 th minute to understand inheritance :  Code along with the youtuber to understand how inheritance works.
<br/><br/>


2)[Watch this video  <a href="https://www.youtube.com/watch?v=irrxnH-nkqg" target='_blank'>
                            Click here&nbsp;
                          </a>to understand the inbuilt Date Class in javascript] Add a new state called membershipactivetilldate : date. Whenever a new member is created keep his membershipactivetilldate fixed to 3rd of March 2023.

<br/><br/>

3) Add a new function called renew Membership which based on membership type (MONTHLYPACKAGE or YEARLYPACKAGE) increase the membershipactivetilldate by 1 month or 1 year respectively.
<br/><br/>


Example 1
<br/><br/>


Input
<br/>
pyash,yash@gmail.com,password123,MONTHLYPACKAGE

<br/><br/>

Output
<br/>
pyash is subscribed to MONTHLYPACKAGE uptill Mon Apr 03 2023

<br/><br/>

Example 2

<br/><br/>

Input
<br/>
vaibhav,vaibhav@gmail.com,password123,YEARLYPACKAGE
<br/><br/>


Output
<br/>
vaibhav is subscribed to YEARLYPACKAGE uptill Sun Mar 03 2024
<br/>
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];

const Starting2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 17) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/13'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 17</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Starting2;
