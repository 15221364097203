import React from "react";
import { FaHeadSideVirus } from "react-icons/fa";
import { IoBagSharp } from "react-icons/io5";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaAddressBook } from "react-icons/fa6";


function getFields(input, field, i) {
    return input[i][field];
}

function getAns(results) {
    const colors = ["#e6edf8", "#f8f6e6", "#e6f8e7", "#f8e6f5", "purple"];

    return (
        <>
            {results.map((result, index) => (
                <div key={index} className="t-1 fs-5" >
                    {index % 2 === 0 ? (
                        <>
                            <div className="t-3" style={{ background: colors[index % colors.length] }}>{result.img}</div>
                            <div className="t-4"style={{ background: colors[index % colors.length] }}>
                                <h3>{result.head1}</h3>
                                <p>{result.head2}</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="t-5"style={{ background: colors[index % colors.length] }}>
                                <h3>{result.head1}</h3>
                                <p>{result.head2}</p>
                            </div>
                            <div className="t-6"style={{ background: colors[index % colors.length] }}>{result.img}</div>
                        </>
                    )}
                </div>
            ))}
        </>
    );
}

export const Trust = () => {
    
    const area = [
        {
            img: <FaHeadSideVirus/>,
            head1: "Regular discussion with Founders like no other!",
            head2: "Sharpener is built on transparency. Every few weeks there are sessions with Founders for you to ask anything!",
        },
        {
            img: <IoBagSharp /> ,
            head1: "No forced placements. It is your decision.",
            head2: "You are not forced to apply for any job. We only refer you to places where you apply on our Dashboard! Thats it     ",
        },
        {
            img: <FaArrowTrendUp />,
            head1: "The higher package you get, the more it benefits us!",
            head2: "Our ISA is a strict % of what you earn. Hence we are always motivated to place our good students in companies",
        },
        {
            img: <FaAddressBook />,
            head1: "Intense resume assessment and profile evaluations",
            head2: "First impressions is the last impression. Its important to make sure you are ready for anything thrown at you!",
        },
    ];

    const results = area.map((item, index) => ({
        img: getFields(area, "img", index),
        head1: getFields(area, "head1", index),
        head2: getFields(area, "head2", index),
    }));

    return (
    <>
      <div className="t-2">
      <h1 className="display-4 text-dark">Why trust us?</h1>
      <p className="text-dark">Important things which no one tells you</p>
      </div>
      {getAns(results)}
    </>
    )
};
