import React from 'react';
import image1 from "../images/image16.avif"
import image2 from "../images/image18.jpg"
import image3 from "../images/image17.jpg"
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Hire() {
  return (
    <div className="bg-white py-5 m-5">
      <div className="container">
        <h2 className=" display-6 text-center mb-5 text-dark fw-normal">
          Have questions about your dream college's department, academic programs, or extracurricular offerings?
        </h2>
        <div className="hire gap-5 ">
          <FeatureItem title="Discover" img={image1} />
          <ArrowRightIcon />
          <FeatureItem title="Schedule" img={image2} />
          <ArrowRightIcon />
          <FeatureItem title="Connect" img={image3} />
        </div>
      </div>
    </div>
  );
}

function FeatureItem({ title, img }) {
  return (
    <div className="text-center">
      <img
        alt={title}
        className="rounded-circle bg-secondary"
        src={img}
        style={{
          width: "220px", // Set width
          height: "220px", // Set height
          objectFit: "cover", // Maintain aspect ratio and cover entire space
          margin: "0 auto", // Center the image horizontally
        }}
      />
      <h3 className="mt-3 text-dark fw-bold">{title}</h3>
    </div>
  );
}

function ArrowRightIcon() {
  return (
    <div className="text-center d-none d-md-block"> {/* Hide on screens below 769px */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5 12h14" />
        <path d="m12 5 7 7-7 7" />
      </svg>
    </div>
  );
}
