import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Videos- Arrays',
      content: (
        <div>
          <p className="task-mvp">Videos</p>
          
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>Go through the below video and doc on BIT Manipulation. try to understand the concepts :-<a href="https://youtu.be/Db8OmMfzwl8?si=LLFqJlmuUFVS37V_" target='_blank'>
                        &nbsp;Click here
                      </a></p>
                      <br/>
                    
                    <p>After watching the above video Click on the Button Done and start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.</p>
                    <br/>
                    <br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Count number of set bits',
        content: (
          <div>
            <p className="task-mvp">Count number of set bits</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        <br/>
                    Write a function that takes an unsigned integer and returns the number of '1' bits it has (also known as the <a href='https://en.wikipedia.org/wiki/Hamming_weight' target='_blank'>Hamming weight</a>).
<br/>
Note:<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
Note that in some languages, such as Java, there is no unsigned integer type.
<br/>
In this case, the input will be given as a signed integer type.
<br/>
It should not affect your implementation, as the integer's internal binary representation is the same, whether it is signed or unsigned.
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In Java, the compiler represents the signed integers using <a href="https://youtu.be/sJXTo3EZoxM?si=E3CK0JQOenDOXTIW" target='_blank'>2's complement notation.</a>
<br/>
Therefore, in Example 3, the input represents the signed integer. -3.
<br/><br/>
 

Example 1:
<br/>
Input: n = 00000000000000000000000000001011
<br/>
Output: 3
<br/>
Explanation: The input binary string 00000000000000000000000000001011 has a total of three '1' bits.
<br/>
Example 2:
<br/>
Input: n = 00000000000000000000000010000000
<br/>
Output: 1
<br/>
Explanation: The input binary string 00000000000000000000000010000000 has a total of one '1' bit.
<br/>
Example 3:
<br/>
Input: n = 11111111111111111111111111111101
<br/>
Output: 31
<br/>
Explanation: The input binary string 11111111111111111111111111111101 has a total of thirty one '1' bits.

 <br/>
 <br/>

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The input must be a binary string of length 32.
                      <br/>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Alternating Bits',
        content: (
          <div>
            <p className="task-mvp">Alternating Bits</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      [Important] Solve the following problem on leetcode 
                        <a href="https://leetcode.com/problems/binary-number-with-alternating-bits/" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        (Solve the following question on the Leetcode, make sure all test case passes and then click submit.)
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Number to Hexadecimal',
        content: (
          <div>
            <p className="task-mvp">Number to Hexadecimal</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>Watch the following video to understand the conversion in different types :<a href="https://youtu.be/5vJLrKx5-8M?si=CA1U-LAyzgfhrqMU" target='_blank'>
                        &nbsp;Click here
                      </a></p>
                      <br/>
                      <p>
                      [Important] Solve the following problem on leetcode 
                        <a href="https://leetcode.com/problems/convert-a-number-to-hexadecimal/" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        (Solve the following question on the Leetcode, make sure all test case passes and then click submit.)
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Sort integers by number of set bits',
        content: (
          <div>
            <p className="task-mvp">Sort integers by number of set bits</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                      <p>
                      You are given an integer array arr. Sort the integers in the array in ascending order by the number of 1's in their binary representation and in case of two or more integers have the same number of 1's you have to sort them in ascending order.

Return the array after sorting it.

 
<br/>
<br/>

Example 1:
<br/>

Input: arr = [0,1,2,3,4,5,6,7,8]
<br/>

Output: [0,1,2,4,8,3,5,6,7]
<br/>

Explantion: [0] is the only integer with 0 bits.
<br/>

[1,2,4,8] all have 1 bit.
<br/>

[3,5,6] have 2 bits.
<br/>

[7] has 3 bits.
<br/>

The sorted array by bits is [0,1,2,4,8,3,5,6,7]


<br/>
<br/>

Example 2:
<br/>

Input: arr = [1024,512,256,128,64,32,16,8,4,2,1]
<br/>

Output: [1,2,4,8,16,32,64,128,256,512,1024]
<br/>

Explantion: All integers have 1 bit in the binary representation, you should just sort them in ascending order.

 
<br/>
<br/>

Constraints:
<br/>
1 &lt;= arr.length &lt;= 500
0 &lt;= arr[i] &lt;= 10^4
<br/>
<br/>
                      (Solve the following question in the compiler, make sure all test case passes and then click submit. )
                      <br/>
                       </p>  
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'DIvide two integers',
        content: (
          <div>
            <p className="task-mvp">DIvide two integers</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                
                      <p>
                      [Important] Solve the following problem on leetcode 
                        <a href=" https://leetcode.com/problems/divide-two-integers/" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        (Solve the following question on the Leetcode, make sure all test case passes and then click submit.)
                        <br/><br/>
                        Any confusion Look at the hints to find the detailed video on this Question.
                     
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Time and Space complexity (Very Important)',
        content: (
          <div>
            <p className="task-mvp">Time and Space complexity (Very Important)</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                      <p>Watch the below 2 videos :-<br/>
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.<a href="https://youtu.be/bQssdSrSGNE?si=QiPAQukXppVj_GCB">
                          &nbsp;Click here
                        </a><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.<a href="https://youtu.be/mV3wrLBbuuE?si=7gea43Oxzq7QtZ_W">
                          &nbsp;Click here
                        </a><br/>
                        After watching the above video Click on the Button Done and start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp
                       </p>  
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Time complexity-1',
        content: (
          <div>
            <p className="task-mvp">Time complexity-1</p>
           
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      [Important] Solve the following problem on leetcode 
                        <a href="https://leetcode.com/discuss/general-discussion/1050754/explain-time-complexity-to-a-10-year-old" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        (Solve the following question on the Leetcode, make sure all test case passes and then click submit. )
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Questions on Time Complexity ',
        content: (
          <div>
            <p className="task-mvp">Questions on Time Complexity</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Make sure you have understood the following pointers from the above videos (Very Important from the interviews perspective) :-

<br/><br/>

1. Understand why we get a TLE
<br/>
2. How to figure that out what solutions will work Coding question -- Using constraints
<br/>
3. Understand what is worst case time complexity and best case time complexity
<br/>
4. Understand how we calculate the time complexity of a for loop, Nested for loops
<br/>
5. Logarithmic time complexity
<br/><br/>



click on submit only if you are comfortable with the above questions. Otherwise Ask your Mentor 
                     
                        <br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Time complexity-2',
        content: (
          <div>
            <p className="task-mvp">Time complexity-2</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      [Important] Understand the following problem on Geeksforgeeks 
                        <a href="https://www.geeksforgeeks.org/time-complexity-and-space-complexity/" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        (Understand the following questions on the Geeksforgeeks, make sure you have to understand all the problems and then click submit. )
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Time complexity-3',
        content: (
          <div>
            <p className="task-mvp">Time complexity-3</p>
           
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      [Important] Understand the following Miscellaneous Problems of Time Complexity
                        on Geeksforgeeks 
                        <a href="https://www.geeksforgeeks.org/miscellaneous-problems-of-time-complexity/" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        <br/>
                        (Understand the following questions on the Geeksforgeeks, make sure you have to understand all the problems and then click submit. )
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Rotate Array -1',
        content: (
          <div>
            <p className="task-mvp">Rotate Array -1</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Given an integer array nums, rotate the array to the right by k steps, where k is non-negative.
<br/><br/>


Try to Solve in time Complexity 0(n*k).

<br/><br/>

You Dont need to return an Array. Modify array in place. 



 
<br/><br/>
Example 1:
<br/>
Input: nums = [1,2,3,4,5,6,7], k = 3
<br/>
Output: [5,6,7,1,2,3,4]
<br/>
Explanation:
<br/>
rotate 1 steps to the right: [7,1,2,3,4,5,6]
<br/>
rotate 2 steps to the right: [6,7,1,2,3,4,5]
<br/>
rotate 3 steps to the right: [5,6,7,1,2,3,4]
<br/>

<br/>


Example 2:
<br/>
Input: nums = [-1,-100,3,99], k = 2
<br/>
Output: [3,99,-1,-100]
<br/>
Explanation:
<br/>
rotate 1 steps to the right: [99,-1,-100,3]
<br/>
rotate 2 steps to the right: [3,99,-1,-100]

<br/><br/>



Try to think of an o(n*k) approach Watch the Hint Video if Stuck
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Disappearing Elements ',
        content: (
          <div>
            <p className="task-mvp">Disappearing Elements</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Given an Array (arr) of size n. It only contains elements from range [1,n].
                      <br/>

Return an Array of all integers in the range [1,n] that do not appear in the array.

<br/>
<br/>


You Have to solve this question without using any extra array.


Hint:- Try using the last approach of rotate arrays Question


<br/>
<br/>

Example 1:
<br/>

Input: nums = [4,3,2,7,8,2,3,1]
<br/>

Output: [5,6]
<br/>

Explanation:- All elements are present from 1 to 8 except 5 and 6.


<br/>
<br/>

Example 2:
<br/>

Input: nums = [1,1]
<br/>

Output: [2]
<br/>

Explanation:- Elements that should be present is 1,2 (because n is 2 ) but 2 is missing.




<br/>

<br/>
Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;n == nums.length<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= n &lt;= 10^5<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= nums[i]&lt;= n<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Strings Videos',
        content: (
          <div>
            <p className="task-mvp">Strings Videos</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        <p>
                        Important Watch the following videos.
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Understand the basic operations on Strings - <a href="https://youtu.be/-pSyzCWsBA8?si=xxcXx8ldx1eTcplf" target='_blank'>
                          &nbsp;Click here
                        </a><br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Splitting a String in Javascript :-  <a href="https://youtu.be/couYPD-SYww?si=yf57mke3ReaDL7wt" target='_blank'>
                          &nbsp;Click here
                        </a><br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Splitting a String in C++ :-  <a href="https://youtu.be/BUy-SE6ZWGM?si=qJKDXgfkp35t4Qok" target='_blank'>
                          &nbsp;Click here
                        </a><br/>
<br/>
Also try to figure out how will you split without using inbuilt method

<br/>

Read the following Articles to understand how to work with Strings in your own Language.

<br/>
<br/>
C++ :-<br/><a href="https://youtu.be/W8hPsBquD6Y?si=tz6tbCuMbycqNrtq" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>

<br/>

Java :-<br/>

Read the following article :- <a href="https://youtu.be/vCRD36bG8xQ?si=xDlEUhVWLw4XEi4D" target='_blank'>
                          &nbsp;Click here
                        </a><br/>
Understand how substring works :-<a href="https://youtu.be/wK2m7mIrfUg?si=1ooigzm-WHRHdQuN" target='_blank'>
                          &nbsp;Click here
                        </a><br/>

<br/>
JavaScript:-<br/>

Read the following article :-<a href="https://youtu.be/fozwNnFunlo?si=t-OwA9KVI5gdhD5k" target='_blank'>
                          &nbsp;Click here
                        </a><br/>

<br/>
Python:-<br/>

<a href="https://youtu.be/Ctqi5Y4X-jA?si=5YcVAQsbHTk-G7k0" target='_blank'>
                          &nbsp;Click here
                        </a>

<br/><br/>
After going through the above resources one should be comfortable with the following things
<br/>
How to create a string, finding the Length
accessing any character in a string
how to find substring
Traversing a string through looping.
Splitting a string using inbuilt method and without using it. 

<br/><br/>
If having any doubts make sure to get it clarified from the mentor.
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Validate String',
        content: (
          <div>
            <p className="task-mvp">Validate String</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Given A String check whether on reversal it is the same or not.
Return True if yes otherwise return False. 
<br/><br/>




Example 1: 

<br/>

Input:- word = "madam"
<br/>
Output : true
<br/>

<br/>
Explanation:-
<br/>
madam when reversed is the same as the original one.
<br/><br/>


Example 2: 
<br/>


Input:- word = "eduyog"
<br/>
Output : false
<br/><br/>


Explanation:-
<br/>
sharpener when reversed is not the same as the original one.

<br/><br/>

Example 3: 

<br/>

Input:- word = "abba"
<br/>
Output : true

<br/><br/>

Explanation:-
<br/>
abba when reversed is the same as the original one.
<br/><br/>




Constraints:
<br/>
1 &lt;= s.length &lt;= 2 * 10^5
s consists only of printable ASCII characters.
<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Maximum Number of Words',
        content: (
          <div>
            <p className="task-mvp">Maximum Number of Words</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      A sentence is a list of words that are separated by a single space with no leading or trailing spaces.
<br/>
You are given an array of strings sentences, where each sentences[i] represents a single sentence.
<br/>
Return the maximum number of words that appear in a single sentence.
<br/><br/>
 

Example 1:
<br/>
Input: sentences = ["alice and bob love leetcode", "i think so too", "this is great thanks very much"]
<br/>
Output: 6
<br/>
Explanation:
<br/>
- The first sentence, "alice and bob love leetcode", has 5 words in total.
<br/>
- The second sentence, "i think so too", has 4 words in total.
<br/>
- The third sentence, "this is great thanks very much", has 6 words in total.
<br/>
Thus, the maximum number of words in a single sentence comes from the third sentence, which has 6 words.
<br/><br/>


Example 2:
<br/>
Input: sentences = ["please wait", "continue to fight", "continue to win"]
<br/>
Output: 3
<br/>
Explanation: It is possible that multiple sentences contain the same number of words.
<br/>
In this example, the second and third sentences (underlined) have the same number of words.
<br/><br/>
 

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= sentences.length &lt;= 100<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= sentences[i].length &lt;= 100<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sentences[i] consists only of lowercase English letters and ' ' only.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sentences[i] does not have leading or trailing spaces.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All the words in sentences[i] are separated by a single space.
<br/><br/>



Watch the hint video if stuck.
<br/>
Contact your mentor if having any issues/ Confusion on how to work with Strings operations.<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Minimum requirement for palindrome',
        content: (
          <div>
            <p className="task-mvp">Minimum requirement for palindrome</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      You have given a string , You have to add characters at start of string to make it a palindrome .
<br/>
return the minimum number of characters required to add to make it a palindrome.

<br/><br/>

Example 1:
<br/>
Input:
<br/>
str = "abcd"
<br/>
Output: 3
<br/>
Explanation: Inserted character marked with bold characters in dcbabcd
<br/><br/>


Example 2:
<br/>
Input:
<br/>
str = "aa"
<br/>
Output: 0
<br/>
Explanation:"aa" is already a palindrome.
<br/><br/>


Constraints:
<br/>
1 ≤ |str| ≤ 10^3
<br/>
str contains only lower case alphabets.
<br/><br/>


                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Extracting Numbers',
        content: (
          <div>
            <p className="task-mvp">Extracting Numbers</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Given a String Extract all numbers from it and store it inside an array. Return the Array Once extraction is completed.

<br/><br/>

Note that if the string is "abc334vf" then the number is 334 and not 3,3,4 as individual numbers

<br/><br/>

Example 1:
<br/>
Input:
<br/>
str = "abc334v44d"
<br/>
Output: [334, 44]
<br/><br/>


Example 2:
<br/>
Input:
<br/>
str = "abv345fjjf123tyir45jf6th"
<br/>
Output: [345, 123, 45, 6]
<br/><br/>




Constraints:
<br/>
1 ≤ |str| ≤ 10^4
<br/>
str contains only alphabets and digits.

<br/><br/>

Hint:- Try to solve the question via ascii value concept.
<br/>
Ascii value :-
<br/>
'0' is 48
<br/>
'1' is 49
<br/>
..
<br/>
'9' is 57
<br/>

<br/>
'a' ascii value is 97
<br/>
'b' ascii value is 98
<br/>
...
<br/>

<br/>
'A' ascii value is 65
<br/>
'B' ascii value is 66<br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Failed Students at end',
        content: (
          <div>
            <p className="task-mvp">Failed Students at end</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      In A exam with negative marking the passing criteria is you have to score zero or positive marks .
<br/>
we have given an array which denotes the score of the students in that exam .
<br/>
you have to move all the failed students to last of the array .
<br/><br/>


Solve this Question in 0(N) time complexity. Using two pointers. Watch Hints if not being able to figure out.
<br/><br/>


Example 1:
<br/>
Input : 
<br/>
N = 8
<br/>
arr[] = {"{1, -1, 3, 2, -7, -5, 11, 6}" }
<br/>
Output :  1 3 2 11 6 -1 -7 -5

<br/><br/>

Example 2:
<br/>
Input :
<br/>
 N=8
 <br/>
arr[] = {"{-5, 7, -3, -4, 9, 10, -1, 11}"}
<br/>
Output : 7 9 10 11 -5 -3 -4 -1

<br/><br/>



Constraints:
<br/>
1 ≤ N ≤ 10^5
<br/>
-10^5 ≤ arr[] ≤ 10^5<br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];

const BManipulation2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 19) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/5'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 19</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default BManipulation2;
