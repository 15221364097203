import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Test Question - 1',
      content: (
        <div>
          <p className="task-mvp">Test Question - 1</p>
         
                  
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p> 
                    Given an Array of Objects(Name,Salary). Return An Array containing Objects sorted in Descending Order of Salary using any of the sorting mechanism.
<br/>
<br/>

Objects is of Class Employee
<br/>
It contains two attributes --&gt; Name, Salary

<br/><br/>

Return the Array containing Objects sorted in Descending Order of Salary using any of the sorting mechanism.

<br/><br/>



Example:-
<br/>
Input Array Contains Object Having Name and Salary.
<br/>
Babu_Rao 150
<br/>
Raju 160
<br/>
Shyam 140
<br/>
Pappu 200

<br/><br/>

Output :-
<br/>
Pappu 200
<br/>
Raju 160
<br/>
Babu_Rao 150
<br/>
Shyam 140.             
<br/><br/>
                    </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Test Question - 2',
        content: (
          <div>
            <p className="task-mvp">Test Question - 2</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                     
                      An integer Arrays nums is provided and a target is provided. Return True if any element has occurred more than Target times else return False.

                      <br/><br/>

Solve it in 0(n) Time Complexity 
<br/><br/>


Example 1:-
<br/>
Input:-
<br/>
Arr - [9,5,3, 2,1,5,1,4,3,5,4,6,5]
<br/>
Target - 3

<br/><br/>

Output:-
<br/>
True

<br/><br/>

Explanation:- 
<br/>
5 has occurred 4 times (4&gt;Target ) therefore output is true.

<br/><br/>

Example 2:-
<br/>
Input:-
<br/>
Arr - [9,5,3, 2,1,5,1,4,3,5,4,6,5]
<br/>
Target - 5
<br/><br/>


Output:-
<br/>
False

<br/><br/>

Explanation:- 
<br/>
5 has occurred 4 times (4&lt;Target) therefore output is False.

<br/><br/>

Example 3:-
<br/>
Input:-
<br/>
Arr- [10,8,10,4,10,1]
<br/>
Target - 2
<br/>
Output:-
<br/>
True

<br/><br/>

Explanation:- 
<br/>
10 has occurred 3 times (3&gt;Target) therefore output is true.


<br/><br/>


Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= nums.length &lt;= 10^6<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0 &lt; nums[i], target &lt; 10^6
<br/><br/>
Please try to understand ths question properly and try to solve this question on your own if any problem occur contact your trainer<br/><br/> 
  Ans:- Output of Question 1 is 5 6 25 , Question 2 is undefined 25 5 5 and Question 3 is 25 25 undefined 5 .<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 3',
        content: (
          <div>
            <p className="task-mvp">Test Question - 3</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                      A Bad Number is defined by a number which is divisible by sum of its digit.
                      <br/>
Example1 :- 12 --&gt; Sum of digit (1+2) = 3. 12 is divisible by 3 therefore its a bad number
<br/>
Example2 :- 45 --&gt; Sum of digit (4+5) = 9. 45 is divisible by 9 therefore its a bad number
<br/>
11 is not a Bad (1+1 ) =2 Since 11 is not divisible by 2.

<br/><br/>

Question :-
<br/><br/>


Write a program to store first n Bad Numbers in an array . After storing return the array.﻿
<br/><br/>


Note :- 0-9 are not Bad Numbers so you can start counting from 10.
<br/><br/>


Input:-
<br/>
n=5
<br/>
Output:- Return the output in the form of an array.
<br/>
10
<br/>
12
<br/>
18
<br/>
20
<br/>
21
                     <br/><br/>
                     Please try to understand ths question properly and try to solve this question on your own if any problem occur contact your trainer<br/><br/> 
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 4',
        content: (
          <div>
            <p className="task-mvp">Test Question - 4</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                      A peak element is an element that is strictly greater than its neighbors.
                      <br/>
Given a 0-indexed integer array nums, find a peak element, and return its index. If the array contains multiple peaks, return the index to any of the peaks.
<br/>
You may imagine that nums[-1] = nums[n] = -∞. In other words, an element is always considered to be strictly greater than a neighbor that is outside the array.
<br/>
You must write an algorithm that runs in O(log n) time.

 
<br/><br/>
Example 1:
<br/>
Input: nums = [1,2,3,1]
<br/>
Output: 2
<br/>
Explanation: 3 is a peak element and your function should return the index number 2.
<br/>
Example 2:
<br/>
Input: nums = [1,2,1,3,5,6,4]
<br/>
Output: 5
<br/>
Explanation: Your function can return either index number 1 where the peak element is 2, or index number 5 where the peak element is 6.

<br/><br/>

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= nums.length &lt;= 1000<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-2^31 &lt;= nums[i] &lt;= 2^31 - 1<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;nums[i] != nums[i + 1] for all valid i.

<br/>  <br/>

Please try to understand ths question properly and try to solve this question on your own if any problem occur contact your trainer<br/><br/> 
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 5',
        content: (
          <div>
            <p className="task-mvp">Test Question - 5</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                      You are Building a website and for authentication there are some conditions for password ‘

                      <br/><br/>

1.All letters in this word are capitals, like "USA".
<br/>
2.All letters in this word are not capitals, like "sharpener".
<br/>
3.Only the first letter in this word is capital, like "Google".

<br/><br/>

Given a string password, return true if any on the above three rules in it.

<br/>
<br/>
﻿Example 1:
<br/>
Input: word = "USA"
<br/>
Output: true

<br/><br/>

Example 2:
<br/>
Input: word = "FlaG"
<br/>
Output: false

<br/><br/>

Hint :- Use Logic of Ascii Value
<br/>


&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= word.length &lt;= 100<br/>
word consists of lowercase and uppercase English letters.<br/><br/>
Please try to understand ths question properly and try to solve this question on your own if any problem occur contact your trainer<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 6',
        content: (
          <div>
            <p className="task-mvp">Test Question - 6</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                      A Teacher wants to revamp the roll numbers of students of his class . The previous roll numbers has a problem that some duplicate roll numbers are present .
                      <br/>
You have given an array of integers which represent roll numbers of students . You have to revamp such that no duplicate roll numbers should be there .
<br/>
You can increase the roll number by one at a time . you have to find minimum operation for revamping the array.
<br/><br/>


HINT :- Sort the Array and find a pattern.
<br/><br/>


Example 1:
<br/>
Input:
<br/>
N = 3
<br/>
arr[] = &#123;1, 2, 2&#125;
<br/>
Output: 1
<br/>
Explanation: If we increase arr[2] by 1 then the resulting array becomes &#123;1, 2, 3&#125; and has all unique values. Hence, the answer is 1 in this case.


<br/><br/>
Example 2:
<br/>
Input: 
<br/>
N = 4
<br/>
arr[] = &#123;1, 1, 2, 3&#125;
<br/>
Output: 3
<br/>
Explanation:  If we increase arr[0] by 3, then all array elements will be unique. Hence, the answer is 3 in this case.

<br/><br/>





Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 ≤ N ≤ 10^5
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 ≤ arr[i] ≤ 10^9

<br/><br/>
Please try to understand ths question properly and try to solve this question on your own if any problem occur contact your trainer<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];


const Tdsa2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 6) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/21'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 6</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Tdsa2;

