// SubmitHomework.js
import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SubmitHomework = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [homeworkContent, setHomeworkContent] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("https://eduyog.in/homework/submitted", {
        email,
        name,
        content: homeworkContent,
      });

      if (res.data.success) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="container homewor">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center mb-4 display-4 fw-normal">Submit Homework</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Homework Content:</label>
                  <textarea
                    rows="4"
                    className="form-control"
                    value={homeworkContent}
                    onChange={(e) => setHomeworkContent(e.target.value)}
                    required
                  ></textarea>
                </div>

                <button type="submit" className="btn btn-primary btn-block">
                  Submit Homework
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-8 offset-md-2">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Additional Information</h5>
              <p className="card-text">
                Make sure to fill in all the required fields. Feel free to provide any additional details
                about your homework in the "Homework Content" section.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-8 offset-md-2">
          <div className="alert alert-info">
            <p className="mb-0">
              Need help or have questions? Contact our support team at support@example.com.
            </p>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default SubmitHomework;
