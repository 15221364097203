import React from 'react';
import { useParams } from 'react-router-dom';

const YogaPosePage = () => {
  const { poseName } = useParams();

  // Use the poseName to fetch and display the content for the specific yoga pose
  if (poseName === 'adhomukhasvan-asana') {
    return (
      <div>
        <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Adho Mukha Svanasana</h2>
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://images.onlymyhealth.com/imported/images/2021/April/23_Apr_2021/adho_mukh_svanasana_inside.jpg" // Replace with your image URL or import the image using require
            alt="Downward Dog Pose"
            className="img-fluid rounded border border-primary"
          />
        </div>
        <div className="col-md-6">
          <p className="text-muted">
            The Adho Mukha Svanasana, also known as Downward Dog pose in English,
            is a fundamental yoga pose that helps stretch and strengthen various
            muscles. It is often included in both yoga and fitness routines for its
            numerous benefits.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Stretches and strengthens the entire body.</li>
            <li>Improves flexibility in the shoulders, hamstrings, and calves.</li>
            <li>Builds strength in the arms and legs.</li>
            <li>Calms the mind and relieves stress.</li>
            <li>Aids in digestion and can help alleviate back pain.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Downward Dog:</strong>
      </p>
      <ol className="text-dark">
        <li>Start on your hands and knees, with your wrists under your shoulders and knees under your hips.</li>
        <li>Tuck your toes, lift your hips towards the ceiling, and straighten your legs.</li>
        <li>Spread your fingers wide and press your palms into the mat.</li>
        <li>Engage your core and lengthen your spine.</li>
        <li>Keep your heels reaching toward the mat to feel a stretch in your calves and hamstrings.</li>
        <li>Hold the pose for 30 seconds to 1 minute, breathing deeply.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice this pose regularly to experience its full benefits. Remember to listen to your body and modify the pose as needed.
      </p>
    </section>
      </div>
    );
  }
  if (poseName === 'ashwasanchalan-asana') {
    return (
      <div>
          <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Ashwa Sanchalanasana</h2>
      <div className="row">
      <div className="col-md-6">
  <img
    src="https://i.postimg.cc/0y6T4PgY/aswaasana.jpg"
    alt="Ashwa Sanchalanasana Pose"
    style={{ height: '400px',width:'700px' }}
    className="img-fluid rounded border border-primary"
  />
</div>

        <div className="col-md-6">
          <p className="text-muted">
            Ashwa Sanchalanasana, also known as Equestrian or Horse Riding Pose,
            is a yoga pose that engages the lower body and helps improve balance
            and strength. It is a part of many yoga sequences and has various benefits.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Strengthens the legs, thighs, and hips.</li>
            <li>Improves balance and focus.</li>
            <li>Stretches the muscles in the groin and hip flexors.</li>
            <li>Aids in digestion and helps relieve sciatica pain.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Ashwa Sanchalanasana:</strong>
      </p>
      <ol className="text-dark">
        <li>Start in a high plank position with your wrists under your shoulders.</li>
        <li>Bring your right foot forward between your hands, placing it near the right hand.</li>
        <li>Lower your left knee to the mat and point your toes, keeping your back leg extended.</li>
        <li>Inhale as you lift your chest and gaze forward, engaging your core.</li>
        <li>Hold the pose for 30 seconds to 1 minute, breathing deeply.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice this pose regularly to experience its full benefits. Remember to maintain proper form and listen to your body.
      </p>
    </section>
      </div>
    );
  }
  if(poseName==='chakr-asana')
  {
    return(
      <div>
        <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Chakrasana (Wheel Pose)</h2>
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://i.postimg.cc/brNM9jH2/chakarasana.jpg" // Replace with your image URL or import the image using require
            alt="Chakrasana Pose"
            style={{ height: '400px',width:'700px' }}
            className="img-fluid rounded border border-primary"
          />
        </div>
        <div className="col-md-6">
          <p className="text-muted">
            Chakrasana, also known as Wheel Pose, is an invigorating backbend
            that opens the chest, strengthens the arms and legs, and increases
            flexibility in the spine. It is a powerful yoga pose with numerous benefits.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Strengthens the arms, wrists, legs, and spine.</li>
            <li>Stretches and opens the chest, shoulders, and hip flexors.</li>
            <li>Improves flexibility and mobility in the spine.</li>
            <li>Energizes the body and stimulates the nervous system.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Chakrasana:</strong>
      </p>
      <ol className="text-dark">
        <li>Lie on your back with your knees bent and feet hip-width apart.</li>
        <li>Place your hands on the floor beside your head, fingers pointing toward your shoulders.</li>
        <li>Press into your hands and feet, lifting your hips towards the ceiling.</li>
        <li>Extend your arms and legs, bringing the crown of your head to the floor.</li>
        <li>Hold the pose for 15-30 seconds, breathing deeply.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice this pose regularly to experience its full benefits. Ensure proper warm-up and listen to your body.
      </p>
    </section>
      </div>
    );
  }
  if(poseName==='ekapadaurdvhadhanur-asana'){
    return(
      <div>
        <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Ekapada Urdhva Dhanurasana (One-Legged Upward Bow Pose)</h2>
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://i.postimg.cc/g0FTBhHp/Ekapada-Urdvha-Dhanurasana.jpg" // Replace with your image URL or import the image using require
            alt="Ekapada Urdhva Dhanurasana Pose"
            style={{ height: '400px',width:'700px' }}
            className="img-fluid rounded border border-primary"
          />
        </div>
        <div className="col-md-6">
          <p className="text-muted">
            Ekapada Urdhva Dhanurasana, also known as One-Legged Upward Bow Pose,
            is an advanced backbend yoga pose that combines the benefits of Urdhva Dhanurasana
            (Wheel Pose) with the challenge of balancing on one leg.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Strengthens the arms, wrists, legs, and spine.</li>
            <li>Stretches and opens the chest, shoulders, and hip flexors.</li>
            <li>Improves balance and focus.</li>
            <li>Enhances flexibility in the spine and legs.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Ekapada Urdhva Dhanurasana:</strong>
      </p>
      <ol className="text-dark">
        <li>Begin in Urdhva Dhanurasana (Wheel Pose).</li>
        <li>Lift one leg towards the ceiling, extending it straight.</li>
        <li>Balance on the other foot and maintain the arch in your back.</li>
        <li>Hold the pose for 15-30 seconds, breathing deeply.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice this pose mindfully and gradually. Ensure proper warm-up and listen to your body.
      </p>
    </section>
      </div>
    );
  }
  if(poseName==='hanuman-asana'){
    return(
      <div>
          <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Hanumanasana (Monkey Pose)</h2>
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://i.postimg.cc/nz1gmL7J/Hanumanasana.jpg" // Replace with your image URL or import the image using require
            alt="Hanumanasana Pose"
            style={{ height: '400px',width:'700px' }}
            className="img-fluid rounded border border-primary"
          />
        </div>
        <div className="col-md-6">
          <p className="text-muted">
            Hanumanasana, also known as Monkey Pose or Splits, is a deep stretch
            that opens the hips, hamstrings, and groins. It is named after Lord Hanuman,
            who is known for his devotion and incredible leaps.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Stretches and strengthens the thighs, groins, and hamstrings.</li>
            <li>Improves hip flexibility and mobility.</li>
            <li>Strengthens the core muscles.</li>
            <li>Promotes balance and concentration.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Hanumanasana:</strong>
      </p>
      <ol className="text-dark">
        <li>Start in a low lunge position with your right foot forward.</li>
        <li>Slide your left leg back and straighten it, lowering your hips toward the mat.</li>
        <li>Extend your arms overhead or place them on the floor for support.</li>
        <li>Keep your chest lifted and square your hips to the front.</li>
        <li>Hold the pose for 30 seconds to 1 minute, breathing deeply.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice this pose with patience and respect for your body. Modify as needed and avoid overstretching.
      </p>
    </section>
      </div>
    );
  }
  if(poseName==='hastapad-asana'){
    return(
      <div>
         <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Hastapadasana (Standing Forward Bend)</h2>
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://i.postimg.cc/28tJhMqV/hastapadasana.jpg" // Replace with your image URL or import the image using require
            alt="Hastapadasana Pose"
            style={{ height: '700px',width:'600px' }}
            className="img-fluid rounded border border-primary"
          />
        </div>
        <div className="col-md-6">
          <p className="text-muted">
            Hastapadasana, also known as Standing Forward Bend, is a simple yet effective
            yoga pose that provides a deep stretch to the entire back of the body. It
            helps improve flexibility in the spine, hamstrings, and calves.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Stretches the entire back of the body, from the heels to the spine.</li>
            <li>Improves flexibility in the spine, hamstrings, and calves.</li>
            <li>Calms the mind and relieves stress.</li>
            <li>Activates the abdominal muscles and aids digestion.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Hastapadasana:</strong>
      </p>
      <ol className="text-dark">
        <li>Stand with your feet hip-width apart and your arms alongside your body.</li>
        <li>Inhale and lift your arms overhead.</li>
        <li>Exhale as you hinge at your hips and fold forward, bringing your hands towards the floor.</li>
        <li>Keep your spine long and chest reaching towards your thighs.</li>
        <li>Hold the pose for 30 seconds to 1 minute, breathing deeply.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice this pose gently, especially if you have any existing back or hamstring issues. Listen to your body and avoid overstretching.
      </p>
    </section>
      </div>
    );
  }
  if(poseName==='sarvang-asana'){
    return(
      <div>
        <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Sarvangasana (Shoulder Stand)</h2>
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://i.postimg.cc/9QqkpbT1/sarvangasana.jpg" // Replace with your image URL or import the image using require
            alt="Sarvangasana Pose"
            style={{ height: '600px',width:'600px' }}
            className="img-fluid rounded border border-primary"
          />
        </div>
        <div className="col-md-6">
          <p className="text-muted">
            Sarvangasana, or Shoulder Stand, is an inverted yoga pose that offers
            a range of benefits for the entire body. It strengthens the shoulders,
            neck, and core while promoting circulation and calming the nervous system.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Strengthens the shoulders, neck, and upper back.</li>
            <li>Improves blood circulation and nourishes the thyroid gland.</li>
            <li>Stimulates the abdominal organs and aids digestion.</li>
            <li>Calms the mind and relieves stress.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Sarvangasana:</strong>
      </p>
      <ol className="text-dark">
        <li>Lie on your back and lift your legs overhead, bringing them beyond your head.</li>
        <li>Support your lower back with your hands and lift your legs towards the ceiling.</li>
        <li>Bring your hands to your back for support and straighten your spine.</li>
        <li>Hold the pose for 30 seconds to 2 minutes, breathing deeply.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice Sarvangasana with caution, especially if you have neck or back issues. Avoid the pose during menstruation or if you have high blood pressure.
      </p>
    </section>
      </div>
    );
  }
  if(poseName==='sirs-asana'){
    return(
      <div>
          <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Sirsasana (Headstand)</h2>
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://i.postimg.cc/0QvhrSzn/shrisasana.jpg" // Replace with your image URL or import the image using require
            alt="Sirsasana Pose"
            style={{ height: '600px',width:'600px' }}
            className="img-fluid rounded border border-primary"
          />
        </div>
        <div className="col-md-6">
          <p className="text-muted">
            Sirsasana, or Headstand, is a challenging inversion that offers a wide
            range of benefits for the body and mind. It strengthens the core,
            improves balance, and enhances focus and concentration.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Strengthens the shoulders, arms, and core muscles.</li>
            <li>Improves balance and stability.</li>
            <li>Increases blood flow to the brain, improving mental alertness.</li>
            <li>Stimulates the lymphatic system and boosts immune function.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Sirsasana:</strong>
      </p>
      <ol className="text-dark">
        <li>Start in a kneeling position and interlace your fingers, placing the forearms on the mat.</li>
        <li>Place the crown of your head on the mat, cradling it with your interlaced fingers.</li>
        <li>Walk your feet towards your head, lifting your hips towards the ceiling.</li>
        <li>Slowly lift one leg, then the other, until your body is in a straight line.</li>
        <li>Hold the pose for 30 seconds to 2 minutes, breathing deeply.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice Sirsasana under the guidance of an experienced teacher, especially if you are a beginner. Avoid the pose if you have neck or back issues, high blood pressure, or during menstruation.
      </p>
    </section>
      </div>
    );
  }
  if(poseName==='siddh-asana'){
    return(
      <div>
        <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Siddhasana (Adept Pose)</h2>
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://i.postimg.cc/fy2PHgBq/sidhasana.jpg" // Replace with your image URL or import the image using require
            alt="Siddhasana Pose"
            style={{ height: '500px',width:'600px' }}
            className="img-fluid rounded border border-primary"
          />
        </div>
        <div className="col-md-6">
          <p className="text-muted">
            Siddhasana, or Adept Pose, is a seated meditation posture with a focus
            on aligning the spine and opening the energy channels. It is commonly
            used for pranayama and meditation practices.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Promotes a straight and aligned spine.</li>
            <li>Calms the mind and aids in meditation.</li>
            <li>Stimulates energy flow in the body.</li>
            <li>Encourages a grounded and centered feeling.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Siddhasana:</strong>
      </p>
      <ol className="text-dark">
        <li>Sit on the floor with your legs extended in front of you.</li>
        <li>Place the right heel at the perineum (area between the genitals and anus).</li>
        <li>Place the left foot on top of the right foot, with the left heel against the pubic bone.</li>
        <li>Keep the spine straight and the hands on the knees in a mudra or meditative gesture.</li>
        <li>Hold the pose for meditation or pranayama, breathing naturally.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice Siddhasana with awareness and comfort. Adjust the pose as needed and avoid it if you experience discomfort or pain.
      </p>
    </section>
      </div>
    );
  }
  if(poseName==='vriksh-asana'){
    return(
      <div>
         <section className="container mt-5">
      <h2 className="text-center mb-4 text-primary">Vrikshasana (Tree Pose)</h2>
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://i.postimg.cc/7YhdCwZF/varkshaasana.jpg" // Replace with your image URL or import the image using require
            alt="Vrikshasana Pose"
            style={{ height: '600px',width:'600px' }}
            className="img-fluid rounded border border-primary"
          />
        </div>
        <div className="col-md-6">
          <p className="text-muted">
            Vrikshasana, or Tree Pose, is a standing balance pose that mimics the
            steady and graceful qualities of a tree. It improves focus, balance,
            and strength in the legs and core.
          </p>

          <p className="text-success">
            <strong>Benefits:</strong>
          </p>
          <ul className="list-unstyled">
            <li>Improves balance and concentration.</li>
            <li>Strengthens the muscles in the legs and core.</li>
            <li>Stretches the thighs, groins, and torso.</li>
            <li>Calms the mind and promotes a sense of stability.</li>
          </ul>
        </div>
      </div>

      <p className="mt-4 text-info">
        <strong>How to Do Vrikshasana:</strong>
      </p>
      <ol className="text-dark">
        <li>Begin standing with your feet together.</li>
        <li>Shift your weight onto the left foot, lift the right foot, and place it on the inner left thigh or calf (avoid the knee).</li>
        <li>Bring your palms together in front of your chest in a prayer position.</li>
        <li>Gaze at a fixed point to help with balance.</li>
        <li>Hold the pose for 30 seconds to 1 minute, breathing deeply.</li>
      </ol>

      <p className="mt-4 text-danger">
        Practice Vrikshasana with a focus on stability and ease. Avoid placing the foot on the knee if it causes discomfort, and use a wall for support if needed.
      </p>
    </section>
      </div>
    );
  }
   else {
    return (
      <>
        <p>Hello</p>
      </>
    );
  }
};

export default YogaPosePage;
