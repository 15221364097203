// import React from 'react';
// import { GiGraduateCap } from "react-icons/gi";
// import { MdRocketLaunch } from "react-icons/md";
// import { IoBagCheck, IoNewspaperOutline } from "react-icons/io5";
// import { FaFolderClosed } from "react-icons/fa6";
// import { MdPeopleOutline } from "react-icons/md";

// export default function Works() {
//   const headingStyle = "display-8 gradien  text-white animated-gradient";
//   const lineStyle = "vertical-line";
//   const iconStyle = "w-2";
//   const buttonStyle = "btn px-3 py-2 fw-bold btn-primary";

//   return (
//     <>
//       <h1 className={headingStyle} id="w-0">How EduYog Works</h1>
//       <div className='w-1'>
//         <div>
//           <div>
//             <h5>Enter the world of opportunities</h5>
//             <p>Sign up on the platform and watch the onboarding videos to get a clear understanding of the platform and the entire journey.</p>
//           </div>
//           <div>
//             <h3 className={headingStyle}>Qualify<GiGraduateCap className={iconStyle} /></h3>
//           </div>
//           <div>
//             <h5>Sign India's most pocket/student-friendly ISA</h5>
//             <p>If you qualify, sign the ISA (Income Share Agreement) which legally confirms that you will pay the amount after you get a job.</p>
//             <button type='button' className={buttonStyle}>Know Details</button>
//           </div>
//           <div>
//             <h3 className={headingStyle}>Start Your Journey<MdRocketLaunch className={iconStyle} /></h3>
//           </div>
//           <div>
//             <h5>Shine in interviews with expert guidance</h5>
//             <p>Once your mentor feels you are ready, it's time for interviews! Share your preferences, if any, and grab a job to start your career.</p>
//           </div>
//           <div>
//             <h3 className={headingStyle}>Get Placed<IoBagCheck className={iconStyle} /></h3>
//           </div>
//         </div>
//         <div>
//           <h1 className={lineStyle} id='linee'></h1>
//         </div>
//         <div>
//           <div>
//             <h3 className={headingStyle}> <FaFolderClosed className={iconStyle} />Sign Up</h3>
//           </div>
//           <div>
//             <h5>Consistency is what we look for</h5>
//             <p>EduYog doesn't evaluate candidature on the basis of just one test. You have to gain a minimum set of points in the first 2 weeks to prove that you are worth it.</p>
//           </div>
//           <div>
//             <h3 className={headingStyle}><IoNewspaperOutline className={iconStyle} />Sign ISA</h3>
//           </div>
//           <div>
//             <h5>Learn from the best in the market.</h5>
//             <ol type='1'>
//               <li>Live doubt sessions</li>
//               <li>Recorded theory lectures</li>
//               <li>Routine exams to monitor progress</li>
//               <li>Regular interview practice drills</li>
//               <li>Multiple industry projects</li>
//             </ol>
//           </div>
//           <div>
//             <h3 className={headingStyle}><MdPeopleOutline className={iconStyle} />Crack Interviews</h3>
//           </div>
//           <div>
//             <h5>Get the job/package you deserve</h5>
//             <p>Only after you get a job, EduYog begins to collect the small amounts promised in ISA, while you enjoy a lifetime of success!</p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import React from 'react';
import { GiGraduateCap } from "react-icons/gi";
import { MdRocketLaunch } from "react-icons/md";
import { IoBagCheck, IoNewspaperOutline } from "react-icons/io5";
import { FaFolderClosed } from "react-icons/fa6";
import { MdPeopleOutline } from "react-icons/md";
import { GiOnTarget } from "react-icons/gi";

export default function Works() {
  return (
    <div className="container-fluid bg-white py-5 about-before">
      <div className="container">
        <h1 className="display-4 text-center mb-5 fw-bold">How EduYog Works</h1>
        <div className="row justify-content-center">
          <Step
            title="Enter the world of opportunities"
            text="Sign up on the platform and watch the onboarding videos to get a clear understanding of the platform and the entire journey."
            icon={<GiOnTarget  className='large-icon'/>}
          />
          <Step
            title="Sign Up"
            text="EduYog doesn't evaluate candidature on the basis of just one test. You have to gain a minimum set of points in the first 2 weeks to prove that you are worth it."
            icon={<FaFolderClosed className="icon large-icon" />}
          />
          <Step
            title="Qualify"
            text="If you qualify, sign the ISA (Income Share Agreement) which legally confirms that you will pay the amount after you get a job."
            icon={<GiGraduateCap className="icon large-icon" />}
          />
          <Step
            title="Sign ISA"
            text="EduYog doesn't evaluate candidature on the basis of just one test. You have to gain a minimum set of points in the first 2 weeks to prove that you are worth it."
            icon={<IoNewspaperOutline className="icon large-icon" />}
          />
          <Step
            title="Start Your Journey"
            text="Once your mentor feels you are ready, it's time for interviews! Share your preferences, if any, and grab a job to start your career."
            icon={<MdRocketLaunch className="icon large-icon" />}
          />
          <Step
            title="Crack Interviews"
            text="Only after you get a job, EduYog begins to collect the small amounts promised in ISA, while you enjoy a lifetime of success!"
            icon={<MdPeopleOutline className="icon large-icon" />}
          />
          <Step
            title="Get Placed"
            text="Once you get a job, EduYog begins to collect the small amounts promised in ISA, while you enjoy a lifetime of success!"
            icon={<IoBagCheck className="icon large-icon" />}
          />
        </div>
      </div>
    </div>
  );
}

const Step = ({ title, text, icon }) => (
  <div className="col-md-4 mb-4">
    <div className="card h-100">
      <div className="card-body d-flex flex-column align-items-center">
        <div className="text-center mb-4">{icon}</div>
        <h3 className="fs-3 card-title text-center mt-3">{title}</h3>
        <p className="card-text text-center">{text}</p>
      </div>
    </div>
  </div>
);
