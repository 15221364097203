import React from 'react';
import { Link } from 'react-router-dom';
import movie1 from '../../video/movie1.mp4';
import movie2 from '../../video/movie2.mp4';
import movie3 from '../../video/movie3.mp4';


const DareItem = ({ imageUrl, altText, title }) => (
  <span>
    <img src={imageUrl} alt={altText} />
    <h4>{title}</h4>
  </span>
);

const Another = () => (
  <>
    <DareItem imageUrl='https://sharpener.blob.core.windows.net/assignmentimages/aeedc8cb-b811-4618-b6a8-3c82c0fcb689_sword.png' altText='Image here' title='Dare to Dream' />
    <span className="divider"></span>
    <DareItem imageUrl='https://sharpener.blob.core.windows.net/assignmentimages/747728b8-1765-40bc-acba-acc870505cdb_info.png' altText='Image here' title='About EduYog' />
    <span className="divider"></span>
    <DareItem imageUrl='https://sharpener.blob.core.windows.net/assignmentimages/934e67ab-afe5-465f-a65f-ce3ec159862c_baloons.png' altText='Image here' title='Journey Begins' />
  </>
);

const DareTo = () => (
  <>
    <div className='Dare0'>
      <Link to="/placement/dashboard">
        <h3>EduYog</h3>
      </Link>
    </div>
    <div className='Dare1'>
      <Another />
    </div>
    <div className='Dare2'>
      <div>

        <video
          src={movie3}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          controls // Display video controls
        />
      </div>
      <div className='world'>
        <p>
          The world has completely changed in the last 20 years, thanks to the internet and all that it has done for humanity. But you have one community who have played a phenomenal role in this journey -
          <br />Developers.<br /><br />
          Let's look at its journey and seek inspiration.<br /><br />
          All the best<br /><br />
          <strong> Let's Dare to dream!</strong>
          <br />
          <Link to="/placement/dashboard/about"><button type='button' className='btnn'>Video has Inspired me!</button></Link>
        </p>
      </div>
    </div>
  </>
);

const About = () => (
  <>
    <div className='Dare0'>
      <Link to="/placement/dashboard">
        <h3>EduYog</h3>
      </Link>
    </div>

    <div className='Dare1' id="Dare11">
      <Another />
    </div>
    <div className='Dare2'>
    <video
          src={movie2}
          style={{ width: '450px', height: '450px', objectFit: 'contain' }}
          controls // Display video controls
        />
      <div className='world'>
        <p>
          The world has completely changed in the last 20 years, thanks to the internet and all that it has done for humanity. But you have one community who have played a phenomenal role in this journey -
          <br />Developers.<br /><br />
          Let's look at its journey and seek inspiration.<br /><br />
          All the best<br /><br />
          <strong> Let's Dare to dream!</strong><br/>
          <div className='div3'>
      <span><Link to="/placement/dashboard/about1"><button type='button' className='btnn'>This is awesome!</button></Link></span>
      <span ><Link to="/placement/dashboard/DareTo"><p className='div1'>Back</p></Link></span>
    </div>
        </p>
      </div>
    </div>
  
  </>
);

const About1 = () => (
  <>
    <div className='Dare0'>
      <Link to="/placement/dashboard">
        <h3>EduYog</h3>
      </Link>
    </div>

    <div className='Dare1' id="Dare11" >
      <Another />
    </div>
    <div className='Dare2' id="div3">
    <video
          src={movie1}
          style={{ width: '250px', height: '430px', objectFit: 'contain' }}
          controls // Display video controls
        />
      <div className='world'>
        What's special about EduYog?
        <br />
        <br />
        People often ask us "Why is EduYog loved while other big ed-tech platforms are constantly doubted?"
        <br /><br />
        Well, that is because at EduYog, we prioritise on learning and not blindly completing the syllabus!
        <br /><br />
        Check out this video to know how to go about using EduYog.
        <br/>
        <span><Link to="/placement/dashboard/index1"><button type='button' className='btnn'>EduYog is unique!</button></Link></span>
      <span ><Link to="/placement/dashboard/about"><p className='div1'>Back</p></Link></span>

      </div>
     
      
    </div>
    
  </>
);

const Index1 = () => (
  <>
    <div className='Dare0'>
      <Link to="/placement/dashboard">
        <h3>EduYog</h3>
      </Link>
    </div>

    <div class='Dare1' id="Dare22">
      <Another />
    </div>
    <div class='Dare2' id="dare15">
      <p className='world'>
        Awesome! You made it this far!
        <br /><br />


        Now let's start with some hard work, right?

        <br /><br />

        You might be having some doubts which you can ask us on Whatsapp, or during a live interaction with our founder.
        <br /><br />


        But don't wait, get started and you can do that later on! Click on the button below and join thousands of others like you!<br /><br />

        All the best!!!
        <br/>
        <span><Link to="/placement/dashboard"><button type='button' className='btnn'>Let's get started</button></Link></span>
      <span ><Link to="/placement/dashboard/about1"><p className='div1'>Back</p></Link></span>
      </p>
    </div>
    <div className='div3'>
      
    </div>
  </>
);

export { DareTo, About, About1, Index1 };
