import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
  {
    title: 'Secret Password for Discord',
    content: (
      <div>
        <p className="task-mvp">Secret Password for Discord</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 
                  IMPORTANT - How does this work?
<br/>
Check it here: <a href="https://youtu.be/rnYGrq95ezA?si=7H8zvpcB8czfEUvi" target='_blank'>
Click here&nbsp;
                        </a>

<br/><br/>

You have unlocked a new level -&gt; JS - 3 ,



Tell the Discord 'Role Bot' the secret password to access the channel!

<br/>

Secret Password:- " "
<br/>

Access EduYog's Discord server here - <a href="">
Click here&nbsp;
                        </a><br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Stacks',
    content: (
      <div>
        <p className="task-mvp">Stacks</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 
                  Complete the function pushIntoStack and popFromStack to complete the Stack class.

<br/>
<br/>
pushIntoStack function
<br/>
It takes the value and pushes it into the stack
<br/>
This function doesnt return anything

<br/><br/>

 popFromStack function
 <br/>
     It returns the last element of the stack 
     <br/>
    if no element present in stack , it should return -1
    <br/>
    <br/>

Implement Stack (Please dont touch this function)
<br/> <br/>


It creates a new Stack with the user inputted values
<br/>
const stack1 = new Stack(arr)

<br/> <br/>

Then we test the stack functions if they are working or not by calling the methods in the sequence shown below.
<br/>
  stack1.pushIntoStack(1)
  <br/>
   console.log(stack1.popFromStack())
   <br/>
   console.log(stack1.popFromStack())
   <br/>
   console.log(stack1.popFromStack())
   <br/>
   stack1.pushIntoStack(1)
   <br/>
   console.log(stack1.popFromStack())
   <br/>
   console.log(stack1.popFromStack())
   <br/> <br/>
 



Example Input  1
<br/> <br/>


3,4,5,7,8

<br/>
<br/>
Ouptput
<br/>
1
<br/>
8
<br/>
7
<br/>
1
<br/>
5
<br/> <br/>


Example Input  2
<br/>
3,2

<br/> <br/>

Output
<br/>
1
<br/>
2
<br/>
3
<br/>
1
<br/>
-1
<br/> <br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Queue',
    content: (
      <div>
        <p className="task-mvp">Queue</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 
 <a href="https://www.geeksforgeeks.org/introduction-and-array-implementation-of-queue/" target='_blank'>
Implement Queue from Scratch&nbsp;
                        </a>
<br/>
<br/>
Watch this video to have a better understanding of how to design using classes - 
<a href="https://youtu.be/LbAKOE5_Du4?si=6Bab5lLzlnMPOYDd" target='_blank'>
Click here&nbsp;
                        </a>
<br/><br/>
Write the code in Javascript
<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'What are callbacks?',
    content: (
      <div>
        <p className="task-mvp">What are callbacks?</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 
 

Go through this video (Watch uptill 11th min)
<br/>

<a href="https://www.youtube.com/embed/PoRJizFvM7s" target='_blank'>
Click here&nbsp;
                        </a>
<br/><br/>
Deliverable (Watch uptill 11th min from starting)
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Implement the code as per the youtuber<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Make a new function call create4thPost for adding one more new post "Post Four". create4thPost should take createPost as a callback function. Once the post is created all the posts should be displayed too.- I have done most of the work .
 You just need to fill in the blanks smartly such that the code works.
<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'The main problem which callback solves',
    content: (
      <div>
        <p className="task-mvp">The main problem which callback solves</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 
 

                  What do you think are the main problems with callbacks?
                  <br/>
Watch <a href="https://www.youtube.com/embed/yEKtJGha3yM" target='_blank'>
this&nbsp;
                        </a>video to understand it better.

<br/><br/>
To understand please debug and fix the way the functions call each other such the output is
<br/>
Expected Output
<br/>
Post Three
<br/>
Post Four
<br/><br/>


Current output
<br/>
Post Four
<br/>
Post Three
<br/><br/>


Only modify the last two lines.
<br/><br/>


Hint --&gt; You have to use callback function smartly.
<br/><br/>


Do not modify the functions above at any cost...
<br/><br/>


Check hints if stuck for more than 30 min. But first try by yourself.
<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Creating a callback hell yourself',
    content: (
      <div>
        <p className="task-mvp">Creating a callback hell yourself</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 
 
To understand please debug and fix the way the functions call each other such the output is
<br/>
Expected Output
<br/>
Post Three
<br/>
Post Four
<br/>
Post Five
<br/><br/>


Current output
<br/>
Post Five
<br/>
Post Four
<br/>
Post Three
<br/><br/>


Only modify the last two lines.
<br/><br/>


Hint --&gt; You have to use callback function smartly. and modify create4thpost such that it can take callbacks like create3rdpost
<br/><br/>

Check hints if stuck for more than 30 min. But first try by yourself.
<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Lets make Promises - Part 1',
    content: (
      <div>
        <p className="task-mvp">Lets make Promises - Part 1</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

Watch <a href="https://www.youtube.com/embed/PoRJizFvM7s?t=665s" target='_blank'>
this&nbsp;
                        </a>video to understand it better.

<br/><br/>
Resources
<br/>
<br/>


1) Watch uptill first 15:30 ,  create a promise as per the youtuber does
<br/>
2) Why do we even need promises - (What is the main advantage). Lets plan a trip together to Manali and understand promises. Watch this video XD.
<br/>

<br/>
Deliverables
<br/>
The code given has 2 functions called create3rdpost and create4thpost
<br/>
The functions return a promise and everything looks good.
<br/>
Only problem is if you run the code , the sequence in which posts get printed is incoorect. Post 4 and Post 3 dont get printed only.
<br/><br/>


Expected Output
<br/>
Post One
<br/>
Post Two
<br/>
post Three
<br/>
post Four
<br/><br/>


Hint - Use promise and .then smartly.. Last 3 lines of the code are wrong. Only change them and the code will work fine.

<br/><br/>

If stuck for more than 40 min check the hints, but first try by yourself<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Lets make Promises - Part 2',
    content: (
      <div>
        <p className="task-mvp">Lets make Promises - Part 2</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                  Deliverables
<br/>
The code given has 2 functions called create3rdpost and create4thpost which are complete.

<br/><br/>

Complete the create5thpost function such that it returns a promise which creates post five after a timeout  of 2 seconds

<br/><br/>



Expected Output
<br/>
POST1
<br/>
POST2
<br/>
POST3
<br/>
POST4
<br/>
POST5
<br/><br/>


Hint - Use promise and .then smartly.. Last 4 lines of the code are also wrong. Change them too and the code will work fine.

<br/><br/>

If stuck for more than 40 min check the hints, but first try by yourself<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Lets break Promise Part 1',
    content: (
      <div>
        <p className="task-mvp">Lets break Promise Part 1</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                  We have been creating a lot of posts using promises.
<br/>
But now the user wants to deletepost from the post array.

<br/><br/>


Deliverable 1
<br/><br/>


So we need to design a deletepost function which returns a promise and deletes in 1 second (processing time - mimic it with setimeout).
<br/>
Everytime you call it, it should delete the last element of the array
<br/><br/>


Deliverable 2 - The sequence

<br/><br/>

User did not like his 3rd post and he wants to delete it and only after deleting he wants to create a 4th post .

<br/><br/>

So it would be like
<br/>
create3rdost --&gt; then --&gt; deletepost --&gt; then create4thpost --&gt; then --&gt; printposts

<br/><br/>

Expected Output
<br/>
POST1<br/>

POST2<br/>

POST4<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Lets break Promise Part 2',
    content: (
      <div>
        <p className="task-mvp">Lets break Promises Part 2</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                  We need to understand the use of resolve and reject.

<br/>
<br/>
The Love story:-
<br/><br/>


Imagine you make a promise to your girlfriend that you will buy her a watch on her Birthday.
<br/>
She says that she will go on a date with you after she gets the watch.
<br/>
But if he breaks the promise => she say that "she will breakup with him"
<br/><br/>


2 days before her birthday
<br/>
Since you have made a promise for her birthday, you will not resolve it before her birthday.
<br/>
So girlfriend has a pending promise and you also don't have a date.

<br/><br/>

1 day before her birthday
<br/>
The girlfriend is still waiting for the promise to resolve. She is very excited

<br/><br/>

On her birthday.
<br/>
2 things can happen.
<br/>
<br/>

You resolve the promise with the watch --&gt; resolve(watch) --&gt; .then((watch) => seeing the watch your girlfriend take you on the best date)


<br/><br/>
You reject the promise with some excuse(Error situation) --&gt; reject('Error: Out of budget')--&gt; .catch((errormessage) => she looks at the errormessage and she breaks up with him)

<br/><br/>

So folks its super important to handle both --&gt; rejection as well as resolve
<br/><br/>



Deliverable 
<br/>
Look at the code which is provided
<br/>
<br/>

This is the sequence in which it should be executed. Check the last 5 lines
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. After breakup the boy logs ino facebook.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.He calls create2ndpost to create post2<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. After creating2ndpost , the user wants to deletes the 2nd post and print it.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. After creating3rdpost , the user wants to deletes the 3rd post and print it.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. Now he goes mad and he deletes all the posts and prints each one of them, till nothing remains.<br/>

<br/><br/>
We want to print all the post which we have deleted in the  sequence in which they got deleted.

<br/><br/>

OUTPUT
<br/>
POST2
<br/>
POST3
<br/>
POST1
<br/>
ERROR: ARRAY IS EMPTY
<br/><br/>


You are only allowed to touch the last 5 lines.
<br/>
Do not touch any other function!!!<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Lets make and break promises',
    content: (
      <div>
        <p className="task-mvp">Lets make and break promises</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                  Deliverable 
                  <br/>
The boy has deleted everything which he had on facebook.
Now he logs into linkedin to write some inspiring blogs
<br/><br/>


This is the sequence in which it should be executed. Check the last 5 lines
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. After breakup the boy logs ino linkedin.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. He calls create1stblog to create his 1st blog<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. He calls create2ndblog to create his 2nd blog<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.Now he goes mad and he deletes all the blog, till nothing remains.<br/>

<br/>
We want to print all the post which we have deleted in the following sequence.

<br/><br/>

OUTPUT<br/>

BLOG2
<br/>
BLOG1
<br/>
ERROR: ARRAY IS EMPTY
<br/><br/>


You are only allowed to touch the last 5 lines.
<br/>
Do not touch any other function!!!
<br/>


[use the previous question as reference]<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  
  {
    title: 'Too many promises in life',
    content: (
      <div>
        <p className="task-mvp">Too many promises in life</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 
                  Watch the video from 
                  <a href="https://www.youtube.com/embed/PoRJizFvM7s?t=925s" target='_blank'>&nbsp;
here&nbsp;
                        </a>
                   uptill 18:30 min and implement the code which the youtuber does based on promise.all<br/><br/>
                   Deliverable -
<br/><br/>
1. I want you to create a new function called updateLastUserActivityTime which returns a promise, we will use this function to update the user's last activity time, 
and when it will resolve, it should send back the updated last activity time for the user (it should take 1 second to execute).<br/>
2. Every time the user creates a post, updateLastUserActivityTime should be called so that the user's last activity time gets updated.<br/>
3. When both the promises resolve (createPost and updateLastUserActivityTime ),<br/>
4. I want you to console log all the posts and lastActivityTime of the user. [If stuck for long watch the hint 2]<br/>
5. Once both the above promises are resolved, I want you to delete the last post by calling the deletePost promise. Once successfully deleted, I want you to log the remaining Posts.
<br/><br/>

Common Questions Interviewers ask
<br/>
Why on Earth do we need promise.all ?
<br/>
 Watch this video and answer this question in your own words.
 <br/><br/>




Push the code and upload the commit ID<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Async Await',
    content: (
      <div>
        <p className="task-mvp">Async Await</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 
                  Watch this video 
                  <a href="https://www.youtube.com/embed/IGoAdn-e5II" target='_blank'>&nbsp;
here&nbsp;
                        </a>
                  We need to understand the use of resolve and reject.

<br/>
<br/>
Deliverable
<br/>
1. Watch uptill the 18th min and write down the code as per the youtuber does both the pronises and asyn await<br/>
2. Now create a new promise called getColdDrinks which come after husband gets butter. Integrate the code in both async and await and also promises' code<br/>
3. Continue watching from the 18th min to 23rd min and understand how to handle Promise.all with async await<br/>
4. Convert the createPost , deletePost you wrote previously into async await completely. If stuck for long watch hint 1.<br/>

<br/><br/>



[Favourite Interview Question] - Why were promises discovered?
<br/>
Why are async await better than promise.then design pattern? ? Explain with an example.
<br/>
1. Lets plan a trip together to Manali and understand promises.<br/>
2. Write your own answer with an example<br/><br/>


Push the code to git and upload the commit IDs<br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Understanding how to use POSTMAN for calling APIs',
    content: (
      <div>
        <p className="task-mvp">Understanding how to use POSTMAN for calling APIs</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                 
                        To make our website Dynamic in nature we need a backend. But currently we dont know how to make backend api endpoints.

                        <br/><br/>

What is a REST API ?
<br/><br/>


Watch this video to understand what a REST API is  <a href="https://www.youtube.com/watch?v=qVTAB8Z2VmA" target='_blank'>&nbsp;
here&nbsp;
                        </a>

                        <br/><br/>

Also these apis return a JSON response.

<br/><br/>

What is JSON now?
<br/><br/>


Watch this video to understand that <a href="https://www.youtube.com/watch?v=JuFdz8f-cT4" target='_blank'>&nbsp;
here&nbsp;
                        </a>


                        <br/><br/>
We can simply make POST, PUT , GET and Delete request on this easily.

<br/><br/>

 Install POSTMAN from the internet. Follow any youtube video.(Max 20 min)


 <br/><br/>


Make your first GET request
<br/>
Watch this video - <a href="https://www.youtube.com/watch?v=cR_FqveTewo" target='_blank'>&nbsp;
here&nbsp;
                        </a>

                        <br/><br/>

What are collections , make collection 2 like the way the youtuber does and attach screenshot (video 4)
<br/>
Watch this video - <a href="https://www.youtube.com/watch?v=BExXvMHi3mk" target='_blank'>&nbsp;
here&nbsp;
                        </a>
                        <br/>
How to run a collection and download the result
<br/>
Watch this video - <a href="https://www.youtube.com/watch?v=BExXvMHi3mk" target='_blank'>&nbsp;
here&nbsp;
                        </a>

                        <br/><br/>

How to make POST request
<br/>
Watch this video - <a href="https://www.youtube.com/watch?v=rZILP_3OP-A" target='_blank'>&nbsp;
here&nbsp;
                        </a>

                        <br/><br/>

Once done take a sceenshot and send to trainer on whatsapp


<br/><br/>


Going forward you will not be getting points for the tasks which you do. 
<br/>
But dont worry there would be demos ahead in which mentor will check you progress manually.
                  <br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Intro to crudcrud apis and making your first POST request',
    content: (
      <div>
        <p className="task-mvp">Intro to crud apis and making your first POST request</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                 
                  To make our website Dynamic in nature we need a backend. But currently we dont know how to make backend api endpoints.

<br/>
<br/>
What do we do now???
<br/><br/>


Enter crudcrud.com which provides us a backend (REST API ) for free.
<br/><br/>


We can simply make POST, PUT , GET and Delete request on this easily.
<br/>
Watch this video to understand how CRUD works
<br/>
Watch this video - <a href="https://youtu.be/e5Tnmw_ie_w?si=Z2J8HEB955AHNJpF" target='_blank'>&nbsp;
here&nbsp;
                        </a>

<br/><br/>

Deliverable

<br/>
1) Lets Experiment and understand POSTMAN better. Install POSTMAN from the internet. Follow any youtube video.(Max 20 min)

<br/><br/>

Guys try to make a POST request to crudcrud via POSTMAN. Take a screenshot when u get success<br/>
GET the object which you posted.Take a screenshot when u get success<br/>
UPDATE the object and change the emailID of the person to new value. Take a screenshot when u get success<br/>
DELETE the object from crudcrud.Take a screenshot when u get success<br/>

<br/>
Take all these screenshots and upload them on google drive.
<br/>
Share the links here for the admin to check your answer.
<br/>

                  <br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Understanding how to make api calls (Axios)',
    content: (
      <div>
        <p className="task-mvp">Understanding how to make api calls (Axios)</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                  Go to this url <a href="https://github.com/prasadyash2411/axios-crash" target='_blank'>&nbsp;
click here&nbsp;
                        </a> and do the following
                        <br/>
Click on Fork button at the top right(It means you are duplicating the code into your git repo)<br/>
Once forked clone/download the code as per your convenience<br/>

<br/>
Now start watching this video
<br/>


<a href="https://www.youtube.com/watch?v=6LyagkoRWYA" target='_blank'>&nbsp;
click here&nbsp;
                        </a>
                        <br/>   <br/>
Deliverable
<br/><br/>


Implement all the commented functions in the code and understand them properly
<br/>

Understand the code that you are writing as we would be using them in the next few tasks. Alway use axios for network calls.                 
Push the code to git and upload the commit ID


<br/><br/>


Going forward you will not be getting points for the tasks which you do.
<br/>
But dont worry there would be demos ahead in which mentor will check you progress manually.
                  <br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Saving the user Details on Crud Crud',
    content: (
      <div>
        <p className="task-mvp">Saving the user Details on Crud Crud</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

 Coming back to the Booking Appointment App which you made in the first Assignment

 <br/><br/>

Now instead of storing the object in the local storage we will try to store in the cloud
<br/>
Take the object and do a POST request to crudcrud.com using axios

<br/><br/>

I have explained how to do it in the video below.
<br/>
Check this video -<a href="https://youtu.be/La5cL2jNoVw?si=CY8OdNOSUvjtmIyk" target='_blank'>&nbsp;
click here&nbsp;
                        </a> 
                        <br/>
After watching the video try writing code by yourself

<br/><br/>

Check Crud Crud resource and see if the request was sucessful or not. Whether you can see the object or not
<br/><br/>




Push the code to Git and upload the commit ID
                  <br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Get the saved User Details from crudcrud.',
    content: (
      <div>
        <p className="task-mvp">Get the saved User Details from crudcrud.</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                  Previously you were able to save the user Details to crud crud.
                  <br/>
But then when you refresh the page , everything is lost. We have to solve this problem

<br/><br/>

Solution:-
<br/>
Make a GET request to crud crud when the DOM has loaded and get the user Details which have been saved and show it on the website

<br/><br/>

I have explained how to do in this video.

<a href="https://youtu.be/AX9k9bjCBD0?si=dICz1MWxVMxy6T7O" target='_blank'>&nbsp;
click here&nbsp;
                        </a> 
                        <br/>
AFTER watching the video try writing code by yourself

<br/><br/>

I should be able to see the details of all the previously saved appointments

<br/><br/>



Push the code to git and upload commit ID
<br/><br/>




Going forward you will not be getting points for the tasks which you do.
<br/>
But dont worry there would be demos ahead in which mentor will check you progress manually.
                  <br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Deleting the Appointments',
    content: (
      <div>
        <p className="task-mvp">Deleting the Appointments</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                  In the previous task you were able to make a GET request and get all the user Details that have been saved. You showed it on the website.

                  <br/><br/>




DELIVERABLE
<br/>

The user should be able to delete the user detail of an existing user by clicking on the delete icon placed next to the user.
<br/>
So place a small delete icon next to user detail<br/>
On clicking the delete icon, delete that particular user detail from the crud crud. Basically perform DELETE OPERATION with the _id.<br/>
Remove the user details from the website too on sucessful deletion. The user detail should no longer be visible. We do not have to refresh the page.
<br/><br/>



Push the code to git and upload commit ID.
<br/><br/>


Going forward you will not be getting points for the tasks which you do.
<br/>
But dont worry there would be demos ahead in which mentor will check you progress manually.
                  <br/><br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  {
    title: 'Update the user Details',
    content: (
      <div>
        <p className="task-mvp">Update the user Details</p>
       
                
              <div className="des-8v9">
                <p className="title-chv">
                  Task details
                </p>
                <div className="ql-6os ql-5xn gkajs">
                    
                  <p> 

                  In the previous task you were able to make a GET request and get all the user Details that have been saved.
                  <br/>
You were also able to delete Appointments

<br/><br/>

Now give the functionality of edit Appointments

<br/><br/>

DELIVERABLE
<br/>
<br/>
The user should be able to edit the user detail of an existing user by clicking on the Edit icon placed next to the user.
<br/>
1.So place a small edit icon next to user detail<br/>
2.On clicking the edit icon, automatically populate the main registration form with the userdetails so that user can edit his details.<br/>
3.On Submiting this form it should it should edit the user details of the same user.<br/>
4.Once we click on submit , the website should automatically get updated with new user details<br/>

<br/>
But dont worry there would be demos ahead in which mentor will check you progress manually.
                  <br/>
                  </p>
                </div>
              </div>
      </div>
    ),
  },
  ];


const RegistrationPage2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 20) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>  
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/19'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 20</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default RegistrationPage2;

