import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'What on Earth Is Git?',
      content: (
        <div>
          <p className="task-mvp">What on Earth Is Git?</p>
         
                  
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>
                    Why do we use Git as a versioning tool?
<br/>
<br/>
<a href="https://www.youtube.com/embed/WbwIoQYP6no" target='_blank'>
Basic Understanding of git (Github Ui no CLI)&nbsp;
                          </a>
 - Watch the video uptill 43 min.
<br/><br/>


Follow what the youtuber would be doing and push your code to git and upload the commit ID of the final task
<br/><br/>
                    </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Adding a new file to GIT',
        content: (
          <div>
            <p className="task-mvp">Adding a new file to GIT</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                 
  
                            Signup on github if you haven't yet from this<a href='https://github.com/' target='_blank'>&nbsp;Url</a>


                            <br/><br/>
Watch these 3 videos in the playlist till the end and understand how to push the code to commit ID

 <a href='https://youtu.be/ufKRYe8ZPaw?si=RZRQZvDQwGh9zpG3' target='_blank'>&nbsp;Click here</a><br/>


<br/><br/>






if stuck for long. watch this video in Hindi<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href='https://www.youtube.com/watch?v=DQXdXFPowVU' target='_blank'>&nbsp;Click here</a>

<br/><br/>


(ls -al might now work in windows . Its fine as it is not required)

<br/><br/>

Try to make some changes in a folder like the way I did and push the code .

<br/><br/>

If nothing works create a new folder and follow this video<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href='https://youtu.be/IeL7iq7Lb90?si=hGHg15YZswgONJMR' target='_blank'>&nbsp;Click here</a><br/><br/>

                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Linked List - FInd Sum',
        content: (
          <div>
            <p className="task-mvp">Linked List - FInd Sum</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Find the sum of odd elements in a linked list


                      <br/><br/>
Example


<br/><br/>
Input
<br/>
1--&gt;2--&gt;4--&gt;3--&gt;9--&gt;10--&gt;5


<br/><br/>
Output
<br/>
18


<br/><br/>
Reason
<br/>
1+3+9+5 = 18


<br/><br/>
Example 2
<br/>
2--&gt;4--&gt;6


<br/><br/>
Ouput
<br/>
0


<br/><br/>
Reason
<br/>
No odd values present<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Linked List - Find Middle Element',
        content: (
          <div>
            <p className="task-mvp">Linked List - Find Middle Element</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Given the head of a singly linked list, return the middle node of the linked list.

If there are two middle nodes, return the second middle node.
                      <br/><br/>
<img src='https://miro.medium.com/v2/resize:fit:1400/format:webp/1*5ui3Gk-TD6JQvYD3UJxRGQ.png' alt='uploading' style={{width:500}}/>
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit.)<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Linked List - Delete element',
        content: (
          <div>
            <p className="task-mvp">Linked List - Delete element</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Given a linked list, remove the 3rd last Element of a linked list.

<br/>
<br/>
Example
<br/><br/>


Input 1
<br/>
1--&gt;3--&gt;2--&gt;5--&gt;1--&gt;9
<br/><br/>


Output
<br/>
1--&gt;3--&gt;2--&gt;1--&gt;9
<br/><br/>


Example 2
<br/><br/>


Input 2
<br/>
1--&gt;3--&gt;2--&gt;5--&gt;1--&gt;9--&gt;10--&gt;7

<br/><br/>

Output
<br/>
1--&gt;3--&gt;2--&gt;1--&gt;10--&gt;7
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];

const Dom2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 5) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/14'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 5</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Dom2;
