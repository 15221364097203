import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const UpdateGoogleMeetLink = () => {
  const [newLink, setNewLink] = useState('');
  const [message, setMessage] = useState('');

  const handleUpdate = async () => {
    try {
      const response = await axios.post('https://eduyog.in/api/google-meet-link', { link: newLink });
      setMessage(response.data.message);
      setNewLink(''); // Reset the input field
    } catch (error) {
      console.error('Error updating Google Meet link:', error);
      setMessage('Failed to update link');
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center">Update Google Meet Link For Yoga</h2>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={newLink}
                  onChange={(e) => setNewLink(e.target.value)}
                  placeholder="Enter new Google Meet link"
                />
              </div>
              <button className="btn btn-primary btn-block" onClick={handleUpdate}>Update Link</button>
              {message && <p className="mt-3 text-center">{message}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateGoogleMeetLink;

