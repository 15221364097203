// src/components/SessionStatus.js
import React from 'react';

const SessionStatus = ({ isLive }) => {
  return (
    <div>
      <p>Status: {isLive ? 'Live' : 'Offline'}</p>
    </div>
  );
};

export default SessionStatus;
