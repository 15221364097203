import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import { Card, CardBody, CardTitle, CardText, CardImg, Modal, ModalBody } from 'reactstrap';
import DownloadButton from './DownloadButton';

const generateSlug = (title) => {
  return slugify(title, { lower: true, remove: /[*+~.()'"!:@]/g });
};

const Content = ({ items }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);

  const toggleModal = (fileId) => {
    setSelectedFileId(fileId);
    setModalOpen(!modalOpen);
  };

  const cardStyle = {
    border: '1px solid #ddd',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    height: '90%',
    width: '100%',
  };

  const cardImgWrapperStyle = {
    overflow: 'hidden',
    position: 'relative',
  };

  const cardImgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  };

  const cardBodyStyle = {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const cardTitleStyle = {
    fontSize: '1.2em',
    fontWeight: 'bold',
    textAlign: 'center',
    maxHeight: '7rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '4',
    WebkitBoxOrient: 'vertical',
  };

  const cardTextStyle = {
    fontSize: '1em',
    textAlign: 'center',
    maxHeight: '3rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  };

  const buttonStyle = {
    marginRight: '5px',
    marginLeft: '5px',
    width: '100%',
  };

  return (
    <div>
      <div className="row">
        {items.map((item, index) => (
          <div key={index} className="col-md-5 mb-4 d-flex justify-content-center">
            <Card className="" style={cardStyle}>
              <div style={cardImgWrapperStyle}>
                <CardImg top src={item.image} alt={item.title} style={cardImgStyle} />
              </div>
              <CardBody style={cardBodyStyle}>
                <CardTitle tag="h5" style={cardTitleStyle}>{item.title}</CardTitle>
                <CardText style={cardTextStyle}>
                  <strong>Amount: &#8377;
                    {item.discount > 0 ? (
                      <>
                        <span style={{ textDecoration: 'line-through' }}>{item.amount}</span>
                        <div style={{ color: 'red' }}> Discounted Price: &#8377;{(item.amount - (item.amount * (item.discount / 100))).toFixed(2)}</div>
                      </>
                    ) : item.amount}
                  </strong>
                </CardText>
                <div className="d-flex justify-content-between flex-nowrap">
                  <Link to={`/buynow/${generateSlug(item.title)}/${item.id}`} className="btn btn-primary" style={buttonStyle}>Buy Now</Link>
                  <Link to={`/details/${generateSlug(item.title)}/${item.id}`} className="btn btn-secondary" style={buttonStyle}>Details</Link>
                  
                </div>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
      {/* Modal for displaying timetable */}
      <Modal isOpen={modalOpen} toggle={() => toggleModal(null)}>
        <ModalBody>
          {selectedFileId && <DownloadButton fileId={selectedFileId} />}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Content;
