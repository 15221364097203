import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Videos- Arrays',
      content: (
        <div>
          <p className="task-mvp">Videos- Arrays</p>
         
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>Go through the following set of materials to understand about how to implement Array DS in your own language :-</p>
                    <br/>
                    <p>
                    Watch this video first to get a basic understanding in Arrays -
                      <a href="https://youtu.be/Gn7PGIEJejs?si=Tm3mFwkN9pcFt1I-" target='_blank'>
                        &nbsp;Click here
                      </a>
                      <br/>
                      then watch the following videos in your own Language:- 
                    </p>
                    <br/>
                    <p>C++<br/>First Step is to understand Pointers :-
                     <a href="https://youtu.be/gHxmAgedyDk?si=OjO_3a2iYEzcfH7X" target='_blank'>&nbsp;Click here</a>
                     <br/>
                      Then Watch the following videos to understand the direct implementation
                      <br/>
                     &nbsp;&nbsp;&nbsp;1.<a href="https://youtu.be/2ybLD6_2gKM?si=RtwzGk7wKhtN86_A" target='_blank'>&nbsp;Click here</a>
                     <br/>
                     &nbsp;&nbsp;&nbsp;2.<a href="https://youtu.be/PyTK_g1l8V8?si=bgohy8xNS5kGKaGM" target='_blank'>&nbsp;Click here</a>
                    </p>
                    <br/>
                    <p>
                     
                       Java:-<a href="https://youtu.be/NTHVTY6w2Co?si=k0buyMpVeqOv0NYi" target='_blank'>&nbsp;Click here</a>
                     
                     </p>
                     <br/>
                     <p>
                     
                     Javascript:-<a href="https://youtu.be/cejBux2gtEE?si=lm4MVqDosz77WYd0" target='_blank'>&nbsp;Click here</a>
                   
                   </p>
                   <br/>
                   <p>
                     
                       Python:-
                    <a href="https://youtu.be/phRshQSU-xA?si=HTN11WXNSyddvw-T" target='_blank'>&nbsp;Click here</a>
                     
                     
                     </p>
                     <br/>
                   <br/>
                    
                    <p>After watching the above video Click on the Button Done and start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.</p>
                    <br/>
                    <br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Videos - Primitive DT and Reference DT',
        content: (
          <div>
            <p className="task-mvp">Videos - Primitive DT and Reference DT</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>Go through the following set of videos to understand primitive Data Type and Reference Data Types.</p>
                      <br/>
                      <p>
                      Properties of Primitive / Reference Data Type :- 
                        <a href="https://youtu.be/NyT9vvSBoeo?si=BXJJeyESfxEpEg7q" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        Important Example :- 
                        <a href="https://www.youtube.com/watch?v=tUijuz8aEJA" target='_blank'>
                          &nbsp;Click here
                        </a>
                      </p>
                      <br/>
                      
                      <p>After watching the above videos then start with your tasks.<br/> Any Queries or Confusion contact your mentor Directly on Whatsapp.</p>
                      
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Storing Elements in Array',
        content: (
          <div>
            <p className="task-mvp">Storing Elements in Array</p>
           
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                      <p>
                       
                        Write a program to declare an array of size n and store the numbers 1,2,3,4 ...n in the array and return the array.
                        <br/>
                      </p>
                      <br/>
                      <p>Ask your mentor if not being able to understand the implementation<br/>Click on submit after all the test-cases has passed.</p>  
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Maximum in an Array',
        content: (
          <div>
            <p className="task-mvp">Maximum in an Array</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                      <p>
                      Given an array find the maximum in it and return it <br/> Example:-<br/> Input:-<br/>Arr=[5, 4, 7, 2, 6] <br/>Ouput:-<br/>7
                        
                      <br/> 
                      (Solve the following question in the compiler, make sure all test case passes and then click submit. )
                       </p>  
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Minimum element in an Array',
        content: (
          <div>
            <p className="task-mvp">Minimum element in an Array</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                      <p>
                      Complete the function to find the minimum in the array and return it
                       <br/>
                       Input :- Array and the length of the array<br/>[5, 6, 2, 9, -2],5 <br/>Ouput:-<br/>-2
                        <br/>
                        Write a program to return the minimum element in the array.
                        <br/> 
                        <br/>
                      (Solve the following question in the compiler, make sure all test case passes and then click submit. )
                       </p>  
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Store Prime numbers in an array',
        content: (
          <div>
            <p className="task-mvp">Store Prime numbers in an array</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                      <p>
                      Watch the following video to understand about prime numbers and how to store them :- <a href="https://youtu.be/SKh_sXkPvVE?si=lhCYFqokR2pAPtDX" target='_blank'>
                          &nbsp;Click here
                        </a>
                     <br/>
                    Understand the importance of Flag concept:- <a href="https://youtu.be/MiEmlzeUijg?si=i5JjnZOlvBf5RbRM" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/><br/>
                  After watching the video solve the following Question :-
                  <br/><br/>
                  Write a program to store first n prime numbers in an array . After storing return the array.
                  <br/>
                       Input :-<br/>n=5 <br/>Ouput:- Return the output in the form of an array.<br/>2<br/>3<br/>5<br/>7<br/>11<br/>
                      (Solve the following question in the compiler, make sure all test case passes and then click submit. )
                       </p>  
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Subarrays',
        content: (
          <div>
            <p className="task-mvp">Subarrays</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                      <p>
                     (important) Watch the following video to understand about subarrays :- <a href="https://youtu.be/b0tLvc_gTkk?si=mdWidPo0yukueq_F" target='_blank'>
                          &nbsp;Click here
                        </a>
                     <br/>
                      (Important) Watch the following video to understand the role of i,j,k loop - <a href="https://youtu.be/5v1w0V4GPrE?si=9BnZ_8HBR46VV_oY" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        After watching the video write the code to print all the subarrays possible. Note - print in the similar way as given here.
                  <br/>
                       Input :-<br/>[1,2,3,4,5]<br/>Ouput:-<br/>1<br/>12<br/>123<br/>1234<br/>12345<br/>2<br/>23<br/>234<br/>2345<br/>3<br/>34<br/>345<br/>4<br/>45<br/>5<br/>
                      (Solve the following question in the compiler, make sure all test case passes and then click submit. )
                       </p>  
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Maximum Sum Subarray',
        content: (
          <div>
            <p className="task-mvp">Maximum Sum Subarray</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                      <p>
                      Given an array find the maximum sum subarray. Return the maximum sum of the subarray.
                      <br/>

Input:-
<br/>
[5,2,-4,-5, 3,-1,2,3,1]
<br/>
Output:-
<br/>
8
<br/>
Reason :- 3,-1,2,3,1 is the maximum subarray possible.
<br/>
(Solve the following question in the compiler, make sure all test case passes and then click submit. )
                       </p>  
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Video - Dynamic Arrays',
        content: (
          <div>
            <p className="task-mvp">Video - Dynamic Arrays</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Important Understand How dynamic arrays works :-
                        <a href="https://youtu.be/jzJlq35dQII?si=gf8lavC4EiXIloDI" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        After watching the video go through the following videos:-<br/>
                        Java:-
                        <a href="https://youtu.be/liFyhzZl9uw?si=JGqflmhaKPZRZ14F" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        C++:-
                        <a href="https://youtu.be/MwwbgqG6bSk?si=MRyypiL-TPxQYwyG" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        Python:-
                        <a href="https://youtu.be/xT70mHdAM74?si=jaqJRbPM9X1BWyqc" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        Javascript:-
                        <a href="https://youtu.be/PJaHqI8djMY?si=7zF-0q7cGZ7qmSAb" target='_blank'>
                          &nbsp;Click here
                        </a>
                      </p>
                      <br/>
                      <p>After watching the above video then start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.</p>
                      
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'How to debug any code',
        content: (
          <div>
            <p className="task-mvp">How to debug any code</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      [Important] Watch the following video to understand how to debug any code :- 
                        <a href="https://youtu.be/J8uAiZJMfzQ?si=0sQBbUZcqasVJgK_" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        <br/>
                        After watching the above video Click on the Button Done and start with your tasks.Any Queries or Confusion contact your mentor Directly on Whatsapp.
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Running sum',
        content: (
          <div>
            <p className="task-mvp">Running sum</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      [Important] Solve the following problem on leetcode 
                        <a href="https://leetcode.com/problems/running-sum-of-1d-array/" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        (Solve the following question on the Leetcode, make sure all test case passes and then click submit. )
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Maximum in 2D array',
        content: (
          <div>
            <p className="task-mvp">Maximum in 2D array</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Watch the following video on 2D array :- 
                        <a href="https://youtu.be/HMBYWhpP8i4?si=-VlmXQv4f6emNLfF" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        You are given an m x n integer grid accounts where accounts[i][j] is the amount of money the i​​​​​​​​​​​th​​​​ customer has in the j​​​​​​​​​​​th​​​​ bank. Return the wealth that the richest customer has.
<br/>
A customer's wealth is the amount of money they have in all their bank accounts. The richest customer is the customer that has the maximum wealth.

 
<br/><br/>
Example 1:
<br/>
Input: accounts = [[1,2,3],[3,2,1]]
<br/>
Output: 6
<br/>
Explanation:
<br/>
1st customer has wealth = 1 + 2 + 3 = 6
<br/>
2nd customer has wealth = 3 + 2 + 1 = 6
<br/>
Both customers are considered the richest with a wealth of 6 each, so return 6.


<br/><br/>
Example 2:
<br/>
Input: accounts = [[1,5],[7,3],[3,5]]
<br/>
Output: 10
<br/>
Explanation: 
<br/>
1st customer has wealth = 6
<br/>
2nd customer has wealth = 10 
<br/>
3rd customer has wealth = 8
<br/>
The 2nd customer is the richest with a wealth of 10.


<br/><br/>
Example 3:
<br/>
Input: accounts = [[2,8,7],[7,1,3],[1,9,5]]
<br/>
Output: 17

 <br/>
<br/>
Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;m == accounts.length<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;n == accounts[i].length<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= m, n &lt;= 50<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= accounts[i][j] &lt;= 100<br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Sum of Odd length Subarrays',
        content: (
          <div>
            <p className="task-mvp">Sum of Odd length Subarrays</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      [Important] Solve the following problem on leetcode 
                        <a href="https://leetcode.com/problems/sum-of-all-odd-length-subarrays/" target='_blank'>
                          &nbsp;Click here
                        </a>
                        <br/>
                        (Solve the following question on the Leetcode, make sure all test case passes and then click submit. )
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Missing Number',
        content: (
          <div>
            <p className="task-mvp">Missing Number</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      You are given an array Containing n-1 distinct numbers from range [1, n].

There is one element missing from the range[1,n] in the array. Find that missing Number and return it.
<br/><br/>


Solve it without using any extra array.

<bvr/><br/>

Example 1:
<br/>
Input: nums = [3,4,1]
<br/>
Output: 2
<br/>
Explanation: n = 4 since there are 3 numbers, so all numbers are in the range [1,4]. 2 is the missing number in the range since it does not appear in nums.
<br/>
<br/>

Example 2:
<br/>
Input: nums = [2,1]
<br/>
Output: 3
<br/>
Explanation: n = 3 since there are 2 numbers, so all numbers are in the range [1,3]. 2 is the missing number in the range since it does not appear in nums.
<br/><br/>


Example 3:
<br/>
Input: nums = [9,6,4,2,3,5,7,1]
<br/>
Output: 8
<br/>
Explanation: n = 9 since there are 8 numbers, so all numbers are in the range [1,9]. 8 is the missing number in the range since it does not appear in nums. 
 <br/>
<br/>
Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;n == nums.length +1<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= n &lt;= 10^4<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0 &lt;= nums[i]&lt;= n<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All the numbers of nums are unique.<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Sorting',
        content: (
          <div>
            <p className="task-mvp">Sorting</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                        Watch the following Videos on sorting and understand the concept :-<br/><br/>
                 Understand what is Sorting and various other Algorithms :-  <a href="https://youtu.be/_9nFQaxsdV0?si=ci46LQY4w91DyQKB" target='_blank'>
                          &nbsp;Click here
                        </a><br/><br/>



After watching the above video Click on the Button Done and start with your tasks on Sorting
<br/>
Any Queries or Confusion contact your mentor Directly on Whatsapp.<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Bubble Sort',
        content: (
          <div>
            <p className="task-mvp">Bubble Sort</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Write a program to sort the array in descending order using Bubble sort algorithm. After Sorting return the array.
                        <br/><br/>
                      Note both the array and size is already given.<br/>

Input:-
<br/>
[4,3,2,5,1]
<br/><br/>


Output:-
<br/>
[5,4,3,2,1]
<br/><br/>


Stuck on how to code watch the hint Video.
<br/><br/>


Click on submit after all the test-cases has passed.<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Insertion Sort',
        content: (
          <div>
            <p className="task-mvp">Insertion Sort</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Write a program to sort the array in ascending order using Insertion sort algorithm. After Sorting return the array.
                        <br/><br/>
                      Note both the array and size is already given.<br/>

Input:-
<br/>
[4,3,2,5,1]
<br/><br/>


Output:-
<br/>
[1,2,3,4,5]
<br/><br/>


Stuck on how to code watch the hint Video.
<br/><br/>


Click on submit after all the test-cases has passed.<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Selection Sort',
        content: (
          <div>
            <p className="task-mvp">Selection Sort</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Write a program to sort the array in descending order using Selection sort algorithm. After Sorting return the array.
                        <br/><br/>
                      Note both the array and size is already given.<br/>

Input:-
<br/>
[4,3,2,5,1]
<br/><br/>


Output:-
<br/>
[5,4,3,2,1]
<br/><br/>


Stuck on how to code watch the hint Video.
<br/><br/>


Click on submit after all the test-cases has passed.<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];

const Arrays2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 18) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/4'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 18</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Arrays2;
