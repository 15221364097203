import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import Axios from "../../../Axios";
import { useAuth } from "../../../context/AuthContext";

const Objective = ({ question, qid, topicId }) => {
  const { data } = useFetch(`topic/question/option/${qid}`);
  const [list, setList] = useState({});
  const [selected, setSelected] = useState();
  const [flag, setFlag] = useState(false);
  const [reward, setreward] = useState(0);
  const [auth] = useAuth();
  useEffect(() => {
    setList(data[0]);
  }, [data]);

  const keys = [];
  const values = [];
  if (list) {
    for (let [key, value] of Object.entries(list)) {
      if (key !== "questions_id" && key !== "id" && key !== "correct") {
        values.push(value);
      }
      keys.push(key);
    }
  }
  const handleSelect = (i) => {
    if (selected === i && selected === list?.correct) {
      return "select";
    } else if (selected === i && selected !== list?.correct) return "wrong";
    else if (i === list?.correct) return "select";
  };

  const handleCheck = (i) => {
    setSelected(i);
    if (selected === i) {
      setFlag(true);
      setreward(question?.reward);
    }
    // setError(false);
  };
  console.log(reward);
  const handleMark = async () => {
    try {
      if (!selected) {
        toast.error("select on of the solution");
        return;
      }
      if (flag) {
        setreward(question?.reward);
      }
      const res = await Axios.post("/solved/question/option", {
        status: true,
        reward: reward,
        user_id: auth?.user?.id,
        question_id: qid,
        topic_id: topicId,
      });
      console.log(res);
      if (res.data && res.data.success) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("server error try again...");
    }
  };
  console.log(reward);

  return (
    <div className="objective d-flex flex-column h-100">
      <h5>Choose one of these option:</h5>
      <div className="options m-auto col-12">
        <div className="row row-cols-2 px-3 pe-5">
          {values
            ? values.map((i) => (
                <div>
                  <button
                    style={{ cursor: "pointer" }}
                    className={`my-3 w-100 text-start text-capitalize p-2 mx-4  rounded  ${
                      selected && handleSelect(i)
                    }`}
                    key={i}
                    onClick={() => handleCheck(i)}
                    disabled={selected}
                  >
                    {i}
                  </button>
                </div>
              ))
            : null}
        </div>
        {/* <div className="text-center mt-auto">
          <button className="btn btn-primary" onClick={hanlde}>
            Submit
          </button>
        </div> */}
      </div>
      <div className="mx-auto">
        <button className="btn btn-success fw-bold" onClick={handleMark}>
          Mark As Done
        </button>
      </div>
    </div>
  );
};

export default Objective;
