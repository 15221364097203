import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Test Question - 1',
      content: (
        <div>
          <p className="task-mvp">Test Question - 1</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                   <p>
                   You are given an array of strings sentences, where each sentences[i] represents a single sentence.
<br/>
<br/>

Return the index of sentence that contain maximum number of alphabet 'a' in a single sentence.

<br/><br/>

Example 1:-

<br/><br/>

Input: sentences = ["ananya loves sharpener", "apple is a very healthy fruit", "this is great thanks very much"]

<br/><br/>

Output : 0 ( Array Index of String containing maximum number of 'a' )

<br/><br/>

Explanation:-
<br/>
ananya loves sharpener - a count --&gt; 4
<br/>
apple is a very healthy fruit - a count --&gt; 3
<br/>
this is great thanks very much - a count --&gt; 2
<br/><br/>


Maximum is 4 for the string having index 0.

<br/><br/>

Example 2:-

<br/><br/>

Input:- sentences = ["please wait", "money in my bank", "i have a lots of cars"]
<br/><br/>


Output : 2

<br/><br/>

Explanation:-
<br/>
i have a lots of cars" - a count --&gt; 3 (Which is the Maximum) and its index is 2

<br/><br/>



Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= sentences.length &lt;= 100<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= sentences[i].length &lt;= 100<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sentences[i] consists only of lowercase English letters and ' ' only.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sentences[i] does not have leading or trailing spaces.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All the words in sentences[i] are separated by a single space.<br/>
<br/>
                   </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Test Question - 2 ',
        content: (
          <div>
            <p className="task-mvp">Test Question - 2</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Print the following pattern using any of the looping method:-
                       <br/>
                       <img src='https://www.simplilearn.com/ice9/free_resources_article_thumb/C_Pattern_Programs_1.png' alt='uplaoding'/><br/><br/>
(Solve the following question on the Compiler, make sure all test case passes and then click submit.)<br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 3',
        content: (
          <div>
            <p className="task-mvp">Test Question - 3</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      You are given an m x n integer grid accounts where accounts[i][j] is the amount of money the i​​​​​​​​​​​th​​​​ customer has in the j​​​​​​​​​​​th​​​​ bank. Return the wealth that the richest customer has.
<br/>
A customer's wealth is the amount of money they have in all their bank accounts. The richest customer is the customer that has the maximum wealth.

<br/><br/>

Example 1:
<br/>
Input: accounts = [[1,2,3],[3,2,1]]
<br/>
Output: 6
<br/>
Explanation:
<br/>
1st customer has wealth = 1 + 2 + 3 = 6
<br/>
2nd customer has wealth = 3 + 2 + 1 = 6
<br/>
Both customers are considered the richest with a wealth of 6 each, so return 6.
<br/><br/>


Example 2:
<br/>
Input: accounts = [[1,5],[7,3],[3,5]]
<br/>
Output: 10
<br/>
Explanation: 
<br/>
1st customer has wealth = 6
<br/>
2nd customer has wealth = 10 
<br/>
3rd customer has wealth = 8
<br/>
The 2nd customer is the richest with a wealth of 10.
<br/><br/>


Example 3:
<br/>
Input: accounts = [[2,8,7],[7,1,3],[1,9,5]]
<br/>
Output: 17

<br/><br/>

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;m == accounts.length<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;n == accounts[i].length<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= m, n &lt;= 50<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= accounts[i][j] &lt;= 100
                      <br/><br/>
                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)<br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 4',
        content: (
          <div>
            <p className="task-mvp">Test Question - 4</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    You have numCoconuts coconut shells that are initially full of water. You can exchange numExchange empty coconut shells from the market with one full coconut shell of water.
<br/>
The operation of drinking a full coconut of water turns it into an empty coconut shell.
<br/>
Given the two integers numCoconuts and numExchange, return the maximum number of coconut shells of water you can drink.
<br/><br/>


Example 1:
<br/>
Input: numCoconuts = 9, numExchange = 3
<br/>
Output: 13
<br/>
Explanation: You can exchange 3 empty coconut shells for 1 full coconut of water. With 9 coconut shells, you can exchange them for 3 full coconuts of water. You can then drink the 9 full coconuts and have 3 empty coconut shells left. You can exchange 3 empty coconut shells for 1 full coconut of water, and now you have 1 full coconut and 2 empty shells. You can't exchange the 2 empty shells for another coconut, so you can only drink the 1 full coconut. In total, you can drink 9 + 1 + 1 + 1 + 1 = 13 coconut shells of water.

<br/><br/>



Example 2:
<br/>
Input: numCoconuts = 15, numExchange = 4
<br/>
Output: 19
<br/>
Explanation: You can exchange 4 empty coconut shells for 1 full coconut of water. With 15 coconut shells, you can exchange them for 3 full coconuts of water and have 3 empty shells left. You can exchange the 3 empty shells for 1 more full coconut of water. You can then drink the 4 full coconuts and have 2 empty shells left. You can exchange the 2 empty shells for 1 more full coconut of water. You can then drink the last coconut of water. In total, you can drink 15 + 1 + 1 + 1 + 1 = 19 coconut shells of water.
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      
  ];

const Mock32 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 4) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/12'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 4</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Mock32;
