import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, FormGroup, Label, Input, Button, Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';

const AdminPage = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const [newItem, setNewItem] = useState({
    title: '',
    description: '',
    amount: '',
    image: null,
    category1: '',
    category2: '',
    category3: '',
    category4: '',
    schedule: null,
    discount: ''
  });

  const courseOptions = ['Chartered Accountancy (CA)', 'Company Secretary (CS)', 'Cost And Management Accountancy (CMA)'];
  const levelOptions = ['BEGINNER', 'INTERMEDIATE', 'ADVANCED'];
  const attemptOptions = ['2 MONTHS','3 MONTHS', '4 MONTHS', '6 MONTHS' , '9 MONTHS'];
  const typeOptions = ['Single Subject Test', 'Multi Chapter Test', 'Fastrack Test', 'Full Course Test', 'MTP'];

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get('https://eduyog.in/api/items');
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });
  };

  const handleImageUpload = (e) => {
    const { name, files } = e.target;
    setNewItem({ ...newItem, [name]: files[0] });
  };

  const handleAddItem = async () => {
    if (!newItem.title || !newItem.description || !newItem.amount || !newItem.category1 || !newItem.category2) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append('title', newItem.title);
      formData.append('description', newItem.description);
      formData.append('amount', newItem.amount);
      formData.append('image', newItem.image);
      formData.append('category1', newItem.category1);
      formData.append('category2', newItem.category2);
      formData.append('category3', newItem.category3);
      formData.append('category4', newItem.category4);
      formData.append('schedule', newItem.schedule);
      formData.append('discount', newItem.discount);

      const response = await axios.post('https://eduyog.in/api/items', formData);
      setItems([...items, response.data]);
      setNewItem({ title: '', description: '', amount: '', image: null, category1: '', category2: '', category3: '', category4: '', schedule: null, discount: '' });
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.delete(`https://eduyog.in/api/items/${id}`);
      setItems(items.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/adminDash');
  };

  return (
    <>
      <div className="text-center my-4">
      <Link to={'/users-data'} className="btn btn-outline-primary mr-2">Get Users Data</Link>
      <Link to={'/course-content'} className="btn btn-outline-primary mr-2">Update Course Content</Link>
        <Link to={'/adminForm'} className="btn btn-outline-primary mr-2">Schedule Downloaded Student</Link>
        <Link to={'/banner'} className="btn btn-outline-primary mr-2">Add Banner</Link>
        <Link to={'/payment-status'} className="btn btn-outline-primary mr-2">All Payment Status</Link>
        <Link to={'/adminAdd'} className="btn btn-outline-primary mr-2">Please Add Content</Link>
        <Link to={'/student-answer'} className="btn btn-outline-primary mr-2">Get Student Answer</Link>
        <Link to={'/teamMember'} className="btn btn-outline-primary mr-2">Manage Team Member</Link>
        <Link to={'/adminSend'} className="btn btn-outline-primary">Send Student Answer</Link>
        <Link to={'/discount'} className="btn btn-outline-primary">Discount Page</Link>
        <Link to={'/counsellingUser'} className="btn btn-outline-primary">Get Counselling User</Link>
        <Link to={'/payAfterUser'} className="btn btn-outline-primary">Pay After Placement User</Link>
        <Button color="primary" className='m-3' onClick={handleLogout}>Main Page</Button>
      </div>
      <Container className="my-5">
        <h2 className="text-center mb-4 display-6 fw-normal">Add Course</h2>
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="shadow">
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="title">Title</Label>
                    <Input type="text" name="title" value={newItem.title} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input type="text" name="description" value={newItem.description} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="amount">Amount</Label>
                    <Input type="number" name="amount" value={newItem.amount} onChange={handleInputChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="image">Image (Keep the size of all images must be same ) ,(jpg,jpeg)</Label>
                    <Input type="file" name="image" onChange={handleImageUpload} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="category1">Course Name*</Label>
                    <Input type="text" name="category1" value={newItem.category1} onChange={handleInputChange}>
                      <option value="">Select</option>
                      {courseOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="category2">Select Level*</Label>
                    <Input type="select" name="category2" value={newItem.category2} onChange={handleInputChange}>
                      <option value="">Select</option>
                      {levelOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="category3">Duration</Label>
                    <Input type="select" name="category3" value={newItem.category3} onChange={handleInputChange}>
                      <option value="">Select</option>
                      {attemptOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="category4">Type</Label>
                    <Input type="text" name="category4" value={newItem.category4} onChange={handleInputChange}>
                      <option value="">Select</option>
                      {typeOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="schedule">Please Upload Schedule(jpg,jpeg)</Label>
                    <Input type="file" name="schedule" onChange={handleImageUpload} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="discount">Discount</Label>
                    <Input type="number" name="discount" value={newItem.discount} onChange={handleInputChange} />
                  </FormGroup>
                  <Button color="primary" onClick={(e) => { e.preventDefault(); handleAddItem(); }}>Add Item</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <h3 className="text-center my-5 display-6 fw-normal">All Courses</h3>
        <Row>
          {items.map(item => (
            <Col md="4" key={item.id}>
              <Card className="mb-4 h-100 shadow">
                <CardBody style={{ minHeight: "200px" }}>
                  <CardTitle tag="h5" className="text-primary" style={{ marginBottom: "10px" }}>{item.title}</CardTitle>
                  <CardText style={{ marginBottom: "10px", maxHeight: "50px", overflow: "hidden" }}>{item.description}</CardText>
                  <CardText style={{ marginBottom: "10px", maxHeight: "50px", overflow: "hidden" }}>id:{item.id}</CardText>
                  <CardText><strong>&#8377;{item.amount}</strong></CardText>
                  {item.image && <img src={item.image} alt={item.title} className="img-fluid rounded mb-2" style={{ maxHeight: "150px", objectFit: "cover", width: "100%" }} />}
                  {item.schedule && <img src={item.schedule} alt="Schedule" className="img-fluid rounded mb-2" style={{ maxHeight: "150px", objectFit: "cover", width: "100%" }} />}
                  <div className="text-center mt-auto">
                    <Button color="danger" onClick={() => handleDeleteItem(item.id)}>Delete</Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default AdminPage;
