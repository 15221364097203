import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function AdminTeam() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [image, setImage] = useState(null); 
  const [newMember, setNewMember] = useState({ name: '', role: '', qualification: '' });

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    const response = await axios.get('https://eduyog.in/api/team-members');
    setTeamMembers(response.data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMember({ ...newMember, [name]: value });
  };

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  const addTeamMember = async () => {
    const formData = new FormData();
    formData.append('name', newMember.name);
    formData.append('role', newMember.role);
    formData.append('qualification', newMember.qualification);
    if (image) {
      formData.append('image', image);
    }

    const response = await axios.post('https://eduyog.in/api/team-members', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    setTeamMembers([...teamMembers, response.data]);
    setNewMember({ name: '', role: '', qualification: '' });
    setImage(null);
  };

  const deleteTeamMember = async (id) => {
    await axios.delete(`https://eduyog.in/api/team-members/${id}`);
    setTeamMembers(teamMembers.filter(member => member.id !== id));
  };

  return (
    <>
      <div style={{textAlign:"center" , marginTop:"2%" }}>
        <Link to={'/addCourse'} className="btn btn-outline-primary mr-2">HomePage</Link>
        
      </div>
      <div className="container py-4">
        <h2 className="text-center mb-4">Admin Panel: Manage Team Members</h2>
        <div className="mb-3">
          <input
            type="text"
            className="form-control mb-2"
            name="name"
            value={newMember.name}
            onChange={handleInputChange}
            placeholder="Name"
          />
          <input
            type="text"
            className="form-control mb-2"
            name="role"
            value={newMember.role}
            onChange={handleInputChange}
            placeholder="Role"
          />
          <input
            type="text"
            className="form-control mb-2"
            name="qualification"
            value={newMember.qualification}
            onChange={handleInputChange}
            placeholder="Qualification"
          />
          <div className="mb-3">
            <p>Upload Member Image (image size should be less than 15MB)</p>
            <input type="file" className="form-control" onChange={handleImageUpload} accept="image/*" />
          </div>
          <button className="btn btn-primary" onClick={addTeamMember}>Add Team Member</button>
        </div>
        <ul className="list-group">
          {teamMembers.map(member => (
            <li key={member.id} className="list-group-item d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">{member.name}</h5>
                <small className="text-muted">{member.role} - {member.qualification}</small>
              </div>
              <button className="btn btn-danger" onClick={() => deleteTeamMember(member.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default AdminTeam;
