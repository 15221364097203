import React from "react";

const QuestionDetails = ({ data, lastq }) => {
  // console.log(data?.id + " " + lastq);
  return (
    <div className="bg-white p-3 shadow">
      <div className="details">
        <h6>{data?.title}</h6>
        <div className="bg-light p-3  rounded">
          <div className="fw-bold">Task Points: {data?.reward}</div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="p-0 fs-5 mt-3 m-0">Points Awarded:</p>
              <h4 className="fw-bold">0/{data?.reward}</h4>
            </div>
            <div className="my-auto">
              <span>Task Status:</span>{" "}
              <span className="text-success fw-bold">
                {lastq >= data?.id ? "Done" : "Uncomplete"}
              </span>
            </div>
          </div>
        </div>
        <p className="fw-bold"> Task Details</p>
        <div>
          <p>{data?.statement}</p>
        </div>
        <div>
          <p>{data?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default QuestionDetails;
