import { Link } from "react-router-dom";
import Layout from "../../component/Layout";
import React from 'react'
import "../../component/Navbar.css"
import Coding from "./video/Coding11.jpg"
//import { Link } from "react-router-dom";

export default function Forcolleges() {
  return (
    <div>
        {/* <p><a href="#contact">Get in touch</a>...</p>
        <div id="contact">Hello</div> */}
      <Layout>
        <div className="c-0">
             <div className="c-1">
                 <div className="display-4 gradien fw-bold text-white animated-gradient ">
                 EduYog for colleges
                 </div>
               <h4>From a brand known for its promises.</h4>
               <p><span className="c-3">Guranteed</span> Internship assistance.</p><br/>
               <h5>Master Competitive Coding and become a Full-Stack Developer, for <br/><span className="c-3">FREE!</span></h5>
              <Link to="/signUp"> <button type="button" className="btn1">LEARN FOR FREE</button></Link>
               <button type="button" className="btn2" onClick={() => scrollToSection('#c-9')}>SEE WHAT YOU GET</button>
               <p className="c-4 text-white">Complete the program and get directly into EduYog's Pay After Placement program</p>
             </div>
          
             <div className='container'>
    <div className='row justify-content-center'>
        <div className='col-12 col-md-10 col-lg-9 col-xl-10 col-xxl-12 col-sm-10 col-md-12 col-xxl-12 col-xs-12'>
            <img src={Coding} className='img-fluid img-colleges '  />
        </div>
    </div>
</div>





            
        </div>
        <Companylist/>
           <div className="c-6">
                 <div>
                     <img src="https://cdn-icons-png.flaticon.com/128/868/868876.png" width={35} alt="loading"/>
                    
                     <span className="c-8">10x better than all others! <span className=" gradien text-white animated-gradient ">Promise</span></span>
                     
                     <ul className="space">
        <li><a href="#c-9" onClick={() => scrollToSection('#c-9')}>Program Overview</a></li>
        <li><a href="#c-10" onClick={() => scrollToSection('#c-10')}>Eligibility</a></li>
        <li><a href="#c-14" onClick={() => scrollToSection('#c-14')}>Course Highlights</a></li>
        <li><a href="#c-15" onClick={() => scrollToSection('#c-15')}>What you will learn</a></li>
        <li><a href="#c-17" onClick={() => scrollToSection('#c-17')}>Why are we 10x better</a></li>
        <li><Link to="/signUp"> <button type="button" className="btn1">START FOR FREE</button></Link></li>
      </ul>
                    
                 </div>
                 <div className="scroll">
                       
                       <div id="c-9">
                          <h3 className="text-dark">Program Overview</h3>
                         <p>
                          Let’s skip some unnecessary lecture, because you have had enough of that!<br/><br/>

                          We do not plan to recreate just another course, that’s already there. We plan to change the way you learn things, the way you challenge the problems and how you approach resolution.
                          <br/><br/>
                          Based on understanding tens of thousands of students, we found the elements missing in college education and we plan to fill it up.
                           <br/><br/>
                           Welcome to EduYog for Colleges.<span> <img src="https://cdn-icons-png.flaticon.com/128/868/868876.png" width={35} alt="loading"/></span>
                         </p>
                       </div>
                       <div id="c-10">
                           <h3 className="text-dark">Eligibility</h3>
                           <p>Long story short - Everyone!</p>
                            <div className="c-11">
                               <h5>&#9432;</h5>
                               <p>Additionally, if you are not eligible for EduYog’s flagship Pay After Placement program, this is a clever way to get in. Once we are sure you are skilled enough, we are happy to connect you with opportunities!</p>
                            </div>
                       </div>
                       <div id="c-14">
                              <h5 className="text-dark">Course Highlights</h5>
                              <div className="c-12">
                                    <div><img src="https://i.ibb.co/yh4QMJL/Screenshot-2024-03-22-at-11-17-16-AM.png" alt="Loading"/></div>
                                    <div className="c-13">
                                        <img src="https://images2.imgbox.com/55/07/EsBmZ93P_o.jpg"alt="loading"/>
                                         <h4>Optional:</h4>
                                         <h5>Lifetime access for EduYog’s famous Pay After Placement</h5>
                                         <img src="https://i.ibb.co/kD98VCd/53500902412-373688d523-b.jpg" alt="Loadding" className="imgspecial"/>
                                    </div>
                             </div>
                       </div>
                       <div id="c-15">
                          <h4>What you will learn</h4>
                             <div className="c-16">
                                <img src="https://cdn-icons-png.flaticon.com/128/5433/5433226.png" alt="Uploading"/>
                                <p>
                                    Data Structures and Algorithms<br/>
                                     Beginner: Basics of Coding, Looping, Arrays, Bit Manipulation, Stacks and Queues, Recursion, Linked Lists
                                      <br/>
                                     Advanced: Trees and Graphs
                                </p>
                             </div>
                             <div className="c-16">
                                <img src="https://cdn-icons-png.flaticon.com/128/7991/7991055.png" alt="Downloading"/>
                                <p>
                                Basics of Web Development<br/>
                                     Beginner: Basic JavaScript
                                      <br/>
                                     Advanced: Advanced JavaScript DOM Manipulation DSA Revision 2 Micro Projects
                                </p>
                             </div>
                             <div className="c-16">
                                <img src="https://cdn-icons-png.flaticon.com/128/6007/6007875.png" alt="Downloading"/>
                                <p>
                                     Backend Development<br/>
                                      Basics and Advanced Node JS, Databases, Debugging and Architecture

                                      2 Industry - level Macro Projects
                                </p>
                             </div>
                             <div className="c-16">
                                <img src="https://cdn-icons-png.flaticon.com/128/1055/1055666.png" alt="Downloading"/>
                                <p>
                                  Frontend Development<br/>
                                   Basics and Advanced React JS, Styling and Lifecycle Methods

                                   2 Industry - level Macro Projects
                                </p>
                             </div>
                       </div>
                    <div id="c-17">
                        <h4>Why are we 10x better?</h4>
                        <p>Well, if you are good at something, why not brag?</p>
                        <div className="c-18">
                               <div>
                                       <img src="https://cdn-icons-png.flaticon.com/128/2693/2693507.png" alt="Downloading"/>
                                       <p>GUARANTEED Internship assistance on successful course completion.</p>
                               </div>
                               <div>
                                        <img src="https://cdn-icons-png.flaticon.com/128/4439/4439476.png" alt="Downloading"/>
                                        <p>No spoon-feeding! BUT DAILY  doubt classes. Learn like champions.</p>
                               </div>
                               <div>
                                       <img src="https://cdn-icons-png.flaticon.com/128/263/263075.png" alt="downloading"/>
                                       <p>A reputation too good to be true, but it is! Google EduYog Tech!</p>
                               </div>
                        </div>
                        <div className="c-18">
                               <div>
                                       <img src="https://cdn-icons-png.flaticon.com/128/2662/2662503.png" alt="Downloading"/>
                                       <p>At least 10 projects with mentors who are professional coders themselves</p>
                               </div>
                               <div>
                                       <img src="https://cdn-icons-png.flaticon.com/128/12992/12992021.png" alt="Downloading"/>
                                       <p> Live Mock Interviews after every alternate concept. Just like interviews!</p>
                               </div>
                               <div>
                                        <img src="https://cdn-icons-png.flaticon.com/128/955/955252.png" alt="Downloading"/>
                                        <p> You learn with any Batches. Understand at your own learning skills</p>
                               </div>
                        </div>
                    </div>
                 </div>
           </div>
      </Layout>
    </div>
  )
}
const Companylist = () => {
    const mid = Math.ceil(companyLogo.length / 4);
    const first = companyLogo.slice(0, mid);
    return (
      <>
        <div className="company-list">
          <h1 className="text-center ">Too Many Hiring Partners!</h1>
          <div>
            <LogoList list={first} className="right-to-left" />
          </div>
        </div>
      </>
    );
  };
  function scrollToSection(sectionId) {
    var section = document.querySelector(sectionId);
    window.scrollTo({
      top: section.offsetTop,
      behavior: 'smooth'
    });
  }
  
  const LogoList = ({ list, className }) => {
    return (
      <>
        <div className={`d-flex justify-content-between ${className}`}>
          {list?.map((img) => (
            <>
              <img
                className=" h-100 p-2"
                style={{ width: "7rem" }}
                src={img}
                alt=""
              />
            </>
          ))}
        </div>
      </>
    );
  };
  
  const companyLogo = [
    "https://cdn-icons-png.flaticon.com/128/5969/5969043.png",
    "https://cdn-icons-png.flaticon.com/128/732/732178.png",
    "https://cdn-icons-png.flaticon.com/128/5968/5968625.png",
    "https://cdn-icons-png.flaticon.com/128/5968/5968852.png",
    "https://cdn-icons-png.flaticon.com/128/882/882704.png",
    "https://cdn-icons-png.flaticon.com/128/5969/5969033.png",
    "https://cdn-icons-png.flaticon.com/128/732/732229.png",
    "https://cdn-icons-png.flaticon.com/128/5977/5977585.png",
    "https://cdn-icons-png.flaticon.com/128/732/732160.png",
    "https://cdn-icons-png.flaticon.com/128/732/732012.png",
    "https://cdn-icons-png.flaticon.com/128/5969/5969139.png",
    "https://cdn-icons-png.flaticon.com/128/152/152750.png",
    "https://cdn-icons-png.flaticon.com/128/5968/5968756.png",
    "https://cdn-icons-png.flaticon.com/128/5969/5969238.png",
    "https://cdn-icons-png.flaticon.com/128/732/732224.png",
    "https://cdn-icons-png.flaticon.com/128/914/914677.png",
    "https://cdn-icons-png.flaticon.com/128/882/882615.png",
    "https://cdn-icons-png.flaticon.com/128/11865/11865338.png",
    "https://cdn-icons-png.flaticon.com/128/5969/5969057.png",
  ];
