

import React , { useState, useEffect } from "react";
import Layout from "./Layout";
import { Link } from "react-router-dom";
import Trainer from "./Trainer";
import "./Index.css";
import Coding1 from "../Pages/placement/video/Coding1.mp4"
import Coding3 from "../Pages/placement/video/Coding3.mp4"
import Admission from "./Admission.jsx";
import RoadMap from "./RoadMap.jsx";
import Hire from "./Hire.jsx";
import Choose from "./Choose.jsx";
import AboutEduyog from "./AboutEduyog.jsx";
import LastPage from "./LastPage.jsx";
import SeminarPage from "./SeminarPage.jsx";
import BannerStatic from "../AdminCourse/BannerStatic.jsx";
import BannerComponent from "../AdminCourse/BannerComponent.jsx";
import MeetOurTeam from "../Courses/MeetOurTeam.jsx";
import Testimonials from "../Courses/Testimonials.jsx";

const Index = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const photowidth = screenWidth > 1000 ? "1024px" : "70%";
  return (
    <Layout>
      {/* First Section */}
      <div className="text-center text-white" style={{ backgroundColor: "white" }}>
        <div className="container mt-5">
          <div className="text-center ">
            <h1 className="display-4 fw-bold text-dark">Unlock Your Dev Tech Potential</h1>
            <p className="lead text-black">Learn Full Stack Development and Data Science from Industry Expert</p>
          </div>
          <div className="row align-items-center text-center">
            <div className="col-md-6 order-md-last">
              <div className="position-relative overflow-hidden rounded">
              <video src={Coding1} autoPlay loop muted style={{height:'300px'}}/>
               
              </div>
            </div>
            <div className="col-md-6 text-md-start text-center my-md-8 animate__animated animate__fadeInLeft">
              <h2 className="my-md-5 text-warning text-dark fw-bold">Unlock Your Creativity!</h2>
              <p className="text-muted mto">
                Elevate your skills with EduYog's vibrant full-stack development and data-science program. Your journey to success starts here, and you celebrate with payment only after achieving your placement goals. Seize this opportunity to cultivate expertise, unlock your potential, and embark on a fulfilling career in full-stack development with EduYog.
              </p>
            </div>
          </div>
          <div className="mt-4 text-center">
            <button className="btn btn-primary fw-bold py-3 px-3 rounded-pill ">
              <Link to="/forcolleges" className="text-decoration-none text-white">
                Discover EduYog
              </Link>
            </button>
            <button className="btn btn-primary fw-bold py-3 px-4 mx-3 rounded-pill">
              <Link to="/placement" className="text-decoration-none text-white">
                Pay After Success
              </Link>
            </button>
          </div>
        </div>
      </div>
  <BannerStatic/>
  <BannerComponent/>
      {/* Second Section */}
      <div className="p-2 px-4 text-white mt-5" style={{ backgroundColor: "white" }}>
        <div className="container mt-5">
          <div className="text-center">
            <h1 className="display-4 fw-bold text-dark">Yoga for Mind and Body</h1>
            <p className="lead text-black">Discover Inner Peace and Holistic Wellness</p>
          </div>
          <div className="row align-items-center ">
            <div className="col-md-6 order-md-last">
              <div className="position-relative overflow-hidden rounded ">
                {/* <img
                  className="img-fluid rounded imagedata"
                  src="https://i.postimg.cc/hPbdf0wH/YogaPg.png"
                  alt="Yoga Image"
                  width={400}
                /> */}
                <video src={Coding3} autoPlay loop muted style={{height:'300px', width:'max-content'}} />
                <div className="overlay bg-gradient"></div>
              </div>
            </div>
            <div className="col-md-6 text-md-start my-md-8 animate__animated animate__fadeInLeft">
              <h2 className="my-md-5 text-warning text-dark fw-bold">Welcome to our serene yoga community</h2>
              <p className="text-muted mto">
                Immerse yourself in the world of yoga with EduYog and kickstart your journey towards physical and mental well-being. Our dedicated instructors at EduYog are committed to guiding you through transformative practices that promote harmony and balance.Join EduYog on the path to inner peace and self-discovery through the wisdom of yoga. Experience the numerous benefits of a regular practice and embrace a healthier, more balanced life with our expert guidance. Your journey to holistic well-being begins with EduYog.
              </p>
            </div>
          </div>
          <div className="mt-4 text-center">
            <button className="btn btn-primary fw-bold py-3 px-3 rounded-pill ">
              <Link to="/registerForYoga" className="text-decoration-none text-white">
                Join Yoga Classes
              </Link>
            </button>
          </div>
        </div>
      </div>

      {/* Fixed Bottom Section */}
      <div className=" text-center py-3 hel">
        <h5 className="text-center mx-auto  p-2 rounded-4">
          Are you a recruiter? Hire the Top 2% techies in less than 48 hours for FREE! <Link to='/submitRecuriter'><button className="btn btn-primary">Hire now</button></Link>
        </h5>
      </div>
      {/* <Trainer/> */}
      <MeetOurTeam/>
      <Admission/>
      <Hire/>
      <Choose/>
      <AboutEduyog/>
      <LastPage/>
      <Testimonials/>
      <SeminarPage/>
    <RoadMap/>
    </Layout>
  );
};

export default Index;
