import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BannerData = () => {
    const [banners, setBanners] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [currentBanner, setCurrentBanner] = useState(null);

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await axios.get('https://eduyog.in/api/banners');
            setBanners(response.data);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://eduyog.in/api/banners/${id}`);
            fetchBanners();
        } catch (error) {
            console.error('Error deleting banner:', error);
        }
    };

    const handleEdit = (banner) => {
        setCurrentBanner(banner);
        setIsEditing(true);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://eduyog.in/api/banners/${currentBanner.id}`, currentBanner);
            setIsEditing(false);
            setCurrentBanner(null);
            fetchBanners();
        } catch (error) {
            console.error('Error updating banner:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentBanner({ ...currentBanner, [name]: value });
    };

    return (
        <div className="banner-container">
            {isEditing ? (
                <div className="admin-interface" style={{ padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
                    <h2 style={{ marginBottom: '10px' }}>Edit Banner</h2>
                    <form onSubmit={handleUpdate}>
                        <input type="text" name="title" placeholder="Title" value={currentBanner.title} onChange={handleChange} style={{ marginBottom: '10px', padding: '5px', width: '100%', borderRadius: '3px', border: '1px solid #ccc' }} />
                        <input type="text" name="subtitle" placeholder="Subtitle" value={currentBanner.subtitle} onChange={handleChange} style={{ marginBottom: '10px', padding: '5px', width: '100%', borderRadius: '3px', border: '1px solid #ccc' }} />
                        <textarea name="description" placeholder="Description" value={currentBanner.description} onChange={handleChange} style={{ marginBottom: '10px', padding: '5px', width: '100%', borderRadius: '3px', border: '1px solid #ccc' }}></textarea>
                        <input type="text" name="link" placeholder="Link" value={currentBanner.link} onChange={handleChange} style={{ marginBottom: '10px', padding: '5px', width: '100%', borderRadius: '3px', border: '1px solid #ccc' }} />
                        <button type="submit" style={{ padding: '8px 15px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Update Banner</button>
                    </form>
                </div>
            ) : (
                banners.map(banner => (
                    <div key={banner.id} className="banner" style={{ marginBottom: '20px', padding: '15px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 0 5px rgba(0,0,0,0.1)' }}>
                        <h2 className="banner-title" style={{ marginBottom: '5px', fontSize: '1.5em' }}>{banner.title}</h2>
                        <h3 className="banner-subtitle" style={{ marginBottom: '10px', color: '#666' }}>{banner.subtitle}</h3>
                        <p className="banner-description" style={{ marginBottom: '10px', fontSize: '1em', lineHeight: '1.4' }}>{banner.description}</p>
                        <a href={banner.link} className="banner-link" style={{ color: '#007BFF', textDecoration: 'none', display: 'inline-block', marginBottom: '10px' }}>Learn More</a>
                        <button onClick={() => handleEdit(banner)} style={{ marginRight: '10px', padding: '8px 15px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Edit</button>
                        <button onClick={() => handleDelete(banner.id)} style={{ padding: '8px 15px', backgroundColor: '#DC3545', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Delete</button>
                    </div>
                ))
            )}
        </div>
    );
};

export default BannerData;
