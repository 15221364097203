import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import './MeetOurTeam.css';
import axios from 'axios';
import image from "../images/back.jpg";

function MeetOurTeam() {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get('https://eduyog.in/api/team-members');
      
      console.log(response.data)
      setTeamMembers(response.data);
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };

  return (
    <section className="meet-our-team py-5" style={{backgroundImage:`url(${image})`}}>
      <Container>
        <h2 className="text-center mb-4 fw-bold display-4">Meet Our Team</h2>
    
        <Carousel>
          {teamMembers.map((member, index) => (
            <Carousel.Item key={index}>
              <Row className="justify-content-center align-items-center">
                <Col md={3} className="text-center">
                  <div className="team-members">
                    
                  <img   src={`https://eduyog.in/${member.image_url}`} alt={member.name} className="img-fluid rounded-circle shadow" />

                    {console.log(member.image_url)}
                    <div className="member-info mt-3">
                      <h4 className="mb-0">{member.name}</h4>
                      <p className="mb-1 text-muted">{member.role}</p>
                      <p className="mb-0">{member.qualification}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
}

export default MeetOurTeam;
