import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Secret Password for Discord',
      content: (
        <div>
          <p className="task-mvp">Secret Password for Discord</p>
         
                  
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p> 
                    IMPORTANT - How does this work?
<br/>
Check it here: <a href="https://youtu.be/nPmdafMo1b8?si=hxbg9DOUz-1AmjNz" target='_blank'>
Click here&nbsp;
                          </a>

<br/><br/>

You have unlocked a new level -&gt; JS - 2 ,



Tell the Discord 'Role Bot' the secret password to access the channel!

<br/>

Secret Password:- " "
<br/>

Access EduYog's Discord server here - <a href="">
Click here&nbsp;
                          </a><br/><br/>
                    </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'The Execution Context of Javascript',
        content: (
          <div>
            <p className="task-mvp">The Execution Context of Javascript</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      What is the Execution Context in Javascript.

<br/>
<br/>
Go through the following videos from the playlist and understand how does javascript execute the code in multiple phases

<br/>
<br/>

Video 1 - <a href="https://www.youtube.com/watch?v=ZvbzSrg0afE" target='_blank'>
Click here&nbsp;
                          </a>
<br/>
Video 2 - <a href="https://www.youtube.com/watch?v=iLWTnMzWtj4" target='_blank'>
Click here&nbsp;
                          </a>
<br/><br/>




Once you are through the above video , Understand how does the execution context work for the following javascript code.


<br/><br/>
Question 1 

<br/>
<br/>
var number1 = 2;
<br/>
var number2 = 5

<br/><br/>

function add(a, b){
    <>
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;var answer = a + b; //line 1
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return answer;
    </>
}
<br/><br/>
var sum = add(number1, number2); // line 2
<br/><br/>


console.log(sum) //line 3
<br/>

<br/>
console.log(answer) //line 4<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Stepping into the shoes of an Interviewer',
        content: (
          <div>
            <p className="task-mvp">Stepping into the shoes of an Interviewer</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Giving Interviews is one type of an experience , but taking interview of other candidates is a very different experience all together.
  <br/>
  You get to understand what goes inside the mind of an interviewer.
  
  <br/><br/>
  
  Before you start taking interviews
  <br/>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Watch this video to understand about the benefits of being an interviewer - <a href="https://youtu.be/xhQjhk7vtbU?si=rEEDajvW4S8uCxac" target='_blank'>
                              Click here&nbsp;
                            </a>
  
  <br/><br/>
  
  
  If you have any questions, please let your student counsellor know.
  
  <br/><br/>
  
  (If the interviewee you have chosen is not picking up your call - Please send him the following message a
      <br/>
      &nbsp;&nbsp;&nbsp;"This is your mock interviewer from EduYog, Please call me as soon as possible to get your mock interview scheduled. Thank you!!!"
      <br/>
  
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Hoisting in Javascript',
        content: (
          <div>
            <p className="task-mvp">Hoisting in Javascript</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Go through the following video to understand hoisting in depth

<br/><br/>

<a href="https://www.youtube.com/embed/Fnlnw8uY6jo" target='_blank'>
                              Watch this video&nbsp;
                            </a>

                            <br/><br/>

Guess the Output  

<br/><br/>



QUESTION 1

<br/><br/>

var a = 3;

<br/><br/>

function printName(name){
    <>
 <br/>
console.log("name")
<br/>
</>
}

<br/>

printName("YAVTECH");
<br/>
console.log(a)

<br/><br/>

Ans:- YAVTECH (first line) 3 (second line)


<br/><br/>
QUESTION 2


<br/><br/>
printName("YAVTECH");
<br/>
console.log(a);


<br/><br/>
var a = 3;

<br/><br/>

function printName(name){
 <>
 <br/>
console.log("name")
<br/>
<br/>

Ans:- YAVTECH (first line) undefined (second line)
</>
}


<br/><br/>
QUESTION 3


<br/>

<br/>
console.log(printName);
<br/>
console.log(a);

<br/><br/>

var a = 3;


<br/><br/>
var printName = (name) =&gt; {
 <>
 <br/>
console.log("name")
<br/>
</>
}
<br/>
Watch this video to understand some tricky hoisting question answers

<br/>
<br/>

Ans:- Error as -&gt; printname is invoked without any arguments

<br/><br/>

QUESTION 4


<br/><br/>
console.log(printName);
<br/>
console.log(a);


<br/><br/>
var a = 3;


<br/><br/>
var printName = function (name) {
 <>
 <br/>
console.log("name")
<br/>
<br/>
Ans:-function reference (First line) undefined (Second line)
<br/>
<br/>
After watching the above video Click on the Button Done and start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.<br/><br/>
</>
}
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Windows Object, this Keyword lexical scoping',
        content: (
          <div>
            <p className="task-mvp">Windows Object, this Keyword lexical scoping</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Go through this video to understand how the windows object and this works
<br/>
                      <a href="https://www.youtube.com/embed/QCRpVw2KXf8" target='_blank'>
                              Watch this video&nbsp;
                            </a>

                            <br/><br/>



QUESTION 1
<br/><br/>


What will be the output of the following 

<br/><br/>

var a = 2;
<br/>
var c = 2;
<br/>

<br/>
function b(){
<><br/>
var x = 2;
<br/>
var c = 4
<br/>
console.log(c)<br/>
</>
}

<br/><br/>

console.log(a);
<br/>
console.log(this.a);
<br/>
console.log(this.c)
<br/>
console.log(this.x)
<br/>
console.log(window.a)
<br/>
console.log(window.x)
<br/>
console.log(b());

<br/><br/>



Ans:- 2 2 2 undefined 2 undefined 4<br/><br/>


QUESTION 2
<br/><br/>


For solving the following questions, first understand how lexical scoping works
<br/>
Watch this video
<br/>

<a href="https://www.youtube.com/embed/uH-tVP8MUs8" target='_blank'>
                              Watch this video&nbsp;
                            </a>
<br/><br/>



Find the output 


<br/><br/>
function abc() {
<><br/>
console.log(a);<br/>
</>
}


<br/><br/>
var a = 7;
<br/>
abc();
<br/>
<br/>
Ans:- 7<br/><br/>
QUESTION 3
<br/>
<br/>

Find the output 
<br/><br/>


function abc() {
<><br/>
console.log(a);
<br/>
</>
}
<br/><br/>


abc();
<br/>
var a = 7;


<br/><br/>
Ans:-  undefined <br/><br/>
QUESTION 4


<br/><br/>
Find the output 
<br/><br/>


function outerfunction() {
<><br/>
console.log(a);
<br/>
var c = 10;

<br/><br/>

innerfunction();
<br/><br/>


function innerfunction() {
<><br/>
console.log(b);
<br/>
console.log(c);
<br/>
</>
}<br/>
</>
}

<br/><br/>

var a = 7;
<br/>
var b =3


<br/><br/>
outerfunction();
<br/><br/>
Ans:- 7 3 10
<br/><br/>



QUESTION 5

<br/><br/>

Find the output

<br/><br/>

function outerfunction() {
<><br/>
console.log(a);
<br/>
var a = 10;
<br/><br/>


innerfunction();

<br/><br/>

function innerfunction() {
<><br/>
console.log(a);
<br/>
console.log(window.a);
<br/>
console.log(this.a)
<br/>
</>
}<br/>
</>
}


<br/><br/>
var a = 7;
<br/>
var b =3
<br/>
<br/>

outerfunction();

<br/><br/>
Ans:-  undefined 10 7 7<br/><br/>
This question is a little tricky, please go through this video to understand how things work 
<a href="https://www.youtube.com/embed/pKHgi3sgk4Q" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>
<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Let , Var and Const',
        content: (
          <div>
            <p className="task-mvp">Let , Var and Const</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Go through this video and Understand the following questions WITH REASONs.
                      <a href="https://www.youtube.com/embed/BNC6slYCj50" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>
<br/>
<br/>
QUESTION 1

<br/><br/>

console.log(a)
<br/>
console.log(b);
<br/>
let a =5;
<br/>
var b =6;
<br/>
console.log(b);

<br/><br/>





QUESTION 2


<br/><br/>
What is the difference between type error vs syntax error vs reference error.
<br/>
Give Examples of when you can encounter each one of them.
<br/><br/>


QUESTION 3
<br/><br/>


console.log(a);
<br/>
var a = 5;

<br/><br/>

console.log(b) //we havent initialized b, Guess the output

<br/><br/>

QUESTION 4


<br/><br/>
let a = 5;
<br/>
var b = 6;

<br/><br/>

console.log(this.b);
<br/>
console.log(window.b)
<br/>
console.log(window.a)
<br/>
console.log(this.a);

<br/><br/>

QUESTION 5
<br/><br/>


let a =5;
<br/>
let a = 6;
<br/><br/>


What will be the error


<br/><br/>
QUESSTION 6
<br/>

<br/>
let a =6;
<br/>
const b
<br/>
b=100;
<br/><br/>


What will be the error
<br/><br/>


Watch this video to understand how to iterate through objects (Common Interview question)
<br/>

<a href="https://youtu.be/XnFIX3c7xoI?si=ep5Ha79J6ww8PYxb" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>
<br/>
1) Take any sample object and write the code to iterate through object. <br/><br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is Error --&gt; a is not defined , Question 3 is Errror --&gt; b is not defined , Question 4 is 6 6 undefined undefined , Question 5 is 'a' has already been declared and Question 6 is const declaration should be initialized with a value<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Closures, Settimeout, ES6 features',
        content: (
          <div>
            <p className="task-mvp">Closures, Settimeout, ES6 features</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Find the output of the following function along with reasons. There are no videos to watch in this task.

<br/>
<br/>


QUESTION 1


<br/><br/>
console.log('a');
<br/>
console.log('b');
<br/><br/>


setTimeout(()=&gt; console.log('c'), 1000);

<br/><br/>

console.log('d');


<br/><br/>
QUESTION 2 
<br/><br/>


console.log('a');
<br/>
console.log('b');

<br/><br/>

setTimeout(()=&gt; console.log('c'), 1000);

<br/><br/>

setTimeout(()=&gt; console.log('d'), 0);
<br/><br/>


console.log('e');

<br/><br/>



QUESTION 3

<br/><br/>

Write down the 10 new features introduced in ES6 along with what they mean and where they could be used



<br/><br/>

QUESTION 4

<br/><br/>

What is array.map function . Array.map is used a lot in javascript (Please understand the use case properly) ---&gt; 99% interviewers will ask you this.
<br/>
Watch this video  to understand it in depth and guess the following output

<br/><br/>

4a)
<br/>
var arr = [1, 2,3,5]

<br/><br/>

var newArr = arr.forEach((item, i ) =&gt; {
<><br/>
console.log(item + 'index' + i)
<br/>
return item + i<br/>
</>
})
<br/><br/>


console.log(newArr)
<br/><br/>


4b)
<br/>
var arr = [1, 2,3,5]
<br/><br/>


var newArr = arr.map((item, i ) =&gt; {
<><br/>
console.log(item + 'index' + i)
<br/>
return item + i<br/>
</>
})
<br/><br/>


console.log(newArr)

<br/><br/>

4c) What is the main difference between forEach and map function? [Favourite Interview Question]<br/><br/>
Ans:- Output of Question 1 is a d b c , Question 2 is a b e d c , Question 4a is 1index0 2index1 3index2 5index3 undefined and Question 4b is 1index0 2index1 3index2 5index5 [1,3,5,8]<br/> For remaining question answer please search on google <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Block scope and Shadowing in JS',
        content: (
          <div>
            <p className="task-mvp">Block scope and Shadowing in JS</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Watch this video


<a href="https://www.youtube.com/embed/lW_erSjyMeM" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>
<br/>
What will be the output of the following?
<br/><br/>


1)
<br/>
var a = 50;

<br/><br/>

{

<><br/>
var a =30;
<br/>
let b = 20;
<br/>
let c = 30;<br/>
</>

}

<br/><br/>

console.log(a)
<br/><br/>


2)
<br/><br/>


const a = 50;

<br/><br/>

{

<><br/>
var a =30;
<br/>
let b = 20;
<br/>
let c = 30;<br/>
</>

}


<br/>
console.log(a)

<br/><br/>

3)

<br/><br/>

let a = 50;
<br/><br/>


{

<><br/>
var a =30;
<br/>
let b = 20;
<br/>
let c = 30;<br/>
</>

}
<br/><br/>


console.log(a)
<br/><br/>


4) (THINK SMARTLY BEFORE ANSWERING THIS)

<br/><br/>

var a = 50;
<br/><br/>


function fun(){

<><br/>
var a =30;
<br/>
let b = 20;
<br/>
let c = 30;<br/>
</>

}
<br/>
fun();
<br/>
console.log(a)
<br/>

<br/>
5)

<br/><br/>

let a = 50;
<br/><br/>


function fun(){
<><br/>
var a =30;
<br/>
let b = 20;
<br/>
let c = 30;<br/>
</>
}
<br/>
fun();
<br/>
console.log(a)
<br/><br/>




6)

<br/><br/>

const a = 10;
<br/>
{
<><br/>
var a = 100;<br/>
</>
}
<br/>
console.log(a)
<br/><br/>




7)

<br/><br/>

const a = 10;
<br/>
{
<><br/>
const a = 20;
<br/>
{
<><br/>
const a = 50;
<br/>
console.log(a);<br/>
</>
}<br/>

console.log(a)<br/>
</>
}
<br/>
console.log(a)

<br/><br/>

8)


<br/><br/>
const a = 10;
<br/>
{

<><br/>
const a = 20;
<br/>
{

<><br/>console.log(a);<br/></>

}

console.log(a)<br/>
</>
}
<br/>
console.log(a)

<br/><br/>

9) Explain what did you understand by lexical scope in your own words<br/><br/>
                      <br/><br/>
Ans:- Output of Question 1 is 30 , Question 2 is Error --&gt; a is already declared , Question 3 is 

Error --&gt; a is already declared , Question 4 is 50 , Question 5 is 50 , Question 6 is Error --&gt; var can come out of block scope or Error --&gt; a connot be redeclared , Question 7 is 50 20 10 and  Question 8 is 20 20 10 <br/> For remaining question answer please search on google <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Closures (99 % interviewers ask this)',
        content: (
          <div>
            <p className="task-mvp">Closures (99 % interviewers ask this)</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      
                      
                            Watch this video to understand closures in depth.
<br/>
Take Notes to prepare before interview!!!
<a href="https://www.youtube.com/embed/qikxEIxsXco" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>

                            <br/><br/>


Questions

<br/><br/>

1) 
<br/>
function x(){

<><br/>
let a = 10;
<br/>
function y(){

<><br/>
console.log(a);<br/>
</>

}
<br/>
y()<br/>
</>

}

<br/>

x();

<br/><br/>

2) 
<br/>
function x(){

<><br/>
let a = 10;
<br/>
function y(){

<><br/>
return a;<br/>
</>

}
<br/>
console.log(y());<br/>
</>

}

<br/>

x();

<br/><br/>



3)  function x(){

<><br/>
let a = 10;
<br/>

function y(){
  <><br/>
console.log(a);<br/>
</>
}

<br/>
return y;<br/>
</>

}

<br/>

console.log( x());

<br/><br/>

4)  function x(){

<><br/>

let a = 10;
<br/>
function y(){

<><br/>
console.log(a);
<br/>
</>
}
<br/>
return y;
<br/>
</>
}

<br/>

const z = x()
<br/>
console.log(z());

<br/><br/>

5) 

function x(){

<><br/>
let a = 10;
<br/>
function y(){

<><br/>
console.log(a);
<br/>
</>
}
<br/>
a= 50;
<br/>
return y;<br/>
</>

}

<br/>

const z = x()
<br/>
console.log(z());
<br/><br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is 10 , Question 2 is 10 , Question 3 is Reference of function y , Question 4 is 10 undefined and Question 5 is 50 undefined<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Call Apply and Bind',
        content: (
          <div>
            <p className="task-mvp">Call Apply and Bind</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      
                      
                            Watch this video to Understand Call Apply and Bind.

<a href="https://www.youtube.com/embed/c0mLRpw-9rI" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>

                            <br/><br/>



                            Guess the output


                            <br/><br/>
1)
<br/>
var obj = {
<>
<br/>
val: 100
<br/>
</>
}
<br/>
function fun(){
<>
<br/>
console.log(this.val)
<br/>
</>
}
<br/>
fun()
<br/><br/>


2)

<br/><br/>

var obj = {
  <>
  <br/>
val: 100
<br/>
  </>
}
<br/>
function fun(){
 <>
  <br/>
console.log(this.val)
<br/>
 </>
}
<br/>
obj.fun()
<br/><br/>


3)
<br/>
var obj = {
 <>
  <br/>
val: 100
<br/>
 </>
}
<br/>
function fun(){
  <>
  <br/>
console.log(this.val)
<br/>
  </>
}
<br/>
fun.call(obj)
<br/><br/>


4)


<br/><br/>
var obj = {
  <>
  <br/>
val: 100
<br/>
  </>
}
<br/>
function fun(a){
  <>
  <br/>
console.log(this.val + a)
<br/>
  </>
}
<br/>
fun.call(obj)

<br/><br/>

5)

<br/><br/>

var obj = {
  <>
  <br/>
val: 100
<br/>
  </>
}
<br/>
function fun(a){
  <>
  <br/>
console.log(this.val + a)
<br/>
  </>
}
<br/>
fun.call(obj, 3)
<br/><br/>


6)

<br/><br/>

var obj = {
 <>
  <br/>
val: 100
<br/>
 </>
}
<br/>
function fun(a, b , c){
  <>
  <br/>
console.log(this.val + a + b + c)
<br/>
  </>
}
<br/>
fun.call(obj, 3, 4, 5)

<br/><br/>

7)
<br/>
var obj = {
 <>
  <br/>
val: 100
<br/>
 </>
}
<br/>
function fun(a, b , c){
  <>
  <br/>
console.log(a)
<br/>
console.log(b)
<br/>
console.log(c)
<br/>
  </>
}
<br/>
fun.call(obj, [3, 4, 5])
<br/><br/>


8)

<br/><br/>

var obj = {
  <>
  <br/>
val: 100
<br/>
  </>
}
<br/>
function fun(a, b , c){
 <>
  <br/>
console.log(this.val + a + b + c)
<br/>
 </>
}
<br/>
fun.apply(obj,[ 3, 4, 5])

<br/><br/>

9)
<br/>
var obj = {
 <>
  <br/>
val: 100
<br/>
 </>
}
<br/>
function fun(a, b , c){
  <>
  <br/>
console.log(this.val + a + b + c)
<br/>
  </>
}
<br/>
const fun2 = fun.bind(obj)

<br/>

fun2(1, 2,3)
<br/><br/>




Call Apply and bind are very powerful and useful keywords.


<br/><br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is undefined , Question 2 is Error , Question 3 is 100 , Question 4 is NAN , Question 5 is 103 , Question 6 is 112 , Question 7 is 3 4 5 undefined undefined
  , Question 8 is 112 and Question 9 is  None of the bove.
  <br/><br/>
  Question 10 is Bind returns a function with object attached to it or Call and apply invokes the function with the object and arguments.
  <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Advanced Closures',
        content: (
          <div>
            <p className="task-mvp">Advanced Closures</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      
                      
                      Watch this video for reference and answer the following questions

<a href="https://www.youtube.com/embed/eBTBG4nda2A" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>

                            <br/><br/>



                            Questions




                            <br/>
                            <br/>
1)
<br/>

function y(){

<>
<br/><br/>

setTimeout(() =&gt; console.log("a"), 1000)
<br/>
console.log('Done Coding')

<br/><br/>
</>

}
<br/>
y();

<br/><br/>

Explain how you got the answer

<br/>

2)
<br/>
function y(){

<>
<br/><br/>

setTimeout(() =&gt; console.log("a"), 0)
<br/>
console.log('Done Coding')
<br/><br/>
</>


}
<br/>
y();
<br/><br/>


Explain how you got the answer

<br/><br/>

3)
<br/>
function y(){

<>
<br/><br/>

for(var i=1;i&lt;6;i++){
  <>
  <br/>
setTimeout(() =&gt; console.log(i ), i * 1000)
<br/>
  </>
}
<br/>
console.log('Done Coding')
<br/><br/>

</>

}
<br/>
y();
<br/><br/>


Explain how you got the answer

<br/><br/>

4)
<br/><br/>
function y(){
<>
<br/><br/>
for(let i=1;i&lt;6;i++){
  <>
  <br/>
setTimeout(() =&gt; console.log(i ), i * 1000)
<br/>
  </> 
}
<br/>
console.log('Done Coding')

<br/>
<br/>
</>
}
<br/>
y();

<br/><br/>

Explain how you got the answer

<br/><br/>

5) Can you complete the following function "name" so that it prints as per the comments.
<br/><br/>


Const name = (arr)=&gt;

  <>
  {"{  }"}
  </>

<br/><br/>
let fun = name(["Ram","Shyam"]);
<br/>
fun()// Print Hello Ram
<br/>
fun()//print Hello Shyam

<br/>
<br/>
Try by yourself first , if stuck check the hints.
<br/><br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is Done Coding a , Question 2 is Done Coding a , Question 3 is Done Coding 6 6 6 6 6 and Question 4 is Done Coding 6 6 6 6 6 
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Functions',
        content: (
          <div>
            <p className="task-mvp">Functions</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      
                      
                      Watch this video to understand javascript function in depth . Make Notes!!!!

<a href="https://www.youtube.com/embed/SHINoHxvTso" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>

                            <br/><br/>



                            Guess the output


                            <br/><br/>
                            1)
<br/>
a()
<br/>
b()
<br/><br/>


function a(){
 <>
  <br/>
console.log('inside a');
<br/>
 </>
}

<br/><br/>

var b = function (){
  <>
  <br/>
console.log('inside b');
<br/>
  </>
}
<br/><br/>


2) Difference between function declaration and function expression
<br/><br/>
3)
<br/>
function a(){
  <>
  <br/>
console.log('inside a');
<br/>
  </>
}

<br/><br/>

var b = function abc(){
  <>
  <br/>
console.log('inside b');
<br/>
  </>
}
<br/>
a()
<br/>
abc()

<br/><br/>

4)
<br/>
function fun(a){
  <>
  <br/>
console.log(a)
<br/>
  </>
}
<br/>
var b = 10;
<br/>
fun(b)
<br/><br/>


5) Please go through this video and understand what is currying in javascript 

<br/><br/>
If you didnt understand this video , then i have tried again to explain .Watch this new video<a href="https://www.youtube.com/embed/qlOXWmv_srU" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>
<br/><br/>
Guess the output
<br/>
5a)
<br/>
function fun1(){
  <>
  <br/>
console.log('a')
<br/>
return () =&gt; {
<>
<br/>
console.log('b')
<br/>
</>
}
  </>
}
<br/><br/>


fun1()

<br/><br/>



5b) function fun1(){
  <>
  <br/>
var a = 10
<br/>
return () =&gt; {
<>
<br/>
a = 20;
<br/>
console.log(a)
<br/>
</>
}
  </>
}

<br/><br/>

fun1()()
<br/><br/>


5c)
<br/>
function fun1(a){
<>
<br/>
return () =&gt; {
<>
<br/>
a = a + 20;
<br/>
console.log(a)
<br/>
</>
}
</>
}
<br/><br/>


fun1(10)(20)

<br/><br/>

5d)
<br/>
 function fun1(a){
  <>
  <br/>
return (b) =&gt; {
<>
<br/>
a = a + b;
<br/>
console.log(a)
<br/>
</>
}
  </>
}

<br/><br/>

fun1(10)(30)
<br/><br/>


5e)

<br/><br/>

 function fun1(a){
  <>
  <br/>
const fun2 = (b) =&gt; {
<>
<br/>
a = a + b;
<br/>
console.log(a)
<br/>
</>
}
  </>
}

<br/><br/> 

fun1(10)(30)
<br/><br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is error , Question 2 is Function statement is alloted memory in the very begining itself (or) Function expression is alloted memory when execution context reaches the expression line. Before that it is undefined 
  , Question 3 is error , Question 4 is Ability to return function from inside a function (or) Ability to pass function as arguments is called first class function , 
  Question 5a is a , Question 5b is 20 , Question 5c is 30 , Question 5d is 40 , Question 5e is error.

  <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Design Pattern (The "This" Keyword)',
        content: (
          <div>
            <p className="task-mvp">Design Pattern (The "This" Keyword)</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      UNDERSTAND THIS KEYWORD (Used every where)
                      <br/> <br/>
                      Watch this video to understand this keyword

<a href="https://www.youtube.com/embed/NV9sHLX-jZU" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>
                            <br/> <br/>
                            Deliverables
                            <br/> <br/>




1. Design pattern problem - How will you design a Student class which stores the name , age , phone number, board marks of each student. Complete the constructor to initialize the values.
<br/>
2. Complete the function eligible to check whether the student is eligible or not for college. If board marks > 40 --> eligible , if less than 40 --> not eligible.
<br/>
3. Complete the function increaseStudentCount which keep track of the number of students created. Have you heard of static variables. Leverage that now. Check Hints 1 if you are stuck.
<br/>
4. Complete the printStudentCount which prints the total count of students created till date.
<br/>
5. Please only fill in the blanks and do not modify anything else. Output should match and there should be no extra spaces.
<br/> <br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'CallBack Function',
        content: (
          <div>
            <p className="task-mvp">CallBack Function</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      
                      Is javascript single threaded or multithreaded?



Watch this video to understand how callback functions work 
                     

<a href="https://www.youtube.com/embed/btj35dh3_U8" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>

                            <br/><br/>



                            Questions

<br/>
<br/>
What will be the output of the following
<br/><br/>


1)

<br/>

setTimeout(() =&gt; console.log('timer expired'), 1000)

<br/><br/>

function x(y) {
  <>
  &#123;
  <br/>
console.log('inside x');
<br/>
y();
<br/>
&#125;
  </>
}


<br/><br/>
x(function y(){
  <>
&#123;
  <br/>
console.log('inside y')
<br/>
&#125;
  </>
}
)
<br/><br/>


2)
<br/>


setTimeout(() =&gt; console.log('timer1 expired'), 1000)

<br/><br/>

setTimeout(() =&gt; console.log('timer2 expired'), 0)
<br/><br/>


function x(y) {
  <>
  &#123;
  <br/>
console.log('inside x');
<br/>
y();
<br/>
&#125;
  </>
}
<br/><br/>


x(function y(){
  <>
  &#123;
  <br/>
console.log('inside y')
<br/>
&#125;
  </>
}
)

<br/><br/>



3)

<br/>

setTimeout(() =&gt; console.log('timer1 expired'), 1000)

<br/><br/>

setTimeout(() =&gt; console.log('timer2 expired'), 0)

<br/><br/>

function x(y) {
  <>
  &#123;
  <br/>
console.log('inside x');
<br/>
y();
<br/>
&#125;
  </>
}

<br/><br/>

x(function y(){
  <>
  &#123;
  <br/>
setTimeout(() =&gt; console.log('inside y'), 0)
<br/>
&#125;
  </>
}
)

<br/><br/>



4) How does the browser go about execution of the above program.Check the hint if you are not sure. The above questions are very important.

<br/><br/>

5) Add an event listener called DOMCONTENTLOADED , inside this add a call back function which consoles "DOM has loaded"

<br/><br/>

6) What is garbage collection . Why do we need to free up memory , any idea
<br/><br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is inside x inside y timer expired , Question 2 is inside x inside y timer2 expired timer1 expired and 
  , Question 3 is inside x timer2 expired inside y timer1 expired 
<br/>
For remaining question answer please search on google.
  <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Fat Arrow Functions',
        content: (
          <div>
            <p className="task-mvp">Fat Arrow Functions</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      
                      Go through this video to understand fat arrow functions in depth
                     

<a href="https://www.youtube.com/watch?v=_pfXEv9cFGE" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>

                            <br/><br/>



                            Questions

<br/>
<br/>
Guess the output of the following
<br/>
1)
<br/>
var fun = a =&gt; a
<br/>
var a =10;
<br/>
console.log(fun(a))
<br/><br/>


2)
<br/>
var fun = a =&gt; a
<br/>
console.log(fun(a))
<br/>
var a =10;

<br/><br/>

3)

<br/>
var fun = a=&gt; a 
<br/>
var a =10;
<br/>
console.log(fun(a))
<br/><br/>



4)
<br/>
var fun =( a, b) =&gt;&#123;
<>
<br/>
var sum = a + b
<br/>
return sum
<br/>
</>
&#125;
<br/>
var a =10;
<br/>
var b = 20
<br/>
console.log(fun(a, b))
<br/><br/>


5)
<br/>
var fun =( a, b) =&gt;
<br/>
var sum = a + b
<br/>
return sum
<br/>
var a =10;
<br/>
var b = 20
<br/>
console.log(fun(a, b))
<br/><br/>


6) Main problem solved by fat arrow function
<br/>
var student = function(name)&#123;

  <><br/>
  this.name = name;
  <br/>
function printName()&#123;

  <><br/>
  console.log(this.name)<br/>
  </>

  &#125;
<br/>
 printName()<br/>
  </>

  &#125;
<br/>
var yash = new student("yash")
<br/><br/>


7) Beautifully solved by fat arrow function. Always remember this.[Favorite interview question]
<br/>
var student = function(name)&#123;

  <><br/>
  this.name = name;
  <br/>
var printName = () =&gt; &#123;

  <><br/>
  console.log(this.name)
  <br/>
  </>
  &#125;
<br/>
 printName()<br/>
  </>

  &#125;
<br/>
var yash = new student("yash")
<br/><br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is 10 , Question 2 is undefined , Question 3 is undefined  , Question 4 is 30  , Question 5 is error
  , Question 6 is undefined or yash  and 
   Question 7 is yash. 
<br/>
For remaining question answer please search on google.
  <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'The wierd mystery',
        content: (
          <div>
            <p className="task-mvp">The wierd mystery</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      
                      Understand currying to solve the following problem.
                     

<a href="https://www.youtube.com/watch?v=vQcCNpuaJO8" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>

                            <br/><br/>



                            Deliverables
                            <br/>
1.Take the student class and write a function called setPlacementAge which basically takes the minimum board marks required by a candidate to sit for the company coming for placement and it returns a function.
<br/>2.The function takes minAge as an argument and returns true if the given student has board marks greater than minimum board marks required by company and is above the required age set by the company.[If stuck check the hint 1]
<br/>3.Run the code across all the students and print the names of egligible students.

<br/><br/>


Push the code to git and upload the commit ID
  <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Event Loop',
        content: (
          <div>
            <p className="task-mvp">Event Loop</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>

                      Watch uptill the 27th min from the beging. Skip remaining for now.

<a href="https://www.youtube.com/watch?v=8zKuNo4ay8E" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>

                            <br/><br/>



                            This is one of the most important topics for interview purpose.



Deliverable



1) which function would get in the call stack first
<br/>
function fun1()&#123;
<br/>
<>
console.log(á)
</>
<br/>
&#125;
<br/>
function fun2()&#123;
<br/>
<>
console.log(b)
</>
<br/>
&#125;
<br/><br/>


fun2()
<br/>
fun1()
<br/>

<br/>
2)
<br/>
Which function would be picked by the Global Execution Context first ?
<br/>
function fun1()&#123;
<br/>
console.log(a)
<br/>
&#125;
<br/>
function fun2()&#123;
<br/>
console.log(b)
<br/>
&#125;
<br/><br/>


fun2()
<br/>
fun1()

<br/><br/>

3)What all things are not a part of javascript?
<br/>

<br/>
4)If so many things are not a part of javacript then how do we get to access them in browser? Write in your own words.

<br/><br/>

5) If window object would not have been a global object which of the following statements would not work? More than one correct answer.

<br/><br/>

6)
<br/>
function fun1()&#123;
<br/>
console.log("a")
<br/>
&#125;
<br/>
function fun2()&#123;
<br/>
console.log(b)
<br/>

&#125;

<br/>
setTimeout(fun2, 1000)
<br/>
fun1()
<br/>

<br/>
7) function fun1()&#123;
<br/>
console.log("a")
<br/>
&#125;
<br/>
function fun2()&#123;
<br/>
console.log(b)
<br/>
&#125;
<br/><br/>


document.getElementById("some button").addEventListener("click", fun1)
<br/>
fun2();

<br/><br/>





Love the way event loop iis explained by this restaurant example <a href="https://www.youtube.com/watch?v=EI7sN1dDwcY" target='_blank'>
                             &nbsp; Click here&nbsp;
                            </a>and answer the following.
<br/>
Why is a callback queue used and not a callback stack?<br/>
Explain how does event loop handle async code? why does it not wait for async function ?<br/>
<br/><br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is fun2, Question 2 is fun2 
  , Question 3 is fetch ,setTimeOut, Localstorage ,document.getElementById ,
  Output of Question 5 is <>
    setTimeout(() =&gt; &#123; console.log("a")&#125;, 1000&#125;</>&#125; ,localStorage.setItem("name", "yash") , document.getElementByID("some ID"), window.document.getElementByID("some ID")
, Output of Question 6 is fun1 enters call stack first and then fun2 , fun1 enters call stack first and fun2 first enters callback queue and then callsatck
, Output of Question 6 when no one clicks on button is b and Output of Question 6 when user clicks on button 2 times is b a a . 
<br/>
For remaining question answer please search on google.
  <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Searching student of particular age',
        content: (
          <div>
            <p className="task-mvp">Searching student of particular age</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>

                      Given a student object as shown below can you write code to iterate through object and find the student's name who is of age n.
                      <br/><br/>


Example 1
<br/>
Input
<br/>
studentobj = &#123;
<>
<br/>
'yash': 26,
<br/>
'vaibhav': 24,
<br/>
'rajesh' : 25,<br/>
</>

&#125;

<br/><br/>

age = 25
<br/><br/>


Output
<br/>
rajesh
<br/><br/>


Explanation
<br/>
Rajesh is the only one with age 25 in the above object.
<br/><br/>


Example 2
<br/>
Input
<br/>
studentobj = &#123;
<br/>
<>
'yash': 26,
<br/>
'vaibhav': 24,
<br/>
'rajesh' : 25,
</>
<br/>
&#125;
<br/><br/>


age = 29
<br/><br/>


Output
<br/>
-1
<br/><br/>


Explanation
<br/>
No student has the age 29 , thats why function will return -1
<br/><br/>




Assumption
<br/>
There will be only one student, at max ,in student Object whose age will match.
<br/>
2 students of same age will never exist.
  <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Application of event loop and spread operators',
        content: (
          <div>
            <p className="task-mvp">Application of event loop and spread operators</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      
                      Guess the output with reason stating why you chose the answer

                      <br/><br/>

1)<br/>

console.log('a');
<br/>
console.log('b');
<br/>
setTimeout(() =&gt; console.log('c'), 1000)
<br/>
console.log('d')

<br/><br/>



2)
<br/>
console.log('a');
<br/>
console.log('b');
<br/>
setTimeout(() =&gt; console.log('c'), 0)
<br/>
console.log('d')

<br/><br/>



3)
<br/>
console.log('a');
<br/>
console.log('b');
<br/>
setTimeout(() =&gt; console.log('e'), 1000)
<br/>
setTimeout(() =&gt; console.log('c'), 0)
<br/>
console.log('d')
<br/><br/>


4a)What is the use of spread operator?
<br/><br/>


4b) const obj1= &#123;
<br/>
<>
"key1": "value1",
<br/>
"key2" : "value2",
<br/>
"key3" : "value3"
</>
<br/>
&#125;
<br/>
const obj2 =&#123; ...obj1&#125;
<br/>
console.log(obj2)
<br/><br/>


4c) const obj1=&#123;
<br/>
<>
"key1": "value1",
<br/>
"key2" : "value2",
<br/>
"key3" : "value3"
</>
<br/>
&#125;
const obj2 =&#123; ...obj1	, "key3": "new value"&#125;
<br/><br/>


4d) const obj1= &#123;
<>
<br/>
"key1": "value1",
<br/>
"key2" : "value2",
<br/>
"key3" : "value3"
</>
<br/>
&#125;
<br/>
const obj2 =&#123; ...obj1, "key3": "new value", "key1": "new val" &#125;
<br/><br/>


4e) const obj= &#123;
<br/>
<>
"key1": "value1",
<br/>
"key2" : "value2",
<br/>
"key3" : "value3"
<br/>
</>
&#125;
<br/>
const obj2 = &#123;...obj&#125;
<br/>
console.log(obj2 === obj)
<br/><br/>


4f) const arr1 = [1, 2, 3]
<br/>
const arr2 = [3,5,6]
<br/>
const arr3 = [...arr1, ...arr2]
<br/>
console.log(arr3)
<br/><br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is a b d c , Question 2 is a b d c , Question 3 is  a b d c e , Question 4(b) is same as obj1  , Question 4(c) is Same as obj1 and only key3 value changes  ,
  Question 4(d) is Same as obj1 and only key3 and key 1 value changes ,Question 4(e) is alse, because the objects point to different references and 
  Question 4(f) is 123356 .
<br/>
For remaining question answer please search on google.
  <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Iterating through Objects',
        content: (
          <div>
            <p className="task-mvp">Iterating through Objects</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                   
                      Given a student object as shown below can you write code to iterate through object and find average age of students.

                      <br/>   <br/>

Input
<br/>
studentobj = &#123;
<br/>
'yash': 26,
<br/>
'vaibhav': 24,
<br/>
'rajesh' : 25,
<br/>
&#125;

<br/>   <br/>

Output
<br/>
Average age = 25

<br/>   <br/>

Explanation
<br/>
On adding all the ages we get 75
<br/>
Total number of keys = 3
<br/>
So 75/3 = 25.

<br/>   <br/>

Can you write a function which takes the object and return average age.
<br/>   <br/>


function findaverageage(studentObj)&#123;
<br/>
//write your code here.


<br/>   <br/>
return averageAge;
<br/>
&#125;
<br/>
<br/>
Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer
  <br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];


const AdvanceJS2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 20) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/16'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 20</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default AdvanceJS2;


