import React, { useEffect, useState } from "react";
import { Box, Button, Input, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ErrorHandler from "../UtilsData/ErrorHandler";
import CNavbar from "../../component/CNavbar";

export const SignUpData = () => {
  const nav = useNavigate();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const [data, setData] = useState(null);

  const sendData = async (body) => {
    try {
      const response = await fetch("https://eduyog.in/authFT/registerFT", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(
          `HTTP error! Status: ${response.status} Message : ${error.message}`
        );
      }

      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      setShowAlert(true);
      setErrorMsg(error.message);
      setData(null);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    const userData = { name, email, password, phone };

    if (phone.length < 10) {
      setShowAlert(true);
      setErrorMsg("Enter a valid phone number!");
      return;
    }

    if (name.length < 6) {
      setShowAlert(true);
      setErrorMsg("Enter a name of at least 6 characters!");
      return;
    }

    if (!validateEmail(email)) {
      setShowAlert(true);
      setErrorMsg(`${email} is not a valid email address.`);
      return;
    }

    if (password.length < 8) {
      setShowAlert(true);
      setErrorMsg("Password must be at least 8 characters long.");
      return;
    }

    sendData(userData);
  };

  useEffect(() => {
    if (data) {
      nav("/timerFT", { state: { id: data.id } });
    }
  }, [data]);

  return (
    <div>
      <CNavbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "#f5f5f5",
          padding: "20px",
          marginTop:"-6%"
        }}
      >
        <Stack
          sx={{
            bgcolor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            p: "2rem",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Typography variant="h5" sx={{ mb: "2rem", textAlign: "center" }}>
            Register
          </Typography>
          <ErrorHandler
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            errorMsg={errorMsg}
          />
          <form onSubmit={handleSignUp}>
            <Input
              fullWidth
              value={name}
              disableUnderline={true}
              sx={{ mb: "1rem" }}
              type="text"
              required={true}
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              fullWidth
              value={email}
              disableUnderline={true}
              sx={{ mb: "1rem" }}
              type="email"
              required={true}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              fullWidth
              value={password}
              disableUnderline={true}
              sx={{ mb: "1rem" }}
              type="password"
              required={true}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              fullWidth
              value={phone}
              disableUnderline={true}
              sx={{ mb: "1rem" }}
              type="text"
              required={true}
              placeholder="Phone"
              onChange={(event) => {
                var regex = /^[0-9]+$/;
                const value = event.target.value;

                if (value.match(regex)) {
                  if (event.target.value.length <= 10) {
                    setPhone(event.target.value);
                  }
                } else if (value === "") setPhone(event.target.value);
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: "1rem" }}
            >
              Register
            </Button>
            <Typography
              variant="body2"
              sx={{ mt: "1rem", textAlign: "center" }}
            >
              Already have an account?{" "}
              <Link to="/loginFT" style={{ textDecoration: "none" }}>
                Login
              </Link>
            </Typography>
          </form>
        </Stack>
      </Box>
    </div>
  );
};
