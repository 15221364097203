import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DataWait = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [homeworkContent, setHomeworkContent] = useState("");
  const [timerSeconds, setTimerSeconds] = useState(20);
  const [redirectToNextPage, setRedirectToNextPage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("https://eduyog.in/datawork/done", {
        email,
        name,
        content: homeworkContent,
      });

      if (res.data.success) {
        toast.success(res.data.message);
        localStorage.setItem("timerStartTime", new Date().getTime());
        localStorage.setItem("timerEmail", email);
        setRedirectToNextPage(true);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    const timerStartTime = localStorage.getItem("timerStartTime");
    const storedEmail = localStorage.getItem("timerEmail");

    if (timerStartTime && storedEmail === email) {
      const elapsedTime = (new Date().getTime() - parseInt(timerStartTime)) / 1000;

      if (elapsedTime < timerSeconds) {
        const remainingSeconds = timerSeconds - elapsedTime;
        setTimerSeconds(remainingSeconds);
        setRedirectToNextPage(true);

        const countdownInterval = setInterval(() => {
          setTimerSeconds((prevSeconds) => {
            if (prevSeconds > 0) {
              return prevSeconds - 1;
            } else {
              clearInterval(countdownInterval);
              return 0;
            }
          });
        }, 1000);

        return () => clearInterval(countdownInterval);
      }
    }
  }, [email, timerSeconds]);

  useEffect(() => {
    if (redirectToNextPage && timerSeconds === 0) {
      window.location.href = "/nextPage";
    }
  }, [redirectToNextPage, timerSeconds]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center mb-4 text-dark fw-normal display-5 ">Any Query</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Query Content:</label>
                  <textarea
                    rows="4"
                    className="form-control"
                    value={homeworkContent}
                    onChange={(e) => setHomeworkContent(e.target.value)}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary btn-block text-center mt-2 ">
                  Submit Query
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Additional Information</h5>
              <p className="card-text">
                Make sure to fill in all the required fields. Feel free to provide any additional details
                about your query in the "Query Content" section.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-12">
          <div className="alert alert-info">
            <p className="mb-0">Don't forget to stretch before starting the session!</p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DataWait;
