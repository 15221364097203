import React, { useEffect, useState } from "react";
import { Box, Button, Input, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CNavbar from "../../component/CNavbar";
import ErrorHandler from "../UtilsData/ErrorHandler";

export const SignInData = () => {
  const nav = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const [data, setData] = useState(null);

  const sendData = async (body) => {
    try {
      const response = await fetch("https://eduyog.in/authFT/loginFT", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      setShowAlert(true);
      setErrorMsg(error.message);
      setData(null);
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    const userData = { email, password };

    if (!validateEmail(email)) {
      setShowAlert(true);
      setErrorMsg(`${email} is not a valid email address.`);
      return;
    }

    if (password.length < 8) {
      setShowAlert(true);
      setErrorMsg("Password must be at least 8 characters long.");
      return;
    }

    sendData(userData);
  };

  useEffect(() => {
    if (data) {
      nav("/timerFT", { state: { id: data.id } });
    }
  }, [data]);

  return (
    <div>
      <CNavbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          background: "#f5f5f5",
          padding: "20px",
          marginTop:"-6%"
        }}
      >
        <Stack
          sx={{
            bgcolor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            p: "2rem",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <Typography variant="h5" sx={{ mb: "2rem", textAlign: "center" }}>
            Login
          </Typography>
          <ErrorHandler
            showAlert={showAlert}
            setShowAlert={setShowAlert}
            errorMsg={errorMsg}
          />
          <form onSubmit={handleSignIn}>
            <Input
              fullWidth
              value={email}
              disableUnderline={true}
              sx={{ mb: "1rem" }}
              type="email"
              required={true}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              fullWidth
              value={password}
              disableUnderline={true}
              sx={{ mb: "1rem" }}
              required={true}
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: "1rem" }}
            >
              Login
            </Button>
          </form>
          <Typography
            variant="body2"
            sx={{ mt: "1rem", textAlign: "center" }}
          >
            Don't have an account?{" "}
            <Link to="/registerFT" style={{ textDecoration: "none" }}>
              Sign Up
            </Link>
          </Typography>
        </Stack>
      </Box>
    </div>
  );
};
