import React, { useState, useEffect } from 'react';
import axios from 'axios';
import image from "../images/back.jpg";


const BannerComponent = () => {
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const response = await axios.get('https://eduyog.in/api/banners');
            console.log(response.data);
            setBanners(response.data);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    const bannerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '20px',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: '20px',
        fontFamily: 'Times New Roman',
        position: 'relative',
        borderRadius: '15px',
        overflow: 'hidden',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    };

    const bannerCardStyle = {
        border: '2px solid #ddd',
        backgroundImage: `url(${image})`,
        borderRadius: '10px',
        padding: '20px',
        textAlign: 'center',
        transition: 'transform 0.2s',
        maxWidth: '400px',
        width: '100%',
        height: '250px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(5px)',
    };

    const titleStyle = {
        color: '#333',
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '10px 0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
    };

    const subtitleStyle = {
        color: '#555',
        fontSize: '18px',
        margin: '10px 0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
    };

    const descriptionStyle = {
        color: '#777',
        fontSize: '16px',
        margin: '10px 0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    };

    const linkStyle = {
        color: '#fff',
        backgroundColor: 'black',
        fontFamily:"times roman",
        padding: '10px 20px',
        textDecoration: 'none',
        borderRadius: '10px',
        display: 'inline-block',
        fontWeight: 'bold',
        marginTop: '10px',
        fontSize:"20px"
    };

    return (
        <div style={bannerStyle}>
            {banners.map(banner => (
                <div key={banner.id} style={bannerCardStyle}>
                    <h2 style={titleStyle}>{banner.title}</h2>
                    <h3 style={subtitleStyle}>{banner.subtitle}</h3>
                    <p style={descriptionStyle}>{banner.description}</p>
                    <a href={banner.link} style={linkStyle}>Buy Now</a>
                </div>
            ))}
        </div>
    );
};

export default BannerComponent;
