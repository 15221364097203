import React, { useState } from "react";
import Layout from "../../component/Layout";
import { Link } from "react-router-dom";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { toast } from "react-toastify";
import Axios from "../../Axios";

const RegisterForYoga = () => {
  const [isPass, setPass] = useState(false);
  const [iscPass, setcPass] = useState(false);
  const [user, setUser] = useState(undefined);

  const handelChange = (e) => {
    setUser((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault(); // Prevent the default form submission behavior
      const res = await Axios.post("https://eduyog.in/authForYoga", user);
      console.log(res);
      if (res.data && res.data.success) {
        toast.success(res.data.message);
        // Reload the page after successful form submission
        window.location.reload();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong.");
    }
  };

  return (
    <Layout>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="card shadow p-4">
              <h3 className="text-center mb-4 text-dark fw-normal display-5">Register For Yoga</h3>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="mb-3">
                  <input
                    type="text"
                    onChange={handelChange}
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    onChange={handelChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <div className="input-group">
                    <input
                      type={isPass ? "text" : "password"}
                      className="form-control border-end-0"
                      placeholder="Password"
                      name="password"
                      onChange={handelChange}
                      required
                    />
                    <span
                      className="input-group-text bg-transparent border-start-0"
                      onClick={() => setPass(!isPass)}
                    >
                      {isPass ? <BsEyeSlash /> : <BsEye />}
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="input-group">
                    <input
                      type={iscPass ? "text" : "password"}
                      className="form-control border-end-0"
                      placeholder="Confirm password"
                      name="cnfpass"
                      onChange={handelChange}
                      required
                    />
                    <span
                      className="input-group-text bg-transparent border-start-0"
                      onClick={() => setcPass(!iscPass)}
                    >
                      {iscPass ? <BsEyeSlash /> : <BsEye />}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  {/* <Link to="forget" className="text-decoration-none">
                    Forget password
                  </Link>{" "} */}
                  <Link to="/loginForYoga" className="text-decoration-none">
                    Login
                  </Link>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary fw-bold">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterForYoga;
