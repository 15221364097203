import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import { Link } from 'react-router-dom';


const RegistrationPage1 = () => {

  const assignments = [
    { id: 1, name: 'Secret Password for Discord	', status: 'Started'},
    { id: 2, name: 'Stacks', status: 'Started'},
    { id: 3, name: 'Queue', status: 'Started'},
    { id: 4, name: 'What are callbacks?', status: 'Started'},
    { id: 5, name: 'The main problem which callback solves', status: 'Started'},
    { id: 6, name: 'Creating a callback hell yourself', status: 'Started'},
    { id: 7, name: 'Lets make Promises - Part 1', status: 'Started'},
    { id: 8, name: 'Lets make promises Part 2', status: 'Started'},
    { id: 9, name: 'Lets break Promise Part 1', status: 'Started'},
    { id: 10, name: 'Lets break promises part 2', status: 'Started'},
    { id: 11, name: 'Lets make and break promises', status: 'Started'},
    { id: 12, name: 'Too many promises in life', status: 'Started'},
    { id: 13, name: 'Async Await', status: 'Started'},
    { id: 14, name: 'Understanding how to use POSTMAN for calling APIs', status: 'Started'},
    { id: 15, name: 'Intro to crudcrud apis and making your first POST request', status: 'Started'},
    { id: 16, name: 'Understanding how to make api calls (Axios)', status: 'Started'},
    { id: 17, name: 'Saving the user Details on Crud Crud', status: 'Started'},
    { id: 18, name: 'Get the saved User Details from crudcrud.', status: 'Started'},
    { id: 19, name: 'Deleting the Appointments', status: 'Started'},
    { id: 20, name: 'Update the user Details', status: 'Started'},
  ];


  return (
    <>
     <div className='plac1'><PlacementNavbar/></div>
      <div className='page1'>
      <div>
        <Sidebar/>
      </div>
      <div>
      <div className="assignment-foj">
      <div className="rig-phw">
        <div className="fle-fok justify-6c5 item-ycb orqly">
          <Link to='/assignment/20'>
          <button className="btn-zle bvadh">Next Assignment</button>
          </Link>
          
        </div>
        <div className="right-c1e">
          <table>
           
            <colgroup>
              {Array(4).fill().map((_, index) => <col key={index} className={`style-${index}EMdC`} />)}
            </colgroup>
            <thead>
              <tr>
                <th className="style-q61Z3">#</th>
                <th>Name</th>
                <th className="style-6q7nt">Status</th>
                <th className='sh'></th>
              </tr>
            </thead>
            <tbody className="style-iNhB2">
              {assignments.map(({ id, name, status, points }, index) => (
                <tr key={id} className="style-GbKIa">
                  <td className="style-CQJlN">{id}</td>
                  <td>
                    {name}
                  </td>
                  <td className="style-mF9jW">
                  <Link to={`/RegistrationPage2/${index + 1}`} className='style-ckn'>{status}</Link>
                  </td>
                  <td className="style-hsgX7">{points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default RegistrationPage1;