import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import image1 from "../images/vainki.jpg";
import image2 from "../images/nitish.jpg";
import image3 from "../images/aditya.jpg";
import image4 from "../images/ritu.jpg";
import image from "../images/back.jpg";

function Testimonials() {
  return (
    <div style={{ 
      backgroundImage: `url(${image})`, 
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      padding: '50px 0' 
    }}>
      <Container>
        <h2 style={{
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '3.5rem',
          marginBottom: '30px',
          color: 'black',
        }}>
          What Our Students Say
        </h2>
        <Carousel>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8} style={{ textAlign: 'center', margin: '20px' }}>
                <img 
                  src={image1} 
                  alt="Student A" 
                  style={{ 
                    display: 'block',
                    margin: '0 auto 20px',
                    borderRadius: '50%', 
                    height: '200px', 
                    width: '200px', 
                    objectFit: 'cover', 
                    border: '3px solid #000',
                  }} 
                />
                <p style={{ fontSize: '1.25rem', color: 'black', marginBottom: '10px' }}>
                  "The Data Science course at EduYog was a game-changer for me. The instructors were supportive, and the content was perfectly aligned with industry standards. I'm now working as a Data Analyst, thanks to EduYog!"
                </p>
                <h5 style={{ color: 'black', fontWeight: 'bold' }}>Vainki Nagaswami - Connect and Heals</h5>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8} style={{ textAlign: 'center', margin: '20px' }}>
                <img 
                  src={image2} 
                  alt="Student B" 
                  style={{ 
                    display: 'block',
                    margin: '0 auto 20px',
                    borderRadius: '50%', 
                    height: '200px', 
                    width: '200px', 
                    objectFit: 'cover', 
                    border: '3px solid #000',
                  }} 
                />
                <p style={{ fontSize: '1.25rem', color: 'black', marginBottom: '10px' }}>
                  "EduYog's Web Development course was exactly what I needed to kickstart my career. The instructors were always available for guidance, and the real-world projects gave me the experience I needed. I secured a job within weeks of completing the course!"
                </p>
                <h5 style={{ color: 'black', fontWeight: 'bold' }}>Nitish Raman - Cisco</h5>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8} style={{ textAlign: 'center', margin: '20px' }}>
                <img 
                  src={image3} 
                  alt="Student C" 
                  style={{ 
                    display: 'block',
                    margin: '0 auto 20px',
                    borderRadius: '50%', 
                    height: '200px', 
                    width: '200px', 
                    objectFit: 'cover', 
                    border: '3px solid #000',
                  }} 
                />
                <p style={{ fontSize: '1.25rem', color: 'black', marginBottom: '10px' }}>
                  "The Python course at EduYog was fantastic. The interactive exercises and real-world examples made it easier to grasp complex concepts. I highly recommend this course to anyone looking to learn Python from scratch."
                </p>
                <h5 style={{ color: 'black', fontWeight: 'bold' }}>Aditya - Nagarro</h5>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8} style={{ textAlign: 'center', margin: '20px' }}>
                <img 
                  src={image4} 
                  alt="Student D" 
                  style={{ 
                    display: 'block',
                    margin: '0 auto 20px',
                    borderRadius: '50%', 
                    height: '200px', 
                    width: '200px', 
                    objectFit: 'cover', 
                    border: '3px solid #000',
                  }} 
                />
                <p style={{ fontSize: '1.25rem', color: 'black', marginBottom: '10px' }}>
                  "EduYog's Data Science course offered a perfect blend of theory and practical experience. The projects helped me build a strong portfolio, and the support from the community was incredible. Highly recommended!"
                </p>
                <h5 style={{ color: 'black', fontWeight: 'bold' }}>Ritu Singh - Tech Mahindra</h5>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
  );
}

export default Testimonials;
