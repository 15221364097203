import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";

const CNavbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navData = [
    { name: "Home", path: "/" },
    { name: "About", path: "/aboutPage" },
    { name: "Services", path: "/service" },
    { name: "Courses", path: "/ourcourses" },
    { name: "Yoga", path: "/yoga" },
    { name: "Free Content", path: "/signIn" },
    { name: "Login", path: "/LoginForCourse" },
  ];

  const logoMaxHeight = screenWidth > 1000 ? "90px" : "70px";
  const navmaxHeight = screenWidth > 1000 ? "80px" : "500px";

  return (
    <Navbar
      expand="lg"
      bg="transparent"
      variant="light"
      className={isScrolled ? "fixed-top" : ""}
      style={{
        minHeight: "80px",
        maxHeight: navmaxHeight,
        borderRadius: "20px",
        backdropFilter: "blur(10px)",
        background: isScrolled
          ? "rgba(255, 255, 255, 0.7)"
          : "rgba(255, 255, 255, 0.4)",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
        transition: "background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease",
        transform: isScrolled ? "scale(0.98)" : "scale(1)",
      }}
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={Logo}
            alt="Logo"
            className="img-fluid"
            style={{
              maxWidth: "130px",
              maxHeight: logoMaxHeight,
              transition: "transform 0.3s ease",
              transform: isScrolled ? "scale(0.9)" : "scale(1)",
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {navData.map((data, index) => (
              <Nav.Link
                key={index}
                as={Link}
                to={data.path}
                style={{
                  transition: "color 0.3s ease, font-size 0.3s ease, transform 0.3s ease",
                  color: data.name === "Free Content" ? "red" : "#333",
                  fontSize: data.name === "Free Content" ? "1.2rem" : "1rem",
                  fontWeight: data.name === "Free Content" ? "bold" : "500",
                  fontFamily: data.name === "Free Content" ? "'Arial', sans-serif" : "'Roboto', sans-serif",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  padding: "0 15px",
                  transform: isScrolled ? "translateY(-2px)" : "translateY(0)",
                  background: data.name === "Free Content" ? "rgba(255, 0, 0, 0.2)" : "transparent",
                  borderRadius: "5px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.color = data.name === "Free Content"
                    ? "darkred"
                    : "#3498db";
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = data.name === "Free Content"
                    ? "red"
                    : "#333";
                  e.target.style.transform = "scale(1)";
                }}
              >
                {data.name}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CNavbar;
