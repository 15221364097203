import React, { useState, useEffect } from 'react';
import { useLocation , Link} from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import axios from 'axios';

const StudentAnswer = () => {
  const location = useLocation();
  const user = location.state?.user;
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`https://eduyog.in/api/forTest`);
        console.log(response.data);
        setContents(response.data);
      } catch (error) {
        console.error('Error fetching course content:', error);
        alert('Failed to fetch course content. Please try again later.');
      }
    };

    fetchContent();
  }, []);

  const handleDownload = (fileUrl) => {
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.target = '_blank'; // Open in a new tab
    anchor.rel = 'noopener noreferrer';
    anchor.click();
  };

  return (
    <>
      <style>
        {`
          .navbar-custom {
            background-color: #0056b3; /* Dark blue */
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
          .navbar-custom .nav-link {
            color: #fff;
          }
          .navbar-custom .btn-danger {
            margin-left: 10px;
          }
          .card-custom {
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            background: linear-gradient(to bottom, #f8f9fa 0%, #f0f2f5 100%);
            transition: transform 0.3s ease-in-out;
          }
          .card-custom:hover {
            transform: translateY(-5px);
          }
          .card-custom .card-body {
            padding: 20px;
          }
          .card-title-custom {
            font-size: 1.5rem;
            margin-bottom: 10px;
            color: #333; /* Dark grey */
          }
          .card-text-custom {
            font-size: 1rem;
            color: #666; /* Medium grey */
          }
          .modal-header-custom {
            background-color: #0056b3; /* Dark blue */
            color: #fff;
            border-bottom: none;
          }
          .modal-body-custom p {
            margin-bottom: 10px;
            color: #333; /* Dark grey */
          }
          .modal-footer-custom {
            display: flex;
            justify-content: flex-end;
            border-top: none;
          }
        `}
      </style>
      <div style={{textAlign:"center" , marginTop:"2%"}}>
        <Link to={'/addCourse'} className="btn btn-outline-primary mr-2">HomePage</Link>
      </div>
      <Container className="mt-5">
        <Row>
          <Col md={12}>
            <h2 className="text-center mb-4">Student AnswerSheet</h2>
            {contents.map((content) => (
              <Card key={content.id} className="card-custom shadow-sm mb-4">
                <CardBody className="card-body">
                  <h4 className="card-title card-title-custom">Name: {content.userName}</h4>
                  <p className="card-text card-text-custom">Email: {content.userEmail}</p>
                  <p className="card-text card-text-custom">CourseId: {content.courseId}</p>
                  {content.fileName && content.fileName.endsWith('.pdf') && (
                    <Button color="primary" onClick={() => handleDownload(content.fileName)}>
                      View PDF
                    </Button>
                  )}
                </CardBody>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StudentAnswer;
