import React from 'react';
import { Link } from 'react-router-dom';
import './Yoga.css';
import CNavbar from './CNavbar';
const YogaPose = ({ name }) => {
  return (
    <li className='yoga-pose'>
      <Link to={`/poses/${name.toLowerCase().replace(' ', '-')}`}>{name}</Link>
    </li>
  );
};

const Yoga = () => {
  const yogaPoses = [
    'AdhoMukhaSvan asana',
    'AshwaSanchalan asana',
    'Chakr asana',
    'EkapadaUrdvhaDhanur asana',
    'Hanuman asana',
    'Hastapad asana',
    'Sarvang asana',
    'Sirs asana',
    'Siddh asana',
    'Vriksh asana'
    // Add more yoga poses as needed
  ];

  return (
    <>
   <CNavbar/>
    <div className='yoga-container'>
      <header className=' display-4 yoga-header'>
        <h1 className='fw-bold text-dark '>Yoga Time</h1>
      </header>
      <main className='yoga-main'>
        <section className='yoga-info'>
          <h2 className='fw-bold text-dark'>About Yoga</h2>
          <p>
            Yoga's diverse benefits extend beyond physical fitness, encompassing stress reduction, enhanced flexibility, and improved mental clarity. The philosophy behind yoga emphasizes the union of mind, body, and spirit, fostering a sense of inner balance and harmony. Additionally, the ethical principles, known as Yamas and Niyamas, guide practitioners in cultivating virtues such as truthfulness, self-discipline, and contentment. The ancient wisdom embedded in yoga teachings transcends cultural boundaries, making it a globally embraced practice. As individuals engage in yoga, they often discover not only a means of physical wellness but also a transformative journey towards self-discovery and mindfulness.
          </p>
        </section>
        <section className='yoga-poses'>
          <h2 className='fw-bold text-dark display-4'>Popular Yoga Poses</h2>
          <ul className='display-6 text-dark fw-bold'>
            {yogaPoses.map((pose, index) => (
              <YogaPose key={index} name={pose} />
            ))}
          </ul>
        </section>
        <section className='yoga-benefits'>
          <h2 className='fw-bold text-dark'>Benefits of Yoga</h2>
          <ul>
  <li>1. Improved flexibility and balance</li>
  <li>2. Enhanced mental clarity and focus</li>
  <li>3. Stress reduction and relaxation</li>
  <li>4. Increased strength and endurance</li>
  <li>5. Better posture and body awareness</li>
</ul>


        </section>
       
      </main>
         <section className='yoga-register'>
          <Link to='/registerForYoga'>
            <button className='register-btn'>Register</button>
          </Link>
        </section>
      <footer className='yoga-footer'>
        <p>Copyright © 2024 All rights reserved.</p>
      </footer>
    </div>
    </>
  );
};

export default Yoga;

