import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CNavbar from './CNavbar';

const ContactUs = () => {
  return (
    <>
      <CNavbar />
      <Container 
        className="mt-5" 
        style={{ 
          maxWidth: '800px', 
          padding: '2rem', 
          borderRadius: '0.5rem', 
          boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)', 
          backgroundColor: '#fff' 
        }}
      >
        <h1 
          className="text-center mb-4" 
          style={{ 
            fontSize: '2.5rem', 
            color: '#333', 
            fontWeight: 'bold' 
          }}
        >
          Contact Us
        </h1>

        <Row className="mb-4">
          <Col>
            <h3 
              style={{ 
                fontSize: '1.5rem', 
                color: '#007bff', 
                borderBottom: '2px solid #007bff', 
                paddingBottom: '0.5rem' 
              }}
            >
              Merchant Legal Entity Name:
            </h3>
            <p 
              style={{ 
                marginTop: '0.5rem', 
                fontSize: '1.2rem', 
                color: '#555' 
              }}
            >
              Eduyog Techno Solution Private Limited
            </p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <h3 
              style={{ 
                fontSize: '1.5rem', 
                color: '#007bff', 
                borderBottom: '2px solid #007bff', 
                paddingBottom: '0.5rem' 
              }}
            >
              Registered Address:
            </h3>
            <p 
              style={{ 
                marginTop: '0.5rem', 
                fontSize: '1.2rem', 
                color: '#555' 
              }}
            >
              Front Part, 55, Village Sahasaraw, Pihuli<br />
              Siwan, Bihar<br />
              PIN: 841287
            </p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <h3 
              style={{ 
                fontSize: '1.5rem', 
                color: '#007bff', 
                borderBottom: '2px solid #007bff', 
                paddingBottom: '0.5rem' 
              }}
            >
              Operational Address:
            </h3>
            <p 
              style={{ 
                marginTop: '0.5rem', 
                fontSize: '1.2rem', 
                color: '#555' 
              }}
            >
              C- 303 2nd Floor C Block, Sector - 122<br />
              Noida, GAUTAM BUDDHA NAGAR, UTTAR PRADESH<br />
              PIN: 201316
            </p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <h3 
              style={{ 
                fontSize: '1.5rem', 
                color: '#007bff', 
                borderBottom: '2px solid #007bff', 
                paddingBottom: '0.5rem' 
              }}
            >
              Telephone No:
            </h3>
            <p 
              style={{ 
                marginTop: '0.5rem', 
                fontSize: '1.2rem', 
                color: '#555' 
              }}
            >
              +91 7295993480
            </p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <h3 
              style={{ 
                fontSize: '1.5rem', 
                color: '#007bff', 
                borderBottom: '2px solid #007bff', 
                paddingBottom: '0.5rem' 
              }}
            >
              E-Mail ID:
            </h3>
            <p 
              style={{ 
                marginTop: '0.5rem', 
                fontSize: '1.2rem', 
                color: '#555' 
              }}
            >
              eduyogtechno@eduyog.in
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUs;
