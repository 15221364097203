export const statesAndCities = {
    "Andhra Pradesh": ["Adoni", "Amaravati", "Anantapur", "Bhimavaram", "Chilakaluripet", "Chittoor", "Dharmavaram", "Eluru", "Gooty", "Gudivada", "Guntakal", "Guntur", "Hindupur", "Kadapa", "Kakinada", "Kavali", "Kurnool", "Machilipatnam", "Madanapalle", "Nandyal", "Narasapuram", "Nellore", "Ongole", "Proddatur", "Rajahmundry", "Srikakulam", "Tadepalligudem", "Tadipatri", "Tenali", "Tirupati", "Vijayawada", "Visakhapatnam", "Vizianagaram"],
    "Arunachal Pradesh": ["Anjaw", "Changlang", "Dibang Valley", "East Kameng", "East Siang", "Itanagar", "Kamle", "Kra Daadi", "Kurung Kumey", "Lepa Rada", "Lohit", "Longding", "Lower Dibang Valley", "Lower Siang", "Lower Subansiri", "Namsai", "Pakke-Kessang", "Papum Pare", "Shi Yomi", "Siang", "Tawang", "Tirap", "Upper Dibang Valley", "Upper Siang", "Upper Subansiri", "West Kameng", "West Siang"],
    "Assam": ["Abhayapuri", "Amguri", "Barpeta", "Barpeta Road", "Bilasipara", "Bongaigaon", "Cachar", "Charaideo", "Darrang", "Dhemaji", "Dhubri", "Dibrugarh", "Diphu", "Goalpara", "Golaghat", "Guwahati", "Hailakandi", "Hojai", "Jorhat", "Karbi Anglong", "Karimganj", "Kokrajhar", "Lakhimpur", "Majuli", "Mangaldoi", "Morigaon", "Nagaon", "Nalbari", "North Lakhimpur", "Rangia", "Sibsagar", "Silchar", "Sonitpur", "Tezpur", "Tinsukia", "Udalguri"],
    "Bihar": ["Araria", "Arrah", "Arwal", "Aurangabad", "Banka", "Begusarai", "Bettiah", "Bhagalpur", "Bihar Sharif", "Buxar", "Chhapra", "Darbhanga", "Dehri", "Dumraon", "Forbesganj", "Gaya", "Gopalganj", "Hajipur", "Jahanabad", "Jamui", "Jehanabad", "Katihar", "Kishanganj", "Lakhisarai", "Madhepura", "Madhubani", "Mokama", "Motihari", "Munger", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Purnia", "Rajgir", "Raxaul", "Saharsa", "Samastipur", "Sasaram", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Supaul"],
    "Chhattisgarh": ["Ambikapur", "Bhatapara", "Bhilai", "Bilaspur", "Champa", "Chirmiri", "Dhamtari", "Durg", "Jagdalpur", "Janjgir", "Jashpur", "Kabirdham", "Korba", "Koriya", "Mahasamund", "Manendragarh", "Mungeli", "Naila", "Raigarh", "Raipur", "Rajnandgaon", "Sakti", "Surajpur"],
    "Goa": ["Bicholim", "Canacona", "Curchorem", "Cuncolim", "Mapusa", "Margao", "Mormugao", "Panaji", "Pernem", "Ponda", "Quepem", "Sanquelim", "Sanguem", "Vasco da Gama"],
    "Gujarat": ["Ahmedabad", "Amreli", "Anand", "Anjar", "Bharuch", "Bhavnagar", "Bhuj", "Botad", "Dahod", "Deesa", "Dharampur", "Dholka", "Dwarka", "Gandhidham", "Gandhinagar", "Godhra", "Himatnagar", "Idar", "Jamnagar", "Junagadh", "Kalol", "Karjan", "Keshod", "Khambhalia", "Kheda", "Khedbrahma", "Kutch", "Lunawada", "Mahesana", "Manavadar", "Morbi", "Nadiad", "Navsari", "Okha", "Palanpur", "Patan", "Porbandar", "Rajkot", "Sanand", "Siddhpur", "Surat", "Surendranagar", "Unjha", "Valsad", "Vapi", "Veraval", "Vyara", "Wankaner"],
    "Haryana": ["Ambala", "Bahadurgarh", "Bhiwani", "Charkhi Dadri", "Faridabad", "Fatehabad", "Gohana", "Gurgaon", "Hisar", "Jhajjar", "Jind", "Kaithal", "Karnal", "Kurukshetra", "Ladwa", "Mahendragarh", "Narnaul", "Palwal", "Panchkula", "Panipat", "Pehowa", "Rewari", "Rohtak", "Sirsa", "Sonipat", "Thanesar", "Tohana", "Yamunanagar"],
    "Himachal Pradesh": ["Bilaspur", "Chamba", "Dalhousie", "Dharamshala", "Hamirpur", "Kangra", "Kasauli", "Kullu", "Mandi", "Manali", "Nahan", "Palampur", "Parwanoo", "Shimla", "Solan", "Sundernagar", "Una"],
    "Jharkhand": ["Bokaro", "Chaibasa", "Chatra", "Deoghar", "Dhanbad", "Dumka", "Giridih", "Godda", "Gomoh", "Hazaribagh", "Jamshedpur", "Jharia", "Jhumri Tilaiya", "Lohardaga", "Pakur", "Palamu", "Ramgarh", "Ranchi", "Sahibganj", "Saraikela", "Simdega"],
    "Karnataka": ["Bagalkot", "Ballari", "Bangalore", "Belgaum", "Bellary", "Bhadravati", "Bidar", "Bijapur", "Chikkaballapur", "Chikmagalur", "Chitradurga", "Davangere", "Dharwad", "Gadag", "Gulbarga", "Hassan", "Hospet", "Hubli", "Karwar", "Kolar", "Koppal", "Mandya", "Mangalore", "Mysore", "Raichur", "Ramanagara", "Shimoga", "Tumkur", "Udupi", "Vijayapura"],
    "Kerala": ["Alappuzha", "Aluva", "Attingal", "Chalakudy", "Changanassery", "Cherthala", "Guruvayur", "Kanhangad", "Kannur", "Kasargod", "Kayamkulam", "Kochi", "Kollam", "Kottayam", "Kozhikode", "Malappuram", "Manjeri", "Mavelikkara", "Nedumangad", "Neyyattinkara", "Ottapalam", "Palakkad", "Pathanamthitta", "Payyannur", "Ponnani", "Punalur", "Shoranur", "Thalassery", "Thiruvalla", "Thiruvananthapuram", "Thrissur", "Tirur", "Vadakara", "Varkala"],
    "Madhya Pradesh": ["Agar Malwa", "Alirajpur", "Ashoknagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur", "Chhatarpur", "Chhindwara", "Damoh", "Datia", "Dewas", "Dhar", "Dindori", "Guna", "Gwalior", "Harda", "Hoshangabad", "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Mandla", "Mandsaur", "Morena", "Nagda", "Neemuch", "Panna", "Raisen", "Rajgarh", "Ratlam", "Rewa", "Sagar", "Satna", "Sehore", "Seoni", "Shahdol", "Shajapur", "Sheopur", "Shivpuri", "Sidhi", "Singrauli", "Tikamgarh", "Ujjain", "Vidisha"],
    "Maharashtra": ["Ahmednagar", "Akola", "Amravati", "Aurangabad", "Baramati", "Beed", "Bhandara", "Bhusawal", "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Ichalkaranji", "Jalgaon", "Jalna", "Karad", "Kolhapur", "Latur", "Malegaon", "Mira-Bhayandar", "Mumbai", "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Palghar", "Parbhani", "Pimpri-Chinchwad", "Pune", "Ratnagiri", "Sangli", "Satara", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"],
    "Manipur": ["Bishnupur", "Chandel", "Churachandpur", "Imphal", "Jiribam", "Kakching", "Kamjong", "Kangpokpi", "Noney", "Pherzawl", "Senapati", "Tamenglong", "Tengnoupal", "Thoubal", "Ukhrul"],
    "Meghalaya": ["Baghmara", "Jowai", "Nongpoh", "Nongstoin", "Shillong", "Tura", "Williamnagar"],
    "Mizoram": ["Aizawl", "Champhai", "Kolasib", "Lawngtlai", "Lunglei", "Mamit", "Saiha", "Serchhip"],
    "Nagaland": ["Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Mon", "Peren", "Phek", "Tuensang", "Wokha", "Zunheboto"],
    "Odisha": ["Angul", "Balangir", "Balasore", "Baripada", "Bargarh", "Berhampur", "Bhadrak", "Bhubaneswar", "Boudh", "Cuttack", "Deogarh", "Dhenkanal", "Ganjam", "Jagatsinghpur", "Jajpur", "Jharsuguda", "Kalahandi", "Kandhamal", "Kendrapara", "Kendujhar", "Khordha", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangpur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Rourkela", "Sambalpur", "Sonepur", "Sundergarh"],
    "Punjab": ["Amritsar", "Barnala", "Batala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Ferozepur", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Khanna", "Kotkapura", "Ludhiana", "Malerkotla", "Mansa", "Moga", "Mukerian", "Muktsar", "Nabha", "Nawanshahr", "Pathankot", "Patiala", "Phagwara", "Rupnagar", "Sangrur", "Sirhind", "Sunam", "Tarn Taran"],
    "Rajasthan": ["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Beawar", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Ganganagar", "Hanumangarh", "Jaipur", "Jaisalmer", "Jhalawar", "Jhunjhunu", "Jodhpur", "Karauli", "Kota", "Kumbhalgarh", "Mount Abu", "Nagaur", "Pali", "Pushkar", "Rajasthan", "Rajsamand", "Sawai Madhopur", "Sikar", "Sirohi", "Tonk", "Udaipur"],
    "Sikkim": ["Gangtok", "Geyzing", "Mangan", "Namchi", "Rangpo", "Rhenock", "Singtam", "Soreng"],
    "Tamil Nadu": ["Chennai", "Coimbatore", "Cuddalore", "Dindigul", "Erode", "Hosur", "Kancheepuram", "Kanyakumari", "Karur", "Madurai", "Nagercoil", "Nagapattinam", "Namakkal", "Perambalur", "Pollachi", "Pudukkottai", "Rajapalayam", "Ramanathapuram", "Salem", "Sivaganga", "Tenkasi", "Thanjavur", "Theni", "Thiruvallur", "Tiruchirappalli", "Tirunelveli", "Tiruppur", "Tiruvannamalai", "Tuticorin", "Vellore", "Villupuram", "Virudhunagar"],
    "Telangana": ["Adilabad", "Bhadradri Kothagudem", "Hyderabad", "Jagtial", "Jangaon", "Jayashankar Bhupalpally", "Jogulamba Gadwal", "Kamareddy", "Karimnagar", "Khammam", "Kumuram Bheem", "Mahabubabad", "Mahbubnagar", "Mancherial", "Medak", "Medchal", "Mulugu", "Nagarkurnool", "Nalgonda", "Narayanpet", "Nirmal", "Nizamabad", "Peddapalli", "Rajanna Sircilla", "Ranga Reddy", "Sangareddy", "Siddipet", "Suryapet", "Vikarabad", "Wanaparthy", "Warangal Rural", "Warangal Urban", "Yadadri Bhuvanagiri"],
    "Tripura": ["Agartala", "Ambassa", "Belonia", "Dharmanagar", "Kailashahar", "Khowai", "Melaghar", "Udaipur"],
    "Uttar Pradesh": ["Agra", "Aligarh", "Allahabad", "Amroha", "Auraiya", "Azamgarh", "Bahraich", "Ballia", "Banda", "Barabanki", "Bareilly", "Basti", "Bijnor", "Bulandshahr", "Chandauli", "Deoria", "Etah", "Etawah", "Faizabad", "Farrukhabad", "Fatehpur", "Firozabad", "Ghaziabad", "Ghazipur", "Gonda", "Gorakhpur", "Hamirpur", "Hardoi", "Hathras", "Jaunpur", "Jhansi", "Kannauj", "Kanpur", "Kasganj", "Lakhimpur", "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Noida", "Pilibhit", "Pratapgarh", "Raebareli", "Rampur", "Saharanpur", "Sambhal", "Sant Kabir Nagar", "Shahjahanpur", "Shamli", "Shravasti", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"],
    "Uttarakhand": ["Almora", "Bageshwar", "Bageshwar", "Champawat", "Chamoli", "Dehradun", "Haridwar", "Nainital", "Pauri", "Pithoragarh", "Rudraprayag", "Tehri", "Udham Singh Nagar", "Uttarkashi"],
    "West Bengal": ["Asansol", "Balurghat", "Bankura", "Bardhaman", "Burdwan", "Chinsurah", "Cooch Behar", "Darjeeling", "Durgapur", "Haldia", "Howrah", "Jalpaiguri", "Kalimpong", "Kharagpur", "Kolkata", "Malda", "Midnapore", "Murshidabad", "Nabadwip", "Purulia", "Raiganj", "Siliguri"],
    "Andaman and Nicobar Islands": ["Port Blair", "Diglipur", "Rangat", "Mayabunder"],
    "Chandigarh": ["Chandigarh"],
    "Dadra and Nagar Haveli and Daman and Diu": ["Silvassa", "Daman", "Diu"],
    "Lakshadweep": ["Kavaratti", "Agatti", "Amini", "Andrott", "Bitra", "Chetlat", "Kadmat", "Kalpeni", "Minicoy"],
    "Delhi": ["New Delhi", "North Delhi", "East Delhi", "South Delhi", "Central Delhi", "North East Delhi", "North West Delhi", "West Delhi", "South West Delhi", "South East Delhi"],
    "Puducherry": ["Puducherry", "Karaikal", "Mahe", "Yanam"],
  };
  
  