import React from 'react';
import { Link } from 'react-router-dom';


const AssignmentButton = ({  imageSrc, title, points, linkTo }) => (
  <Link to="DareTo" className="ass-phf rel-iat">
    {imageSrc && (
      <div className="logo-x1g">
        <img src={imageSrc} alt="Assignment Logo" />
      </div>
    )}
    <div>
      <p className="assignment-ahw">{title}</p>
      {/* <p className="fejpk nwhyx cnooh poi-pws">Points awarded: {points}</p> */}
    </div>
  </Link>
);

const CourseSection = ({ title, assignments }) => (
  <div className="course-gba rel-iat">
    <div className="button-do7 header-old header-omc">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M5.33325 16C5.33325 21.8911 10.1089 26.6667 15.9999 26.6667C21.891 26.6667 26.6666 21.8911 26.6666 16C26.6666 10.109 21.891 5.33337 15.9999 5.33337C10.1089 5.33337 5.33325 10.109 5.33325 16Z"
          stroke="#6750A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        ></path>
        <path
          d="M12 16C12 18.2091 13.7909 20 16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16Z"
          stroke="#6750A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        ></path>
      </svg>
      <p>{title}</p>
    </div>
    <div className="jou-eyx">
      <div className="ass-wwv">
        {assignments.map((assignment, index) => (
          <AssignmentButton key={index} {...assignment} />
        ))}
      </div>
    </div>
  </div>
);

const WelcomeSection = () => {
  const welcomeAssignments = [
    {
      imageSrc: 'https://sharpener.blob.core.windows.net/assignmentimages/aeedc8cb-b811-4618-b6a8-3c82c0fcb689_sword.png',
      title: 'Dare to Dream',
      points: 50,
      linkTo: '/dare-to-dream',
    },
    {
      imageSrc: 'https://sharpener.blob.core.windows.net/assignmentimages/747728b8-1765-40bc-acba-acc870505cdb_info.png',
      title: 'About EduYog',
      points: 100,
      linkTo: '/about-sharpener',
    },
    {
      imageSrc: 'https://sharpener.blob.core.windows.net/assignmentimages/934e67ab-afe5-465f-a65f-ce3ec159862c_baloons.png',
      title: 'Journey Begins',
      points: 50,
      linkTo: '/journey-begins',
    },
    // Add more assignments as needed
  ];

  return <CourseSection title="Welcome" assignments={welcomeAssignments} />;
};

export default WelcomeSection;
