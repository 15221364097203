import React, { useState } from 'react';
import {Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Know Your Mentor',
      content: (
        <div>
          <p className="task-mvp">Know Your Mentor</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Go through the following set of videos to learn how to create a stack Data structure from scratch in your own preferred coding language:-
                  <br/><br/>


[Important] What are Stacks and where to use it :- <a href="https://youtu.be/I37kGX-nZEI?si=wWYUkJfaz4Zg4WNa" target='_blank'>
                        &nbsp;Click here
                      </a>
<br/><br/>


Python : <a href="https://youtu.be/NKmasqr_Xkw?si=jlt06EUTpEboTRmP" target='_blank'>
                        &nbsp;Click here
                      </a>

<br/><br/>

Java :-
<br/>
Video 1 - <a href="https://youtu.be/fptlqsesjxY?si=xmZOpxoOAR6pMHQT"  target='_blank'>
                        &nbsp;Click here
                      </a><br/>
Video 2- <a href="https://youtu.be/fwULtO8WPpw?si=lQKpH0MGrcWMsenw"  target='_blank'>
                        &nbsp;Click here
                      </a><br/>
<br/>

C++ :-
<br/>
Video 1- <a href="https://youtu.be/JvuaAgDar1c?si=w40tAHMG_ZHbGQZM" target='_blank'>
                        &nbsp;Click here
                      </a><br/>
Video 2-  <a href="https://youtu.be/k1O1PhuarpI?si=9w6YXlPQ1vOrgBLC"  target='_blank'>
                        &nbsp;Click here
                      </a><br/>

<br/>
Javascript :- <a href="https://youtu.be/wtynhUwS5hI?si=434_zurJs1OOFs1N" target='_blank'>
                        &nbsp;Click here
                      </a>
<br/><br/>


After Watching the video click on submit any doubts/confusion ask your mentor.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Progress Check by Mentor',
      content: (
        <div>
          <p className="task-mvp">Progress Check by Mentor</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Deliverable
                  <br/><br/>


&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.Once you have completed the interview you can meet the mentor at the timing mentioned below.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.The mentor would be checking your progress and your knowledge of DSA<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.If DSA is not strong enough then the mentor would ask you to redo a few tasks<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.It is super important that your DSA is very strong as 70% of your interview would be DSA.
<br/><br/>


Demo Joining Timings. Happens Everyday except Sunday.
<br/>
Morning slot - 8:00 AM -8:30: AM  (Mentor - Shubham Singh - 7830013386)



<br/><br/>

Please never call the mentors.
<br/>
Always drop them a message on Whatsapp if something is very urgent.
<br/><br/>


After Watching the video click on submit any doubts/confusion ask your mentor.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Video - to use the inbuilt stack',
      content: (
        <div>
          <p className="task-mvp">Video - to use the inbuilt stack</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Watch the Masterclass on Stacks - <a href="https://youtu.be/r2yHEW8HmBE?si=v5jcn0zSQicKEyLG" target='_blank'>
                        &nbsp;Click here
                      </a>


                      <br/><br/>
Python :-<a href="https://youtu.be/AKO9nI4tuhs?si=rHX6fXcaWt0s0KqH" target='_blank'>
                        &nbsp;Click here
                      </a>


                      <br/><br/>
Java:- <a href="https://youtu.be/ILJgewz5Dxw?si=tUWHLCgwgwypBa62" target='_blank'>
                        &nbsp;Click here
                      </a>

                      <br/><br/>

C++ :- <a href="https://youtu.be/WEwD-ZuTc1w?si=44lFSsJzOa99lR2L" target='_blank'>
                        &nbsp;Click here
                      </a>

                      <br/><br/>

JavaScript:-there is no inbuilt stack as one can push and pop elements from the array itself - <a href="https://youtu.be/1d8tQpZ6KAQ?si=xOi0p8VHZ-YISXKX" target='_blank'>
                        &nbsp;Click here
                      </a>
                      <br/>
peek operation --&gt;var top = stack[stack.length-1];


<br/><br/>
After Watching the video click on submit any doubts/confusion ask your mentor.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Min Stack',
      content: (
        <div>
          <p className="task-mvp">Min Stack</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/min-stack/description/">
                        &nbsp;Click here
                      </a>

<br/>
<br/>



After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Valid Parentheses',
      content: (
        <div>
          <p className="task-mvp">Valid Parentheses</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/valid-parentheses/description/">
                        &nbsp;Click here
                      </a>

<br/>
<br/>



After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Validate Stack Sequence',
      content: (
        <div>
          <p className="task-mvp">Validate Stack Sequence</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/validate-stack-sequences/description/">
                        &nbsp;Click here
                      </a>

<br/>
<br/>



After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Remove Adjacent Duplicates',
      content: (
        <div>
          <p className="task-mvp">Remove Adjacent Duplicates</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/remove-all-adjacent-duplicates-in-string/description/">
                        &nbsp;Click here
                      </a>

<br/>
<br/>



After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Decode string',
      content: (
        <div>
          <p className="task-mvp">Decode string</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/decode-string/description/">
                        &nbsp;Click here
                      </a>

<br/>
<br/>



After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Next Greater Element (Important)',
      content: (
        <div>
          <p className="task-mvp">Next Greater Element (Important)</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/next-greater-element-ii/description/">
                        &nbsp;Click here
                      </a>

<br/>
<br/>



After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Stepping into the shoes of an interviewer',
      content: (
        <div>
          <p className="task-mvp">Stepping into the shoes of an interviewer</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Giving Interviews is one type of an experience , but taking interview of other candidates is a very different experience all together.
                  <br/>
You get to understand what goes inside the mind of an interviewer.

<br/><br/>

Before you start taking interviews
<br/>
Watch this video to understand about the benefits of being an interviewer -<a href="">
                        &nbsp;Click here
                      </a><br/>
Watch this video to understand the rules and other details.<br/>


<br/><br/>

If you have any questions, please let your student counsellor know.
<br/>

<br/>
(If the interviewee you have chosen is not picking up your call - Please send him the following message a
  <br/>
	"This is your mock interviewer from eduyog, Please call me as soon as possible to get your mock interview scheduled. Thank you!!!"
  <br/>
Update on the sheet that you have messaged the person and try calling him later in the day
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Trapping Rain Water (Important)',
      content: (
        <div>
          <p className="task-mvp">Trapping Rain Water (Important)</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://youtu.be/WDOQBPYEaNs?si=yajNhYSpplO5fp3L" target='_blank'>
                        &nbsp;Click here
                      </a>

<br/>
<br/>



After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Stock Span',
      content: (
        <div>
          <p className="task-mvp">Stock Span</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="">
                        &nbsp;Click here
                      </a>

<br/>
<br/>



After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Maximum area in Histogram (Important)',
      content: (
        <div>
          <p className="task-mvp">Maximum area in Histogram (Important)</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/largest-rectangle-in-histogram/description/" target='_blank'>
                        &nbsp;Click here
                      </a>

<br/>
<br/>



After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Maximum area in Histogram (Important)',
      content: (
        <div>
          <p className="task-mvp">Maximum area in Histogram (Important)</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  <p>
                  [Important] Watch the following video to understand which type of questions require usage of Queues -<a href="https://youtu.be/UbAEP7P0vfk?si=0PsAUclD5fdg6JCz" target='_blank'>
                        &nbsp;Click here
                      </a><br/>
Understand the basics of queue -<a href="https://youtu.be/fbonDkYsKj0?si=vwugU6S6FeSzd2pI" target='_blank'>
                        &nbsp;Click here
                      </a>
                      <br/><br/>

Go through the following set of videos to learn how to create a queue from scratch in your own preferred coding language:-

<br/><br/>

C++ Videos:-
<br/>
Video 1-<a href="https://youtu.be/fbonDkYsKj0?si=ddp1MbDmACxkp360" target='_blank'>
                        &nbsp;Click here
                      </a><br/>
Video 2-<a href="https://youtu.be/Yi8Im4_eSQI?si=cAxk2DJqHOYWA4Bz" target='_blank'>
                        &nbsp;Click here
                      </a><br/>
Video 3-<a href="https://youtu.be/JvuaAgDar1c?si=e0oLaoQYqamtGe4Y" target='_blank'>
                        &nbsp;Click here
                      </a>

                      <br/><br/>
Python Videos :-
<br/>
Python implementation - <a href="https://youtu.be/UMggt54FZjU?si=wesIIOFLtC_t-TG3" target='_blank'>
                        &nbsp;Click here
                      </a><br/>
Video 1-<a href="https://youtu.be/FWcQf1y3rUM?si=N0HUlabTbXgJ02K7" target='_blank'>
                        &nbsp;Click here
                      </a><br/>
Video 2-<a href="https://youtu.be/xQC8dh-t-uM?si=tklh8yzxQC0_BL7q" target='_blank'>
                        &nbsp;Click here
                      </a><br/>
Video 3-<a href="https://youtu.be/9t2juEQjI2w?si=Acw1v5oYiSKm4P_2" target='_blank'>
                        &nbsp;Click here
                      </a>
                      <br/><br/>

Java Videos:-
<br/>
Java implementation - <a href="https://youtu.be/T0qUiI_L7S8?si=A903WN8zRIu2aSQe" target='_blank'>
                        &nbsp;Click here
                      </a><br/>
EnQueue, DeQueue, isempty :-<a href="https://youtu.be/rtxjrVpWVmI?si=g-cKA5QSP0G76Rsz" target='_blank'>
                        &nbsp;Click here
                      </a>
                      <br/><br/>

JavaScript Videos:-<a href="https://youtu.be/SktDepxXRdw?si=2HXrm5j78NExQdWF" target='_blank'>
                        &nbsp;Click here
                      </a>
                      <br/><br/>


After Watching the video click on submit any doubts/confusion ask your mentor.
<br/><br/>
                  </p>
<br/>
<br/>

                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Implement Queues from scratch',
      content: (
        <div>
          <p className="task-mvp">Implement Queues from scratch</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  <p>
                  Create a class Queue and implement the following operations to create a Queue from scratch 

                  <br/><br/>

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.Enqueue<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.Dequeue<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.is_empty<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.get_first_element - element present in the front of the the Queue.

<br/><br/>
Create Queue from scratch.

<br/><br/>

(After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.)

<br/><br/>

This Question is Important and will checked Manually by the mentor
                  </p>
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Circular Queue',
      content: (
        <div>
          <p className="task-mvp">Circular Queue</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/design-circular-queue/description/" target='_blank'>
                        &nbsp;Click here
                      </a>

<br/>
<br/>

After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Implement Queue Using Stacks',
      content: (
        <div>
          <p className="task-mvp">Implement Queue Using Stacks</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/implement-queue-using-stacks/description/" target='_blank'>
                        &nbsp;Click here
                      </a>

<br/>
<br/>

After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Stack Using Queues',
      content: (
        <div>
          <p className="task-mvp">Stack Using Queues</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/implement-stack-using-queues/description/" target='_blank'>
                        &nbsp;Click here
                      </a>

<br/>
<br/>

After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Non Repeating characters',
      content: (
        <div>
          <p className="task-mvp">Non Repeating characters</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on Interviewbit:- <a href="https://www.interviewbit.com/problems/first-non-repeating-character-in-a-stream-of-characters/" target='_blank'>
                        &nbsp;Click here
                      </a>

<br/>
<br/>

After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Sliding Window Maxmimum (Important)',
      content: (
        <div>
          <p className="task-mvp">Sliding Window Maxmimum (Important)</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                  Solve the following Question on leetcode:- <a href="https://leetcode.com/problems/sliding-window-maximum/description/" target='_blank'>
                        &nbsp;Click here
                      </a>
                      <br/><br/>
                      Watch this video on youtube :-<a href="https://youtu.be/5VDQxLAlfu0" target='_blank'>
                        &nbsp;Click here
                      </a>
<br/>
<br/>


Important Watch this video which will help you understand why to use DeQue:- <a href="https://youtu.be/OnlgK0gjtB8?si=ya_VzKl-0qUSQe7u" target='_blank'>
                        &nbsp;Click here
                      </a>



<br/>
<br/>

After Solving the question copy paste your code here and click on submit or If you have any doubt so ask your mentor directly on Whatsapp.
<br/><br/>
                  </div>
                </div>
        </div>
      ),
    },
  ];

const Stack2= () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 20) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/10'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 20</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Stack2;
