import React from 'react';
import { FaClock, FaCogs, FaRegLightbulb, FaCheckCircle } from 'react-icons/fa';

export default function RoadMap() {
  return (
    <div className="bg-gradient-to-br from-gray-200 to-gray-300 text-black min-vh-100 py-5 ">
      <div className="container">
        <h1 className="display-5 text-center mb-5 text-uppercase font-italic text-decoration-underline fw-bold">
          Technical Training Roadmap
        </h1>
        <div className="row row-cols-1 row-cols-md-2 g-4  ">
          <div className="col">
            <div className="card h-100 border-0 rounded-3 shadow class-3">
              <div className="card-body">
                <h2 className="text-center fw-bold mb-3 text-dark">MONTH-1</h2>
                <div className="d-flex align-items-center mb-3">
                  <FaClock className="me-2" />
                  <span>2-3 Hours - Theory and conceptual learning.</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaCogs className="me-2" />
                  <span>DATA STRUCTURES</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaRegLightbulb className="me-2" />
                  <span>Interview one to one</span>
                </div>
                <h3 className="fw-bold mb-2 mt-3 text-dark">Stage 1</h3>
                <p>HTML</p>
                <p>CSS</p>
                <p>PERSONALITY DEVELOPMENT</p>
                <p>Interview one to one</p>
                
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 border-0 rounded-3 shadow class-3">
              <div className="card-body">
                <h2 className="text-center fw-bold mb-3 text-dark">MONTH-2</h2>
                <div className="d-flex align-items-center mb-3">
                  <FaClock className="me-2" />
                  <span>Time for Javascript basic and CORE</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaCogs className="me-2" />
                  <span>Learn Dev. Ops</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaRegLightbulb className="me-2" />
                  <span>Start PROJECT-1 (HTML,CSS,JS)</span>
                </div>
                <h3 className="fw-bold mt-3 text-dark mb-2">Stage 2</h3>
                <p>PROJECT-1 (Using Dev. Ops)</p>
                <p>NODE.JS, EXPRESS.JS</p>
                <p>DSA PRACTICE (Dashboard)</p>
                <p>Personality Development</p>
                
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 border-0 rounded-3 shadow class-3">
              <div className="card-body">
                <h2 className="text-center fw-bold mb-3 text-dark">MONTH-3</h2>
                <div className="d-flex align-items-center mb-3">
                  <FaClock className="me-2" />
                  <span>Full revision on Dev. Ops & NODE.JS</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaCogs className="me-2" />
                  <span>Learn REACT.JS</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaRegLightbulb className="me-2" />
                  <span>Continue DSA PRACTICE</span>
                </div>
                <h3 className="fw-bold mt-3 mb-2 text-dark">Stage 3</h3>
                <p>PROJECT-1 (Backend Dev.)</p>
                <p>PROJECT-2 (Frontend Dev.)</p>
                <p>Personality Development</p>
               
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 border-0 rounded-3 shadow class-3">
              <div className="card-body">
                <h2 className="text-center fw-bold mb-3 text-dark">MONTH-4</h2>
                <div className="d-flex align-items-center mb-3">
                  <FaClock className="me-2" />
                  <span>Work on Final Stage projects</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaCogs className="me-2" />
                  <span>DSA PRACTICE</span>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <FaRegLightbulb className="me-2" />
                  <span>Participate in Mock interviews</span>
                </div>
                <h3 className="fw-bold mt-3 text-dark mb-2">Final Stage</h3>
                <p>PROJECT-1 (FULLSTACK DEV.)</p>
                <p>PROJECT-2 (FULLSTACK DEV.)</p>
                
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  );
}
