import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import { Link } from 'react-router-dom';


const Linked1 = () => {

  const assignments = [
    { id: 1, name: 'Video - Linked List from Scratch', status: 'start'},
    { id: 2, name: 'Videos - Famous Interview Questions', status: 'start'},
    { id: 3, name: 'Remove a Node', status: 'start'},
    { id: 4, name: 'Deletion of a node', status: 'start'},
    { id: 5, name: 'Insertion of 2 Linked List	', status: 'start'},
    { id: 6, name: 'Reverse Linked List (Iterative)	', status: 'start'},
    { id: 7, name: 'Is palindrome	', status: 'start'},
    { id: 8, name: 'Rotate List	', status: 'start'},
    { id: 9, name: 'Merge Linked List	', status: 'start'},
    { id: 10, name: 'Add two LinkedList (Important)	', status: 'start'},
    { id: 11, name: 'Remove nth Node from end	', status: 'start'},
    { id: 12, name: 'Linked List Cycle(Important)	', status: 'start'},
    { id: 13, name: 'Video - Fundamentals on Recursion	', status: 'start'},
    { id: 14, name: 'Iterative to Recursion	', status: 'start'},
    { id: 15, name: 'Stack Sequence Recursion	', status: 'start'},
    { id: 16, name: 'Output of Recursion -1	', status: 'start'},
    { id: 17, name: 'Output of Recursion -2	', status: 'start'},
    { id: 18, name: 'Output of Recursion -3', status: 'start'},
    { id: 19, name: 'Output of Recursion -4', status: 'start'},
    { id: 20, name: 'Output of Recursion -5', status: 'start'},
    { id: 21, name: 'Multiples of 5(Recursive)', status: 'start'},
    { id: 22, name: 'Sum of N natural Numbers (Recursive)	', status: 'start'},
    { id: 23, name: 'Factorial in Recursion	', status: 'start'},
    { id: 24, name: 'Video :- Recursion Visualization	', status: 'start'},
    { id: 25, name: 'Fibonacci Number	', status: 'start'},
    { id: 26, name: 'Permutation (Important)	', status: 'start'},
    { id: 27, name: 'Subsequence (Important)	', status: 'start'},
    { id: 28, name: 'Merge Sort (Important)	', status: 'start'},
    { id: 29, name: 'Tim sort video	', status: 'start'},
    { id: 30, name: 'Comparator Videos	', status: 'start'},
    { id: 31, name: 'Question using Comparators	', status: 'start'},
    { id: 32, name: 'Practice DSA Questions Sheet-2	', status: 'start'},
  ];


  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
      <div className='page1'>
      <div>
        <Sidebar/>
      </div>
      <div>
      <div className="assignment-foj">
      <div className="rig-phw">
        <div className="fle-fok justify-6c5 item-ycb orqly">
          <Link to='/assignment/12'>
          <button className="btn-zle bvadh">Next Assignment</button>
          </Link>
          
        </div>
        <div className="right-c1e">
          <table>
           
            <colgroup>
              {Array(4).fill().map((_, index) => <col key={index} className={`style-${index}EMdC`} />)}
            </colgroup>
            <thead>
              <tr>
                <th className="style-q61Z3">#</th>
                <th>Name</th>
                <th className="style-6q7nt">Status</th>
                <th className="style-grDIy"></th>
              </tr>
            </thead>
            <tbody className="style-iNhB2">
              {assignments.map(({ id, name, status, points }, index) => (
                <tr key={id} className="style-GbKIa">
                  <td className="style-CQJlN">{id}</td>
                  
                  <td>
                    {name}
                   
                  </td>
                  <td className="style-mF9jW">
                  <Link to={`/Linked2/${index + 1}`} className='style-ckn'>{status}</Link>
                  </td>
                  <td className="style-hsgX7">{points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default Linked1;