import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, NavbarBrand, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap';
import { FaUserCircle } from 'react-icons/fa';  // Importing profile icon
import axios from 'axios';
import DashboardContent from './DashboardContent';
import CustomVideoPlayer from './CustomVideoPlayer';

const DashboardCourse = () => {
  const { courseId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const [contents, setContents] = useState([]);
  const [answerSheets, setAnswerSheets] = useState([]);
  const [modal, setModal] = useState(false);
  const [videoModal, setVideoModal] = useState({ isOpen: false, videoUrl: '' });
  const [isOpen, setIsOpen] = useState(false); // State for Navbar collapse

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`https://eduyog.in/api/content/${courseId}`);
        setContents(response.data);
      } catch (error) {
        console.error('Error fetching course content:', error);
      }
    };

    fetchContent();
  }, [courseId]);

  useEffect(() => {
    const fetchAnswerSheets = async () => {
      try {
        const response = await axios.get(`https://eduyog.in/api/forAnswer/${courseId}/${user.email}`);
        setAnswerSheets(response.data);
      } catch (error) {
        console.error('Error fetching answer sheets:', error);
      }
    };

    fetchAnswerSheets();
  }, [courseId, user.email]);

  const toggleModal = () => setModal(!modal);
  const toggle = () => setIsOpen(!isOpen);

  const toggleVideoModal = (videoUrl = '') => {
    setVideoModal({ isOpen: !videoModal.isOpen, videoUrl });
  };

  const toggleNavbar = () => setIsOpen(!isOpen); // Toggle function for Navbar

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/LoginForCourse');
  };

  const handleDownload = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };


  return (
    <>
      <style>
        {`
        .navbar-custom {
            background-color: #007bff; /* Navbar background color */
          }
          .navbar-custom .navbar-brand {
            color: #fff;
            font-weight: bold;
          }
          .navbar-custom .navbar-nav {
            width: 100%; /* Full width for nav */
            display: flex;
            justify-content: space-between; /* Align items to the edges */
          }
          .navbar-custom .nav-item {
            display: flex;
            align-items: center;
          }
          .navbar-custom .profile-icon {
            font-size: 1.8rem;
            color: #fff;
            cursor: pointer;
          }
          .navbar-custom .btn-logout {
            color: #fff;
            border-color: #dc3545;
            background-color: #dc3545;
            border-radius: 20px;
            padding: 0.5rem 1.5rem;
          }
          .navbar-custom .btn-logout:hover {
            background-color: #c82333;
            border-color: #bd2130;
          }
          .navbar-custom {
            background: linear-gradient(90deg, #4A90E2, #357ABD);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            padding: 15px;
          }
          .navbar-custom .nav-link, .navbar-custom .navbar-brand {
            color: #fff;
            font-weight: 600;
            font-size: 1.2rem;
          }
          .navbar-custom .btn-danger {
            margin-left: 20px;
            font-size: 1rem;
            border-radius: 20px;
          }
          .header-custom {
            font-size: 2.5rem;
            color: #333;
            font-weight: 700;
            text-align: center;
            margin-top: 30px;
            margin-bottom: 40px;
          }
          .section-title {
            font-size: 1.8rem;
            color: #333;
            font-weight: 600;
            margin-bottom: 30px;
            text-align: center;
            text-transform: uppercase;
          }
          .custom-divider {
            border-bottom: 3px solid #4A90E2;
            width: 80px;
            margin: 15px auto 30px;
          }
          .card-custom {
            border-radius: 15px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
            overflow: hidden;
            padding: 15px; /* Decrease card body size */
          }
          .card-custom:hover {
            transform: translateY(-10px);
            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
          }
          .card-body-custom {
            padding: 20px; /* Decrease content padding */
          }
          .card-title-custom {
            font-size: 1.2rem; /* Decrease title size */
            color: #333;
            font-weight: 600;
            margin-bottom: 10px; /* Adjust margin */
          }
          .card-text-custom {
            font-size: 0.9rem; /* Decrease text size */
            color: #777;
            margin-bottom: 15px;
          }
          .btn-custom {
            background: linear-gradient(90deg, #4A90E2, #357ABD);
            color: #fff;
            border: none;
            border-radius: 25px;
            font-size: 0.9rem; /* Decrease button font size */
            padding: 8px 15px;
            transition: background-color 0.3s ease;
          }
          .btn-custom:hover {
            background-color: #2a65a4;
          }
          .modal-header-custom {
            background-color: #4A90E2;
            color: #fff;
            border-bottom: none;
            font-size: 1.5rem;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          .modal-body-custom p {
            margin-bottom: 10px;
            color: #333;
            font-size: 1.1rem;
          }
          .modal-footer-custom {
            justify-content: flex-end;
            border-top: none;
          }
          .video-controls {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 10px;
          }
          .video-controls input[type="range"] {
            width: 150px;
          }
          .profile-icon {
            font-size: 1.5rem; /* Profile icon size */
            color: #fff;
            cursor: pointer;
          }
        `}
      </style>

      <Navbar className="navbar-custom navbar-expand-md shadow-sm" expand="md">
        <NavbarBrand>EduYog</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="navbar-nav" navbar>
            {user && (
              <>
                <NavItem className="ml-3">
                  <FaUserCircle 
                    className="profile-icon" 
                    onClick={toggleModal} 
                  />
                </NavItem>
                <NavItem className="ml-auto">
                  <Button 
                    color="danger" 
                    outline 
                    className="btn-logout"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>
      </Navbar>

      <Container className="mt-5">
        <h2 className="header-custom">Course Content</h2>
        <Row>
          <Col md={10}>
            <Row>
              {contents.map((content) => (
                <Col md={6} lg={4} key={content.id}>
                  <Card className="card-custom mb-4">
                    <CardBody className="card-body-custom">
                      <h4 className="card-title-custom">{content.title}</h4>
                      <p className="card-text-custom">{content.content}</p>
                      {content.file_path.endsWith('.pdf') && (
                        <Button className="btn-custom" onClick={() => handleDownload(content.file_path)}>
                          View PDF
                        </Button>
                      )}
                      {content.video_path.endsWith('.mp4') && (
                        <>
                          <Button className="btn-custom" onClick={() => toggleVideoModal(content.video_path)}>
                            Watch Video
                          </Button>

                          <Modal
                            isOpen={videoModal.isOpen}
                            toggle={() => toggleVideoModal('')}
                            size="lg"
                            centered
                          >
                            <ModalHeader
                              toggle={() => toggleVideoModal('')}
                              className="modal-header-custom"
                            >
                              Video Player
                            </ModalHeader>
                            <ModalBody>
                              <CustomVideoPlayer videoUrl={videoModal.videoUrl} onClose={() => toggleVideoModal('')} />
                            </ModalBody>
                          </Modal>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        {user && <DashboardContent user={user} courseId={courseId} />}
      </Container>
      <Container className="mt-5">
      <div className="section-title">Answer Sheets</div>
      <div className="custom-divider"></div>
        <Row>
          <Col md={10}>

           
            <Row>
              {answerSheets.map((sheet) => (
                <Col md={6} lg={4} key={sheet.id}>
                  <Card className="card-custom mb-4">
                    <CardBody className="card-body-custom">
                      <h4 className="card-title-custom">{sheet.name},</h4>
                      <p className="card-text-custom">please view your answersheet</p>
                      {sheet.file_path.endsWith('.pdf') && (
                        <Button className="btn-custom" onClick={() => handleDownload(sheet.file_path)}>
                          View PDF
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal} className="modal-header-custom">
          User Profile
        </ModalHeader>
       
<ModalBody className="modal-body-custom">
<p><strong>Name:</strong> {user.firstName} {user.lastName}</p>
<p><strong>Email:</strong> {user.email}</p>
<p><strong>Mobile Number:</strong> {user.mobileNumber}</p>
<p><strong>Address:</strong> {user.address}</p>
<p><strong>State:</strong> {user.state}</p>
<p><strong>City:</strong> {user.city}</p>
</ModalBody>
        <ModalFooter className="modal-footer-custom">
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DashboardCourse;


