import React, { useState } from 'react';
import axios from 'axios';

function AdminPanel() {
  const [image, setImage] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', image);

    try {
      const response = await axios.post('https://eduyog.in/api/add/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('image added:', response.data);
      setSubmitted(true);
    } catch (error) {
      console.error('Error adding news:', error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="card-title text-center mb-4">Add Seminar Image</h2>
                  {submitted ? (
                    <div className="alert alert-success" role="alert">
                     Photo added successfully!
                    </div>
                  ) : null}
                  <form onSubmit={handleSubmit}>
                    
                    <div className="mb-3">
                      <input type="file" className="form-control" onChange={(e) => setImage(e.target.files[0])} required />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
