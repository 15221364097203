import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


const Interview = () => {
  const [interviewLink, setInterviewLink] = useState('');

  const generateInterviewLink = () => {
    const randomLink = `https://eduyog.in/${Math.random().toString(36).substring(7)}`;
    setInterviewLink(randomLink);
  };

  return (
    <div className="container interv">
      <div className="jumbotron">
        <h2 className="display-5">Interview Page</h2>
        <p className="lead">
          Welcome to the interview page! Generate a unique interview link below.
        </p>

        {interviewLink ? (
          <div>
            <hr className="my-4" />
            <p className="lead">
              Your Interview Link:
            </p>
            <a
              href={interviewLink}
              className="btn btn-primary btn-lg"
              target="_blank"
              rel="noopener noreferrer"
            >
              {interviewLink}
            </a>
          </div>
        ) : (
          <button
            className="btn btn-primary btn-lg"
            onClick={generateInterviewLink}
          >
            Generate Interview Link
          </button>
        )}

        {/* Additional Content */}
        <div className="mt-5">
          <h3>Interview Process</h3>
          <p>
            Our interview process is designed to evaluate your skills and fit within our organization. Below are the key steps:
          </p>

          <ul>
            <li>Application Review: Our team will review your application to ensure it meets our criteria.</li>
            <li>Technical Assessment: You may be asked to complete a technical assessment to evaluate your skills.</li>
            <li>Interview Rounds: Expect multiple interview rounds with different team members.</li>
            <li>Final Evaluation: The final decision will be based on your performance in the assessment and interviews.</li>
          </ul>

          <h3>Preparation Tips</h3>
          <p>
            To succeed in our interviews, we recommend the following preparation tips:
          </p>

          <ul>
            <li>Review our company and its values.</li>
            <li>Understand the role you are applying for.</li>
            <li>Prepare for technical questions relevant to the position.</li>
            <li>Be ready to discuss your past experiences and achievements.</li>
          </ul>

          <h3>Contact Information</h3>
          <p>
            If you have any questions or concerns, please feel free to contact our HR department:
          </p>
          <p>
            Email: hr@example.com<br />
            Phone: (123) 456-7890
          </p>
        </div>
      </div>
    </div>
  );
};

export default Interview;
