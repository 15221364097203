import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Index from "./component/Index";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import Placement from "./Pages/placement/Placement";
import YogaDashBoard from "./Pages/placement/Dashboard/YogaDashBoard";
import Forcolleges from "./Pages/placement/Forcolleges";
import YogaPosePage from "./component/YogaPosePage";
import Dashboard, {
  DashboardHome,
} from "./Pages/placement/Dashboard/Dashboard";
import Yoga from "./component/Yoga";
import Assingmment from "./Pages/placement/Assingmment";
import AboutPage from "./component/AboutPage";

import Playground from "./Pages/placement/playground/Playground";
import { AdminProtect, StudentProtect } from "./component/ProtectRoutes";
import Interview from "./Pages/placement/Interview";
import Practise from "./Pages/placement/Dashboard/Practise";
import { DareTo,About,About1,Index1 } from "./Pages/placement/Dashboard/component/DareToDream";
import Service from "./component/Service";
import Page1 from "./Pages/placement/Dashboard/component/Page1";
import { AssignmentPage } from "./Pages/placement/Dashboard/component/YourComponent";
import BpageL from "./Pages/placement/Dashboard/BasicCoding/BpageL";
import LpageL from "./Pages/placement/Dashboard/BasicCoding/LpageL";
import Arrays2 from "./Pages/placement/Dashboard/BasicCoding/Arrays2";
import BManipulation2 from "./Pages/placement/Dashboard/BasicCoding/BManipulation2";
import ProTextL from "./Pages/placement/Dashboard/BasicCoding/ProTestL";
import Oops2 from "./Pages/placement/Dashboard/BasicCoding/Oops2";
import ProLevel22 from "./Pages/placement/Dashboard/BasicCoding/ProLevel22";
import Mock22 from "./Pages/placement/Dashboard/BasicCoding/Mock22";
import Stack2 from "./Pages/placement/Dashboard/BasicCoding/Stack2";
import Linked2 from "./Pages/placement/Dashboard/BasicCoding/Linked2";
import Mock32 from "./Pages/placement/Dashboard/BasicCoding/Mock32";
import Starting2 from "./Pages/placement/Dashboard/WebDev/Starting2";
import Dom2 from "./Pages/placement/Dashboard/WebDev/Dom2";
import Dsa2 from "./Pages/placement/Dashboard/WebDev/Dsa2";
import AdvanceJS2 from "./Pages/placement/Dashboard/WebDev/AdvanceJS2";
import Projectjs2 from "./Pages/placement/Dashboard/WebDev/Projectjs2";
import Testaj2 from "./Pages/placement/Dashboard/WebDev/Testaj2";
import RegistrationPage2 from "./Pages/placement/Dashboard/WebDev/RegistrationPage2";
import JsProject2 from "./Pages/placement/Dashboard/WebDev/JsProject2";
import BlogPost from "./Pages/placement/Dashboard/component/BlogPost";
import RegisterForYoga from "./Pages/Auth/RegisterForYoga";
import LoginForYoga from "./Pages/Auth/LoginForYoga";
import SubmitRecuriter from "./Pages/Auth/Recuriter";
import SubmitHomework from "./Pages/Auth/SubmitHomework";
import Tdsa2 from "./Pages/placement/Dashboard/WebDev/Tdsa2";
import { SignUpData } from "./Pages/AuthData/SignUpData";
import { SignInData } from "./Pages/AuthData/SignInData";
import { DashboardData } from "./Pages/AuthData/DashboardData";
import { TimerData,Wrapper } from "./Pages/AuthData/TimerData";
import { ProtectedRoute } from "./Pages/UtilsData/ProtectedRoute";
import AdminDashboard from "./component/AdminDashboard";
import ForgottenPassword from "./component/ForgottenPassword";
import LoginPage from "./component/LoginPage";
import Signin from "./component/SignIn";
import Signup from "./component/SignUp";
import PrivateRoute from "./component/PrivateRoute";
import ProtectRoute from "./component/ProtectRoutes";
import JoinNow from "./component/JoinNow";
import Content from "./Courses/ContentPage";
import FilterPage from "./Courses/FilterPage";
import AdminPage from "./AdminCourse/AdminControls";
import BuyNowPage from "./Courses/BuyNowPage";
import DetailsPage from './Courses/DetailsPage';
import PaymentPage from "./Courses/PaymentPage";
import Confirmation from "./Courses/Confirmation";
import LoginPaid from "./Dashboard/login";
import ForgotPasswordPage from "./Dashboard/ForgotPasswordPage";
import PrivatedRoute from "./Dashboard/PrivatedRoute";
import DashboardCourse from "./Dashboard/Dashboard";
import Users from "./AdminCourse/UsersData";
import CourseContents from "./AdminCourse/CourseContents";
import AdminGetForm from "./AdminCourse/AdminGetForm";
import PaymentStatus from "./Courses/PaymentStatus";
import AdminPortal from "./AdminCourse/AdminPortal";
import StudentAnswer from "./AdminCourse/StudentAnswer";
import AdminSend from "./AdminCourse/AdminSend";
import DiscountPage from "./AdminCourse/DiscountPage";
import BannerAdd from "./AdminCourse/BannerAdd";
import AdminTeam from "./AdminCourse/AdminTeam";
import GetCounsellingUsers from "./AdminCourse/getCounsellingUser";
import PapRegister from "./component/PapRegister";
import GetPapUsers from "./AdminCourse/GetPapUsers";
import Faqs from "./component/Faqs.jsx";
import TermsConditions from "./component/Terms.jsx";
import Refund from "./component/Refunds.jsx";
import ContactUs from "./component/ContactUs.jsx";


function App() {
  
  return (
    <div>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/collage" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/loginForYoga" element={<LoginForYoga />} />
        <Route path="/registerForYoga" element={<RegisterForYoga/>} />
        <Route path="/ourcourses" element={<FilterPage/>} />
        <Route path="/payAfterPlacement" element={<PapRegister/>} />
        <Route path="/details/:title/:id" element={<DetailsPage />} />
          <Route path="/buynow/:title/:id" element={<BuyNowPage />} />
          <Route path="/payment/:id" element={<PaymentPage />} />
         
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/LoginForCourse" element={<LoginPaid />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/refunds" element={<Refund />} />
          <Route path="/contactus" element={<ContactUs />} />
    
          <Route element={<PrivatedRoute  />}>
            <Route path="/dashboard/:courseId" element={<DashboardCourse/>} />
          </Route>


        <Route path="/registerFT" element={<SignUpData />} />
        <Route path="/loginFT" element={<SignInData />} />
        
        <Route
          path="/timerFT"
          element={
            <ProtectedRoute>
             <Wrapper/>
            </ProtectedRoute>
          }
        />
       <Route path="/placement/dashboard" element={<PrivateRoute component={Dashboard} />}>
  <Route index element={<DashboardHome />} />
  <Route path="interview" element={<Interview />} />
  <Route path="assignment" element={<Assingmment />} />
  <Route path="practise" element={<Practise />} />
  <Route path="assignment/playground" element={<Playground />} />
</Route>




<Route element={<ProtectRoute  />}>
<Route path="/adminDash" element={<AdminDashboard />} />
<Route path="/addCourse" element={<AdminPage />} />
<Route path="/users-data" element={<Users/>} />
<Route path="/course-content" element={<CourseContents/>} />
<Route path="/adminForm" element={<AdminGetForm />} />
<Route path="/payment-status" element={<PaymentStatus />} />
<Route path="/adminAdd" element={<AdminPortal />} />
<Route path="/student-answer" element={<StudentAnswer />} />
<Route path="/adminSend" element={<AdminSend />} />
<Route path="/discount" element={<DiscountPage />} />
<Route path="/banner" element={<BannerAdd/>} />
<Route path="/teamMember" element={<AdminTeam />} />
<Route path="/counsellingUser" element={<GetCounsellingUsers/>} />
<Route path="/payAfterUser" element={<GetPapUsers/>} />
</Route>
     





         <Route path="forgotten" element={<ForgottenPassword/>} /> 
         <Route path="logInForAdmin" element={<LoginPage/>} />  
         <Route path="signIn" element={<Signin/>} /> 
         <Route path="signUp" element={<Signup/>} /> 





        <Route path="submitHomework" element={<SubmitHomework/>} />
        <Route path="submitRecuriter" element={<SubmitRecuriter/>} />
        <Route path="/placement" element={<Placement />} />
        <Route path="/forcolleges" element={<Forcolleges />} />
        <Route path="/aboutPage" element={<AboutPage />}/>
        <Route path="/Yoga" element={<Yoga/>}/>
        <Route path="/poses/:poseName" element={<YogaPosePage/>} />
        <Route path="/service" element={<Service/>}/>
        <Route path="/join" element={<JoinNow/>}/>
       
         <Route path="/assignment/:id" element={<AssignmentPage/>}/>
       
         
         
            

        <Route> <Route path="/placement/dashboard/dareTo" element={<PrivateRoute component={DareTo} />} /></Route>
          <Route><Route path="/placement/dashboard/about" element={<PrivateRoute component={About} />} /></Route>
          <Route><Route path='/placement/dashboard/about1' element={<PrivateRoute component={About1} />}/></Route>
         <Route><Route path='/placement/dashboard/index1' element={<PrivateRoute component={Index1}/>}/></Route>
         <Route path="/placement/dashboardForYoga" element={<YogaDashBoard />} />
   
         <Route path="/task/:taskId" element={<PrivateRoute component={Page1} />} />
         <Route path="/taskB/:taskId" element={<PrivateRoute component={BpageL} />} />
         <Route path="/LpageL/:taskId" element={<PrivateRoute component={LpageL} />} />
         <Route path="/Arrays2/:taskId" element={<PrivateRoute component={Arrays2} />} />
         <Route path="/BManipulation2/:taskId" element={<PrivateRoute component={BManipulation2} />} />
         <Route path="/ProTextL/:taskId" element={<PrivateRoute component={ProTextL} />} />
         <Route path="/Oops2/:taskId" element={<PrivateRoute component={Oops2} />} />
         <Route path="/ProLevel2/:taskId" element={<PrivateRoute component={ProLevel22} />} />
         <Route path="/Mock22/:taskId" element={<PrivateRoute component={Mock22} />} />
         <Route path="/Stack2/:taskId" element={<PrivateRoute component={Stack2} />} />
         <Route path="/Linked2/:taskId" element={<PrivateRoute component={Linked2} />} />
         <Route path="/Mock32/:taskId" element={<PrivateRoute component={Mock32} />} />
         <Route path="/Starting2/:taskId" element={<PrivateRoute component={Starting2} />} />
         <Route path="/Dom2/:taskId" element={<PrivateRoute component={Dom2} />} />
         <Route path="/Dsa2/:taskId" element={<PrivateRoute component={Dsa2} />} />
         <Route path="/AdvanceJS2/:taskId" element={<PrivateRoute component={AdvanceJS2} />} />
         <Route path="/Projectjs2/:taskId" element={<PrivateRoute component={Projectjs2} />} />
         <Route path="/Testaj2/:taskId" element={<PrivateRoute component={Testaj2} />} />
         <Route path="/RegistrationPage2/:taskId" element={<PrivateRoute component={RegistrationPage2} />} />
         <Route path="/JsProject2/:taskId" element={<PrivateRoute component={JsProject2} />} />
         <Route path="/post/:postId" element={<PrivateRoute component={BlogPost} />} />
         <Route path="/Tdsa/:taskId" element={<PrivateRoute component={Tdsa2} />} />
      </Routes>
    </div>
  );
}

export default App;
