import React from "react";
import Layout from "../../component/Layout";
import { BsPatchCheckFill } from "react-icons/bs";
import { FaHandsHelping } from "react-icons/fa";
import { BiSolidDollarCircle } from "react-icons/bi";
import Student from "./Student";
import { Link } from "react-router-dom";
import  {Trust}  from "./Trust";
import Faq from "./Faq";
import Works from "./Works";
const Placement = () => {
  return (
    <Layout>
      <div className="placement">
        <div className="container">
          <div className="row row-cols-md-2">
            <div className="m-auto">
              <h1 className="display-5 fw-bold my-4">₹ 0 Fee till placement</h1>
              <div className="my-md-5">
                <div className="fw-bold "style={{ fontSize: '20px' }}>
                  Pay only 17% of your monthly pay for 6 months. Most affordable
                  program in the world.
                </div>
                <Link to="/payAfterPlacement">
                <button className="btn my-5 px-3 py-2 fw-bold btn-primary">
                  Apply Now
                </button>
                </Link>
                
              </div>
            </div>
            <div>
              <h2 className="display-6 my-md-5 fw-bold">Making Learning Addictive Affordable</h2>
              <img className="w-100 " src="/images/placement-home.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Tag />
      <Companylist />
    <>
      <div className="bgdev">
          <div className="div31">
          <h1>High Power Courses</h1>
          <p>Extremely hands-on and accelerated courses with a quality-first approach</p>
        </div>
        <div className="div32">
          <CourseCard
            title="Frontend Development"
            duration="4 months"
            eligibility="Any final year student or degree holder"
          />
          <CourseCard
            title="Backend Development"
            duration="4 months"
            eligibility="Any final year student or degree holder"
          />
          <CourseCard
            title="Fullstack Development"
            duration="6 months"
            eligibility="Any final year student or degree holder"
          />
        </div>
      </div>
    </>
    <Trust/>
    <Works/>
    <Faq/>
    </Layout>
  );
};

export default Placement;
const items = [
  {
    icon: <img src="https://www.sharpener.tech/static/media/Rahul.36c0f6dcc2b1edbffbf3.png" alt="Loading"/>,
    num: "Rahul Kurudigi",
    title: "Tier-III college to super dream offers",
  },
  {
    icon: <img src="https://www.sharpener.tech/static/media/Alok.9992dbfdcbf4b69bf4c3.png" alt="Loading"/>,
    num: "Shubham",
    title: "No offer to multiple offers",
  },
  {
    icon: <img src="https://www.sharpener.tech/static/media/Shafeeq.1419036df31e37cc34c9.png" alt="Loading"/>,
    num: "Rajesh",
    title: "No offer to 8 offers",
  },
  {
    icon: <img src="https://www.sharpener.tech/static/media/Tushar.ac82edbb37f22fa67409.png" alt="Loading"/>,
    num: "Ronam Sharma",
    title: "Mechanical engineer to developer",
  },
  {
    icon: <img src="https://www.sharpener.tech/static/media/Navira.9ed6b0456ba48ad25b13.png" alt="Loading"/>,
    num: "Radhika Rastogi",
    title: "Mom cracks frontend developer job",
  },
  {
    icon: <img src="https://www.sharpener.tech/static/media/Akanksha.9b09572555a306a15abc.png" alt="Loading"/>,
    num: "Akansha Gehlot",
    title: "Placed in Amazon with dream package",
  },
];
const tag = [
  {
    icon: <FaHandsHelping className="h2 text-info" />,
    num: 100,
    title: "Partner companies",
  },
  {
    icon: <BsPatchCheckFill className="h2 text-info" />,
    num: "97%",
    title: "Job guarantee    ",
  },
  {
    icon: <BiSolidDollarCircle className="h2 text-info" />,
    num: "18 LPA    ",
    title: "Highest package",
  },
];
const Tag = () => {
  return (
    <>
      <div className="d-flex py-5 flex-wrap container justify-content-between ">
        {tag.map((data) => (
          <div className="p-4">
            <div className="text-center">
              {data.icon} <span className="fs-5">{data.num}</span>
            </div>
            <div className="h5">{data.title}</div>
          </div>
        ))}
      </div>
    </>
  );
};

const Companylist = () => {
  const mid = Math.ceil(companyLogo.length / 4);
  const first = companyLogo.slice(0, mid);
  const second = companyLogo.slice(mid, mid + mid);
  const third = companyLogo.slice(2 * mid, 3 * mid);
  const forthList = companyLogo.slice(3 * mid);
  return (
    <>
      <div className="company-list">
        <h1 className="display-5 text-center my-4 text-dark fw-normal">EduYogian Are Hired Everywhere</h1>
        <div>
          <LogoList list={first} className="right-to-left" />
          <LogoList list={second} className="left-to-right" />
          <LogoList list={third} className="right-to-left" />
          <LogoList list={forthList} className="left-to-right" />
        </div>
      </div>
    </>
  );
};

const LogoList = ({ list, className }) => {
  return (
    <>
      <div className={`d-flex justify-content-between ${className}`}>
        {list?.map((img) => (
          <>
            <img
              className=" h-100 p-2"
              style={{ width: "5rem" }}
              src={img}
              alt=""
            />
          </>
        ))}
      </div>
    </>
  );
};

const CourseCard = ({ title, duration, eligibility }) => {
  return (
    <div>
      <h3>{title}</h3>
      <h4>Duration: {duration}</h4>
      <h5>Eligibility: {eligibility}</h5>
      <h6>Pay After Placement: <img src="https://cdn-icons-png.flaticon.com/128/5290/5290119.png" alt="Pay After Placement" /></h6>
     
    </div>
  );
};


const companyLogo = [
  "https://cdn-icons-png.flaticon.com/128/5969/5969043.png",
  "https://cdn-icons-png.flaticon.com/128/732/732178.png",
  "https://cdn-icons-png.flaticon.com/128/5968/5968625.png",
  "https://cdn-icons-png.flaticon.com/128/5968/5968852.png",
  "https://cdn-icons-png.flaticon.com/128/882/882704.png",
  "https://cdn-icons-png.flaticon.com/128/5969/5969033.png",
  "https://cdn-icons-png.flaticon.com/128/732/732229.png",
  "https://cdn-icons-png.flaticon.com/128/5977/5977585.png",
  "https://cdn-icons-png.flaticon.com/128/732/732160.png",
  "https://cdn-icons-png.flaticon.com/128/732/732012.png",
  "https://cdn-icons-png.flaticon.com/128/5969/5969139.png",
  "https://cdn-icons-png.flaticon.com/128/152/152750.png",
  "https://cdn-icons-png.flaticon.com/128/5968/5968756.png",
  "https://cdn-icons-png.flaticon.com/128/5969/5969238.png",
  "https://cdn-icons-png.flaticon.com/128/732/732224.png",
  "https://cdn-icons-png.flaticon.com/128/914/914677.png",
  "https://cdn-icons-png.flaticon.com/128/882/882615.png",
  "https://cdn-icons-png.flaticon.com/128/11865/11865338.png",
  "https://cdn-icons-png.flaticon.com/128/5969/5969057.png",
];
