import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Content from './ContentPage';
import TestPage from './TestPage';
import CNavbar from '../component/CNavbar';

const FilterPage = () => {
  const initialFilters = [
    { label: 'Course*', selectedOption: '', options: ['Chartered Accountancy (CA)', 'Company Secretary (CS)', 'Cost And Management Accountancy (CMA)'] },
    { label: 'Select Level*', selectedOption: '', options: [] },
    { label: 'Attempt', selectedOption: '', options: [] },
    { label: 'Type', selectedOption: '', options: ['Single Subject Test', 'Multi Chapter Test', 'Fastrack Test', 'Full Course Test', 'MTP'] },
  ];

  const [filters, setFilters] = useState(initialFilters);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get('https://eduyog.in/api/items');
      setItems(response.data);
      setFilteredItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const category2Options = {
    'Chartered Accountancy (CA)': ['CA FOUNDATION', 'CA INTERMEDIATE', 'CA FINAL'],
    'Company Secretary (CS)': ['CSEET', 'CS EXECUTIVE', 'CS PROFESSIONAL'],
    'Cost And Management Accountancy (CMA)': ['CMA FOUNDATION', 'CMA INTERMEDIATE', 'CMA FINAL'],
  };

  const category3Options = {
    'CA FOUNDATION': ['SEP', 'JAN', 'JUNE', 'MAY', 'NOV'],
    'CA INTERMEDIATE': ['SEP', 'JAN', 'JUNE', 'MAY', 'NOV'],
    'CA FINAL': ['SEP', 'JAN', 'JUNE', 'MAY', 'NOV'],
    'CSEET': [],
    'CS EXECUTIVE': [],
    'CS PROFESSIONAL': [],
    'CMA FOUNDATION': [],
    'CMA INTERMEDIATE': [],
    'CMA FINAL': [],
  };

  const handleFilterChange = (index, selectedOption) => {
    const newFilters = filters.map((filter, i) => {
      if (i === index) {
        return { ...filter, selectedOption };
      }
      if (i > index && i < filters.length - 1) {
        return { ...filter, selectedOption: '', options: [] };
      }
      return filter;
    });

    if (index === 0 && selectedOption) {
      newFilters[1].options = category2Options[selectedOption] || [];
      newFilters[2].options = [];
    } else if (index === 1 && selectedOption) {
      newFilters[2].options = category3Options[selectedOption] || [];
    }

    setFilters(newFilters);
    filterItems(newFilters);
  };

  const filterItems = (appliedFilters) => {
    let filteredItems = items.filter(item =>
      appliedFilters.every(filter => filter.selectedOption === '' || item[`category${appliedFilters.indexOf(filter) + 1}`] === filter.selectedOption)
    );
    setFilteredItems(filteredItems);
  };

  const handleSubmit = () => {
    filterItems(filters);
  };

  const handleReset = () => {
    setFilters(initialFilters);
    setFilteredItems(items);
  };

  return (
    <>
      <CNavbar />
      <div style={{ marginTop: "0%", backgroundColor: "white" }}>
        <div className="container py-5">
          <div className="row mb-4">
            <div className="col-12 text-center">
              <h1 className="display-4 text-primary">Explore Our IT Courses</h1>
              <p className="lead text-muted">Enhance your skills with our comprehensive IT courses, tailored for both beginners and professionals.</p>
            </div>
          </div>
          <div className="row">
            {/* Static content */}
            {/* <div className="col-lg-4">
              <div className="p-4 bg-white rounded shadow-sm">
                <TestPage filters={filters} onFilterChange={handleFilterChange} onSubmit={handleSubmit} onReset={handleReset} />
              </div>
            </div> */}
            <div className="col-lg-8">
              <Content items={filteredItems} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterPage;
