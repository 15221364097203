import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import { Link } from 'react-router-dom';


const Arrays1 = () => {

  const assignments = [
    { id: 1, name: 'Videos- Arrays', status: 'start'},
    { id: 2, name: 'Videos - Primitive DT and Reference DT', status: 'start'},
    { id: 3, name: 'Storing Elements in Array', status: 'start'},
    { id: 4, name: 'Maximum in an Array', status: 'start'},
    { id: 5, name: 'Minimum element in an array	', status: 'start'},
    { id: 6, name: 'Store Prime numbers in an array	', status: 'start'},
    { id: 7, name: 'Subarrays', status: 'start'},
    { id: 8, name: 'Maximum Sum Subarray', status: 'start'},
    { id: 9, name: 'Video - Dynamic Arrays', status: 'start'},
    { id: 10, name: 'How to debug any code', status: 'start'},
    { id: 11, name: 'Running sum', status: 'start'},
    { id: 12, name: 'Maximum in 2D array', status: 'start'},
    { id: 13, name: 'Sum of Odd length Subarrays', status: 'start'},
    { id: 14, name: 'Missing Number', status: 'start'},
    { id: 15, name: 'Videos - Sorting', status: 'start'},
    { id: 16, name: 'Bubble Sort', status: 'start'},
    { id: 17, name: 'Insertion Sort', status: 'start'},
    { id: 18, name: 'Selection Sort', status: 'start'},
  ];


  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
      <div className='page1'>
      <div>
        <Sidebar/>
      </div>
      <div>
      <div className="assignment-foj">
      <div className="rig-phw">
        <div className="fle-fok justify-6c5 item-ycb orqly">
          <Link to='/assignment/5'>
          <button className="btn-zle bvadh">Next Assignment</button>
          </Link>
          
        </div>
        <div className="right-c1e">
          <table>
           
            <colgroup>
              {Array(4).fill().map((_, index) => <col key={index} className={`style-${index}EMdC`} />)}
            </colgroup>
            <thead>
              <tr>
                <th className="style-q61Z3">#</th>
                <th>Name</th>
                <th className="style-6q7nt">Status</th>
                <th className="style-grDIy"></th>
              </tr>
            </thead>
            <tbody className="style-iNhB2">
              {assignments.map(({ id, name, status, points }, index) => (
                <tr key={id} className="style-GbKIa">
                  <td className="style-CQJlN">{id}</td>
                  <td>
                    {name}
                   
                  </td>
                  <td className="style-mF9jW">
                  <Link to={`/Arrays2/${index + 1}`} className='style-ckn'>{status}</Link>
                  </td>
                  <td className="style-hsgX7">{points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default Arrays1;
