import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Button } from 'reactstrap';
import { FiUploadCloud } from 'react-icons/fi'; // Importing cloud upload icon
import axios from 'axios';

const DashboardContent = ({ user, courseId }) => {
  const [formData, setFormData] = useState({
    file: ''
  });
  const [contentSent, setContentSent] = useState(false);
  const [fileName, setFileName] = useState('No file chosen');

  useEffect(() => {
    // Check if content has been sent previously
    const contentSentStatus = localStorage.getItem('contentSent');
    const contentSentTime = localStorage.getItem('contentSentTime');
    if (contentSentStatus === 'true') {
      const currentTime = new Date().getTime();
      const twentyFourHours = 24 * 60 * 60 * 1000;
      if (currentTime - parseInt(contentSentTime) < twentyFourHours) {
        setContentSent(true);
      } else {
        // If more than 24 hours have passed, clear the localStorage items
        localStorage.removeItem('contentSent');
        localStorage.removeItem('contentSentTime');
      }
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      file
    });
    setFileName(file.name); // Set the name of the chosen file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if content already sent
    const contentSent = localStorage.getItem('contentSent');
    if (contentSent) {
      alert('Content already sent!');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('file', formData.file);
    formDataToSend.append('userEmail', user.email);
    formDataToSend.append('userName', `${user.firstName} ${user.lastName}`); // Combine first and last name
    formDataToSend.append('courseId', courseId);

    try {
      const response = await axios.post('https://eduyog.in/api/forTest', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 201) {
        alert('Answersheet sent successfully');
        // Mark content as sent and set expiration time to 24 hours
        localStorage.setItem('contentSent', 'true');
        localStorage.setItem('contentSentTime', new Date().getTime().toString());
        setContentSent(true);
        setFormData({ file: '' });
        setFileName('No file chosen'); // Reset the file name display
      }
    } catch (error) {
      console.error('There was an error adding the content!', error);
      alert('Failed to add content');
    }
  };

  return (
    <Container className="mt-5">
      <style>
        {`
          .card-custom {
            border-radius: 15px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
          }
          .card-body-custom {
            padding: 2rem;
            background-color: #f8f9fa;
          }
          .upload-button {
            font-size: 1.2rem;
            padding: 0.75rem 1.5rem;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 25px;
            cursor: pointer;
            transition: background-color 0.3s;
          }
          .upload-button:hover {
            background-color: #0056b3;
          }
          .file-name {
            font-size: 1rem;
            color: #6c757d;
            margin-top: 0.5rem;
          }
          .alert-custom {
            color: #28a745;
            font-size: 1rem;
            font-weight: bold;
          }
          .upload-btn-custom {
            font-size: 1rem;
            background-color: #28a745;
            color: #fff;
            border-radius: 25px;
            padding: 0.5rem 2rem;
            border: none;
            transition: background-color 0.3s;
          }
          .upload-btn-custom:hover {
            background-color: #218838;
          }
        `}
      </style>

      <Card className="card-custom mx-auto" style={{ maxWidth: '600px' }}>
        <CardBody className="card-body-custom text-center">
          <h3 className="mb-3">Upload Your Answersheet</h3>
          <p className="text-muted mb-4">
            Upload your answersheet only once for particular HomeWork. Additional attempts will not be considered.
            Once uploaded, the upload button will be visible again after 24 hours.
          </p>
          {contentSent ? (
            <p className="alert-custom">Answersheet has been sent.</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="file"
                  className="d-none"
                  id="fileInput"
                  accept=".pdf"
                  onChange={handleFileChange}
                  required
                />
                <label htmlFor="fileInput" className="upload-button">
                  <FiUploadCloud className="me-2" /> Choose File
                </label>
                <p className="file-name">{fileName}</p>
              </div>
              <Button type="submit" className="upload-btn-custom">
                Upload Homework
              </Button>
            </form>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default DashboardContent;
