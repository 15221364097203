import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Test Question - 1',
      content: (
        <div>
          <p className="task-mvp">Test Question - 1</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>
                    An Array of Elements sorted in descending Order is given and an integer Target.
<br/>
If target exists, then return its index. Otherwise, return -1

<br/><br/>

Example 1:-
<br/>
Input:-
<br/>
Arr - [9,5,3, 2,1]
<br/>
Target - 5


<br/><br/>
Output:-
<br/>
1

<br/><br/>

Explanation:- 
<br/>
Target 5 is present at index 1.

<br/><br/>

Example 2:-
<br/>
Input:-
<br/>
Arr- [10,8,6,4,2,1]
<br/>
Target - 6

<br/><br/>

Output:-
<br/>
2

<br/><br/>

Explanation:- 
<br/>
Target 6 is present at index 2.

<br/><br/>

Example 3:-
<br/>
Input:-
<br/>
Arr- [10,8,6,4,2,1]
<br/>
Target - 11
<br/><br/>


Output:-
<br/>
-1

<br/>
<br/>
Explanation:- 
<br/>
Target 11 is not present therefore we have to return -1.


<br/><br/>


Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= nums.length &lt;= 104<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-104 &lt; nums[i], target&lt; 104<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All the integers in nums are unique.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;nums is sorted in descending order.<br/>
<br/><br/> (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
                    <br/>
                    </p>
                     
                    
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Test Question - 2',
      content: (
        <div>
          <p className="task-mvp">Test Question - 2</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>
                    An Array of Elements is given. Return the element being repeated maximum number of times.
<br/>
If there are more than one element whose occurrence is the same then you can return any of those element.
<br/><br/>




Solve it in 0(n) using Maps.

<br/><br/>

Example 1:-
<br/>
Input:-
<br/>
Arr - [5,3,5,9,5,3, 2,1]

<br/><br/>



Output:-
<br/>
5
<br/><br/>


Explanation:- 
<br/>
Element 5 is present 3 times in the array
<br/>
Element 3 is present 2 times.
<br/>
and other elements occur only 1 time
<br/><br/>


Example 2:-
<br/>
Input:-
<br/>
Arr- [10,8,10,8,8,8,3,2]

<br/><br/>



Output:-
<br/>
8

<br/><br/>

Explanation:- 
<br/>
Element 8 is present 4 times in the array
<br/>
Element 10 is present 2 times.
<br/>
and other elements occur only 1 time
<br/><br/>


Example 3:-
<br/>
Input:-
<br/>
Arr- [10,8,10,8,3,2]

<br/><br/>



Output:-
<br/>
8 or 10

<br/><br/>

Explanation:- 
<br/>
Element 8 is present 2 times in the array
<br/>
Element 10 is present 2 times.
<br/>
and other elements occur only 1 time
<br/>
therefore answer can be 8 or 10.


<br/><br/>


Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= nums.length &lt;= 10^6<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0 &lt; nums[i], target &lt;10^6<br/>
<br/><br/> (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
                    <br/>
                    </p>
                     
                    
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Test Question - 3',
      content: (
        <div>
          <p className="task-mvp">Test Question - 3</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>
                    An integer Arrays nums is provided and a target is provided. Return True if any element has occurred more than Target times else return False.


                    <br/> <br/>
Solve it in 0(n) Time Complexity 
<br/> <br/>


Example 1:-
<br/>
Input:-
<br/>
Arr - [9,5,3, 2,1,5,1,4,3,5,4,6,5]
<br/>
Target - 3
<br/> <br/>


Output:-
<br/>
True
<br/> <br/>


Explanation:- 
<br/>
5 has occurred 4 times (4&gt;Target ) therefore output is true.
<br/>
<br/>

Example 2:-
<br/>
Input:-
<br/>
Arr - [9,5,3, 2,1,5,1,4,3,5,4,6,5]
<br/>
Target - 5

<br/> <br/>

Output:-
<br/>
False
<br/> <br/>


Explanation:- 
<br/>
5 has occurred 4 times (4&lt;Target) therefore output is False.

<br/> <br/>

Example 3:-
<br/>
Input:-
<br/>
Arr- [10,8,10,4,10,1]
<br/>
Target - 2
<br/>
Output:-
<br/>
True
<br/> <br/>


Explanation:- 
<br/>
10 has occurred 3 times (3&gt;Target) therefore output is true.
<br/> <br/>




Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= nums.length &lt;= 10^6<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0 &lt; nums[i], target&lt; 10^6
<br/><br/> (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
                    <br/>
                    </p>
                     
                    
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Test Question - 4',
      content: (
        <div>
          <p className="task-mvp">Test Question - 4</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>
                    Given an array Containing Strings sort on the basis of number of character 'a' present. Return the sorted array
                    <br/>
Note if number of a is same then length will be given preference.

<br/><br/>

Example 1: 
<br/>

<br/>
Input:-
<br/>
arr - ["vaibhav", "almanac" , "is" ,"fat", "button","aabaca"]


<br/><br/>
Output:-
<br/>
["aabaca", "almanac", "vaibhav", "fat", "button", "is"]

<br/><br/>

Explanation:- 
<br/>
"aabaca" contains character 'a' 4 times.
<br/>
"almanac" contains character 'a' 3 times.
<br/>
"vaibhav" contains character 'a' 2 times.
<br/>
"fat" contains character "a" 1 times.
<br/>
"button" and "is" does not contain character 'a' but length of "button" is more. 



<br/><br/>

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= nums.length &lt;= 1 * 10^4<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-5 * 10^4 &lt;= nums[i] &lt;=  5 * 10^4
<br/><br/> (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
                    <br/>
                    </p>
                     
                    
                  </div>
                </div>
        </div>
      ),
    },
      
  ];

const ProLevel22 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 4) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/8'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 4</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default ProLevel22;
