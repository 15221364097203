import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem('token');
  
    if (!isAuthenticated) {
      return <Navigate to="/signin" />;
    }
  
    return <Component {...rest} />;
  };
  

export default PrivateRoute;
