import React, { useState } from 'react';
import axios from 'axios';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import CNavbar from './CNavbar';


const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://eduyog.in/authmy/signin', { email, password });
      const { token } = response.data;
      localStorage.setItem('token', token);
      console.log(response.data);

      
navigate('/placement/dashboard')
    
    } catch (error) {
      console.error(error);
      setError('Your account is not approved. Please contact the administrator or Wait some time');
    }
  };



  return (
    <>
    <div className='counse' style={{height:"100vh"}}>
    <CNavbar/>
    <div className="container sign">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-9">
          <div className="card p-5">
            <h1 className="text-center mb-4 display-4 fw-normal">Sign In</h1>
            {error && <p className="alert alert-danger">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="mb-3">
                <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <button type="submit" className="btn btn-primary w-100">Sign In</button>
            </form>
          </div>
          <div className="text-center mt-3">
            <Link to="/signup" className="text-decoration-none">SignUp</Link>
          </div>
          <div className="text-center mt-2">
            <Link to="/forgotten" className="text-decoration-none">Forgotten Password</Link>
          </div>
        </div>
      </div>
    
    </div>
    </div>
    </>
  );
};

export default Signin;
