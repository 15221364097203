import React from 'react';
import image15 from "../images/image15.png"
import { Link } from 'react-router-dom';


const Admission = () => {
  return (
    <>
    <div className="bg-white class-2">
      <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="row align-items-center py-5">
          <div className="col-md-6 mb-4 mb-md-0">
            <h1 className="text-4xl display-5 fw-bold leading-tight text-gray-900">
              Your Direct Line to Ivy League Professors
            </h1>
            <p className="mt-3 text-base text-gray-500">Elevate Your College Journey with EduYog</p>
            
            <Link to={'/join'}>
            <button className="mt-4 btn bg-primary text-white fw-bold" type='submit'>
              Book Now
            </button>
            </Link>
          </div>
          <div className="col-md-6 imageTack">
            <img
              alt="Mentorship session"
              className="w-100 rounded-lg"
              src={image15}
              style={{ aspectRatio: "600/400", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
      <div className="class-1 bg-primary" id='class-1'>
        <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 fw-bold">
          <div className="py-12 text-center text-white">
            <h2 className="display-6 fw-bold text-white mb-3 ">Get 1-To-1 Mentorship From Top Mentors</h2>
            <div className="mt-8 row row-cols-1 gap-4 gap-md-0 row-cols-md-3">
              <div className="col">
                <h3 className="text-2xl font-bold text-white">1-on-1</h3>
                <p className="mt-2 text-base">Counseling Sessions</p>
              </div>
              
              <div className="col">
                <h3 className="text-2xl font-bold text-white">15+</h3>
                <p className="mt-2 text-base">Academic Departments</p>
              </div>
              <div className="col">
                <h3 className="text-2xl font-bold text-white">20+</h3>
                <p className="mt-2 text-base">Colleges Across India</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </>
  );
};

export default Admission;
