import React from 'react';
import Optimize from './Optimize';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import Page2 from './Page2';
import { Link } from 'react-router-dom';
import Bpage from '../BasicCoding/Bpage';
import Lpage from '../BasicCoding/Lpage';
import Arrays1 from '../BasicCoding/Arrays1';
import BManipulation1 from '../BasicCoding/BManipulation1';
import ProText1 from '../BasicCoding/ProText1';
import Oops1 from '../BasicCoding/Oops1';
import ProLevel21 from '../BasicCoding/ProLevel21';
import Mock21 from '../BasicCoding/Mock21';
import Stack1 from '../BasicCoding/Stack1';
import Linked1 from '../BasicCoding/Linked1';
import Mock31 from '../BasicCoding/Mock31';
import Starting1 from '../WebDev/Starting1';
import Dom1 from '../WebDev/Dom1';
import Dsa1 from '../WebDev/Dsa1';
import AdvanceJS1 from '../WebDev/AdvanceJS1';
import Projectjs1 from '../WebDev/Projectjs1';
import Testaj1 from '../WebDev/Testaj1';
import RegistrationPage1 from '../WebDev/RegistrationPage1';
import JsProject1 from '../WebDev/JsProject1';
import Tdsa1 from '../WebDev/Tdsa1';
import UploadingSoon from './UploadingSoon';

const AssignmentButton = ({  id, title, points, imageSrc }) => (
  // Create a link using react-router-dom Link component
  <Link to={`/assignment/${id}`} className="ass-phf rel-iat">
    {/* Optimize component for some visual element */}
    <Optimize />

    {/* Display assignment logo if imageSrc is provided */}
    {imageSrc && (
      <div className="logo-x1g">
        <img src={imageSrc} alt="Assignment Logo" />
      </div>
    )}

    {/* Display assignment title and points */}
    <div>
      <p className="assignment-ahw">{title}</p>
      {/* <p className="fejpk nwhyx cnooh poi-pws">Points awarded: {points}</p> */}
    </div>
  </Link>
);
const AssignmentPage = ({ match }) => {
  
  const {id} = useParams(match);
  // Fetch assignment details based on the ID
  if(id==1)
  {
  return (
    <div>
      <Page2/>
    </div>
  );
  }
  if(id==2)
  {
    return <Bpage/>;
  }
  if(id==3)
  {
      return <Lpage/>;
  }
  if(id==4)
  {
      return <Arrays1/>;
  }
  if(id==5)
  {
      return <BManipulation1/>;
  }
  if(id==6)
  {
      return <ProText1/>;
  }
  if(id==7)
  {
      return <Oops1/>;
  }
  if(id==8)
  {
      return <ProLevel21/>;
  }
  if(id==9)
  {
      return <Mock21/>;
  }
  if(id==10)
  {
      return <Stack1/>;
  }
  if(id==11)
  {
      return <Linked1/>;
  }
  if(id==12)
  {
      return <Mock31/>;
  }
  if(id==13)
  {
      return <Starting1/>;
  }
  if(id==14)
  {
      return <Dom1/>;
  }
  if(id==15)
  {
      return <Dsa1/>;
  }
  if(id==16)
  {
      return <AdvanceJS1/>;
  }
  if(id==17)
  {
      return <Projectjs1/>;
  }
  if(id==18)
  {
      return <Testaj1/>;
  }
  if(id==19)
  {
      return <RegistrationPage1/>;
  }
  if(id==20)
  {
      return <JsProject1/>;
  }
  if(id==21)
  {
      return <Tdsa1/>;
  }
  else{
    return (
      <>
      <UploadingSoon/>
      </>
    )
  }
};
const CourseSection = ({ title, assignments }) => (
  <div className="course-gba rel-iat">
    <div className="button-do7 header-old header-omc">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M5.33325 16C5.33325 21.8911 10.1089 26.6667 15.9999 26.6667C21.891 26.6667 26.6666 21.8911 26.6666 16C26.6666 10.109 21.891 5.33337 15.9999 5.33337C10.1089 5.33337 5.33325 10.109 5.33325 16Z"
          stroke="#6750A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        ></path>
        <path
          d="M12 16C12 18.2091 13.7909 20 16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16Z"
          stroke="#6750A4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        ></path>
      </svg>
      <p>{title}</p>
    </div>
    <div className="jou-eyx">
      <div className="ass-wwv">
        {assignments.map((assignment, index) => (
          <AssignmentButton key={index} {...assignment} />
        ))}
      </div>
    </div>
  </div>
);

// const YourComponent = () => (
// <Routes>
    // <Route path="/assignment/:id" element={<AssignmentPage />} />
    // <Route path="/" element={ // Define your root route content here
    //   <>
    // <CourseSection title="Fundamentals of coding and linear dsa" assignments={[
    //  { id: "1", title: "Getting Started"},
    //  { id: "2", title: "Basic Coding" },
    //  { id: "3", title: "Looping" },
    //  { id: "4", title: "Arrays" },
    //  { id: "5", title: "Bit Manipulation And Complexity" },
    //  { id: "6", title: "Pro Test Level 1" },
    //  { id: "7", title: "OOPS, Binary Search, Map and Set" },
    //  { id: "8", title: "Pro Test Level 2" },
    //  { id: "9", title: "Mock Interview 2" },
    //  { id: "10", title: "Stacks & Queues" },
    //  { id: "11", title: "Linked List and Basic Recursion" },
    //  { id: "12", title: "Mock Interview 3" },
    //   // Add more assignments as needed
    // ]} />
//     <CourseSection title="Algorithms" assignments={[
//       { id: "style-PSq5C", title: "Lets Get Started with React" },
//       { id: "style-mqQDf", title: "Advance React" },
//       // Add more assignments as needed
//     ]} />
//     <CourseSection title="Kickstarting Web Dev" assignments={[
//       { id: "style-PSq5C", title: "Lets Get Started with React" },
//       { id: "style-mqQDf", title: "Advance React" },
//       // Add more assignments as needed
//     ]} />
//     <CourseSection title="Backend Dev with Node and Express" assignments={[
//       { id: "style-PSq5C", title: "Lets Get Started with React" },
//       { id: "style-mqQDf", title: "Advance React" },
//       // Add more assignments as needed
//     ]} />
//     <CourseSection title="Backend Placement Starts" assignments={[
//       { id: "style-PSq5C", title: "Lets Get Started with React" },
//       { id: "style-mqQDf", title: "Advance React" },
//       // Add more assignments as needed
//     ]} />
//     <CourseSection title="Your Course Title" assignments={[
//       { id: "style-rlE3T", title: "The NOSQL way of doing stuff", points: "Review Pending" },
//       { id: "style-Er8HP", title: "No SQL Demo" },
//       { id: "style-txZeE", title: "Resume Prep & Final Mock Interview" },
//     ]} />
//     <CourseSection title="Frontend Development with React JS" assignments={[
//       { id: "style-PSq5C", title: "Lets Get Started with React" },
//       { id: "style-mqQDf", title: "Advance React" },
//       // Add more assignments as needed
//     ]} />
//      <CourseSection title="Fundamentals of Coding and Linear DSA" assignments={[
//       { id: "style-IRivJ", title: "Getting Started" },
//       { id: "q22nn", title: "Basic Coding" },
//       // Add more assignments as needed
//     ]} />
//     <CourseSection title="React Assignments" assignments={[
//       { id: "EaWpa", title: "Styling, Debugging, and Lifecycle Methods in React" },
//       { id: "q22nn", title: "3 hours React JS Project" },
//       // Add more assignments as needed
//     ]} />
//     <CourseSection title="Super Dream Placement Starts" assignments={[
//       { id: "pv9fj", title: "React Js Interview Question" },
//       // Add more assignments as needed
//     ]} />
//     {/* Additional course sections can be added as needed */}
//   </>
// } />
// </Routes>
// );
const YourComponent = () => (
  
    <Routes>
      <Route path="/assignment/:id" element={<AssignmentPage />} />
      <Route path="/" element={
        <>
          {/* CourseSection components with assignments */}
          <CourseSection title="Fundamentals of coding and linear dsa" assignments={[
             { id: "1", title: "Getting Started"},
             { id: "2", title: "Basic Coding" },
             { id: "3", title: "Looping" },
             { id: "4", title: "Arrays" },
             { id: "5", title: "Bit Manipulation And Complexity" },
             { id: "6", title: "Pro Test Level 1" },
             { id: "7", title: "OOPS, Binary Search, Map and Set" },
             { id: "8", title: "Pro Test Level 2" },
             { id: "9", title: "Mock Interview 2" },
             { id: "10", title: "Stacks & Queues" },
             { id: "11", title: "Linked List and Basic Recursion" },
             { id: "12", title: "Mock Interview 3" },
          ]} />
           <CourseSection title="Kickstarting Web Dev" assignments={[
             { id: "13", title: "Starting off with Web Development"},
             { id: "14", title: "DOM Manipulations" },
             { id: "15", title: "3 hours test on Basic dsa" },
             { id: "16", title: "Advanced Javascript" },
             { id: "17", title: "3 hours project on Javascript" },
             { id: "18", title: "3 hours test on Advanced Javascript" },
             { id: "19", title: "Making the Registration Page Dynamic" },
             { id: "20", title: "3 hours JS Project (Integrating REST API)" },
             { id: "21", title: "3 hours test on DSA" },
             { id: "22", title: "Javascript Final Mock Interview" },
          ]} />
          <CourseSection title="Backend Dev with Node and Express" assignments={[
            { id: "23", title: "Kickstarting Node Js"},
            { id: "24", title: "Starting with Express Framework"},
            { id: "25", title: "Node Js Test Project 1"},
            { id: "26", title: "Demo Test Project"},
            { id: "27", title: "Expense Tracker - Node.js Project"},
            { id: "28", title: "Building a Group Chat App"},
           ]} />
           <CourseSection title="Backend Placement Starts" assignments={[
            { id: "23", title: "Placement Interview Questions (JS & SQL)"},
            { id: "24", title: "Placement Interview Questions (Node Js)"},
            { id: "25", title: "3 hours DSA Test"},
            { id: "26", title: "The NOSQL way of doing stuff"},
            { id: "27", title: "No SQL Demo"},
            { id: "28", title: "Resume Prep & Final Mock Interv."},
           ]} />
          {/*... other CourseSection components ...*/}
        </>
      } />
    </Routes>

);

export { YourComponent,AssignmentPage};


