import React from 'react';
import { Navigate , Outlet } from 'react-router-dom';

const PrivatedRoute = () => {
  const token = localStorage.getItem('authToken');

  return token ? <Outlet /> : <Navigate to="/LoginForCourse" />;
};

  

export default PrivatedRoute;
