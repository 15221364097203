import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminPanel from './AdminPanel';
import { Button } from 'reactstrap';
import UpdateGoogleMeetLink from './UpdateGoogleMeet';
import { Link, useNavigate } from 'react-router-dom';

export const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://eduyog.in/api/admin/users/pending');
        setUsers(response.data.users);
        setError(null);
      } catch (error) {
        console.error('Error:', error);
        setError('no users available for fetching. Please try again.');
      }
    };

    fetchUsers();
  }, []);

  const handleApprove = async (userId) => {
    try {
      await axios.put(`https://eduyog.in/api/admin/approve/${userId}`);
      const response = await axios.get('https://eduyog.in/api/admin/users/pending');
      setUsers(response.data.users);
      setError(null);
      setMessage('User approved successfully.');
    } catch (error) {
      console.error('Error:', error);
      setError('User approved successfully.');
    }
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/logInForAdmin');
  };

  return (
    
   <div>
    <Button color="danger" className='m-3' onClick={handleLogout}>Logout</Button>
    <Link to={'/addCourse'}><Button color="primary" className='m-3' onClick={handleLogout}>Add Course</Button></Link>
     <div className="container  text-center">
      
      <h1 className="mb-4 display-4">Admin Panel</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {message && <div className="alert alert-success">{message}</div>}
      <h2>Users Awaiting Approval</h2>
      {users.length === 0 ? (
        <p>No users awaiting approval</p>
      ) : (
        <ul className="list-group">
          {users.map((user) => (
            <li key={user.id} className="list-group-item">
              <div className="row">
                <div className="col-md-4">
                  <p><strong>User Email-Id:</strong> {user.email}</p>
                  <p><strong>User ID:</strong> {user.id}</p>
                </div>
                
              </div>
              <button className="btn btn-success mt-2" onClick={() => handleApprove(user.id)}>Approve</button>
            </li>
          ))}
        </ul>
      )}
      <AdminPanel/>
      <UpdateGoogleMeetLink/>
    </div>
   </div>
  );
};

export default AdminDashboard;
