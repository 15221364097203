import React from 'react';
import './AboutPage.css'; // Import the CSS file
import CNavbar from "./CNavbar";
import image10 from "../images/image10.jpg";
import image11 from "../images/image11.jpg";
import image12 from "../images/image12.jpg";
import image13 from "../images/image13.jpg";
import image14 from "../images/image14.jpg";

function AboutPage() {
  // JavaScript for interactive elements
  const handleAccordion = (id) => {
    const accordionItem = document.getElementById(id);
    accordionItem.classList.toggle('active');
  };

  return (
    <>
    <div className='counse' style={{height:"100vh"}}>
    <CNavbar/>
    <div className="about-container">
      
      <header>
        <h1 className='display-3 fw-bold text-dark'>Welcome to EDUYOG</h1>
        <p className='text-dark'>A fusion of education and yoga</p>
      </header>
      <section className="vision" onClick={() => handleAccordion('vision-content')}>
        <h2 className='aboutcolor'>Our Vision</h2>
        <div className="accordion-content" id="vision-content">
        <img src={image10} alt="Photo2" width={730}height={700} border="0"/>
        <img src={image11} alt="Photo1" width={730}height={700}/>
        </div>
      </section>
      <section className="technical-mastery" onClick={() => handleAccordion('technical-content')}>
        <h2 className='aboutcolor'>Technical Mastery at Your Fingertips</h2>
        <div className="accordion-content" id="technical-content">
        <img src={image12} alt="Photo4" border="0"width={730}height={700}/>
        </div>
      </section>
      <section className="yoga-for-mindful-living" onClick={() => handleAccordion('yoga-content')}>
        <h2 className='aboutcolor'>Yoga for Mindful Living</h2>
        <div className="accordion-content" id="yoga-content">
        <img src={image13} alt="Photo3" border="0" width={730}height={700}/>
        </div>
      </section>
      <section className="why-choose-eduyog" onClick={() =>handleAccordion('chooseEduYog')}>
        <h2 className='aboutcolor'>Why Choose EDUYOG?</h2>
        <div className="accordion-content" id="chooseEduYog">
        
        <img src={image14} alt="JcLTU8J.md.jpg" border="0" width={730}height={700}/>
        </div>
      </section>
      <footer>
        <p className='text-dark'>Join us at EDUYOG and embark on a transformative journey towards a future filled with technical prowess and holistic well-being.</p>
      </footer>
    </div>
    </div>
  
    </>
  );
}

export default AboutPage;

