import React, { useState } from 'react';
import './Service.css';
import image1 from "../images/image1.jpg";
import image2 from "../images/image2.jpg";
import image3 from "../images/image3.jpg";
import image4 from "../images/image4.jpg";
import image5 from "../images/image5.jpg";
import image6 from "../images/image6.jpg";
import image7 from "../images/image7.jpg";
import image8 from "../images/image8.jpg";
import image9 from "../images/image9.jpg";
import CNavbar from './CNavbar';

const Service = () => {
  const itemsPerPage = 1; // Set the number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  const sections = [
    {
      title: 'Technical Skills Excellence',
      images: [
        {
          src: image1,
        },
        {
          src: image2,
        },
        {
          src: image3,
        },
      ],
    },
    {
      title: 'Holistic Fitness Fusion',
      images: [
        {
          src: image4,
        },
        {
          src: image5,
        },
        {
          src: image6,
        },
      ],
    },
    {
      title: 'Synergistic Growth at EDUYOG',
      images: [
        {
          src: image7,
        },
        {
          src: image8,
        },
        {
          src: image9,
        },
      ],
    },
  ];

  const totalSections = sections.length;
  const totalPages = Math.ceil(totalSections / itemsPerPage);

  const handleClick = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderSections = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    return sections.slice(startIndex, endIndex).map((section, index) => (
      <section key={index} className="section">
        <h1 className="text-dark fw-bold large-text"style={{ fontSize: '24px' }}>{section.title}</h1>
        <div className="row">
          {section.images.map((image, imageIndex) => (
            <div key={imageIndex} className="col-12 col-md-6 col-lg-4 mb-2">
              <img
                src={image.src}
                alt="uploading"
                className="img-fluid"
                style={{ width: '100%', height: '500px' }} // Set your desired fixed width and height
              />
            </div>
          ))}
        </div>
      </section>
    ));
  };
  
  
 

  return (
    <>
      <CNavbar />
      <div className="container-1">
        <h5 className="display-6 text-dark fw-bold">Welcome to EDUYOG – Your Gateway to Comprehensive Growth!</h5>

        {renderSections()}

        <div className="pagination">
          <button
            className="btn btn-secondary"
            onClick={() => handleClick(currentPage > 1 ? currentPage - 1 : 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          <span className="mx-2">
            Page {currentPage} of {totalPages}
          </span>

          <button
            className="btn btn-secondary"
            onClick={() => handleClick(currentPage < totalPages ? currentPage + 1 : totalPages)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>

        <footer className="foooter">
          <p>
            At EDUYOG, we're not just a platform; we're your partner in comprehensive growth. Embrace the fusion of technical brilliance and physical vitality.
            Elevate your skills, energize your body, and unlock your full potential with EDUYOG – Where Education Meets Holistic Excellence!
          </p>
        </footer>
      </div>
    </>
  );
};

export default Service;
