

import React, { useState, useEffect } from 'react';
import SessionStatus from './SessionStatus';
import axios from 'axios';
import ReactPlayer from 'react-player';
import 'bootstrap/dist/css/bootstrap.min.css';


const LiveYogaClass = () => {
  const [isLive, setIsLive] = useState(true);
  const [googleMeetLink, setGoogleMeetLink] = useState('');

  useEffect(() => {
    const fetchLiveStatus = async () => {
      try {
        const response = await axios.get('https://eduyog.in/api/live-status');
        setIsLive(response.data.isLive);
        if (response.data.isLive) {
          fetchGoogleMeetLink();
        } else {
          setGoogleMeetLink('');
        }
      } catch (error) {
        console.error('Error fetching live status:', error);
      }
    };

    const fetchGoogleMeetLink = async () => {
      try {
        const response = await axios.get('https://eduyog.in/api/google-meet-link');
        setGoogleMeetLink(response.data.link);
      } catch (error) {
        console.error('Error fetching Google Meet link:', error);
      }
    };

    fetchLiveStatus();

    const intervalId = setInterval(fetchLiveStatus, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="text-center">
        <h2 className="mb-4 fw-bold text-dark display-4">Join the Live Yoga Session</h2>
        <SessionStatus isLive={isLive} />
      </div>

      {googleMeetLink && (
        <div className="text-center mt-3">
          <a href={googleMeetLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
            Join Google Meet
          </a>
        </div>
      )}
      <div className="row mt-5 justify-content-center">
    <div className="col-md-6">
      <img src="https://t3.ftcdn.net/jpg/06/25/92/88/240_F_625928860_cxP4oiGJdtGhSradbNuksdJ5HFrvtk3Z.jpg" alt="Yoga Session" className="img-fluid rounded shadow " />
    </div>
    <div className="col-md-9 mt-5 align-items-center text-center">
      <div>
        <h3 className="mb-4 fw-bold text-dark display-5">Find Your Inner Peace</h3>
        <p className="lead">Join our live yoga session and embark on a journey of relaxation, rejuvenation, and self-discovery. Whether you're a beginner or an experienced yogi, there's something for everyone in our vibrant community.</p>
        <p className="lead">Prepare to stretch, breathe, and connect with your body and mind as our experienced instructors guide you through invigorating poses and calming meditation exercises.</p>
        <p className="lead">Don't miss out on this opportunity to nurture your well-being and enhance your overall quality of life. Join us today!</p>
      </div>
    </div>
  </div>
      
      <div className="m-5">
        <div className="card">
          <div className="card-header bg-dark text-white align-item-center">
            <h5 className="card-title mb-0 text-center">Yoga Session Video For Beginners</h5>
          </div>
          <div className="card-body session">
            <div className="embed-responsive embed-responsive-16by9">
              <ReactPlayer
                url="https://youtu.be/FdyhENXyIQ4?si=FRdRPcDxRWTLe585"
                controls={true}
                width="100%"
                height="40vh"
                className="rounded shadow"
              />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default LiveYogaClass;
