import React, { useEffect, useState } from "react";
import QuestionDetails from "./QuestionDetails";
import { useLocation } from "react-router-dom";
import EditorCompile from "./Editor";
import Objective from "./Objective";
import Axios from "../../../Axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/AuthContext";

const Playground = () => {
  const { state } = useLocation();
  return (
    <div className="playground">
      <div className="row ">
        <div className="col-md-4">
          <QuestionDetails data={state.question} lastq={state.lastq} />
        </div>
        <div className="col-md-8">
          {state.question.type === "Code" && (
            <EditorCompile
              question={state.question}
              topicId={state.id}
              qid={state.question.id}
            />
          )}
          {state.question.type === "objective" && (
            <Objective
              question={state.question}
              topicId={state.id}
              qid={state.question.id}
            />
          )}
          {state.question.type !== "Code" &&
            state.question.type !== "objective" && (
              <MarkDone
                question={state.question}
                topicId={state.id}
                qid={state.question.id}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default Playground;

const MarkDone = ({ qid, question, topicId }) => {
  const [reward, setreward] = useState(0);
  const [auth] = useAuth();
  useEffect(() => {
    setreward(question.reward);
  }, [question]);
  const handleMark = async () => {
    try {
      const res = await Axios.post("/solved/question/option", {
        status: true,
        reward: reward,
        user_id: auth?.user?.id,
        question_id: qid,
        topic_id: topicId,
      });
      console.log(res);
      if (res.data && res.data.success) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("server error try again...");
    }
  };
  return (
    <>
      <div className="d-flex h-100">
        <div className="m-auto">
          <button className="btn btn-success fw-bold" onClick={handleMark}>
            Mark As Done
          </button>
        </div>
      </div>
    </>
  );
};
