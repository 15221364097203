import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";

const BannerStatic = () => {

    const bannerContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '20px',
        padding: '20px',
    };
  
   

    const bannerStyle = {
        border: '2px solid #ddd',
        borderRadius: '10px',
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        transition: 'transform 0.2s',
        maxWidth: '400px',
        width: '100%',
    };

    

    const titleStyle = {
        color: 'black',
        fontSize: '24px',
        fontWeight: 'bold',
    };
   

    
    const subtitleStyle = {
        color: '#333',
        fontSize: '18px',
        margin: '10px 0',
    };

    const descriptionStyle = {
        color: '#666',
        fontSize: '16px',
        margin: '10px 0',
    };
    
    const linkStyle = {
        color: 'black',
        backgroundColor: 'white',
        padding: '10px 20px',
        textDecoration: 'none',
        borderRadius: '5px',
        display: 'inline-block',
        fontWeight: 'bold',
        marginTop: '10px',
        fontSize:"20px"
    };

    
    
    return (
        <div>
        <div style={bannerContainerStyle}>
            {/* Static Discount Banner */}
            <div style={{ ...bannerStyle, backgroundColor: 'black', maxWidth: '100%', margin: '0 auto', textAlign: 'center' }}>
                <h2 style={{ ...titleStyle, color: '#fff' }}>Special Discount!</h2>
                <h3 style={{ ...subtitleStyle, color: 'red' , fontSize:"30px" }}>Get upto 60% off on our courses</h3>
                <p style={{ ...descriptionStyle, color: '#fff' }}>Explore our collection and save big!</p>
                <Link to={'/ourcourses'} style={{ ...linkStyle }}>Buy Now</Link>
            </div>
            </div>

            
            
        </div>
    );
};

export default BannerStatic;


