import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import { Link } from 'react-router-dom';


const Oops1 = () => {

  const assignments = [
    { id: 1, name: 'Practice DSA Questions', status: 'start'},
    { id: 2, name: 'Videos - OOPS', status: 'start'},
    { id: 3, name: 'Constructors', status: 'start'},
    { id: 4, name: 'Functions', status: 'start'},
    { id: 5, name: 'Functions and objects', status: 'start'},
    { id: 6, name: 'Objects', status: 'start'},
    { id: 7, name: 'Functions and objects -2', status: 'start'},
    { id: 8, name: 'Videos - Static (Very Important)', status: 'start'},
    { id: 9, name: 'Famous Interview Question', status: 'start'},
    { id: 10, name: 'Task- 6 (Inheritance)', status: 'start'},
    { id: 11, name: 'Destructors - Videos	', status: 'start'},
    { id: 12, name: 'Task 7 (inheritance)', status: 'start'},
    { id: 13, name: 'Task 8 (Inheritance)', status: 'start'},
    { id: 14, name: 'Video - Binary Search', status: 'start'},
    { id: 15, name: 'Searching', status: 'start'},
    { id: 16, name: 'Important Interview Question -1', status: 'start'},
    { id: 17, name: 'First and Last Occurence of Element', status: 'start'},
    { id: 18, name: 'Important Interview Question-2	', status: 'start'},
    { id: 19, name: 'Map and Set Videos	', status: 'start'},
    { id: 20, name: 'Immutable and Mutable Strings', status: 'start'},
    { id: 21, name: 'Map Question -1', status: 'start'},
    { id: 22, name: 'Lucky Integer in an Array', status: 'start'},
    { id: 23, name: 'Sorting of Objects', status: 'start'},
    { id: 24, name: 'Important Interview Question - 3', status: 'start'},
    { id: 25, name: 'Jewels and Stones', status: 'start'},
    { id: 26, name: 'Me and You will be 0', status: 'start'},
    { id: 27, name: 'How many swaps ?', status: 'start'},
  ];


  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
      <div className='page1'>
      <div>
        <Sidebar/>
      </div>
      <div>
      <div className="assignment-foj">
      <div className="rig-phw">
        <div className="fle-fok justify-6c5 item-ycb orqly">
          <Link to='/assignment/8'>
          <button className="btn-zle bvadh">Next Assignment</button>
          </Link>
          
        </div>
        <div className="right-c1e">
          <table>
           
            <colgroup>
              {Array(4).fill().map((_, index) => <col key={index} className={`style-${index}EMdC`} />)}
            </colgroup>
            <thead>
              <tr>
                <th className="style-q61Z3">#</th>
                <th>Name</th>
                <th className="style-6q7nt">Status</th>
                <th className="style-grDIy"></th>
              </tr>
            </thead>
            <tbody className="style-iNhB2">
              {assignments.map(({ id, name, status, points }, index) => (
                <tr key={id} className="style-GbKIa">
                  <td className="style-CQJlN">{id}</td>
                  <td>
                   {name}
                  </td>
                  <td className="style-mF9jW">
                    
                    <Link to={`/Oops2/${index + 1}`} className='style-ckn'>{status}</Link>
                  </td>
                  <td className="style-hsgX7">{points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default Oops1;