import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import CNavbar from './CNavbar';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
  
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
  
    try {
      const response = await axios.post('https://eduyog.in/authmy/signup', {
        name,
        email,
        password,
        phoneNumber
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      // Check if the response status is 200 or 201
      if (response.status === 200 || response.status === 201) {
        setSuccessMessage('User registered successfully. Check your email for verification.');
      } else {
        // Handle unexpected success responses
        setError('Unexpected response from server.');
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 400) {
          // Handle specific client-side errors
          setError(error.response.data.message || 'user already registered.');
        } else if (error.response.status === 401) {
          // Handle authentication errors
          setError('Unauthorized access. Please check your credentials.');
        } else if (error.response.status === 500) {
          // Handle server-side errors
          setError('Server error. Please try again later.');
        } else {
          // Handle other errors
          setError('An unexpected error occurred. Please try again later.');
        }
      } else if (error.request) {
        // The request was made but no response was received
        setError('No response from the server. Please check your network connection.');
      } else {
        // Something happened in setting up the request that triggered an Error
        setError('Error in setting up the request. Please try again.');
      }
    }
  };

  return (
    <>
      <div className='counse' style={{ height: "100vh" }}>
        <CNavbar />
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="card">
                <h1 className="text-center mb-4 display-4 fw-normal">Sign Up For Free</h1>
                {error && <p className="alert alert-danger">{error}</p>}
                {successMessage && <p className="alert alert-success">{successMessage}</p>}
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <input type="tel" className="form-control" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <input type="password" className="form-control" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                  </div>
                 
                  <button type="submit" className="btn btn-primary w-100">Sign Up</button>
                </form>
              </div>
            </div>
          </div>
          <div className="text-center mt-3">
            <Link to="/signIn" className="text-decoration-none">Sign In</Link>
          </div>
          <div className="text-center mt-2">
            <Link to="/forgotten" className="text-decoration-none">Forgot Password</Link>
          </div>
          <div className="text-center mt-2">
            <Link to="/logInForAdmin" className="text-decoration-none">Admin</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
