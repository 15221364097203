import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from 'react-bootstrap';
import image1 from "../images/image25.jpg";
import image2 from "../images/image24.jpg";
import image3 from "../images/image23.jpg";
import image4 from "../images/image26.jpg";
import { Link } from 'react-router-dom';

export default function Choose() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="bg-white py-5">
      <div className="container">
        <div className="text-center">
          <h2 className="text-4xl display-5 fw-bold text-dark">Why Choose EduYog?</h2>
          <p className="mt-4 text-xl text-gray-800">Get Counseling By The Best Mentors From Top Colleges Across The India</p>
        </div>
        <div className="mt-5">
          <Slider {...settings} className="slick-slider">
            <div className="text-center">
              <img
                alt="University 1"
                className="p-3"
                src={image1}
                style={{
                  maxWidth: '100%',
                  height: '100%',
                  transform: hoveredIndex === 0 ? 'scale(1.2)' : 'none',
                  transition: 'transform 0.3s ease-in-out'
                }}
                onMouseEnter={() => setHoveredIndex(0)}
                onMouseLeave={() => setHoveredIndex(null)}
              />
            </div>
            <div className="text-center">
              <img
                alt="University 2"
                className="p-3"
                src={image2}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  transform: hoveredIndex === 1 ? 'scale(1.2)' : 'none',
                  transition: 'transform 0.3s ease-in-out'
                }}
                onMouseEnter={() => setHoveredIndex(1)}
                onMouseLeave={() => setHoveredIndex(null)}
              />
            </div>
            <div className="text-center">
              <img
                alt="University 3"
                className="p-3"
                src={image3}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  transform: hoveredIndex === 2 ? 'scale(1.2)' : 'none',
                  transition: 'transform 0.3s ease-in-out'
                }}
                onMouseEnter={() => setHoveredIndex(2)}
                onMouseLeave={() => setHoveredIndex(null)}
              />
            </div>
            <div className="text-center">
              <img
                alt="University 4"
                className="p-3"
                src={image4}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  transform: hoveredIndex === 3 ? 'scale(1.2)' : 'none',
                  transition: 'transform 0.3s ease-in-out'
                }}
                onMouseEnter={() => setHoveredIndex(3)}
                onMouseLeave={() => setHoveredIndex(null)}
              />
            </div>
            {/* Add more images here */}
          </Slider>
        </div>
        <div className="mt-5 text-center">
          <Link to={'/join'}>
            <Button variant="primary" className="px-4 py-2 rounded">Join now</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
