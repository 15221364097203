import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import Spinner from 'react-bootstrap/Spinner'; // Assuming you have a spinner component for loading

function SeminarPage() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { category } = useParams();

  const fetchImages = async () => {
    try {
      const response = await axios.get(`https://eduyog.in/api/add/post`);
      setImages(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching images:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, [category]);

  const memoizedImages = useMemo(() => images, [images]);

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4 display-4 fw-normal text-black">Recent Seminar</h2>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : memoizedImages.length > 0 ? (
        <div className="carousel-container">
          <Carousel fade>
            {memoizedImages.map((image, index) => (
              <Carousel.Item key={index}>
                <img src={`https://eduyog.in/${image.imageUrl}`} alt={`Slide ${index}`} className="d-block  seminar-image " />
                <Carousel.Caption>
                  {/* <h3>Image {index + 1}</h3> */}
                  <p>{image.description}</p> {/* Assuming there's a description field in the image data */}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      ) : (
        <p className="text-center">No images available</p>
      )}
    </div>
  );
}

export default SeminarPage;
