
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import './Page1.css';
import movie3 from '../../video/movie3.mp4'
import movie2 from '../../video/movie2.mp4'
import movie1 from '../../video/movie1.mp4'

const taskDetails = [
    {
      title: 'Introduction',
      content: (
        <div>
          <p className="task-mvp">Introduction</p>
         
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                    <p>
                      <strong>Your future is in your hands!!</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>Getting a good package is not dependent on your degree nor your college.</p>
                    <p>It is all dependent on your skillset and your willingness to learn.</p>
                    <p>&nbsp;</p>
                    <p>EduYog started with one simple vision - make high-quality education available to everyone who is willing to learn.</p>
                    <p>&nbsp;</p>
                    <p>
                      <a href={movie3} target='_blank'>Click here</a>
                       
                      <strong> to hear from our Founder and get started. All the best!!!</strong>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>Pro Tip:</strong> Subscribe to the channel and stay updated on interview tips, inspirational content, and motivational success stories!
                    </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Course Structure',
      content: (
        <div>
          <p className="task-mvp">Course Structure</p>
          
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                   
                    <p>EduYog has a lean and focussed course plan to ensure you get a job as soon as possible -</p>
                    <p>&nbsp;</p>
                    <p>
                      <a href={movie2} target='_blank'>
                        Click here
                      </a>
                     
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>Pro Tip:</strong> Subscribe to the channel and stay updated on interview tips, inspirational content, and motivational success stories!
                    </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Placements',
      content: (
        <div>
          <p className="task-mvp">Placements</p>
          
                    <div className="des-8v9">
                      <p className="title-chv">
                        Task details
                      </p>
                      <div className="ql-6os ql-5xn gkajs">
                       
                        <p>Wondering when will companies starting hunting you? Thinking about knowing when you would be ready?</p>
                        <p>&nbsp;</p>
                        <p>
                        Let us answer your questions.
                        <br/>
                        <a href={movie2} target='_blank'>
                        Click here &nbsp;
                      </a>
                          to know all the details.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          <strong>Pro Tip:</strong> Subscribe to the channel and stay updated on interview tips, inspirational content, and motivational success stories!
                        </p>
                      </div>
                    </div>
        </div>
      ),
    },
    {
      title: 'Qualification Period',
      content: (
        <div>
          <p className="task-mvp">Qualification Period</p>
          
                    <div className="des-8v9">
                      <p className="title-chv">
                        Task details
                      </p>
                      <div className="ql-6os ql-5xn gkajs">
                       
                        <p>EduYog does have a "Qualification Period" - But do why is this important? Thinking what is this qualification all about?</p>
                        <p>&nbsp;</p>
                        <p>
                        Let us answer your questions.
                        <br/>
                        <a href={movie2} target='_blank'>
                        Click here &nbsp;
                      </a>
                          to know all the details.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          <strong>Pro Tip:</strong> Subscribe to the channel and stay updated on interview tips, inspirational content, and motivational success stories!
                        </p>
                      </div>
                    </div>
        </div>
      ),
    },
    {
      title: 'Unique Flipped Classrooms',
      content: (
        <div>
          <p className="task-mvp">Unique Flipped Classrooms</p>
          
                    <div className="des-8v9">
                      <p className="title-chv">
                        Task details
                      </p>
                      <div className="ql-6os ql-5xn gkajs">
                       
                        <p>EduYog has an exclusive Flipped Classroom structure.<strong>Proud of it!</strong> It allows students to learn and progress only when they completely understand the concepts - <strong>No Batches! No Backbenchers</strong></p>
                        <p>&nbsp;</p>
                        <p>Confusing?</p>
                        <p>&nbsp;</p>
                        <p>
                        
                        <a href={movie1} target='_blank'>
                        Check out &nbsp;
                      </a>
                          this video and know all about the structure and how to use the EduYog student platform.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                          <strong>Pro Tip:</strong> Subscribe to the channel and stay updated on interview tips, inspirational content, and motivational success stories!
                        </p>
                      </div>
                    </div>
        </div>
      ),
    },
  ];

const Page1 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 5) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/1'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 5</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Page1;
