import React from 'react';
import image from "../images/image20.png";

export default function LastPage() {
  return (
    <div className="bg-white p-5 m-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <img
              alt="Students celebrating"
              className="img-fluid rounded shadow"
              src={image}
              style={{
                aspectRatio: "1024/656",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="col-lg-6">
            <h1 className="display-4 text-dark mb-4 fw-normal">Unlock Your College Journey with EduYog</h1>
            <p className="lead text-secondary">
              Professional networking has arrived in the college application process. Through one-on-one counseling
              sessions with mentors, EduYog allows students to develop unique strategies for standing out
              in applications, better "Why School" essays, and cohesive application narratives that tie their expected
              major to their existing interests and goals. EduYog ensures our platform remains affordable with a robust
              financial aid program. Find out how EduYog can guide you on the path to success in college admissions
              today!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
