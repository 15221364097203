import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Test Question - 1',
      content: (
        <div>
          <p className="task-mvp">Test Question - 1</p>
          
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>
                    Write a program to sort the array based on how close it is to number 5.
<br/>
<br/>

If the difference is the same then the element whose value is lesser will be before. 
<br/>
Note both the array and size is already given.

<br/><br/>



Example 1: 
<br/>
<br/>

Input:-
<br/>
[1,3,5,7,9]

<br/><br/>

Output:-
<br/>
[5,3,7,1,9]
<br/><br/>


Explanation:- 
<br/>
Element 5 is the closest , element 3 and element 7 is 2 values away from number 5 but since 3 &lt; 7. 3 will occur before 7.
<br/>
element 1 and element 9 is 4 values away from number 5 but since 1 &lt; 9. 1 will occur before 9.

<br/><br/>



Input:-
<br/>
[7, 8 ,10 ,1, 4, 9]
<br/><br/>


Output:-
<br/>
[4,7,8,1,9,10]
<br/><br/>


Explanation:- 
<br/>
4 the difference is 1,
<br/>
7 the difference is 2,
<br/>
8 the difference is 3,
<br/>
element 1 and element 9 is 4 values away from number 5 but since 1 &lt; 9. 1 will occur before 9.
<br/>
10 the difference is 5.
<br/><br/>




Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= nums.length &lt;= 1 * 10^4<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-5 * 10^4 &lt;= nums[i] &lt;= 5 * 10^4<br/>
<br/>
                    </p>
                    <br/>
                    <br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Test Question - 2',
        content: (
          <div>
            <p className="task-mvp">Test Question - 2</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>

                       A peak element is an element that is strictly greater than its neighbors.
<br/>
Given a 0-indexed integer array nums, find a peak element, and return its index. If the array contains multiple peaks, return the index to any of the peaks.
<br/>
You may imagine that nums[-1] = nums[n] = -∞. In other words, an element is always considered to be strictly greater than a neighbor that is outside the array.
<br/>
You must write an algorithm that runs in O(log n) time.

<br/><br/>

Example 1:
<br/>
Input: nums = [1,2,3,1]
<br/>
Output: 2
<br/>
Explanation: 3 is a peak element and your function should return the index number 2.
<br/>
Example 2:
<br/>
Input: nums = [1,2,1,3,5,6,4]
<br/>
Output: 5
<br/>
Explanation: Your function can return either index number 1 where the peak element is 2, or index number 5 where the peak element is 6.

<br/><br/>

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= nums.length &lt;= 1000<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-2^31 &lt;= nums[i] &lt;= 2^31 - 1<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;nums[i] != nums[i + 1] for all valid i.<br/>



<br/><br/>


Watch the video from hints if you are not able to devise an log(n) solution<br/><br/>
                       </p>
                    
                      <br/>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 3',
        content: (
          <div>
            <p className="task-mvp">Test Question - 3</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      You're given strings jewels representing the types of stones that are jewels, and stones representing the stones you have. Each character in stones is a type of stone you have. You want to know how many of the stones you have are also jewels.
<br/>
Letters are case sensitive, so "a" is considered a different type of stone from "A".
<br/><br/>
 

Example 1:
<br/>
Input: jewels = "aA", stones = "aAAbbbb"
<br/>
Output: 3
<br/>
Example 2:
<br/>
Input: jewels = "z", stones = "ZZ"
<br/>
Output: 0
<br/><br/>
 

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= jewels.length, stones.length &lt;= 50<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;jewels and stones consist of only English letters.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All the characters of jewels are unique.<br/>

<br/><br/>
Try to solve this Question in 0(n) time complexity. n is length of the maximum word Jewels and stone.

<br/>

and paste your code after solving all the test cases.

<br/><br/>



Watch the Hints if you are not able to figure out the Logic<br/>
                        (Solve the following question on the Leetcode, make sure all test case passes and then click submit.)<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 4',
        content: (
          <div>
            <p className="task-mvp">Test Question - 4</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                      <br/>
                      <p>
                      A sentence is a list of words that are separated by a single space with no leading or trailing spaces.
                      <br/>
You are given an array of strings sentences, where each sentences[i] represents a single sentence.
<br/>
Return the maximum number of words that appear in a single sentence.
<br/>   <br/>
 

Example 1:
<br/>
Input: sentences = ["alice and bob love leetcode", "i think so too", "this is great thanks very much"]
<br/>
Output: 6
<br/>
Explanation:
<br/>
- The first sentence, "alice and bob love leetcode", has 5 words in total.
<br/>
- The second sentence, "i think so too", has 4 words in total.
<br/>
- The third sentence, "this is great thanks very much", has 6 words in total.
<br/>
Thus, the maximum number of words in a single sentence comes from the third sentence, which has 6 words.
<br/>

<br/>
Example 2:
<br/>
Input: sentences = ["please wait", "continue to fight", "continue to win"]
<br/>
Output: 3
<br/>
Explanation: It is possible that multiple sentences contain the same number of words.
<br/>
In this example, the second and third sentences (underlined) have the same number of words.
<br/>
 
<br/>
Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= sentences.length &lt;= 100   <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= sentences[i].length &lt;= 100   <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sentences[i] consists only of lowercase English letters and ' ' only.   <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sentences[i] does not have leading or trailing spaces.   <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All the words in sentences[i] are separated by a single space.   <br/>

<br/>


Watch the hint video if stuck.
<br/>
Contact your mentor if having any issues/ Confusion on how to work with Strings operations.
                        <br/>
                        (Solve the following question on the Leetcode, make sure all test case passes and then click submit.)   <br/>   <br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      
  ];

const Mock22 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 4) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/9'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 4</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Mock22;
