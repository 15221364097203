import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CNavbar from "../../component/CNavbar";

const SubmitRecruiter = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [homeworkContent, setHomeworkContent] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("https://eduyog.in/recuriter/submitrecuriter", {
        email,
        name,
        content: homeworkContent,
      });

      if (res.data.success) {
        toast.success(res.data.message);
        // Refresh the page after successful submission
        window.location.reload();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <CNavbar />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="card shadow-sm border-1">
              <div className="card-body p-4">
                <h3 className="card-title text-center mb-4 display-5 fw-normal">Recruiter Information</h3>
                <form onSubmit={handleSubmit}>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="companyName"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      placeholder="Enter your company name"
                    />
                    <label htmlFor="companyName">Company Name</label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="recruiterEmail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Enter your email"
                    />
                    <label htmlFor="recruiterEmail">Recruiter Email</label>
                  </div>

                  <div className="form-floating mb-4">
                    <textarea
                      className="form-control"
                      id="jobDescription"
                      value={homeworkContent}
                      onChange={(e) => setHomeworkContent(e.target.value)}
                      required
                      placeholder="Describe the job position"
                      style={{ height: '150px' }}
                    ></textarea>
                    <label htmlFor="jobDescription">Job Description</label>
                  </div>

                  <button type="submit" className="btn btn-primary btn-lg w-100">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="card shadow-sm border-1">
              <div className="card-body p-4">
                <h5 className="card-title">Additional Information</h5>
                <p className="card-text">
                  Provide details about your company and the job you are looking to fill in the "Job Description" section.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="alert alert-info shadow-sm border-0">
              <p className="mb-0 text-center">
                Need help or have questions? Contact our support team at{" "}
                <a href="mailto:eduyogtechno@gmail.com" className="alert-link">eduyogtechno@gmail.com</a>.
              </p>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default SubmitRecruiter;
