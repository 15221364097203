import React, { useEffect, useState } from "react";

import "./../Placement.css";
import useFetch from "../../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../Axios";
import { toast } from "react-toastify";

import { useAuth } from "../../../../context/AuthContext";

import { YourComponent } from "./YourComponent";
import WelcomeSection from "./WelcomeSection";
const Current = () => {
  const navigate = useNavigate();
  const [topicdata, setTopic] = useState({});
  const [last, setlast] = useState(0);
  const [auth] = useAuth();

  const { data } = useFetch(`/solved/last/${auth?.user?.id}`);
  useEffect(() => {
    setlast(data[0]?.topic_id || 0);
  }, [data]);
  useEffect(() => {
    getTopic();
  });
  const getTopic = async () => {
    try {
      const res = await Axios.get(`/topic/single/topic/${last + 1}`);
      // console.log(res);
      if (res.data && res.data.success) {
        setTopic(res.data.message[0]);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("something went wrong...");
    }
  };
  const Navigate = (last, topic, id) => {
    navigate("/placement/dashboard/assignment", {
      state: { last, topic, id },
    });
  };
  return (
    <div classNameName="current-assesment bg-white row row-cols-2 shadow p-3 rounded">
      <div classNameName="text-start  d-flex flex-column">
        <h6 classNameName="text-warning ">Current Assignment</h6>
        <h4 classNameName="fw-bold text-capitalize mt-auto">{topicdata?.topic}</h4>
      </div>
      <div classNameName="text-end">
        {/* {last <= 1 ? "Not Started" : "Continue"} */}
        <h4 classNameName="text-danger">
          <span>{last >= 1 ? "Not Start" : "Continue"}</span>
        </h4>
        {/* <StartContinue /> */}
        <button
          onClick={() => Navigate(last, topicdata?.topic, topicdata?.id)}
          classNameName="btn btn-primary px-4 fs-5"
        >
          Start
        </button>
      </div>
    </div>
  );
};
export default Current;



export const TopicRoadMap = () => {
  const [last, setlast] = useState(0);
  const [auth] = useAuth();
  const { data } = useFetch(`/solved/last/${auth?.user?.id}`);
  useEffect(() => {
    setlast(data[0]?.topic_id || 0);
  }, [data]);
  return (
    <>
      <div className="topic-roadmap bg-white shadow p-3 rounded">
        <h6 className="text-warning">Track Journey</h6>
        <TopicComp subject="Full Stack Preperation" last={last} />
        <WelcomeSection/>
        <YourComponent/>
</div>
    </>
  );
};

const TopicComp = ({ subject, last }) => {
  const navigate = useNavigate();
  const { data } = useFetch(`/topic/${subject}`);
  const [list, setlist] = useState([]);
  const [auth] = useAuth();
  useEffect(() => {
    setlist(data);
  }, [data]);
  const handleQuestion = (id) => {
    navigate("/admin/question", { state: id });
  };
  const handleAssingment = (last, topic, id) => {
    if (last + 1 >= id) {
      window.confirm("you are allowed");
      navigate("/placement/dashboard/assignment", {
        state: { last, topic, id },
      });
    } else {
      window.confirm("your not allowed");
    }
  };
  return (
    <>
      <div>
        <h5 classNameName="text-capitalize">{subject}</h5>
        
        {
    }
       </div>
    </>
  );
};
