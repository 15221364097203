import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Videos - While loop',
      content: (
        <div>
          <p className="task-mvp">Videos - While loop</p>
          
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Important watch this video to understand what are while Loops :-
                      <a href="https://youtu.be/A8oirIz1qPs?si=ki5PejCHzQDqphFw" target='_blank'>
                        &nbsp;Click here
                      </a>
                    </p>
                    <br/>
                      
                    <p>Go through the following set of materials to understand the basic fundamentals of while loop in your own preferred language:-</p>
                    <br/>
                    <ol>C++
                      <li>&nbsp;&nbsp;&nbsp;While Loop :-<a href="https://youtu.be/VA8t4ACItsg?si=E5iDh4zvHNtIiua7" target='_blank'>&nbsp;Click here</a></li>
                     
                      <li>&nbsp;&nbsp;&nbsp;Do While:-<a href="https://youtu.be/Ue-oMrZHBpE?si=YZn8mx_vqqBlPQgj"target='_blank'>&nbsp;Click here</a></li>
                    </ol>
                    <ol>Java
                      <li>&nbsp;&nbsp;&nbsp;While Loop :-<a href="https://youtu.be/g8GcFboF2rM?si=rpewiC4vs_j8qAWD" target='_blank'>&nbsp;Click here</a></li>
                      <li>&nbsp;&nbsp;&nbsp;Do While:-<a href="https://youtu.be/PL3ASTPQrMQ?si=NDTZ6nY1NR78lWea" target='_blank'>&nbsp;Click here</a></li>
                    </ol>
                    <ol>Python
                      <li>&nbsp;&nbsp;&nbsp;While Loop :-<a href="https://youtu.be/z59-VOEonMM?si=DokxhheJeCWwfy6w" target='_blank'>&nbsp;Click here</a></li>
                    </ol>
                    <ol>Javascript
                      <li>&nbsp;&nbsp;&nbsp;While Loop :-<a href="https://youtu.be/N1AkUCoGRSA?si=HgWbBDGnKr2C90o9" target='_blank'>&nbsp;Click here</a></li>
                      <li>&nbsp;&nbsp;&nbsp;Do While:-<a href="https://youtu.be/id0gwYgDT68?si=_6HBnLaHyJRLPAZb" target='_blank'>&nbsp;Click here</a></li>
                    </ol>
                    <p>After watching the above video Click on the Button Done and start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.</p>
                    <br/>
                    <br/>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Even Numbers by using While loop',
      content: (
        <div>
          <p className="task-mvp">Even Numbers by using While loop</p>
          
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                   
                    <p>Write a program using only while loops to print all the even numbers from 1 to n</p>
                    <p>Input : if n=10<br/>output<br/>2<br/>4<br/>6<br/>8<br/>10<br/></p><br/>
                    <p>If you get stuck then ask your doubt's to mentor directly on their whatsapp.</p><br/>
                    <p>(Solve the following question in the compiler, make sure all test case passes and then click submit.)</p>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Series using While Loop',
      content: (
        <div>
          <p className="task-mvp">Series using While Loop</p>
          
                    <div className="des-8v9">
                      <p className="title-chv">
                        Task details
                      </p>
                      <div className="ql-6os ql-5xn gkajs">
                        <p>Print the following series using while loop<br/>1 4 9 16 25 36 …. n</p>
                        <p>Input : n=49<br/>output<br/>1<br/>4<br/>9<br/>16<br/>25<br/>36<br/>49</p><br/>
                         <p>If you get stuck then ask your doubt's to mentor directly on their whatsapp.</p><br/>
                         <p>Solve the following question in the compiler, make sure all test case passes and then click submit.</p>
                      </div>
                    </div>
        </div>
      ),
    },
    {
      title: 'Series except 5 multiples',
      content: (
        <div>
          <p className="task-mvp">Series except 5 multiples</p>
          
                    <div className="des-8v9">
                      <p className="title-chv">
                        Task details
                      </p>
                      <div className="ql-6os ql-5xn gkajs">
                        <p>Watch this video to understand what are Continue and Break Statement:-<a href='https://youtu.be/RnYxuR6E3vA?si=5LqHxxu4H1I-46kb' alt='uploading' target='_blank'>&nbsp;Click Here</a> </p>
                        <br/>
                        <p>By using while loop and continue statement Print all number from 1 to n but then it should not be divisible by 5</p><br/>
                        <p> Example:-<br/>Input : n=7<br/>output<br/>1<br/>2<br/>3<br/>4<br/>6<br/>7</p><br/>
                         <p>(Solve the following question in the compiler, make sure all test case passes and then click submit.)</p>
                      </div>
                    </div>
        </div>
      ),
    },
    {
      title: 'Print the digits of a Number',
      content: (
        <div>
          <p className="task-mvp">Print the digits of a Number</p>
          
                    <div className="des-8v9">
                      <p className="title-chv">
                        Task details
                      </p>
                      <div className="ql-6os ql-5xn gkajs">
                       
                        <p>Write a program to print the digits of a number .</p>
                        <br/>
                        <p>Input:-<br/>n=153<br/>Output:<br/>3<br/>5<br/>1</p><br/>
                        <p>Important Understand how to do integer Division :-<a href="https://youtu.be/IJMj4hvQ9LM?si=ZEtVR3IaTUUDmce5" target='_blank'>&nbsp;Click here</a></p>
                        
                        <br/>
                        <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                      </div>
                    </div>
        </div>
      ),
    },
    {
        title: 'Armstrong number',
        content: (
          <div>
            <p className="task-mvp">Armstrong number</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                         
                          <p>Write a program to print whether a given number is an Armstrong number or not..<br/>(Armstrong number is a number in which all the sum of cubes of digits is equal to the number</p>
                          <br/>
                          <p>Example:-<br/>Input:-<br/>n=153<br/>Output:<br/>true</p>
                          <br/>
                          <p>Logic - 153 is an Armstrong number because 1^3 + 5^3 + 3^3 = 1 +125 + 27 = 153 which is equal to the original number )</p>
                          <br/>
                          <p>Example:-<br/>Input:-<br/>n=123<br/>Output:<br/>false</p>
                          <br/>
                          <p>Logic - 123 is not an Armstrong number because 1^3 + 2^3 + 3^3 = 1 + 8 + 27 = 36 which is not equal to the original number )</p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Reverse the number',
        content: (
          <div>
            <p className="task-mvp">Reverse the number</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                         
                          <p>Write a program to return the reverse of a number<br/>Input<br/>n=123<br/>output<br/>321</p>
                          <br/>
                          <p>Important Watch this video to understand when and how to return :-<a href='https://youtu.be/HuKYb1yN7Ik?si=cqShKlW8bRZOyc2Z' alt='uploading' target='_blank'>Click here</a></p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Videos - For Loops',
        content: (
          <div>
            <p className="task-mvp">Videos - For Loops</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                         
                        <p>Important watch this video to understand what are for Loops :-<a href="https://youtu.be/E68uezDkQxc?si=0pyCLg0jpvlMpS0m" target='_blank'>
                            &nbsp;Click here
                          </a></p>
                          <br/>
                           <p>C++ :- <br/>&nbsp;&nbsp;&nbsp;1. For Loops:-<a href="https://youtu.be/Y0TYYEhHvIU?si=dsH3_M0OMPdq9mv2" target='_blank'>Click here</a></p>
                           <p>Java :- <br/>&nbsp;&nbsp;&nbsp;1. For Loops:-<a href="https://youtu.be/GwcisLY5avc?si=LNv7WvaSxk1-Iyff" target='_blank'>Click here</a></p>
                           <p>Python :- <br/>&nbsp;&nbsp;&nbsp;1. For Loops:-<a href="https://youtu.be/9LgyKiq_hU0?si=Hu25xSfrBJT4rCZN" target='_blank'>Click here</a></p>
                           <p>&nbsp;&nbsp;&nbsp;2. Important watch this video to understand how for loop functions in python :-<a href="https://youtu.be/QE6RJWyFB1M?si=GEJY7cLRJvXusSaT" target='_blank'>Click here</a></p>
                           <p>Javascript :- <br/>&nbsp;&nbsp;&nbsp;1. For Loops:-<a href="https://youtu.be/24Wpg6njlYI?si=ygsG67Ut0FOBaqgX" target='_blank'>Click here</a></p>
                           <br/>
                           <p>After watching the above video then start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Odd Number Series',
        content: (
          <div>
            <p className="task-mvp">Odd Number Series</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        <p>Write a program to print all odd numbers from 1 to n using for loops</p>
                        <p>Input : n=7<br/>output<br/>1<br/>3<br/>5<br/>7</p><br/>
                         <p>Solve the following question in the compiler, make sure all test case passes and then click submit.</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Series using for Loop',
        content: (
          <div>
            <p className="task-mvp">Series using for Loop</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                          <p>Print the following series using for loop:-<br/>1,8,27,64,125,216,......n</p>
                          <br/>
                          <p>Input:-<br/>125<br/>Output:<br/>1<br/>8<br/>27<br/>64<br/>125</p>
                          <br/>
                          <p>If you have any doubts or confusion refer to the hints to view the video solution.</p>
                   
                          {/* <p>Watch the following video to understand how for loop function in Python :-<a href="">
                            &nbsp;Click here
                          </a></p> */}
                          <br/>
                          <p>Watch the Hint video if not being able to figure out the series.</p><br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Using Continue in For Loops',
        content: (
          <div>
            <p className="task-mvp">Using Continue in For Loops</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                         
                          <p>Understand Continue from this video :- <a href="https://www.scholarhat.com/tutorial/c/jump-statements-in-c" target='_blank'>
                            &nbsp;Click here</a> and solve the question using continue jumping statement.</p>
                          <br/>
                          <p>Write a program to print even numbers from 1 to n except numbers which are divisible by 4. <br/>Use Continue statement to avoid printing.</p>
                          <br/>
                          <p>Input:-<br/>a=10<br/>Output:<br/>2<br/>6<br/>10</p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Using Break in for loop',
        content: (
          <div>
            <p className="task-mvp">Using Break in for loop</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        <p>Understand the use case of break by going through this video -<a href="https://youtu.be/RnYxuR6E3vA?si=frFIGmvWIPZR0EQY" target='_blank'>
                            &nbsp;Click here
                          </a></p>
                          <br/>
                        <p>and solve the following question :-</p>
                        <p>Write a program to print all the numbers from 1 to n .If m is present in between the sequence then stop printing any other number and break out of the loop.</p>
                        <br/>
                        
                          <p>Input:-<br/>n=10 , m=4<br/>Output:<br/>1<br/>2<br/>3</p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Videos - Patterns',
        content: (
          <div>
            <p className="task-mvp">Videos - Patterns</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        
                        <p>Watch the following videos on patterns to understand how nested for loops and nested while loops are used :-</p>
                        <br/>
                        
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[Important] Watch the Video explaining the basic of patterns and trick to solve any complex pattern :-<a href="https://youtu.be/hWhMAoBhi7M?si=qOZiIPjpJ78l7Fgy" target='_blank'>
                            &nbsp;Click here
                          </a> </p>
                      
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Python people also watch this video <a href="https://youtu.be/k8SXsT5TLxQ?si=pye1u4qXQeS7UB0h" target='_blank'>
                            &nbsp;Click here
                          </a></p>
                          <br/>
                          <p>After watching the above video Click on the Button Done and start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Pattern For Loop -1 ',
        content: (
          <div>
            <p className="task-mvp">Pattern For Loop -1</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        <p>Print the following pattern using for loops</p>
                        
                        <p>*****<br/>****<br/>***<br/>**<br/>*</p>
                        
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Pattern While Loop - 2',
        content: (
          <div>
            <p className="task-mvp">Pattern While Loop - 2</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        <p>[Important] Watch this video to understand how to solve pattern using while loops :-<a href="">
                            &nbsp;Click here
                          </a> </p>
                        <p>Print the following pattern using while loops</p>
                        
                        <p>*****<br/>****<br/>***<br/>**<br/>*</p>
                        
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Pattern For Loop -3',
        content: (
          <div>
            <p className="task-mvp">Pattern For Loop -3</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        <p>Print the following pattern using for loops</p>
                        
                        <p>*****<br/>****<br/>***<br/>**<br/>*<br/>*<br/>**<br/>***<br/>****<br/>*****</p>
                        
                          <br/>
                          
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Pattern For Loop -4',
        content: (
          <div>
            <p className="task-mvp">Pattern For Loop -4</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        <p>Print the following pattern using for loops</p>
                        
                        <img src='https://static.javatpoint.com/cpages/images/star-program-in-c_20.png' alt='uploading'/>
                        
                          <br/>
                          
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Pattern While Loop -5',
        content: (
          <div>
            <p className="task-mvp">Pattern While Loop -5</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        <p>Print the following pattern using while loops</p>
                        
                        <p>*****<br/>****<br/>***<br/>**<br/>*<br/>*<br/>**<br/>***<br/>****<br/>*****</p>
                        
                          <br/>
                          
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Diamond Star Pattern',
        content: (
          <div>
            <p className="task-mvp">Diamond Star Pattern</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        <p>Print the following pattern using any loops</p>
                        
                        <img src='https://static.javatpoint.com/cpages/images/star-program-in-c_24.png' alt='uploading'/>
                        
                          <br/>
                          
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
  ];

const LpageL = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 19) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/3'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 19</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default LpageL;
