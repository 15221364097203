// Assignment.js

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SubmitHomework from '../Auth/SubmitHomework';
const Assignment = () => {
  

  return (
    <div >
       
   <div className='home11 container row'>   <SubmitHomework/></div>

    </div>
  );
};

export default Assignment;
