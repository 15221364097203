import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const CustomVideoPlayer = ({ videoUrl, onClose }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1); // Volume range from 0.0 to 1.0
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeChange = (event) => {
    const time = event.target.value;
    videoRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleLoadedMetadata = () => {
    setDuration(videoRef.current.duration);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleVolumeChange = (event) => {
    const volume = event.target.value;
    videoRef.current.volume = volume;
    setVolume(volume);
  };

  const handleFullScreenToggle = () => {
    if (!document.fullscreenElement) {
      videoRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleFullScreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  return (
    <div className={`custom-video-player ${isFullScreen ? 'fullscreen' : ''}`}>
      <style>
        {`
          .custom-video-player {
            position: relative;
          }

          .video-controls {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-top: 10px;
          }

          .video-controls input[type="range"] {
            width: 100px;
          }

          .video-controls button {
            background-color: #4A90E2;
            color: white;
            border: none;
            padding: 5px 10px;
            cursor: pointer;
          }

          .video-controls button:hover {
            background-color: #357ABD;
          }

          .custom-video-player.fullscreen {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: black;
            z-index: 1000;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .video-controls.hidden {
            display: none;
          }

          .video-controls.fullscreen {
            position: absolute;
            bottom: 10px;
            right: 10px;
            flex-direction: column;
            align-items: flex-end;
          }

          .video-controls.fullscreen button {
            background-color: #E94E77;
          }

          .video-controls.fullscreen button:hover {
            background-color: #D43F65;
          }
        `}
      </style>
      <video
        ref={videoRef}
        src={videoUrl}
        controls={false}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
        style={{ width: '100%', height: '100%' }}
      >
        Your browser does not support the video tag.
      </video>
      <div className={`video-controls ${isFullScreen ? 'fullscreen' : ''}`}>
        {!isFullScreen && (
          <>
            <button onClick={handlePlayPause}>
              {isPlaying ? 'Pause' : 'Play'}
            </button>
            <input
              type="range"
              min="0"
              max={duration}
              value={currentTime}
              onChange={handleTimeChange}
            />
            <span>{Math.floor(currentTime)} / {Math.floor(duration)}</span>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
            <span>{Math.round(volume * 100)}%</span>
            <button onClick={handleFullScreenToggle}>
              Full Screen
            </button>
          </>
        )}
        {isFullScreen && (
          <>
            <button onClick={handleFullScreenToggle}>
              Exit Full Screen
            </button>
            <button onClick={onClose}>
              Close
            </button>
          </>
        )}
      </div>
    </div>
  );
};

CustomVideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CustomVideoPlayer;
