import React, { useEffect, useState } from "react";
import Axios from "../../../Axios";
import CodeNav from "./CodeNav";
import { Editor } from "@monaco-editor/react";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/AuthContext";

const EditorCompile = ({ question, qid, topicId }) => {
  const [userLang, setUserLang] = useState("python");
  const [defaultV, setDefault] = useState("");
  const [userCode, setUserCode] = useState(`${defaultV}`);

  const [userTheme, setUserTheme] = useState("vs-dark");

  const [fontSize, setFontSize] = useState(20);

  const [userInput, setUserInput] = useState("");

  const [userOutput, setUserOutput] = useState("");

  const [loading, setLoading] = useState(false);
  const [reward, setreward] = useState(0);
  const [auth] = useAuth();

  const options = {
    fontSize: fontSize,
  };
  useEffect(() => {
    if (userLang === "java") {
      setDefault("// java template");
    }
  }, [userLang]);

  function compile() {
    setLoading(true);
    if (userCode === ``) {
      return;
    }

    // Post request to compile endpoint
    Axios.post(`http://localhost:8000/compile`, {
      code: userCode,
      language: userLang,
      input: userInput,
    })
      .then((res) => {
        setUserOutput(res.data.output);
      })
      .then(() => {
        setLoading(false);
      });
  }
  // console.log(userLang);

  // Function to clear the output screen
  function clearOutput() {
    setUserOutput("");
  }
  const handleMark = async () => {
    try {
      const res = await Axios.post("/solved/question/option", {
        status: true,
        reward: reward,
        user_id: auth?.user?.id,
        question_id: qid,
        topic_id: topicId,
      });
      console.log(res);
      if (res.data && res.data.success) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("server error try again...");
    }
  };
  return (
    <div className="App">
      <CodeNav
        userLang={userLang}
        setUserLang={setUserLang}
        userTheme={userTheme}
        setUserTheme={setUserTheme}
        fontSize={fontSize}
        setFontSize={setFontSize}
      />
      <div className="main">
        <div className="left-container">
          <Editor
            options={options}
            height="calc(60vh - 50px)"
            width="100%"
            theme={userTheme}
            language={userLang}
            defaultLanguage={userLang}
            defaultValue={defaultV}
            onChange={(value) => {
              setUserCode(value);
            }}
          />
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <button
              className="btn btn-primary m-auto "
              onClick={() => compile()}
            >
              Run
            </button>
          </div>
          <div className="input-box">
            <h4>Input:</h4>
            <textarea
              id="code-inp"
              onChange={(e) => setUserInput(e.target.value)}
            ></textarea>
          </div>
          <div className="p-4">
            <h4>Output:</h4>
            {loading ? (
              <div className="spinner-box">
                {/* <img src={spinner} alt="Loading..." /> */}
              </div>
            ) : (
              <div className="output-box">
                <pre>{userOutput}</pre>
                <button
                  onClick={() => {
                    clearOutput();
                  }}
                  className="clear-btn"
                >
                  Clear
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-auto">
        <button className="btn btn-success fw-bold" onClick={handleMark}>
          Mark As Done
        </button>
      </div>
    </div>
  );
};

export default EditorCompile;
