import React from "react";
import { Link } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';


const ProfileHandle = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();

  const handleLogout = () => {
    setAuth({ ...auth, user: null, token: "" });
    localStorage.removeItem("auth");
    navigate("/");
    toast.success("You have successfully logged out");
  };

  return (
    <>
      <ToastContainer />
      <BiLogOut
        className="h4 text-light"
        onClick={handleLogout}
        style={{ cursor: "pointer" }}
      />
    </>
  );
};

export const YogaNavbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div className="container">
        <Link to="/placement/dashboardForYoga" className="navbar-brand text-decoration-none">
          <h3 className="m-0 d-inline-block">
            <span style={{ color: "#ffffff", fontFamily: "Arial, sans-serif", fontWeight: "bold", fontSize: "28px", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>Edu</span>
            <span style={{ color: "#ffcc00", fontFamily: "Arial, sans-serif", fontWeight: "bold", fontSize: "28px", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>Yog</span>
          </h3>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <ProfileHandle />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
