import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Know Your Mentor',
      content: (
        <div>
          <p className="task-mvp">Know Your Mentor</p>
          
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                   
                 
                    <p>Mentor WhatsApp Number of Shubham - 7830013386</p>
                    <br/>
                    <p>
                      <strong>Note -</strong>We have Classes Daily and the Link to Join is Visible in the Top right corner of the Dashboard.
                    </p>
                     <br/>
                    <p>
                      <strong>After watching the above video Click on the Button Done and start with your tasks.</strong> Any Queries or Confusion contact your mentor Directly on Whatsapp.
                    </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Video - Understand Variables',
      content: (
        <div>
          <p className="task-mvp">Video - Understand Variables</p>
          
               
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                   
                    <p>Confused about Which Language to choose watch this :- 
                      <a href="https://www.youtube.com/embed/deztUdPApiM" target='_blank'>
                        &nbsp;Click here
                      </a>
                    </p>
                    <br/>
                    <p>
                        <strong>Important :-</strong> Understand what are Variables:- 
                        <a href="https://www.youtube.com/embed/ghCbURMWBD8" target='_blank'>
                        &nbsp;Click here
                      </a>
                    </p>
                    <br/>
                    <p>
                      Go through the following set of videos to understand about variables and different operations in your
                      <strong> own preferred coding language:-</strong> 
                      <br/>
                      (<strong>Only watch the videos in the language which you have chosen</strong>)
                    </p>
                    <br/>
                    <p>C++ :- <a href="https://www.youtube.com/embed/fZbSl58orNs" target='_blank'>Click here</a></p>
                    <p>Java :- <a href="https://www.youtube.com/embed/so1iUWaLmKA" target='_blank'>Click here</a></p>
                    <p>Python :- <a href="https://www.youtube.com/embed/X6TcB0DNLE8" target='_blank'>Click here</a></p>
                    <p>Javascript :- <a href="https://www.youtube.com/embed/Q4p8vRQX8uY" target='_blank'>Click here</a></p>
                    <br/>
                    <p><strong>After watching the above video Click on the Button Done and start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.</strong></p>
                  </div>
                </div>
        </div>
      ),
    },
    {
      title: 'Swap Two Variables a and b',
      content: (
        <div>
          <p className="task-mvp">Swap Two Variables a and b</p>
         
                    <div className="des-8v9">
                      <p className="title-chv">
                        Task details
                      </p>
                      <div className="ql-6os ql-5xn gkajs">
                       
                        <p>Write a program to Swap two Variables a and b (Swapping basically means interchanging)</p>
                        <br/>
                        <p>
                          Example:-
                           <br/>
                          Input
                          <br/>
                          a=3 , b=4
                           <br/>
                          Output
                             <br/>
                          a=4, b=3
                          </p>
                        <br/>
                        <p>Before trying to code Understand what do we mean by <strong>input as a parameter :-</strong><a href="https://www.youtube.com/embed/_ztGoCKDLHs" target='_blank'>
                            &nbsp;Click here
                          </a></p>
                          <br/>
                       <p>Watch the Hints if you are not able to figure out how to code.
                       
                      If you have any doubts or confusion refer to the hints to view the video solution.
                      
                       (Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                       <br/>
                      </div>
                    </div>
        </div>
      ),
    },
    {
      title: 'Conditional statements',
      content: (
        <div>
          <p className="task-mvp">Conditional statements</p>
          
                    <div className="des-8v9">
                      <p className="title-chv">
                        Task details
                      </p>
                      <div className="ql-6os ql-5xn gkajs">
                       
                        <p>Important watch this video on IF ELSE IF LADDER : <a href="https://www.youtube.com/embed/2STObWfRm5k" target='_blank'>
                        &nbsp;Click here
                          </a></p>
                        <p>
                        Important watch this to understand the use case of AND or OR operators :- <a href="https://www.youtube.com/embed/zk22pzTxR2I" target='_blank'>
                        &nbsp;Click here
                          </a>
                        <br/>
                        </p>
                        <p>
                        Try to understand it with a help of an example:- <a href="https://www.youtube.com/embed/JVL6xEzOCrE" target='_blank'>
                        &nbsp;Click here
                          </a>
                        </p>
                        <p>Important Understand the importance of Indentation or use of Brackets {} - <a href="https://www.youtube.com/embed/YZ46BRmMFdw" target='_blank'>
                        &nbsp;Click here
                          </a></p>
                          <br/>
                        <p>
                        Go through the following set of materials to understand if else and nested if logic :-
                        </p>
                        <br/>
                        <p>C++ :- <a href="https://youtu.be/lZK6b5ee16o?si=Cvmu_8ybddZgZ7Nv" target='_blank'>Click here</a></p><br/>
                    <p>Java :- <a href="https://youtu.be/6sYZDA8JPhk?si=E8WS5QF7XMuBOVND" target='_blank'>Click here</a></p><br/>
                    <p>Python :- <a href="https://youtu.be/9xiFcK3MRYA?si=ME6j5EmDGUDE14e5" target='_blank'>Click here</a></p><br/>
                    <p>Javascript :- <a href="https://youtu.be/IsG4Xd6LlsM?si=GOFZqfrwlBgn96SB" target='_blank'>Click here</a></p><br/>
                    <br/>
                    <p>Play with some examples to become comfortable in the topic :)</p>
                    <br/>
                        <p>After watching the above video Click on the Button Done and start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.</p>
                      </div>
                    </div>
        </div>
      ),
    },
    {
      title: 'Largest among 2 number',
      content: (
        <div>
          <p className="task-mvp">Largest among 2 number</p>
          
                    <div className="des-8v9">
                      <p className="title-chv">
                        Task details
                      </p>
                      <div className="ql-6os ql-5xn gkajs">
                       
                        <p>Write a program to print the largest number of the two numbers given.</p>
                        <br/>
                        <p>Input:-<br/>a=3, b=4<br/>Output:<br/>4</p>
                        <br/>
                        <p>If you have any doubts or confusion then ask your mentor.</p>
                        <br/>
                        <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                      </div>
                    </div>
        </div>
      ),
    },
    {
        title: 'Largest among 3 numbers',
        content: (
          <div>
            <p className="task-mvp">Largest among 3 numbers</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                         
                          <p>Write a program to print the largest number of the three numbers given.</p>
                          <br/>
                          <p>Input:-<br/>a=3, b=5, c=1<br/>Output:<br/>5</p>
                          <br/>
                          <p>If you have any doubts or confusion then ask your mentor.</p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'IF-ELSE-IF ladder',
        content: (
          <div>
            <p className="task-mvp">IF-ELSE-IF ladder</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                         
                          <p>Given a variable D (distance) write a program to print the cost associated with it as shown in the image:-</p>
                          <br/>
                          <img src='https://lh6.googleusercontent.com/7pe3ckB2OwqxNCw0EpMgJIRjvU3Rpv7BPbBY-2DuLXA30gA1XE9lh3ABYeNo9yvQqvolU_QNsEwobuqnSvNPvvT2ohoA-BrvG-hIH2xAXFuFj1GNnph_MDRoLtqlG0xoY9IAN3rA' alt='uploding'/>
                          <p>Input:-<br/>D=700<br/>Output:<br/>10</p>
                          <br/>
                          <p>If you have any doubts or confusion then ask your mentor.</p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Whatsapp Group',
        content: (
          <div>
            <p className="task-mvp">Whatsapp Group</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                         
                          <p>Set of Whatsapp Group created for different Languages. Please introduce yourself when you join and let us know your coding interests. Let's create a supportive and collaborative community together!</p>
                          <br/>
                           <p>C++ :- <a href="">Click here</a></p><br/>
                           <p>Java :- <a href="">Click here</a></p><br/>
                           <p>Python :- <a href="">Click here</a></p><br/>
                           <p>Javascript :- <a href="">Click here</a></p><br/>
                           <br/>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Video - (Ternary Operators , Switch Statements)',
        content: (
          <div>
            <p className="task-mvp">Video - (Ternary Operators , Switch Statements)</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        <p>Important Watch the following video on ternary operators :-<a href="https://youtu.be/70GmtV-5Ggo?si=UwxSKcZiYbFHA60h" target='_blank'>
                            &nbsp;Click here
                          </a></p>
                          <br/>
                          <p>Important Watch the following video on Switch Statements :-<a href="https://youtu.be/d-Y4CmyrAuA?si=PWMOlL_B7EST-O1z" target='_blank'>
                            &nbsp;Click here
                          </a></p>
                          <br/>
                          <p>Go through the following set of videos to understand about variables in your own preferred coding language:-</p>
                          <br/>
                          <ol type='1'>C++
                            <li>&nbsp;&nbsp;&nbsp;Ternary operators :-<a href="https://youtu.be/-QVc_fGSJAw?si=6ZaQ33UxefptOTwl" target='_blank'>&nbsp;Click here</a></li>
                            <li>&nbsp;&nbsp;&nbsp;Switch :-<a href="https://youtu.be/G80-j_xnE_8?si=uFzxkeZ8j6_WJPK0" target='_blank'>&nbsp;Click here</a></li>
                          </ol>
                          <p>Java :-<a href="https://youtu.be/LTC6PIVOtqw?si=TG0Gv0yMLRf7ibIs" target='_blank'>&nbsp;Click here</a></p><br/>
                          <ol type='1'>Python
                            <li>&nbsp;&nbsp;&nbsp;Ternary operators :-<a href="https://youtu.be/zjwhh2MEa0Q?si=0RueEeRB7708GDoV" target='_blank'>&nbsp;Click here</a></li>
                            <li>&nbsp;&nbsp;&nbsp;Switch :- Note python does not have switch case but it is important as it is asked in MCQ's so go through this video<a href="https://youtu.be/UNnz4THVhy0?si=WkdbK-jV2SutZRQ_" target='_blank'>&nbsp;Click here</a></li>
                          </ol>
                          <br/>
                          <ol type='1'>Javascript
                            <li>&nbsp;&nbsp;&nbsp;Ternary operators :-<a href="https://youtu.be/VBCOx4CqBz0?si=o8Mo0wRY7RYVy_1K" target='_blank'>&nbsp;Click here</a></li>
                            <li>&nbsp;&nbsp;&nbsp;Switch :-<a href="https://youtu.be/SJPsOFfvEQA?si=25tlCtE3fehEpUEo" target='_blank'>&nbsp;Click here</a></li>
                          </ol>
                          <p>After watching the above video Click on the Button Done and start with your tasks. Any Queries or Confusion contact your mentor Directly on Whatsapp.</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Ternary Operators - 1',
        content: (
          <div>
            <p className="task-mvp">Ternary Operators - 1</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        
                          <p>Write a program to assume a value of marks and print whether a person has failed or passed using ternary Operator.-</p>
                          <br/>
                          <p>If Marks&lt; =40 --&lt; pass</p>
                          <p>ELSE ---&lt; fail</p>
                          <p>Input:-<br/>Marks=52<br/>Output:<br/>pass</p>
                          <br/>
                          <p>If you have any doubts or confusion refer to the hints to view the video solution.</p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Ternary Operator -2',
        content: (
          <div>
            <p className="task-mvp">Ternary Operator -2</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                         
                          <p>Write a program to print the maximum of two numbers using Ternary operator.</p>
                          <br/>
                          <p>Input:-<br/>a=3, b=5<br/>Output:<br/>5</p>
                          <br/>
                          <p>If you have any doubts or confusion refer to the hints to view the video solution.</p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Switch-1',
        content: (
          <div>
            <p className="task-mvp">Switch-1</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        
                        <p>People who code in python just understand the Algorithm and submit.</p>
                        <br/>
                        <p>Given the Day number, print the Day name in lower case corresponding to it by using the help of switch statement.</p>
                        <br/>
                        <p>Note: Day 1 - is monday.</p>
                        <p>If the day is not valid example 1&lt; day &lt;7 then print invalid</p>
                          <br/>
                          <p>Input:-<br/>Day-3<br/>Output:<br/>wednesday</p>
                          <br/>
                          <p>If you have any doubts or confusion then ask your mentor.</p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
      {
        title: 'Switch-2',
        content: (
          <div>
            <p className="task-mvp">Switch-2</p>
            
                      <div className="des-8v9">
                        <p className="title-chv">
                          Task details
                        </p>
                        <div className="ql-6os ql-5xn gkajs">
                        
                        <p>Given a number N, if the number is between 1 and 10 both inclusive then return the number in words (Lower case English Alphabets) otherwise return "not in range".</p>
                        <br/>
                          <p>Input:- 5<br/>Output:<br/>five</p>
                          <br/>
                          <p>If you have any doubts or confusion then ask your mentor.</p>
                          <br/>
                          <p>(Solve the following question in the compiler, make sure all test case passes and then click submit. )</p>
                        </div>
                      </div>
          </div>
        ),
      },
  ];

const BpageL = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 13) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/2'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 13</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default BpageL;
