import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Alert, Container } from 'reactstrap';
import { Link } from 'react-router-dom';

const PaymentStatus = () => {
  const [orderData, setOrderData] = useState([]);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await axios.get('https://eduyog.in/payment/all-orders');
        setOrderData(response.data);
      } catch (error) {
        console.error('Error fetching order data:', error);
        setNotification(`Failed to fetch order data: ${error.response?.data?.message || error.message}`);
      }
    };

    fetchOrderData();

    const intervalId = setInterval(fetchOrderData, 5000); // Fetch order data every 5 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
        <div className="text-center my-4">
        <Link to={'/addCourse'} className="btn btn-outline-primary mr-2">HomePage</Link>
       
      </div>
     <h1 className='text-center  m-4 fw-normal display-5'>Payment Status</h1>
     <Container>
     {notification && <Alert color="danger">{notification}</Alert>}
      <Table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Payment ID</th>
            <th>Status</th>
            <th>Amount</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {orderData.map(order => (
            <tr key={order.order_id}>
              <td>{order.order_id}</td>
              {order.payment && order.payment.length > 0 ? (
                <>
                  <td>{order.payment[0].cf_payment_id}</td>
                  <td>{order.payment[0].payment_status}</td>
                  <td>{order.payment[0].payment_amount}</td>
                  <td>{new Date(order.payment[0].payment_time).toLocaleString()}</td>
                </>
              ) : (
                <td colSpan="4">No payment details available</td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
     </Container>
    </div>
  );
};

export default PaymentStatus;
