import React, { useState, useRef } from "react";
import Editor from "@monaco-editor/react";
import { Spinner, Form, Button, Alert } from "react-bootstrap";
import "./Practise.css";

const Compiler = () => {
  const [sourceCode, setSourceCode] = useState("");
  const [output, setOutput] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("javascript");
  const [customInput, setCustomInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const editorRef = useRef(null);

  const languageIds = {
    javascript: 63,
    c: 50,
    cpp: 54,
    python: 71,
    java: 62,
  };

  const handleEditorChange = (value) => {
    setSourceCode(value);
  };

  const fetchData = async (url, options) => {
    try {
      const response = await fetch(url, options);
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
      throw new Error(`Error: ${error.message}`);
    }
  };

  const handleSubmit = async () => {
    setError(null);
    setOutput("");
    setLoading(true);

    const url = "https://judge0-ce.p.rapidapi.com/submissions";
    const apiKey = "429ff1d0a2msh3dbc1f1513b2f77p1a1da8jsnccecda044f83"; // Replace with your actual RapidAPI key

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-RapidAPI-Key": apiKey,
        "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
      },
      body: JSON.stringify({
        source_code: sourceCode,
        language_id: languageIds[selectedLanguage],
        stdin: customInput,
      }),
    };

    try {
      const result = await fetchData(url, options);

      if (result.token) {
        await checkSubmissionStatus(result.token);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkSubmissionStatus = async (token) => {
    const url = `https://judge0-ce.p.rapidapi.com/submissions/${token}?base64_encoded=true`;
    const apiKey = "429ff1d0a2msh3dbc1f1513b2f77p1a1da8jsnccecda044f83"; // Replace with your actual RapidAPI key

    try {
      let status = "Queue";
      const startTime = Date.now();

      while (status !== "Accepted" && Date.now() - startTime < 10000) {
        const result = await fetchData(url, {
          method: "GET",
          headers: {
            "X-RapidAPI-Key": apiKey,
            "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
          },
        });

        status = result.status.description;

        // You can update the UI with the submission status if needed
        console.log(`Submission Status: ${status}`);

        // You may want to add a delay between status checks to avoid rate limiting
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      if (status === "Accepted") {
        // Fetch the final result after the submission is accepted
        const finalResult = await fetchData(url, {
          method: "GET",
          headers: {
            "X-RapidAPI-Key": apiKey,
            "X-RapidAPI-Host": "judge0-ce.p.rapidapi.com",
          },
        });

        // Update the UI with the final result
        setOutput(atob(finalResult.stdout));
      } else {
        setError("Code execution timed out (more than 10 seconds).");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mar">
      <h2 className="text-center display-6 fw-normal">Online Code Compiler</h2>
      <Editor
        height="300px"
        defaultLanguage="javascript"
        value={sourceCode}
        onChange={handleEditorChange}
        options={{
          minimap: { enabled: false },
        }}
        ref={editorRef}
      />
      <br />
      <Form>
        <Form.Group controlId="languageSelect">
          <Form.Label>Choose a Language:</Form.Label>
          <Form.Control
            as="select"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            <option value="javascript">JavaScript</option>
            <option value="c">C</option>
            <option value="cpp">C++</option>
            <option value="python">Python</option>
            <option value="java">Java</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="customInputCheckbox">
          <Form.Check
            type="checkbox"
            label="Use Custom Input"
            onChange={() => setCustomInput("")}
          />
        </Form.Group>
        {customInput && (
          <Form.Group controlId="customInput">
            <Form.Label>Custom Input:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={customInput}
              onChange={(e) => setCustomInput(e.target.value)}
            />
          </Form.Group>
        )}
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={loading || !sourceCode.trim()}
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" /> Running...
            </>
          ) : (
            "Run Code"
          )}
        </Button>
      </Form>
      <br />
      {error && <Alert variant="danger">{error}</Alert>}
      <div>
        <h3>Output:</h3>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <pre>{output || "No output yet"}</pre>
        )}
      </div>
    </div>
  );
};

export default Compiler;
