import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BannerData from './BannerData';

const BannerAdd = () => {
    const [formData, setFormData] = useState({
        title: '',
        subtitle: '',
        description: '',
        link: ''
    });

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://eduyog.in/api/banners', formData);
            setFormData({
                title: '',
                subtitle: '',
                description: '',
                link: ''
            });
        } catch (error) {
            console.error('Error creating banner:', error);
        }
    };

    return (
        <>
        <div className="text-center my-4">
        <Link to={'/addCourse'} className="btn btn-outline-primary mr-2">HomePage</Link>
       
       
      </div>
        <div className="admin-interface" style={{ padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
            <h2 style={{ marginBottom: '10px' }}>Add New Banner</h2>
            <form onSubmit={handleFormSubmit} style={{ marginBottom: '20px' }}>
                <input type="text" placeholder="Title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} style={{ marginBottom: '10px', padding: '5px', width: '100%', borderRadius: '3px', border: '1px solid #ccc' }} />
                <input type="text" placeholder="Subtitle" value={formData.subtitle} onChange={(e) => setFormData({ ...formData, subtitle: e.target.value })} style={{ marginBottom: '10px', padding: '5px', width: '100%', borderRadius: '3px', border: '1px solid #ccc' }} />
                <textarea placeholder="Description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} style={{ marginBottom: '10px', padding: '5px', width: '100%', borderRadius: '3px', border: '1px solid #ccc' }}></textarea>
                <input type="text" placeholder="Link" value={formData.link} onChange={(e) => setFormData({ ...formData, link: e.target.value })} style={{ marginBottom: '10px', padding: '5px', width: '100%', borderRadius: '3px', border: '1px solid #ccc' }} />
                <button type="submit" style={{ padding: '8px 15px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer' }}>Add Banner</button>
            </form>
            <BannerData />
        </div>
        </>
    );
};

export default BannerAdd;
