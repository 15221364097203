import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axios.get('https://eduyog.in/verify/get-user');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    getUsers();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://eduyog.in/verify/delete/${id}`);
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <div>
    <div style={{textAlign:"center" , marginTop:"2%"}}>
      <Link to={'/addCourse'} className="btn btn-outline-primary mr-2">HomePage</Link>
      
      </div>
    <div style={styles.container}>
    <h1 style={styles.heading}>User List</h1>
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.th}>ID</th>
          <th style={styles.th}>First Name</th>
          <th style={styles.th}>Last Name</th>
          <th style={styles.th}>Email</th>
          <th style={styles.th}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id} style={styles.tr}>
            <td style={styles.td}>{user.id}</td>
            <td style={styles.td}>{user.firstName}</td>
            <td style={styles.td}>{user.lastName}</td>
            <td style={styles.td}>{user.email}</td>
            <td style={styles.td}>
              <button style={styles.button} onClick={() => handleDelete(user.id)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  th: {
    backgroundColor: '#f4f4f4',
    color: '#333',
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  tr: {
    ':nth-child(even)': {
      backgroundColor: '#f9f9f9',
    },
  },
  td: {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  },
  button: {
    padding: '8px 16px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#0056b3',
    },
  },
};

export default Users;
