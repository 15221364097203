import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import { Link } from 'react-router-dom';


const BManipulation1 = () => {

  const assignments = [
    { id: 1, name: 'Videos', status: 'start'},
    { id: 2, name: 'Count number of set bits', status: 'start'},
    { id: 3, name: 'Alternating Bits', status: 'start'},
    { id: 4, name: 'Number to Hexadecimal', status: 'start'},
    { id: 5, name: 'Sort integers by number of set bits', status: 'start'},
    { id: 6, name: 'DIvide two integers', status: 'start'},
    { id: 7, name: 'Time and Space complexity (Very Important)', status: 'start'},
    { id: 8, name: 'Time complexity -1', status: 'start'},
    { id: 9, name: 'Questions on Time Complexity', status: 'start'},
    { id: 10, name: 'Time complexity -2', status: 'start'},
    { id: 11, name: 'Time Complexity-3', status: 'start'},
    { id: 12, name: 'Rotate Array -1', status: 'start'},
    { id: 13, name: 'Disappearing Elements', status: 'start'},
    { id: 14, name: 'Strings Videos', status: 'start'},
    { id: 15, name: 'Validate String', status: 'start'},
    { id: 16, name: 'Maximum Number of Words', status: 'start'},
    { id: 17, name: 'Minimum requirement for palindrome', status: 'start'},
    { id: 18, name: 'Extracting Numbers', status: 'start'},
    { id: 19, name: 'Failed Students at end', status: 'start'},
  ];


  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
      <div className='page1'>
      <div>
        <Sidebar/>
      </div>
      <div>
      <div className="assignment-foj">
      <div className="rig-phw">
        <div className="fle-fok justify-6c5 item-ycb orqly">
          <Link to='/assignment/6'>
          <button className="btn-zle bvadh">Next Assignment</button>
          </Link>
          
        </div>
        <div className="right-c1e">
          <table>
           
            <colgroup>
              {Array(4).fill().map((_, index) => <col key={index} className={`style-${index}EMdC`} />)}
            </colgroup>
            <thead>
              <tr>
                <th className="style-q61Z3">#</th>
                <th>Name</th>
                <th className="style-6q7nt">Status</th>
                <th className="style-grDIy"></th>
              </tr>
            </thead>
            <tbody className="style-iNhB2">
              {assignments.map(({ id, name, status, points }, index) => (
                <tr key={id} className="style-GbKIa">
                  <td className="style-CQJlN">{id}</td>
                  <td>
                    {name}
                   
                  </td>
                  <td className="style-mF9jW">
                  <Link to={`/BManipulation2/${index + 1}`} className='style-ckn'>{status}</Link>
                  </td>
                  <td className="style-hsgX7">{points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default BManipulation1;
