import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Input } from 'reactstrap';
import image from "../images/logo.png"; // Adjust the image path as per your setup


const AdminLoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Using useNavigate hook

  const handleSubmit = (e) => {
    e.preventDefault();

    // Hardcoded credentials for demonstration
    if (username === 'eduyog2024' && password === 'Shubham@123') {
      // Set token in localStorage upon successful login
      localStorage.setItem('token', 'your-auth-token'); // Replace 'your-auth-token' with an actual token

      // Redirect to /admin if authenticated
      navigate('/adminDash');
      alert('User authenticated as admin');
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <>
     
      <div style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        position: 'relative',
      }}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.6)',
          zIndex: 1
        }}></div>
        <Container style={{ zIndex: 2, marginTop: '-5%' }}>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <Card className="shadow-lg border-0 rounded" style={{
                background: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(10px)',
                animation: 'fadeIn 1s ease-in-out',
                borderRadius: '20px',
              }}>
                <CardBody>
                  <h2 className="text-center mb-4" style={{
                    fontWeight: 'bold',
                    color: '#333',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                    animation: 'slideIn 1s ease-in-out'
                  }}>Admin Login</h2>
                  {error && <div className="alert alert-danger text-center">{error}</div>}
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        placeholder="Username"
                        style={{
                          borderRadius: '20px',
                          padding: '10px 15px',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          animation: 'fadeIn 1.5s ease-in-out'
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Password"
                        style={{
                          borderRadius: '20px',
                          padding: '10px 15px',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          animation: 'fadeIn 1.5s ease-in-out'
                        }}
                      />
                    </FormGroup>
                    <Button type="submit" color="primary" className="w-100 mb-3" style={{
                      borderRadius: '20px',
                      padding: '10px 0',
                      background: 'linear-gradient(45deg, #6b5b95, #b8a9c9)',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      animation: 'fadeIn 2s ease-in-out'
                    }}>Login</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <style>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        @keyframes slideIn {
          from { transform: translateY(-20px); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
        @media (max-width: 768px) {
          .card {
            width: 90%;
            margin: auto;
          }
        }
        @media (max-width: 576px) {
          .card {
            width: 100%;
            margin: auto;
          }
        }
      `}</style>
    </>
  );
};

export default AdminLoginPage;
