

import React from 'react';
import { useParams } from 'react-router-dom';

const getPostById = (postId) => {
  const blogPosts = [
    { id: 1, title: 'First Blog', content: 'This is the content of the first blog.' },
    { id: 2, title: 'Second Blog', content: 'This is the content of the second blog.' },
  ];

  return blogPosts.find(post => post.id === parseInt(postId));
};

const BlogPost = () => {
  const { postId } = useParams();
  const post = getPostById(postId);

  if (!post) {
    return <div className="container">Post not found</div>;
  }

  return (
    <div className="container">
      <h2>{post.title}</h2>
      <p>{post.content}</p>
    </div>
  );
};

export default BlogPost;
