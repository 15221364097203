import React, { useState } from "react";
import Layout from "../../component/Layout";
import { Link, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { toast } from "react-toastify";
import Axios from "../../Axios";
import { useAuth } from "../../context/AuthContext";

const LoginForYoga = () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: ""
  });
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await Axios.post("https://eduyog.in/authForYoga/loginForYoga", user);
      if (res.data && res.data.success) {
        setAuth({ ...auth, user: res?.data?.message, token: res?.data?.token });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate("/placement/dashboardForYoga");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card bg-light shadow p-4">
              <h3 className="text-center mb-3 display-5 text-dark fw-normal">Login For  Yoga</h3>
              <form className="login" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-3">
                  <div className="input-group">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                      className="form-control border-end-0"
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary border-start-0"
                      onClick={() => setPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? <BsEyeSlash /> : <BsEye />}
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  {/* <Link to="forget" className="text-decoration-none">
                    Forgot password?
                  </Link> */}
                  <Link to="/registerForYoga" className="text-decoration-none">
                    Register
                  </Link>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary fw-bold">Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginForYoga;
