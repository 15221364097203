import React, { useState } from "react";
import "./Placement.css";
import "./PlacementResponse.css";
import { Link } from 'react-router-dom';

import { BsFillGridFill, BsFillPersonFill } from "react-icons/bs";
import { FaClipboardList, FaFlag } from "react-icons/fa";
import { MdLeaderboard } from "react-icons/md";
import { BiLogOut, BiSolidVideo } from "react-icons/bi";
import { VscTasklist } from "react-icons/vsc";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { IoIosNotifications } from "react-icons/io";
import Current, { TopicRoadMap } from "./component/Current";
import Qualification, {
  EventComp,
  Placement,
} from "./component/Qualification";
import UpcomingSession from "./component/UpcomingSession";
import { useAuth } from "../../../context/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import BlogList from "./component/BlogList";
const Dashboard = () => {
  return (
    <div className="placement-dashboard d-flex bg-light">
      <Sidebar />
      <div className="dashboard-right">
        <PlacementNavbar />
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;

export const DashboardHome = () => {
  return (
    <>
      <div className="row row-cols-lg-3 row-cols-md-2 anotherfor">
        <div>
          <TopicRoadMap />
        </div>
        <div>
        <Qualification />
          <Placement />
       
        </div>
        <div>
          <UpcomingSession />
         <BlogList/>
        </div>
      </div>
    </>
  );
};

export const Sidebar = () => {
  return (
    <>
      <div className="dashboard-sidebar">
        <ul className="p-0 response">
          {sidebarData.map((data) => (
            <NavLink
              activeclassname="active"
              to={data.path}
              className="text-decoration-none sidebar-active text-dark"
            >
              <li className="d-flex m-sm-3 m-2">
                {data.icons} <span className="toggle m-auto">{data.name}</span>
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
    </>
  );
};
const sidebarData = [
  
  {
    icons: <BsFillGridFill className="h2 pe-1 text-danger m-atuo mb-0" />,
    name: "Dashboard",
    path: "/placement/dashboard",
  },
  {
    icons: <FaClipboardList className="h2 pe-1 text-danger m-atuo mb-0" />,
    name: "Assignment",
    path: "/placement/dashboard/assignment",
  },
  {
    icons: <BsFillGridFill className="h2 pe-1 text-danger m-atuo mb-0" />,
    name: "Practise",
    path: "/placement/dashboard/practise",
  },
  {
    icons: <BiSolidVideo className="h2 pe-1 text-danger m-atuo mb-0" />,
    name: "Interview",
    path: "/placement/dashboard/interview",
  },
];
const ProfileHanlde = () => {
  const [toggle, settoggle] = useState(false);
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const hanldeLogout = () => {
    setAuth({ ...auth, user: null, token: "" });
    localStorage.removeItem("auth");
    navigate("/");
    toast.success("your successfully logout");
  };
  return (
    <>
      <ToastContainer />
      <BsFillPersonFill className="h4" onClick={() => settoggle(!toggle)} />
      {toggle && (
        <div
          className="position-absolute bg-white w-100"
          style={{ left: "90%" }}
        >
          <li className="my-2 p-2 profile-hov ">
            <BsFillPersonFill /> Profile
          </li>
          <li className="my-2 p-2 profile-hov" onClick={hanldeLogout}>
            <BiLogOut /> Logout
          </li>
        </div>
      )}
    </>
  );
};
const placementNavData = [
  <VscTasklist className="h4" />,
  <FaFlag className="h4" />,
  <IoIosNotifications className="h4" />,
  <ProfileHanlde />,
  // <BsFillPersonFill className="h4" />,
];
// export const PlacementNavbar = () => {
//   return (
//     <>
//     <div>
//       <div className='Dare01'>
//       <Link to="/placement/dashboard">
//       <h3>EduYog</h3>
//       </Link>
//     </div>
//       <ul className="sh d-flex">
//         <div className="ms-auto position-relative  gap-4 my-auto me-3 px-2 py-1 shadow border rounded-5  bg-white d-flex">
//           {placementNavData.map((data) => (
//             
//           ))}
//         </div>
//       </ul>
//     </div>
//     </>
//   );
// };
export const PlacementNavbar = () => {
  return (
<nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top checkfor">
      <Link to="/placement/dashboard" className="navbar-brand ms-4">
      <h3>
          <span style={{ color: "#0e99ae" }}>Edu</span>
           <span style={{ color: "#0f7908" }}>Yog</span>
      </h3>
      </Link>
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav ms-auto" >
          <div className="d-flex gap-4 my-auto me-3 px-2 py-1 shadow border rounded-5 bg-white">
            {placementNavData.map((data) => (
              <li style={{ cursor: "pointer" }}>{data}</li>
            ))}
          </div>
        </ul>
      </div>
    </nav>
  );
};
