import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Practice DSA Questions',
      content: (
        <div>
          <p className="task-mvp">Practice DSA Questions</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                  I have created an excel sheet which contains some set of questions for practice. 
                   <br/>
                    Try to solve 3-4 Questions every week on topics which you have completed. 
                     <br/><br/>
                     Link:-<a href='https://docs.google.com/spreadsheets/d/1OBl8_DEUH8-hJaMRlU0Gqee_XbsgX5CIFyPJyEgGEG0/edit?usp=sharing' alt='uploading' target='_blank'>https://docs.google.com/spreadsheets/d/1OBl8_DEUH8-hJaMRlU0Gqee_XbsgX5CIFyPJyEgGEG0/edit?usp=sharing</a>
                        <br/><br/>
                  (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Videos - OOPS ',
        content: (
          <div>
            <p className="task-mvp">Videos - OOPS</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Watch the following Video to Understand what are OOPS:-&nbsp;<a href='https://youtu.be/j_IY76AdkGY?si=fTUcW5wekMqROI04' alt='uploading'target="_blank">Click here</a>

<br/>
<br/>
 Try to understand each and every topic in your Language and also try it in your editor parallelly.
 <br/><br/>


Python OOPS 
<br/>
 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Watch the following video to understand how OOPS works in python:- &nbsp;<a href='https://youtu.be/bjM1JjGzvb8?si=WaFfCJlqOsva31ZB' alt='uploading'target="_blank">Click here</a>
<br/> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Understand what are inheritance :- &nbsp;<a href='https://youtu.be/w2jV9qZ6PGo?si=omyZ4OKC7WRh3KhH' alt='uploading'target="_blank">Click here</a>

<br/><br/>

C++ OOPS:-
<br/>


&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Basic introduction to objects :-&nbsp;<a href='https://youtu.be/muKta_d7BAo?si=ChNvA9NNJSBqskeM' alt='uploading'target="_blank">Click here</a><br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Understand what are inheritance :- &nbsp;<a href='https://youtu.be/jD-lNjISISk?si=zIPn5xnjXDmRTmHd' alt='uploading'target="_blank">Click here</a><br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Inheritance :-&nbsp;<a href='https://youtu.be/7ZfEp71tIec?si=hhFjwX2Kllti0I3q' alt='uploading'target="_blank">Click here</a> <br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Operator Overloading :-&nbsp;<a href='https://youtu.be/cPfcLaP_DqA?si=KOY7XgFIfRSfhKp-' alt='uploading'target="_blank">Click here</a><br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Function Overloading :- &nbsp;<a href='https://youtu.be/YQ1k64cCfys?si=voWdMufX0QZ7_QvB' alt='uploading'target="_blank">Click here</a><br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Dynamic memory allocation :-&nbsp;<a href='https://youtu.be/UY2_lLpDu7U?si=D-iVEzdxk0uUgU7s' alt='uploading'target="_blank">Click here</a><br/>

<br/><br/>

Java OOPS<br/>

&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Watch the following video to understand how OOPS works in Java:- &nbsp;<a href='https://youtu.be/AEo4KgwKYoU?si=7vubUDQlcAPkAa91' alt='uploading'target="_blank">Click here</a><br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Understand what are inheritance :- &nbsp;<a href='https://youtu.be/dFuVh_Bzy9c?si=d4jg5FUTe34SOVQB' alt='uploading'target="_blank">Click here</a><br/>


Javascript OOPS:-<br/>

&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Important - Watch the following video on Javascript to understand how OOPS works :- &nbsp;<a href='https://youtu.be/pN-Qmv4zBcI?si=TW7rE82fK8SQnxIZ' alt='uploading'target="_blank">Click here</a><br/>

&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Understand what are inheritance :- &nbsp;<a href='https://youtu.be/jnME98ckDbQ?si=XWnTWcylLwrVDjsC' alt='uploading'target="_blank">Click here</a><br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Detailed Video on OOPS :- &nbsp;<a href='https://youtu.be/PFmuCDHHpwk?si=jFzwg2xrue_4l0kH' alt='uploading'target="_blank">Click here</a><br/><br/>


After watching the above video Click on the Button Done and start with your tasks. <br/>

Any Queries or Confusion contact your mentor Directly on Whatsapp.<br/><br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Constructors',
        content: (
          <div>
            <p className="task-mvp">Constructors</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    
                      <p>
                      Create a class named 'Student' with String variable 'name' and integer variable 'roll_no'. 
<br/>
Create a constructor through which you can assign values through objects on creation of objects.
<br/>
if No value is passed through object then by default name should be initialized to "john" and roll_no as 2
<br/><br/>


Watch the following video if stuck :-
<br/><br/>
Java - &nbsp;<a href='https://youtu.be/Fxj4n8En8lw?si=Q17GR0b0J_1qC2ha' alt='uploading'target="_blank">Click here</a><br/><br/>
C++ - &nbsp;<a href='https://youtu.be/mHslbPp7Qis?si=rQJKQ6KTU-VjVK_9' alt='uploading'target="_blank">Click here</a><br/><br/>
Python - &nbsp;<a href='https://youtu.be/12HRkYld22c?si=vEWGGxP_cpY5M8t5' alt='uploading'target="_blank">Click here</a><br/><br/>
JavaScript - &nbsp;<a href='https://youtu.be/0E2akQ5E-5Y?si=yLyYpS1V_y6_yXio' alt='uploading'target="_blank">Click here</a><br/><br/>
<br/>

Note -  object will be created by Question Setter but try to play around with it so that you understand and build confidence around it.<br/>
                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)<br/><br/>
                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Functions',
        content: (
          <div>
            <p className="task-mvp">Functions</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    A class named 'Student' with String variable 'name' and integer variable 'roll_no' and Constructor is already created.
                    <br/>
Create a method display to print the attributes name and roll_no.

<br/><br/>

Watch the hints if you are stuck.


<br/><br/>
If there is any confusion make sure to ask the mentor and get it resolved . 
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Functions and Objects',
        content: (
          <div>
            <p className="task-mvp">Functions and objects</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Create a class Triangle Having 3 sides as attributes (side1, side2, side 3).
                    <br/>
Create a constructor to initialize the sides in realtime on object creation.
<br/>
Create 2 functions calculate_area and calculate_perimeter
<br/>
calculate_area functions returns the area of the triangle ( side1 * side2 * side3)
<br/>
calculate_perimeter functions returns the perimeter of the triangle (side1+side2+side3).

<br/><br/>

Use the help of hints if Stuck


<br/><br/>
Since OOPS is a very important Topic this task will be checked Manually by the mentor during the mock interview ::).


<br/><br/>
If there is any confusion make sure to ask the mentor and get it resolved . 
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Objects',
        content: (
          <div>
            <p className="task-mvp">Objects</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Create a class Employee Having 3 attributes (name, year of joining, address)
                    <br/>
Create a constructor to initialize the attributes in realtime on object creation.
<br/>
Create a function print_details to print the details of the employee. (it should be just separated by a space).
<br/><br/>


Example:- (there is only space in between of name, year, address)
<br/>
Robert      1994        64C-WallsStreet
<br/>
Sam        2000        68D-WallsStreet
<br/>
John        1999        26B-WallsStreet.

<br/><br/>

Use the help of hints if Stuck

<br/><br/>

Write Down the Entire Code for the above Question in your IDE and copy paste the code here.

<br/><br/>

Since OOPS is a very important Topic this task will be checked Manually by the mentor during the mock interview ::).


<br/><br/>
If there is any confusion make sure to ask the mentor and get it resolved . 
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Functions and Objects - 2',
        content: (
          <div>
            <p className="task-mvp">Functions and objects - 2</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Create a class Employee Having 2 attributes (salary , working_hours).

                    <br/><br/>

It will contain the following methods :- 


<br/><br/>
1 - 'getInfo()' which takes the salary, number of hours of work per day of employee as parameter and initializes the attributes. (Note there is no constructor instead get_info method is used)
<br/>
2 - 'AddSal()' which adds 10 to salary of the employee if it is less than 500. (note you don't have to print or return anything)
<br/>
3 - 'AddWork()' which adds 5 to salary of employee if the working_hours is more than 6 hours. (note you don't have to print or return anything)
<br/><br/>


Use the help of hints if Stuck

<br/><br/>

Since OOPS is a very important Topic this task will be checked Manually by the mentor during the mock interview ::).

<br/><br/>

If there is any confusion make sure to ask the mentor and get it resolved . 
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Videos - Static (Very Important)',
        content: (
          <div>
            <p className="task-mvp">Videos - Static (Very Important)</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Understand Static and non Static keyword from the below set of Videos

                    <br/><br/>

Important Watch these Video -1  -- &nbsp;<a href='https://youtu.be/u98KvPuaIus?si=NzKKSuIS5PPQHWCV' alt='uploading'target="_blank">Click here</a><br/>
Interview Question  -- &nbsp;<a href='https://www.cquestions.com/2011/02/static-variable-in-c.html' alt='uploading'target="_blank">Click here</a><br/>


<br/><br/>

After Watching the above videos watch the below videos to understand the implementation in your own language

<br/><br/>

Python :- &nbsp;<a href='https://youtu.be/GcSVYNSsJxo?si=aIZbPn34AjU14B_G' alt='uploading'target="_blank">Click here</a>


<br/><br/>
JavaScript :- &nbsp;<a href='https://youtu.be/__l6ZOPaijs?si=tchlfDbxcM_p3gxQ' alt='uploading'target="_blank">Click here</a>


<br/><br/>
Java :- &nbsp;<a href='https://youtu.be/qRCEdWQ0f4Q?si=4N2xHJIHIP3QazgC' alt='uploading'target="_blank">Click here</a>


<br/><br/>
C++ :-
<br/>

Video Link :- &nbsp;<a href='https://youtu.be/f3FVU-iwNuA?si=pl4lONWF7IZOfW10' alt='uploading'target="_blank">Click here</a><br/>
2nd Video Link :- &nbsp;<a href='https://youtu.be/QcLI2zGVYFo?si=_EZxm3W0frlLP4uW' alt='uploading'target="_blank">Click here</a><br/>
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Famous Interview Question',
        content: (
          <div>
            <p className="task-mvp">Famous Interview Question</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Write a program to create a class count_objects and calculate the number of objects that has been created in realtime.
                    <br/> <br/>


Use the help of hints if there are any confusion


<br/> <br/>
Write Down the Entire Code for the above Question in your IDE and copy paste the code here.


<br/> <br/>
Since OOPS is a very important Topic this task will be checked Manually by the mentor during the mock interview ::).

<br/> <br/>

If there is any confusion make sure to ask the mentor and get it resolved . 
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Task- 6 (Inheritance)',
        content: (
          <div>
            <p className="task-mvp">Task- 6 (Inheritance)</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Create a class named 'Shape' with a method to printShape "This is shape". Then create two other classes named 'Rectangle', 'Circle' inheriting the Shape class, both having a method to print "This is rectangle" and "This is circle" respectively. Create a child class 'Square' of 'rectangle' having a method to printSquare "Square is a rectangle". Now call the method of 'Shape' and 'Rectangle' class by the object of 'Square' class.


                    <br/> <br/>
Watch the Hints if Stuck.

<br/> <br/>

Write Down the Entire Code for the above Question in your IDE and copy paste the code here.
<br/> <br/>


Since OOPS is a very important Topic this task will be checked Manually by the mentor during the mock interview ::). 
<br/> <br/>


If there is any confusion make sure to ask the mentor and get it resolved . 
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Destructors - Videos',
        content: (
          <div>
            <p className="task-mvp">Destructors - Videos</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch the following video and understand what are destructors and how to call them explicitly in different languages - &nbsp;<a href='https://youtu.be/ou433we3bYY?si=BFTMBoXWQhS28U-s' alt='uploading'target="_blank">Click here</a>
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Task 7 (inheritance)',
        content: (
          <div>
            <p className="task-mvp">Task 7 (inheritance)</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Create a class "PARENT" with a method print that prints "this is a parent class" and its Child class "CHILD" with a method print that prints "this is a child class". Now, create an object for each of the class and call
                    <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 - method of parent class by object of parent class
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2 - method of child class by object of child class
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3 - method of parent class by object of child class
<br/> <br/>


Watch the Hints if Stuck.

<br/> <br/>

Write Down the Entire Code for the above Question in your IDE and copy paste the code here.

<br/> <br/>

Since OOPS is a very important Topic this task will be checked Manually by the mentor. 
<br/> <br/>


If there is any confusion make sure to ask the mentor and get it resolved . 
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Task 8 (inheritance)',
        content: (
          <div>
            <p className="task-mvp">Task 8 (inheritance)</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Create a class named 'Member' having the following attributes:
                    <br/>
Data Attributes of Member Class
<br/>
1 - Name
<br/>
2 - Age
<br/>
3 - Phone number
<br/>
4 - Address
<br/>
5 - Salary
<br/>
It also has a method named 'printSalary' which prints the salary of the members.
<br/>
Two classes 'Employee' and 'Manager' inherits the 'Member' class. The 'Employee' and 'Manager' classes have attributes 'specialization' and 'department' respectively.
<br/>
Both these classes will also have a method print_details.
<br/>
Print_details of Employee class will print name & 'specialization' and will also call the printSalary method.
<br/>
Print_details of Manager class will print name & 'department' and will also call the printSalary method.

<br/> <br/>

Now, assign name, age, phone number, address and salary to an employee and a manager by making an object of both of these classes.
<br/>
and print the same.


<br/>
Hint:- Use super method to call parent constructor

<br/>
<br/>
C++ People go through this document to understand how super works :- &nbsp;<a href='https://www.fluentcpp.com/2017/12/26/emulate-super-base/' alt='uploading'target="_blank">Doc_Link</a>

<br/> <br/>

Watch the Hints if Stuck.

<br/> <br/>

Write Down the Entire Code for the above Question in your IDE and copy paste the code here.

<br/> <br/>

Since OOPS is a very important Topic this task will be checked Manually by the mentor during the mock interview ::).


<br/> <br/>
If there is any confusion make sure to ask the mentor and get it resolved . 
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Video - Binary Search',
        content: (
          <div>
            <p className="task-mvp">Video - Binary Search</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch the following video to understand Binary Search :- &nbsp;<a href='https://youtu.be/V_T5NuccwRA?si=iVPU-NTaXWbR8t3h' alt='uploading'target="_blank">Click here</a>
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Searching',
        content: (
          <div>
            <p className="task-mvp">Searching</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Solve the following question using Binary Search -&nbsp;<a href='https://www.codechef.com/practice/course/binary-search/INTBINS01/problems/TRICOIN' alt='uploading'target="_blank">Click here</a>
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Important Interview Question -1',
        content: (
          <div>
            <p className="task-mvp">Important Interview Question -1</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    A peak element is an element that is strictly greater than its neighbors.
                    <br/>
Given a 0-indexed integer array nums, find a peak element, and return its index. If the array contains multiple peaks, return the index to any of the peaks.
<br/>
You may imagine that nums[-1] = nums[n] = -∞. In other words, an element is always considered to be strictly greater than a neighbor that is outside the array.
<br/>
You must write an algorithm that runs in O(log n) time.

<br/><br/>

Example 1:
<br/>
Input: nums = [1,2,3,1]
<br/>
Output: 2
<br/>
Explanation: 3 is a peak element and your function should return the index number 2.
<br/>
Example 2:
<br/>
Input: nums = [1,2,1,3,5,6,4]
<br/>
Output: 5
<br/>
Explanation: Your function can return either index number 1 where the peak element is 2, or index number 5 where the peak element is 6.
<br/><br/>


Constraints:<br/>

1 &lt;= nums.length &lt;= 1000<br/>
-2^31 &lt;= nums[i] &lt;= 2^31 - 1<br/>
nums[i] != nums[i + 1] for all valid i.<br/>




<br/><br/>

Watch the video from hints if you are not able to devise an log(n) solution
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'First and Last Occurence of Element',
        content: (
          <div>
            <p className="task-mvp">First and Last Occurence of Element</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    You are Given an array Sorted in ascending order. Find the Starting and ending Position of a given Target value.
                    <br/> <br/>


If target is not found in the array, return [-1, -1].

<br/> <br/>

You must write an algorithm with O(log n) runtime complexity.

<br/> <br/>

Example 1:
<br/>
Input: nums = [5,7,7,8,8,10], target = 8
<br/>
Output: [3,4]

<br/> <br/>
Example 2:
<br/>
Input: nums = [5,7,7,8,8,8,8,8,8,10], target = 8
<br/>
Output: [3,8]

<br/> <br/>

Example 3:
<br/>
Input: nums = [5,7,7,8,8,10], target = 6
<br/>
Output: [-1,-1]

<br/> <br/>



Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0 &lt;= nums.length &lt;= 105<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-109 &lt;= nums[i] &lt;= 109<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;nums is a non-decreasing array.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-109 &lt;= target &lt;= 109<br/>
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Important Interview Question-2',
        content: (
          <div>
            <p className="task-mvp">Important Interview Question-2</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Solve the following question&nbsp;<a href='https://www.codechef.com/practice/course/binary-search/INTBINS01/problems/SHKNUM' alt='uploading'target="_blank">Click here</a>
<br/>
Watch the video from hints if you are not able to devise an log(n) solution
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Map and Set Videos',
        content: (
          <div>
            <p className="task-mvp">Map and Set Videos</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch this video from youtube :- &nbsp;<a href='https://youtu.be/KwS-Vbsha1k?si=V84ebZ9YdUb8XaH1' alt='Uploading' target='_blank'>Click here</a> 
                    <br/>
Important watch the following video :- &nbsp;<a href='https://www.geeksforgeeks.org/sets-in-python/' alt='Uploading' target='_blank'>Click here</a> 
<br/>


After Watching the above Video Read the following Articles to learn Implementation (Based on the language you code in)
<br/> <br/>


python Dictionary read - &nbsp;<a href='https://www.geeksforgeeks.org/python-dictionary/' alt='Uploading' target='_blank'>Click here</a> 
<br/>
Set in python - &nbsp;<a href='https://www.geeksforgeeks.org/sets-in-python/' alt='Uploading' target='_blank'>Click here</a> 

<br/> <br/>

Java HashMap read - &nbsp;<a href='https://www.geeksforgeeks.org/java-util-hashmap-in-java-with-examples/' alt='Uploading' target='_blank'>Click here</a> 
<br/>
Set and HashSet - &nbsp;<a href='https://www.geeksforgeeks.org/set-in-java/' alt='Uploading' target='_blank'>Click here</a> 
<br/> <br/>


Map in C++ - &nbsp;<a href='https://www.geeksforgeeks.org/map-associative-containers-the-c-standard-template-library-stl/' alt='Uploading' target='_blank'>Click here</a> 
<br/>
Set in C++ -  &nbsp;<a href='https://www.geeksforgeeks.org/set-in-cpp-stl/' alt='Uploading' target='_blank'>Click here</a> 

<br/> <br/>

Map in JavaScript :- &nbsp;<a href='https://www.geeksforgeeks.org/set-in-cpp-stl/' alt='Uploading' target='_blank'>Click here</a> 
<br/>
Set in JavaScript :- &nbsp;<a href='https://www.geeksforgeeks.org/set-in-cpp-stl/' alt='Uploading' target='_blank'>Click here</a> 


<br/> <br/>
After watching the above video Click on the Button Done and start with your tasks. 
<br/>
Any Queries or Confusion contact your mentor Directly on Whatsapp.
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Map and Set Videos',
        content: (
          <div>
            <p className="task-mvp">Map and Set Videos</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Watch this video to understand about immutable concept and Mutable concept.
                    <br/>
Video_Link -&nbsp;<a href='https://youtu.be/JjIewPuyexA?si=iz4fd-iQHfNCoaH0' alt='Uploading' target='_blank'>Click here</a> 
<br/>
Java Folks:-
<br/>
What are StringBuilder and StringBuffer:-&nbsp;<a href='https://youtu.be/_m04SPTU4N0?si=3faNuqxUbO05J2f5' alt='Uploading' target='_blank'>Click here</a> 
<br/>
Reference Link :-&nbsp;<a href=' https://www.geeksforgeeks.org/string-vs-stringbuilder-vs-stringbuffer-in-java/' alt='Uploading' target='_blank'>Click here</a> 
<br/>
Try to play with some examples and it will help you understand of how to use them.




<br/><br/>
After watching the above video Click on the Button Done and start with your tasks. 

Any Queries or Confusion contact your mentor Directly on Whatsapp.
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Map Question -1',
        content: (
          <div>
            <p className="task-mvp">Map Question -1</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Solve the following question on leetcode:- &nbsp;<a href='https://leetcode.com/problems/two-sum/description/' alt='Uploading' target='_blank'>Click here</a>

<br/>
<br/>
Can you solve the question in linear time complexity . (Refer Hints)

<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Lucky Integer in an Array',
        content: (
          <div>
            <p className="task-mvp">Lucky Integer in an Array</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Solve the following question on leetcode:- &nbsp;<a href='https://leetcode.com/problems/find-lucky-integer-in-an-array/description/' alt='Uploading' target='_blank'>Click here</a>

<br/>
<br/>
Can you solve the question in linear time complexity . 

<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Sorting of Objects',
        content: (
          <div>
            <p className="task-mvp">Sorting of Objects</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Given an Array of Objects(Name,Salary). Return An Array containing Objects sorted in Ascending Order of Salary using any of the sorting mechanism.

                    <br/><br/>

Objects is of Class Employee
<br/>
It contains two attributes --&gt; Name, Salary

<br/><br/>

Return the Array containing Objects sorted in Ascending Order of Salary using any of the sorting mechanism.
<br/><br/>
 



Example:-
<br/>
Input Array Contains Object Having Name and Salary.
<br/>
Babu_Rao 150
<br/>
Raju 160
<br/>
Shyam 140
<br/>
Pappu 200
<br/>

<br/>
Output :-
<br/>
Shyam 140
<br/>
Babu_Rao 150
<br/>
Raju 160
<br/>
Pappu 200

<br/><br/>



Since OOPS is a very important Topic this task will be checked Manually by the mentor during the mock interview ::). 
<br/><br/>


If there is any confusion make sure to ask the mentor and get it resolved . 

<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Important Interview Question - 3',
        content: (
          <div>
            <p className="task-mvp">Important Interview Question - 3</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    Solve this question on leetcode :-&nbsp;<a href=' https://leetcode.com/problems/count-primes/description/' alt='Uploading' target='_blank'>Click here</a>

                    <br/><br/>

Important - Watch this video to understand the most optimal way to solve the above question -&nbsp;<a href=' https://www.youtube.com/watch?v=pKvGYOnO9Ao' alt='Uploading' target='_blank'>Click here</a>

<br/><br/>

After solving copy paste your code here
<br/>
<br/>

                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Jewels and Stones',
        content: (
          <div>
            <p className="task-mvp">Jewels and Stones</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    You're given strings jewels representing the types of stones that are jewels, and stones representing the stones you have. Each character in stones is a type of stone you have. You want to know how many of the stones you have are also jewels.
                    <br/>
Letters are case sensitive, so "a" is considered a different type of stone from "A".

<br/> <br/> 

Example 1:
<br/>
Input: jewels = "aA", stones = "aAAbbbb"
<br/>
Output: 3
<br/>
Example 2:
<br/>
Input: jewels = "z", stones = "ZZ"
<br/>
Output: 0
<br/> <br/>
 

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt;= jewels.length, stones.length &lt;= 50<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;jewels and stones consist of only English letters.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All the characters of jewels are unique.<br/>

<br/> <br/>
Try to solve this Question in 0(n) time complexity. n is length of the maximum word Jewels and stone.

<br/> <br/>

and paste your code after solving all the test cases.

<br/> <br/>




                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Me and You will be 0',
        content: (
          <div>
            <p className="task-mvp">Me and You will be 0</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    You are given a array with positive and negative numbers .
                    <br/>
you have to find the part of the array i.e subarray which has addition 0 .
<br/>
the output should be true if subarray present in array whose sum is 0 and return false if not.

<br/> <br/>

Solve it in 0(n).
<br/> <br/>


Example 1:
<br/>
Input: 5 4 2 -3 1 6
<br/>
Output: True
<br/>
 Explanation: 2, -3, 1 is the subarray with sum 0.

 <br/> <br/>

Example 2:
<br/>
Input: 5 4 2 0 1 6
<br/>
Output: True
<br/>
Explanation: 0 is one of the element in the array so there exist a subarray with sum 0.

<br/> <br/>

Example 3:
<br/>
Input: 5 4 2 2 1 6
<br/>
Output: False
<br/> <br/>




Hint :- Use Prefix sum(running Sum) and the concept of two sum.

<br/> <br/>

Example:-
<br/>
prefix sum of this array  [5 4 2 -3 1 6] is [ 0, 5, 9,11, 8, 9 , 15]
<br/>
Now if you want to get any subarray you can subtract and get it .
<br/>
like if you want this subarray [4,2,-3] is 8-5 (visualize it to understand)
<br/>
[5,4,2,-3] - [5]



<br/> <br/>




                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'How many swaps ?',
        content: (
          <div>
            <p className="task-mvp">How many swaps ?</p>
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                    <p>
                    You are maintaining a register of students roll numbers which is a array the roll numbers can be in sorted or unsorted manner .
                    <br/>
The task is to arrange all the roll numbers in ascending order , Find the number of changes required to do so
<br/>
that number should be minimum


<br/>
Example 1:
<br/>
Input:
<br/>
 nums = {"{2, 8, 5, 4}"}
 <br/>
Output: 1
<br/>
Explanation: swap 8 with 4.
<br/><br/>


Input:
<br/>
 nums = {" {10, 19, 6, 3, 5}"}
 <br/>
Output: 2
<br/>
Explanation: swap 10 with 3 and swap 19 with 5.
<br/><br/>


Input:
<br/>
 nums = {"{10, 19, 6, 5, 3}"}
 <br/>
Output: 3
<br/>
Explanation: 
<br/>
1st - swap 10 with 5
<br/>
then swap 5 with 19
<br/>
and then swap 19 with 3.
<br/><br/>


HINT:-
<br/>
With Example :- {"{10,9,6,5,3}"}
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.Sort the Array Given to you =&gt;{"{3,5,6,10,19}"}<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.In HashMap store the correct index of an element =&gt;{"{3:0,5:1,6:2,10:3,19:4}"}<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.Now since we know the index we can use the Last technique of rotate array concept.<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(when there is a cycle count will decrease by 1)

<br/> <br/>




                        (Solve the following question on the Compiler, make sure all test case passes and then click submit.)
 <br/>

                        </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];

const Oops2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 27) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/7'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 27</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Oops2;
