import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AdminSend = () => {
  const [formData, setFormData] = useState({
    courseId: '',
    title: '',
    email: '',
    file: ''
  });

  const handleChange = (e) => {
    if (e.target.name === 'file') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0]
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('courseId', formData.courseId);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('file', formData.file);
  

    try {
      const response = await axios.post('https://eduyog.in/api/forAnswer', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 201) {
        alert('Answersheet sent successfully');
        setFormData({ courseId: '', title: '', email: '', file: '' });
      }
    } catch (error) {
      console.error("There was an error adding the content!", error);
      alert('Failed to add content');
    }
  };

  return (
    <>
        <div style={{textAlign:"center" , margin:"2%"}}>
        <Link to={'/addCourse'} className="btn btn-outline-primary mr-2">HomePage</Link>
     
      </div>
      <Container className="mt-5">
        <Row>
          <Col md={6} className="mx-auto">
            <Card className="shadow">
              <CardBody>
                <h1 className="text-center mb-4 display-5 fw-normal">Send Answer-Sheet</h1>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="courseId"
                    value={formData.courseId}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="Course ID For Which Student Enroll *"
                  />
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="Student Name *"
                  />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="form-control mb-3"
                    placeholder="Student Registered Email *"
                  />
                  <input
                    type="file"
                    name="file"
                    onChange={handleChange}
                    className="form-control mb-3"
                    accept=".pdf"
                  />
                  <Button type="submit" className="btn btn-primary mb-3 ">Send Answer-Sheet</Button>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminSend;
