import React, { useState } from "react";
import Layout from "../../component/Layout";
import { Link, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { toast } from "react-toastify";
import Axios from "../../Axios";
import { useAuth } from "../../context/AuthContext";

const Login = () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [user, setUser] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await Axios.post("https://eduyog.in/auth/login", user);
      if (res.data && res.data.success) {
        setAuth({ ...auth, user: res?.data?.message, token: res?.data?.token });
        localStorage.setItem("auth", JSON.stringify(res.data));
        navigate("/placement/dashboard");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow p-3">
              <h3 className="text-center mb-3">Login</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <div className="input-group">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="form-control"
                      placeholder="Enter your password"
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                      required
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setPasswordVisible(!isPasswordVisible)}
                      style={{ cursor: "pointer" }}
                    >
                      {isPasswordVisible ? <BsEyeSlash /> : <BsEye />}
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    {/* <Link to="forget" className="text-decoration-none">
                      Forgot password?
                    </Link> */}
                    <Link to="/register" className="text-decoration-none">
                      Register
                    </Link>
                  </div>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary">Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
