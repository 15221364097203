import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Test Question - 1',
      content: (
        <div>
          <p className="task-mvp">Test Question - 1</p>
         
                  
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>
                    You are given an integer array arr. Sort the integers in the array in ascending order by the number of 1's in their binary representation and in case of two or more integers have the same number of 1's you have to sort them in ascending order.
<br/>
Return the array after sorting it.

<br/><br/>

Example 1:
<br/>
Input: arr = [0,1,2,3,4,5,6,7,8]
<br/>
Output: [0,1,2,4,8,3,5,6,7]
<br/>
Explantion: [0] is the only integer with 0 bits.
<br/>
[1,2,4,8] all have 1 bit.
<br/>
[3,5,6] have 2 bits.
<br/>
[7] has 3 bits.
<br/>
The sorted array by bits is [0,1,2,4,8,3,5,6,7]
<br/><br/>


Example 2:
<br/>
Input: arr = [1024,512,256,128,64,32,16,8,4,2,1]
<br/>
Output: [1,2,4,8,16,32,64,128,256,512,1024]
<br/>
Explantion: All integers have 1 bit in the binary representation, you should just sort them in ascending order.
<br/><br/>
 

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt; arr.length &lt; 500<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0 &lt; arr[i] &lt; 10^4<br/><br/>
                    </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Test Question - 2',
        content: (
          <div>
            <p className="task-mvp">Test Question - 2</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Watch the following video on 2D array :-<a href='https://www.youtube.com/embed/QiYPfbSDgZY' alt='uploading'>Click here</a> <br/><br/>
                      You are given an m x n integer grid accounts where accounts[i][j] is the amount of money the i​​​​​​​​​​​th​​​​ customer has in the j​​​​​​​​​​​th​​​​ bank. Return the wealth that the richest customer has.
<br/>
A customer's wealth is the amount of money they have in all their bank accounts. The richest customer is the customer that has the maximum wealth.
<br/><br/>

 

Example 1:
<br/>
Input: accounts = [[1,2,3],[3,2,1]]
<br/>
Output: 6
<br/>
Explanation:
<br/>
1st customer has wealth = 1 + 2 + 3 = 6
<br/>
2nd customer has wealth = 3 + 2 + 1 = 6
<br/>
Both customers are considered the richest with a wealth of 6 each, so return 6.

<br/><br/>

Example 2:
<br/>
Input: accounts = [[1,5],[7,3],[3,5]]
<br/>
Output: 10
<br/>
Explanation: 
<br/>
1st customer has wealth = 6
<br/>
2nd customer has wealth = 10 
<br/>
3rd customer has wealth = 8
<br/>
The 2nd customer is the richest with a wealth of 10.
<br/><br/>


Example 3:
<br/>
Input: accounts = [[2,8,7],[7,1,3],[1,9,5]]
<br/>
Output: 17
<br/><br/>
 

Constraints:
<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;m == accounts.length<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;n == accounts[i].length<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt; m, n &lt; 50<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt; accounts[i][j] &lt; 100<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 3',
        content: (
          <div>
            <p className="task-mvp">Test Question - 3</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      You have given a sorted array of how much sharpenerians is placed by sharpener in a month ,the number of sharpenerians placed can be duplicates
<br/>
You are given a number X which is the number of sharpenerians placed , it can have many duplicate X elements
<br/>
You have to Find the index of first and last occurance of X .
<br/><br/>


Solve it in LOG(N) Time Complexity

<br/><br/>

Example 1:
<br/>
Input:
<br/>
n=9, x=5
<br/>
arr[] = [ 1, 3, 5, 5, 5, 5, 67, 123, 125 ]
<br/>
Output: 2 5
<br/>
Explanation: First occurrence of 5 is at index 2 and last occurrence of 5 is at index 5.
<br/><br/>


Example 2:
<br/>
Input: 
<br/>
n=9, x=7
<br/>
arr[] = [ 1, 3, 5, 5, 5, 5, 7, 123, 125 ]
<br/>
Output: 6 6
<br/>
Explanation: First occurrence of 7 is at index 6 and last occurrence of 7 is also at index 6.

<br/><br/>

Constraints:
<br/>
1 ≤ N ≤ 107<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 4',
        content: (
          <div>
            <p className="task-mvp">Test Question - 4</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      Print the following pattern using for loop<br/>
                      <img src='https://images.shiksha.com/mediadata/images/articles/1701168808phptQvbe4.jpeg' alt='uploading'/><br/><br/>
                      (Solve the following question in the compiler, make sure all test case passes and then click submit.)<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 5',
        content: (
          <div>
            <p className="task-mvp">Test Question - 5</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      You are Building a website and for authentication there are some conditions for password ‘

<br/>
<br/>
1.All letters in this word are capitals, like "USA".
<br/>
2.All letters in this word are not capitals, like "sharpener".
<br/>
3.Only the first letter in this word is capital, like "Google".

<br/><br/>

Given a string password, return true if any on the above three rules in it.
<br/><br/>


Example 1:
<br/>
Input: word = "USA"
<br/>
Output: true

<br/><br/>

Example 2:
<br/>
Input: word = "FlaG"
<br/>
Output: false
<br/><br/>


Hint :- Use Logic of Ascii Value
<br/><br/>


&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 &lt; word.length &lt; 100<br/>
word consists of lowercase and uppercase English letters.<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 6',
        content: (
          <div>
            <p className="task-mvp">Test Question - 6</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p>
                      In a secret mission, Agent X is tasked with deciphering a hidden message encrypted within a string. The string is filled with various characters, but there's a catch - the message can only be extracted from the longest substring without any repeating characters. Agent X knows that time is of 
                      the essence, as the enemy is closing in. With their exceptional problem-solving skills, Agent X sets out on a thrilling adventure to find the length of the longest substring without repeating characters and unveil the hidden message within.

<br/>
<br/>
Input: "abcabcbb"
<br/>
Output: 3
<br/>
Explanation: The longest substring without repeating characters is "abc" with a length of 3.
<br/><br/>


Input: "abcbacdbb"
<br/>
Output: 4
<br/>
Explanation: The longest substring without repeating characters is "bacd" with a length of 4.

<br/><br/>

Input: "bbbbb"
<br/>
Output: 1
<br/>
Explanation: The longest substring without repeating characters is "b" with a length of 1.
<br/><br/>


Hint try to use Maps<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];

const Dsa2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 6) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/15'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 6</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Dsa2;