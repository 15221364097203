// import React, { useEffect, useState } from "react";
// import Model from "../../../../Model";
// import { useAuth } from "../../../../context/AuthContext";
// import { toast } from "react-toastify";
// import Axios from "../../../../Axios";
// import useFetch from "../../../../hooks/useFetch";
 

// const UpcomingSession = () => {
//   const [sessions, setSession] = useState([]);
//   const [auth] = useAuth();
//   const { data, error, loading } = useFetch("/meet");
//  useEffect(() => {
//     if (error) {
//       // Handle the error (display an error message, log it, etc.)
//       console.error("Error fetching data:", error);
//     }

//     if (data) {
//       setSession(data);
//     }
//   }, [data, error]);

//   if (loading) {
//     return <p>Loading...</p>; // You can replace this with a loading spinner or message
//   }
//   return (
//     <div className="upcoming bg-white shadow p-3 rounded">
//       <div className="d-flex justify-content-between">
//         <h6 className="fw-bold text-warning">Live Classes</h6>{" "}
//         {auth?.user?.role === "admin" && (
//           <Model val="Add Session" col="4" bg="white">
//             <CreateSession />
//           </Model>
//         )}
//       </div>
//       {sessions && sessions.length > 0
//        ? sessions.map((item) => (
//       <div className="d-flex my-3" key={item.id}>
//         <div className="d-flex ">
//           <img
//             style={{ width: "4rem" }}
//             src="/images/student.jpg"
//             alt=""
//           />
//         </div>
//         <div className="mx-2">
//           <h6 className="fw-bold">
//             <DateTimeDisplay timestamp={item?.date} />
//           </h6>
//           <p>
//             <strong>Session Type:</strong> {item?.type}
//           </p>
//           <p>
//             <strong>Hosted By:</strong> {item?.hostedBy}
//           </p>
//           <p>
//             <strong>Duration:</strong> {item?.duration} minutes
//           </p>
//           <p>
//             <strong>Description:</strong> {item?.description}
//           </p>
//           <small>Live+Doubt</small>
//         </div>
//         <div className="text-end ms-auto">
//           <button className="btn btn-outline-primary m-auto">
//             <a
//               className="text-decoration-none fw-bold text-dark"
//               href={item?.link}
//               target="_blank"
//               rel="noreferrer"
//             >
//               Join
//             </a>
//           </button>
//         </div>
//       </div>
//     ))
//      : (
//       <p>No upcoming sessions</p>
      
//     )}
//     </div>
//   );
// };

// export default UpcomingSession;

// const CreateSession = () => {
//   const [meet, setmeet] = useState();
//   const [auth] = useAuth();
//   const handleChange = (e) => {
//     setmeet((pre) => ({ ...pre, [e.target.name]: [e.target.value] }));
//   };
//   const hanldeSubmit = async (e) => {
//     try {
//       meet.userId = auth?.user?.id;
//       e.preventDefault();
//       const res = await Axios.post("/meet", meet);
//       console.log(res);
//       if (res.data && res.data.success) {
//         toast.success(res.data.message);
//       } else {
//         toast.error(res.data.message);
//       }
//     } catch (error) {
//       toast.error("something went wrong...");
//     }
//   };
//   return (
//     <>
//       <div className="m-auto">
//         <h4 className="my-3">Make Your Session</h4>
//         <form action="" method="post" onSubmit={hanldeSubmit}>
//           <div className="mb-3">
//             <input
//               className="form-control my-2"
//               type="datetime-local"
//               placeholder="Enter Meeting Timing"
//               name="date"
//               onChange={handleChange}
//             />
//           </div>
//           <div className="mb-3">
//             <input
//               className="form-control my-2"
//               type="url"
//               placeholder="Enter Meeting Link"
//               name="link"
//               onChange={handleChange}
//             />
//           </div>
//           <div className="text-center">
//             <button className="btn btn-primary">Submit</button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };
import Moment from "react-moment";
import React, { useState, useEffect } from 'react';
import SessionStatus from './SessionStatus';
import axios from 'axios';
import ReactPlayer from 'react-player';
import 'bootstrap/dist/css/bootstrap.min.css';



// src/components/LiveSession.js

const UpcomingSession = () => {
  const [isLive, setIsLive] = useState(true);

  useEffect(() => {
    // Simulate checking live status using Axios (replace with actual API call)
    const checkLiveStatus = async () => {
      try {
        const response = await axios.get('https://eduyog.in/placement/dashboard'); // Replace with your actual API endpoint
        setIsLive(response.data.isLive);
      } catch (error) {
        console.error('Error checking live status:', error);
      }
    };

    // Check live status on component mount
    checkLiveStatus();

    // Set up interval to check live status every 5 seconds
    const intervalId = setInterval(checkLiveStatus, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container"> {/* Add Bootstrap container class */}
      <h2 className="mb-2">Live Session Page</h2> {/* Add Bootstrap margin class */}
      <SessionStatus isLive={isLive} />

      {isLive && (
        <div>
          <h3 className="mt-2">Live Video Player</h3> {/* Add Bootstrap margin class */}
          <div style={{ borderRadius: "10px", overflow: "hidden" }}>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=wDIQ17T3sRk"
        controls={true}
        width="100%"
        height="220px"
      />
    </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingSession;


export const DateTimeDisplay = ({ timestamp }) => {
  const currentDate = new Date();
  const utcDate = new Date(timestamp);
  // console.log(utcDate.toLocaleTimeString());
  const isToday = utcDate.toDateString() === currentDate.toDateString();
  const isTomorrow =
    utcDate.toDateString() ===
    new Date(currentDate.getTime() + 24 * 60 * 60 * 1000).toDateString();
  const isEnd = utcDate < currentDate;

  let label = "";
  if (isToday) {
    label = "Today";
  } else if (isTomorrow) {
    label = "Tomorrow";
  } else if (isEnd) {
    label = "End";
  } else {
    label = <Moment format="D MMM YYYY">{timestamp}</Moment>;
  }
  return (
    <p>
      {label}{" "}
      <Moment format=" hh:mm A" local>
        {timestamp}
      </Moment>
    </p>
  );
};