import React, { useState } from 'react';
import { BsFillPatchQuestionFill } from "react-icons/bs";
import CNavbar from './CNavbar';

export default function Faqs() {
    const [activeButton, setActiveButton] = useState(1);

    let sh = "btn my-5 px-3 py-2 fw-bold btn-primary";

    const handleClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
    };

    const ans = [
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "What is EduYog?",
            head2: "EduYog is all about making learning fun and addictive. Our objective is that just like how games can get addictive, even learning should. In this process, you upskill and crack AMAZING job offers.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "What is Pay After Placement?",
            head2: "It is simple. Pay after you get a job. We have taken it one level deeper, ensuring you pay only after you start earning. This ensures you don’t hurt your pockets.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "Why EduYog and not other platforms?",
            head2: "Our testimonials speak for themselves. We are much much more affordable. We do NOT spoon feed students. Be ready to work hard! We have a 100% placement record for students that complete our courses.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "How can I trust you? Lot’s of scammy programs online!",
            head2: "I agree. Hence we allow you 14 days to explore our platform. It is also the period wherein we see if you are serious about the program. Only after these 14 days, if you qualify and are happy with us, you sign the ISA. Else don’t!"
        },
    ];

    const ans1 = [
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "Who is eligible for EduYog?",
            head2: "Anyone who is the final year of his bachelors or has already completed his graduation is permitted.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "Is there any work experience requirement?",
            head2: "Work experience is not a requirement from EduYog's side. Just focus on your skills!",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "So I can enroll and I get an ISA for 100% job guarantee?",
            head2: "No. Right from Day 1, you have to prove you are worth it. The first 14 days is the Qualification phase in which you have to score 4000 points. If you fail to do so, there is no ISA for you and you cannot proceed ahead. This also gives time for you to explore the platform without signing the ISA with us. We are totally transparent that way :)",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "So I just need to qualify and sign the ISA? That’s it?",
            head2: "Yes and no. That’s all there is to the agreement between us. However, your hard work has just started. What package you get totally depends on how hard you work. So make sure you keep pushing EACH and EVERY DAY."
        },
    ];

    const ans2 = [
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "Does EduYog give live classes?",
            head2: "Yes, we give live doubt sessions every day. The timings are displayed on your dashboard as soon as you get started. However, make sure you go through the resource materials before coming for the session. No spoon feeding! Try things on your own and discuss doubts live with mentors.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "How can I solve my doubts?",
            head2: "Attending classes is the best way. Of course, discussing with your peers is always fun. Group learning always helps. However, the main thing is for you to really battle the problem yourself first!",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "Is it mandatory to join doubt sessions?",
            head2: "Not at all! Attend doubt sessions only if you have doubts. If resource materials are good enough to solve the questions, go ahead with the entire course.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "How long is the course?",
            head2: "There are some approximations as you can see in the Course section. However, it all depends on how much effort you are putting. We will not consider you ready for placements till we feel you have completed the course with full honesty. Quality comes first for us."
        },
    ];

    const ans3 = [
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "What is an ISA?",
            head2: "ISA is a very common Pay after Placement product wherein one only has to pay for a product after they get a job. It stands for Income Share agreement.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "What is Pay After Placement?",
            head2: "It is simple. Pay after you get a job. We have taken it one level deeper, ensuring you pay only after you start earning. This ensures you don’t hurt your pockets.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "Why EduYog and not other platforms?",
            head2: "Our testimonials speak for themselves. We are much much more affordable. We do NOT spoon feed students. Be ready to work hard! We have a 100% placement record for students that complete our courses.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "How can I trust you? Lot’s of scammy programs online!",
            head2: "I agree. Hence we allow you 14 days to explore our platform. It is also the period wherein we see if you are serious about the program. Only after these 14 days, if you qualify and are happy with us, you sign the ISA. Else don’t!"
        },
    ];

    const ans4 = [
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "So if I work hard, I will surely get a job?",
            head2: "We have never failed to do so till date. We focus heavily on quality and as long as companies hire, EduYogians have it all set!",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "Do I have to take a job that you offer?",
            head2: "We will ask you if you are interested in a particular company before we refer you. But if you accept an interview but then reject proceeding with the interviews or reject the offer, that is a serious violation of our trust and we do not permit that. The ISA will be started in that case based on the offer.",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "So I decide my package?",
            head2: "Yes and no. You can have your expectations but obviously the company decides! Always remember that you can progress ahead all your life, but it is not good to stay jobless or without relevant work experience. Discuss with your EduYog mentors. They will guide you what you should expect. We would always want what’s best for you. After all, we charge you a percentage of your salary. Hence the more you earn, the better it is also for us!",
        },
        {
            photo: <BsFillPatchQuestionFill />,
            head1: "How long before I sit for placements?",
            head2: "Depends on the student. Work harder, smarter and faster, don’t skip assignments, don’t cheat. Once you clear the final mock interview we have, you are ready for placements. How long it takes varies from student to student."
        },
    ];

    return (
       <>
       <CNavbar/>
        <div className='F-01'>
            <h1 className='display-6 gradien fw-bold text-white animated-gradient F-0'>Frequently Asked Questions</h1>
            <div className='F-1'>
                <button className={`${sh} ${activeButton === 1 ? 'active' : ''}`} onClick={() => handleClick(1)} id='faq'>About EduYog</button>
                <button className={`${sh} ${activeButton === 2 ? 'active' : ''}`} onClick={() => handleClick(2)} id='faq'>Eligibility</button>
                <button className={`${sh} ${activeButton === 3 ? 'active' : ''}`} onClick={() => handleClick(3)} id='faq'>course Structure</button>
                <button className={`${sh} ${activeButton === 4 ? 'active' : ''}`} onClick={() => handleClick(4)} id='faq'>ISA & Payments</button>
                <button className={`${sh} ${activeButton === 5 ? 'active' : ''}`} onClick={() => handleClick(5)} id='faq'>Placements</button>
            </div>
            {/* Your conditional rendering based on activeButton */}
            {/* You can map over the appropriate 'ans' array based on the activeButton value */}
            {activeButton === 1 && (
                <>
                    {ans.map((item, index) => (
                        <div key={index} className='F-2'>
                            <div><p>{item.photo}</p></div>
                            <div>
                                <h4>{item.head1}</h4>
                                <p>{item.head2}</p>
                            </div>
                        </div>
                    ))}
                </>
            )}
            {
                activeButton===2 &&(
                    <>
                      {ans1.map((item, index) => (
                        <div key={index} className='F-2'>
                            <div><p>{item.photo}</p></div>
                            <div>
                            <h4>{item.head1}</h4>
                            <p>{item.head2}</p>
                            </div>
                        </div>
                    ))}
                    </>
                )
            }
            {
                activeButton===3 &&(
                    <>
                      {ans2.map((item, index) => (
                        <div key={index} className='F-2'>
                            <div><p>{item.photo}</p></div>
                            <div>
                            <h4>{item.head1}</h4>
                            <p>{item.head2}</p>
                            </div>
                        </div>
                    ))}
                    </>
                )
            }
            {
                activeButton===4 &&(
                    <>
                      {ans3.map((item, index) => (
                        <div key={index} className='F-2'>
                            <div><p>{item.photo}</p></div>
                            <div>
                            <h4>{item.head1}</h4>
                            <p>{item.head2}</p>
                            </div>
                        </div>
                    ))}
                    </>
                )
            }
            {
                activeButton===5 &&(
                    <>
                      {ans4.map((item, index) => (
                        <div key={index} className='F-2'>
                            <div><p>{item.photo}</p></div>
                            <div>
                            <h4>{item.head1}</h4>
                            <p>{item.head2}</p>
                            </div>
                        </div>
                    ))}
                    </>
                )
            }
        </div>
       </>
    );
}
