import { Button } from "react-bootstrap";
import image from "../images/image19.png";
import video from "../images/video3.mp4"; // Import your video file
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";

export default function AboutEduyog() {
  return (
    <section style={{ position: "relative", height: "100vh", overflow: "hidden" }}>
      <video autoPlay muted loop style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover", zIndex: 1 }}>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 0 }}></div>
      <div className="container" style={{ position: "relative", zIndex: 1 ,paddingTop: "11%"}}>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h2 className="display-4 fw-bold text-dark mb-4">About EduYog</h2>
            <p className="text-gray-700 mb-4">
             EduYog schedules video chat with mentors from top colleges. Get detailed information about:
            </p>
            <ul className="list-unstyled mb-4">
              <li className="d-flex align-items-center">
                <CheckCircleIcon className="text-green-500 mr-2" />
                <span className="text-gray-700">Major requirements, classes, and electives.</span>
              </li>
              <li className="d-flex align-items-center">
                <CheckCircleIcon className="text-green-500 mr-2" />
                <span className="text-gray-700">How to navigate office hours and working with TAs.</span>
              </li>
              <li className="d-flex align-items-center">
                <CheckCircleIcon className="text-green-500 mr-2" />
                <span className="text-gray-700"> Opportunities to support Professorial research projects.</span>
              </li>
              <li className="d-flex align-items-center">
                <CheckCircleIcon className="text-green-500 mr-2" />
                <span className="text-gray-700"> Internship and job opportunities both on and off campus.</span>
              </li>
            </ul>
            <Link to={'/aboutPage'}><Button variant="primary" className="px-5 py-2 rounded">About Us</Button></Link>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <img
              alt="Group of people"
              className="img-fluid rounded-lg shadow-lg"
              src={image}
              style={{
                aspectRatio: "600/400",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function CheckCircleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
      <polyline points="22 4 12 14.01 9 11.01" />
    </svg>
  );
}
