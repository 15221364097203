import React, { useState } from 'react';
import axios from 'axios';
import CNavbar from './CNavbar';

const JoinNow = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: '',
    passOut: '',
    percentage: '',
    examName: '',
    address: ''
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post('https://eduyog.in/users/counselling', formData);
      setSuccessMessage('User registered successfully. We will get back to you as soon as possible');
      setFormData({
        name: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        passOut: '',
        percentage: '',
        examName: '',
        address: ''
      });
    } catch (error) {
      setError(error.response.data || 'An error occurred. Please try again later.');
    }
  };

  return (
    <>
      <div className='counse' style={{ height: '100vh' }}>
      <CNavbar />
      <div className="container">
        <div className="row justify-content-center">
          <div className="container mt-2">
            <h1 className="text-center display-4 font-weight-bold text-dark fw-normal">
              EduYog Counselling Center
            </h1>
            <p className="text-center text-muted">Eduyog will support everywhere admission ke saath bhi admission ke baad bhi.</p>
          </div>
          <div className="col-lg-6 col-md-8 mt-4">
            <div className="card ">
              <h1 className="text-center ">Register</h1>
              {error && <p className="alert alert-danger">{error}</p>}
              {successMessage && <p className="alert alert-success">{successMessage}</p>}
              <form onSubmit={handleSubmit}>
                <TextInput
                  label="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <TextInput
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <TextInput
                  label="Phone Number"
                  name="phoneNumber"
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
                <TextInput
                  label="Date Of Birth"
                  name="dateOfBirth"
                  type="date"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  required
                />
                <TextInput
                  label="Full Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
                <TextInput
                  label="12th / 10th Passout"
                  name="passOut"
                  type="number"
                  value={formData.passOut}
                  onChange={handleChange}
                  required
                />
                <TextInput
                  label="Percentage"
                  name="percentage"
                  type="number"
                  value={formData.percentage}
                  onChange={handleChange}
                  required
                />
                <TextInput
                  label="Exam Name"
                  name="examName"
                  value={formData.examName}
                  onChange={handleChange}
                  required
                />
                <button type="submit" className="btn btn-primary w-100 ">Book Now</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

const TextInput = ({ label, name, type, value, onChange, required }) => (
  <div className="mb-3">
    <input
      type={type || 'text'}
      className="form-control"
      placeholder={label}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
    />
  </div>
);

export default JoinNow;
