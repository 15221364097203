import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import { Link } from 'react-router-dom';


const AdvanceJS1 = () => {

  const assignments = [
    { id: 1, name: 'Secret Password for Discord', status: 'start'},
    { id: 2, name: 'The Execution Context of Javascript', status: 'start'},
    { id: 3, name: 'Stepping into the shoes of an Interviewer', status: 'start'},
    { id: 4, name: 'Hoisting in Javascript', status: 'start'},
    { id: 5, name: 'Windows Object, this Keyword lexical scoping', status: 'start'},
    { id: 6, name: 'Let , Var and Const	', status: 'start'},
    { id: 7, name: 'Closures, Settimeout, ES6 features	', status: 'start'},
    { id: 8, name: 'Block scope and Shadowing in JS	', status: 'start'},
    { id: 9, name: 'Closures (99 % interviewers ask this)', status: 'start'},
    { id: 10, name: 'Call Apply and Bind', status: 'start'},
    { id: 11, name: 'Advanced Closures', status: 'start'},
    { id: 12, name: 'Functions', status: 'start'},
    { id: 13, name: 'Design Pattern (The "This" Keyword)', status: 'start'},
    { id: 14, name: 'CallBack Function', status: 'start'},
    { id: 15, name: 'Fat Arrow Functions', status: 'start'},
    { id: 16, name: 'The wierd mystery', status: 'start'},
    { id: 17, name: 'Event Loop', status: 'start'},
    { id: 18, name: 'Searching student of particular age', status: 'start'},
    { id: 19, name: 'Application of event loop and spread operators', status: 'start'},  
    { id: 20, name: 'Iterating through Objects', status: 'start'},
  ];


  return (
    <>
     <div className='plac1'><PlacementNavbar/></div>
      <div className='page1'>
      <div>
        <Sidebar/>
      </div>
      <div>
      <div className="assignment-foj">
      <div className="rig-phw">
        <div className="fle-fok justify-6c5 item-ycb orqly">
          <Link to='/assignment/17'>
          <button className="btn-zle bvadh">Next Assignment</button>
          </Link>
          
        </div>
        <div className="right-c1e">
          <table>
           
            <colgroup>
              {Array(4).fill().map((_, index) => <col key={index} className={`style-${index}EMdC`} />)}
            </colgroup>
            <thead>
              <tr>
                <th className="style-q61Z3">#</th>
                <th>Name</th>
                <th className="style-6q7nt">Status</th>
                <th className='sh'></th>
              </tr>
            </thead>
            <tbody className="style-iNhB2">
              {assignments.map(({ id, name, status, points }, index) => (
                <tr key={id} className="style-GbKIa">
                  <td className="style-CQJlN">{id}</td>
                  <td>
                    {name}
                  </td>
                  <td className="style-mF9jW">
                  <Link to={`/AdvanceJS2/${index + 1}`} className='style-ckn'>{status}</Link>
                  </td>
                  <td className="style-hsgX7">{points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default AdvanceJS1;