import React, { useState, useEffect } from "react";
import Gallery from "./Gallery";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import image4 from "../../video/image4.jpg"
import image5 from "../../video/image5.jpg"
import image6 from "../../video/image6.jpg"
import image7 from "../../video/image7.jpg"
import image8 from "../../video/image8.jpg"
import image9 from "../../video/image9.jpeg"
import image10 from "../../video/image10.jpeg"
import image11 from "../../video/image11.jpeg"
import image12 from "../../video/image12.jpeg"
import image13 from "../../video/image13.jpeg"
import image14 from "../../video/image14.jpeg"
import image15 from "../../video/image15.jpeg"
import image16 from "../../video/image16.jpeg"
import image17 from "../../video/image17.jpeg"
import "./Placement.css";

const Qualification = () => {
  const images = [
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    // Automatically move to the next image every 5 seconds
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    // Clear the interval when the component is unmounted or the images change
    return () => clearInterval(intervalId);
  }, [images]);

  return (
    <div>
      <h2> Image Gallery</h2>
      <Gallery images={images} currentIndex={currentImageIndex} />
      <div className="navigation-buttons">
        <button onClick={handlePrevImage} disabled={currentImageIndex === 0}>
        <FaLongArrowAltLeft/>
        </button>
        <button onClick={handleNextImage} disabled={currentImageIndex === images.length - 1}>
          <FaLongArrowAltRight/>
        </button>
      </div>
    </div>
  );
};

export default Qualification;





export const Placement = () => {
  const studentsData = [
    { name: "Vainki nagaswami", company: "connect and heals", photoUrl: "https://i.postimg.cc/4x324CzZ/image1.jpg" },
    { name: "Ritu Singh", company: "Nagarro", photoUrl: "https://i.postimg.cc/25s0xVg8/image2.jpg" },
    { name: "Aditya", company: "Connect & heals", photoUrl: "https://i.postimg.cc/GtQzrztH/image3.jpg" },
    { name: "Abhishek kumar", company: "connect & heals", photoUrl: "https://i.postimg.cc/K84QSP28/image4.jpg" },
    { name: "Abhishek Sharma", company: "cisco", photoUrl: "https://i.postimg.cc/MK9tFsgL/image5.jpg" },
    { name: "Annu Priya", company: "TIBCO Software India Pvt. Ltd", photoUrl: "https://i.postimg.cc/k5bcD7Dr/image6.jpg" },
    { name: "Rishav", company: "TIBCO Software India Pvt. Ltd", photoUrl: "https://i.postimg.cc/T3S0RKdq/image7.jpg" },
    { name: "Nitish Raman", company: "cisco", photoUrl: "https://i.postimg.cc/pTMBF3nm/image8.jpg" },
    { name: "Parth yadhav", company: "intellect", photoUrl: "https://i.postimg.cc/5N4npcKv/image9.jpg" },
    { name: "Sidharth Pandey", company: "tech Mahindra", photoUrl: "https://i.postimg.cc/Pf4MC3fq/image10.jpg" },
    { name: "Nayub anshari", company: "tech Mahindra", photoUrl: "https://i.postimg.cc/KjZr9LfF/image11.jpg" },
    { name: "Nikash malik", company: "cisco", photoUrl: "https://i.postimg.cc/FRbVgGjD/image12.jpg" },
    { name: "Santosh Mishra", company: "atos syntel", photoUrl: "https://i.postimg.cc/6qYVF3bZ/image13.jpg" },
    // Add more student data as needed
  ];

 
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % studentsData.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [studentsData]);

  const handlePrevStudent = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + studentsData.length) % studentsData.length);
  };

  const handleNextStudent = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % studentsData.length);
  };

  return (
    <div>
      <h3>Placed Students</h3>
      <div className="placement-container">
        <div className="placement-card">
          <img src={studentsData[currentIndex].photoUrl} alt={studentsData[currentIndex].name} className="placement-photo" />
          <div className="placement-details">
            <p className="placement-name">{studentsData[currentIndex].name}</p>
            <p className="placement-company">{studentsData[currentIndex].company}</p>
          </div>
        </div>
      </div>
      <div className="placement-buttons">
        <button onClick={handlePrevStudent}>
          <FaLongArrowAltLeft />
        </button>
        <button onClick={handleNextStudent}>
          <FaLongArrowAltRight />
        </button>
      </div>
    </div>
  );
};



export const EventComp = () => {
  return (
    <>
      <div className="testmonials my-4 bg-white shadow p-3 rounded">
        <h6 className="fw-bold text-warning">Event</h6>
        <div className="d-flex">
          <p className="fs-5 col-4">
            <div className="">
              <img className="w-100" src="/images/student.jpg" alt="" />
            </div>
          </p>
          <div className="prograss m-auto">
            <p>
              Meet our boy Rajkumar who prepared so well that he cracked a super
              dream offer in his very first Interview.Meet him on 21st October
            </p>
          </div>
        </div>
        <div className="text-center">
          <p className=" fw-bold ">
           EduYogians never miss on a super dream offer
          </p>
        </div>
      </div>
    </>
  );
};
