import React, { useState } from "react";
import Layout from "../../component/Layout";
import { Link } from "react-router-dom";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { toast } from "react-toastify";
import Axios from "../../Axios";

const Register = () => {
  const [isPass, setPass] = useState(false);
  const [iscPass, setcPass] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    cnfpass: ""
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await Axios.post("https://eduyog.in/auth", user);
      console.log(res);
      if (res.data && res.data.success) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong...");
    }
  };

  return (
    <Layout>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card shadow p-4">
              <h3 className="text-center mb-4">Register</h3>
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <div className="input-group">
                    <input
                      type={isPass ? "text" : "password"}
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                      className="form-control border-end-0"
                      placeholder="Password"
                      required
                    />
                    <span
                      className="input-group-text bg-transparent border-start-0"
                      onClick={() => setPass(!isPass)}
                    >
                      {isPass ? <BsEyeSlash /> : <BsEye />}
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="input-group">
                    <input
                      type={iscPass ? "text" : "password"}
                      name="cnfpass"
                      value={user.cnfpass}
                      onChange={handleChange}
                      className="form-control border-end-0"
                      placeholder="Confirm Password"
                      required
                    />
                    <span
                      className="input-group-text bg-transparent border-start-0"
                      onClick={() => setcPass(!iscPass)}
                    >
                      {iscPass ? <BsEyeSlash /> : <BsEye />}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  {/* <Link to="forget" className="text-decoration-none">
                    Forgot password?
                  </Link> */}
                  <Link to="/login" className="text-decoration-none">
                    Login
                  </Link>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary fw-bold">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
