import React, { useState } from 'react';
import axios from 'axios';
import CNavbar from './CNavbar';

const PapRegister = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [education, setEducation] = useState('');
  const [preferredCourse, setPreferredCourse] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
      const response = await axios.post('https://eduyog.in/users/pap', {
        name,
        email,
        phoneNumber,
        education,
        preferredCourse
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage('Thank you for registering! We will contact you soon.');
        // Reset the form fields
        setName('');
        setEmail('');
        setPhoneNumber('');
        setEducation('');
        setPreferredCourse('');
      } else {
        setError('Unexpected response from server.');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setError(error.response.data.message || 'User already registered.');
        } else if (error.response.status === 500) {
          setError('Server error. Please try again later.');
        } else {
          setError('An unexpected error occurred. Please try again later.');
        }
      } else {
        setError('No response from the server. Please check your network connection.');
      }
    }
  };

  return (
    <>
      <div className='counse' style={{ height: "100vh" }}>
        <CNavbar />
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="card p-4 shadow-lg">
                <h1 className="text-center mb-4 display-4 fw-normal">Join Our Pay After Placement Program</h1>
                {error && <p className="alert alert-danger">{error}</p>}
                {successMessage && <p className="alert alert-success">{successMessage}</p>}
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <input type="tel" className="form-control" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <input type="text" className="form-control" placeholder="Educational Background" value={education} onChange={(e) => setEducation(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <select className="form-control" value={preferredCourse} onChange={(e) => setPreferredCourse(e.target.value)} required>
                      <option value="">Select Preferred Course</option>
                      <option value="Data Science">Data Science</option>
                      <option value="Full Stack Development">Full Stack Development</option>
                      <option value="Python Programming">Python Programming</option>
                      <option value="Java Programming">Java Programming</option>
                      <option value="CPP Programming">CPP Programming</option>
                      <option value="JS Programming">JavaScript Programming</option>
                      <option value="Other Programming">Other*</option>
                    </select>
                  </div>
                  <button type="submit" className="btn btn-primary w-100">Register Now</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PapRegister;
