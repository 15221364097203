import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Video - Linked List from Scratch',
      content: (
        <div>
          <p className="task-mvp">Video - Linked List from Scratch</p>
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p>
                    C++ :-
<br/>
In order to have a deep understand of how things works in C++ :-
<br/>
First Step is to understand Pointers :-
<br/>
Watch  Videos on pointers from this playlist:-<a href="https://www.youtube.com/watch?v=h-HBipu_1P0&list=PL2_aWCzGMAwLZp6LMUKI3cc7pgGsasm2_" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Watch the following video to understand how linked List works:-
<br/>
Understand how to create linked List in C++ --<a href="https://youtu.be/Crqgl10aIGQ?si=8el_ZvG54kP_hNAa" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Understand how to solve and visualize some of the basic functions in LinkedLists:-<a href="https://youtu.be/R9PTBwOzceo?si=QJSdz0Q-Yb_GzmZu" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Inserting a Node at starting :-<a href="https://youtu.be/jgqg6Qw68_Q?si=oMRQwRiLBZadbVfu" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Inserting at Nth Position :-<a href="https://youtu.be/IbvsNF22Ud0?si=7z7k1x4XjzUQztQ9" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Deleting a Node at nth position :-<a href="https://youtu.be/Lhu3MsXZy-Q?si=Eu3nEyNCNDnCC8hZ" target='_blank'>
                            &nbsp;Click here
                          </a>
                          <br/><br/>

Java :-<br/>

Linked List Basic Operations(Creation, Insertion) -<a href="https://youtu.be/SMIq13-FZSE?si=jMchNsBr_tqFEom2" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Linked List Basic Operations(Deletion) -<a href="https://youtu.be/AeqXFjCUcQM?si=T8jv7rkMM4N-dPNi" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Understand the Basics of Linked_List :-<a href="https://youtu.be/f_AZDdafncE?si=EwAU97VOgK6hs-20" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Using a direct implementation :-<a href="https://youtu.be/fptlqsesjxY?si=734xyHv8icGiS9se" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
linked list and arraylist difference:-<a href="https://youtu.be/5dscMs2hnDI?si=sp2IKClgy5HxewIU" target='_blank'>
                            &nbsp;Click here
                          </a>

                          <br/><br/>


Python :-
<br/>
Linked List Basic Operations(Creation, Insertion) -<a href="https://youtu.be/B-zO18TJKYQ?si=HG-zxi0nFqvFhxYC" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Linked List Basic Operations(Deletion) -<a href="https://youtu.be/o8tWJCFWEPU?si=AVf9pannZ0_J001f" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Understand the Basics of Linked_List :-<a href="https://youtu.be/xRTdfZsAz6Y?si=6QtXwURzCqWgJyXk" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Important - Understand how to solve and visualize some of the basic functions in LinkedLists:-<a href="https://youtu.be/xRTdfZsAz6Y?si=KUy-CPIC7VAbynlQ" target='_blank'>
                            &nbsp;Click here
                          </a>

                          <br/><br/>
Javascript:-
<br/>
Linked List Basic Operations(Creation, Insertion) -<a href="https://youtu.be/ZBdE8DElQQU?si=zQWAhKScl_5_qjSX" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Linked List Basic Operations(Deletion) -<a href="https://youtu.be/D_kWagEfcx8?si=GWcWtXsCr9_WLNp4" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Understand the Basics of Linked_List :-<a href="https://youtu.be/3OsxH-huRc4?si=M-KR3PIS_uy91Jnb" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
Understand how to solve and visualize some of the basic functions in LinkedLists:-<a href="https://youtu.be/cob_kM0PJMo?si=UdA2TuQEd9_l3qAq" target='_blank'>
                            &nbsp;Click here
                          </a><br/>
                          <br/><br/>
                      </p>  
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Videos - Famous Interview Questions',
        content: (
          <div>
            <p className="task-mvp">Videos - Famous Interview Questions</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Watch Video tutorial (12 videos) on Linked List - <a href="https://youtu.be/Rs1KPyb9fHY?list=PLeIMaH7i8JDio7glJoO1rQIAo4g1msRRG" target='_blank'>
                            &nbsp;Click here
                          </a> (Just try to understand the logic and don't look into the code):- 


                       <br/>
<br/>
After Watching the video click on submit any doubts/confusion ask your mentor.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Remove a Node',
        content: (
          <div>
            <p className="task-mvp">Remove a Node</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/remove-linked-list-elements/description/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Deletion of a node',
        content: (
          <div>
            <p className="task-mvp">Deletion of a node</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/delete-node-in-a-linked-list/description/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Insertion of 2 Linked List',
        content: (
          <div>
            <p className="task-mvp">Insertion of 2 Linked List</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/intersection-of-two-linked-lists/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Reverse Linked List (Iterative)',
        content: (
          <div>
            <p className="task-mvp">Reverse Linked List (Iterative)</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/reverse-linked-list/description/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Is palindrome',
        content: (
          <div>
            <p className="task-mvp">Is palindrome</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/palindrome-linked-list/description/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Rotate List',
        content: (
          <div>
            <p className="task-mvp">Rotate List</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/rotate-list/description/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Merge Linked List',
        content: (
          <div>
            <p className="task-mvp">Merge Linked List</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/merge-two-sorted-lists/description/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Add two LinkedList (Important)',
        content: (
          <div>
            <p className="task-mvp">Add two LinkedList (Important)</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/add-two-numbers/description/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Remove nth Node from end',
        content: (
          <div>
            <p className="task-mvp">Remove nth Node from end</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/remove-nth-node-from-end-of-list/description/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Linked List Cycle(Important)',
        content: (
          <div>
            <p className="task-mvp">Linked List Cycle(Important)</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question on leetcode:- 
<a href="https://leetcode.com/problems/linked-list-cycle-ii/description/" target='_blank'>
                            &nbsp;Click here
                          </a> 
                          <br/>
                          <br/>
                          Important) Detection of a cycle in a linked list and calculating the starting point<a href="https://youtu.be/-YiQZi3mLq0" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
<br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Video - Fundamentals on Recursion',
        content: (
          <div>
            <p className="task-mvp">Video - Fundamentals on Recursion</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       (Very Important) Go through the entire video to understand about recursion and its basis of working :- <a href="https://youtu.be/Mr9MVpSoTGk?si=iOBLbbGE3iFCE-Iq" target='_blank'>
                            &nbsp;Click here
                          </a> 
<br/>
<br/>


After Watching the click on Submit. If there is any confusion ask your mentor and get it resolved.
<br/><br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Iterative to Recursion',
        content: (
          <div>
            <p className="task-mvp">Iterative to Recursion</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Write a program to convert the following program in bold into its recursive format.
                       <br/><br/>


Iterative Code :-
<br/>
int sum =0;
<br/>

int n=5;  (you can take any number manually)
<br/>
for(int i=0;i&lt;10;i=i+2)
<br/>
&#123;
  <br/>
if(i==n)
<br/>
&#123;
  <br/>
break;
<br/>
}
<br/>
sum =sum+i;
<br/>
}
<br/>
System.out.println(sum);
<br/><br/>


Convert it into recursive format and copy paste your answer here

<br/><br/>

Write Down the Entire Code for the above Question in your IDE and copy paste the code here.

<br/><br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 


<br/><br/>
If there is any confusion make sure to ask the mentor and get it resolved ASAP. 
<br/><br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Stack Sequence Recursion',
        content: (
          <div>
            <p className="task-mvp">Stack Sequence Recursion</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Write down the stack sequence(Given in comment) and what will be output .. Note- don't run it in your compiler :)

<br/>



<br/>
    

  count(1); //calling the function count

  <br/>
<br/>




  function count(int i) //count function

  <br/>
  &#123;

  <br/>
    if(i>5)

    <br/>
    &#123;

    <br/>
 //Print the elements inside the stack from top to bottom 

 <br/>
      return 0;

      <br/>
      &#125;
<br/>
    else 
<br/>
    &#123;

    <br/>
      return count(i+1);

      <br/>
      &#125;

      <br/>
      &#125;

      <br/>
      &#125;


      <br/>
<br/>





Since Recursion is a very important Topic this task will be checked Manually by the mentor. 


<br/>
<br/>

If there is any confusion make sure to ask the mentor and get it resolved ASAP. 
<br/><br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Output of Recursion -1',
        content: (
          <div>
            <p className="task-mvp">Output of Recursion -1</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       What will be the output :-

<br/>



<br/>
    

print(count(1)); //function call print the output that you get

  <br/>
<br/>




function count(int i) //this is the function

  <br/>
  &#123;

  <br/>
    if(i>5)

    <br/>
    &#123;

    <br/>
      return 0;

      <br/>
      &#125;
<br/>
    else 
<br/>
    &#123;

    <br/>
    return i*count(i+1);

      <br/>
      &#125;

      <br/>
      &#125;

      <br/>
      &#125;


      <br/>
<br/>



What will be the output ?
<br/>
<br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 


<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>
Output:- 0
<br/><br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Output of Recursion -2',
        content: (
          <div>
            <p className="task-mvp">Output of Recursion -2</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       What will be the output :-

<br/>



<br/>
    

print(count(1)); //function call print the output that you get

  <br/>
<br/>




function count(int i) //this is the function

  <br/>
  &#123;

  <br/>
    if(i==5)

    <br/>
    &#123;

    <br/>
      return 0;

      <br/>
      &#125;
<br/>
    else 
<br/>
    &#123;

    <br/>
    return i+count(i+3);

      <br/>
      &#125;

      <br/>
      &#125;

      <br/>
      &#125;


      <br/>
<br/>



What will be the output ?
<br/>
<br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 


<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>
Output:- INFINITY
<br/><br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Output of Recursion -3',
        content: (
          <div>
            <p className="task-mvp">Output of Recursion -3</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       What will be the output :-

<br/>



<br/>
    

print(count(1)); //function call print the output that you get

  <br/>
<br/>




function count(int i) //this is the function

  <br/>
  &#123;

  <br/>
    if(i==6)

    <br/>
    &#123;

    <br/>
      return ;

      <br/>
      &#125;
<br/>
    else 
<br/>
    &#123;

    <br/>
    print(i);
<br/>
      count(i+1);
      <br/>
      &#125;

      <br/>
      &#125;

      <br/>
      &#125;


      <br/>
<br/>



What will be the output ?
<br/>
<br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 


<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>
Output:- 1 2 3 4 5
<br/><br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Output of Recursion -4',
        content: (
          <div>
            <p className="task-mvp">Output of Recursion -4</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       What will be the output :-

<br/>



<br/>
    

print(count(1)); //function call print the output that you get

  <br/>
<br/>




function count(int i) //this is the function

  <br/>
  &#123;

  <br/>
    if(i==6)

    <br/>
    &#123;

    <br/>
      return ;

      <br/>
      &#125;
<br/>
    else 
<br/>
    &#123;

    <br/>
    
      count(i+1);
      <br/>
      print(i);
      <br/>
      &#125;

      <br/>
      &#125;

      <br/>
      &#125;


      <br/>
<br/>



What will be the output ?
<br/>
<br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 


<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>
Output:- 5 4 3 2 1
<br/><br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Output of Recursion -5',
        content: (
          <div>
            <p className="task-mvp">Output of Recursion -5</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       What will be the output :-

<br/>



<br/>
    

print(count(1)); //function call print the output that you get

  <br/>
<br/>




function count(int i) //this is the function

  <br/>
  &#123;

  <br/>
    if(i==6)

    <br/>
    &#123;

    <br/>
      return ;

      <br/>
      &#125;
<br/>
    else 
<br/>
    &#123;

    <br/>
    print(i);
    <br/>
      count(i+1);
      <br/>
      print(i);
      <br/>
      &#125;

      <br/>
      &#125;

      <br/>
      &#125;


      <br/>
<br/>



What will be the output ?
<br/>
<br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 


<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>
Output:- 1 2 3 4 5 5 4 3 2 1
<br/><br/>
                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Multiples of 5(Recursive)',
        content: (
          <div>
            <p className="task-mvp">Multiples of 5(Recursive)</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Write a question to print all the multiples of 5 till n in a recursive way.
                       <br/><br/>


output - 0 5 10 15 20 25 ... to n


<br/><br/>


Write Down the Entire Code for the above Question in your IDE and copy paste the code here.

<br/><br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 


<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Sum of N natural Numbers (Recursive)',
        content: (
          <div>
            <p className="task-mvp">Sum of N natural Numbers (Recursive)</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Write a function which will take the input n from the user and return back an integer which will contain the sum of n natural number .



                       <br/><br/>

Example input n=5

<br/><br/>

return 1+2+3+4+5 = 15 will be returned back from the function


<br/><br/>


Write Down the Entire Code for the above Question in your IDE and copy paste the code here.

<br/><br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 

<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Factorial in Recursion',
        content: (
          <div>
            <p className="task-mvp">Factorial in Recursion</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Write a program to write a function factorial which takes an input n and prints the factorial of the number in a recursive way
<br/>
Example :- n=5;
<br/>
Output = 1*2*3*4*5 = 120
<br/><br/>


Write Down the Entire Code for the above Question in your IDE and copy paste the code here.

<br/><br/>

Watch the hint video if you are having any confusion.


<br/><br/>
Since Recursion is a very important Topic this task will be checked Manually by the mentor. 

<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Video :- Recursion Visualization',
        content: (
          <div>
            <p className="task-mvp">Video :- Recursion Visualization</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Watch the following Video on Recursion. It will help you visualize the state transitioning better :- 
                      
                       <a href=" https://youtu.be/mFb1Fj4sVcU?si=JDA96-cpArFWweyO" target='_blank'>
                            &nbsp;Click here
                          </a> 
                       <br/>
                       <br/>
After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Fibonacci Number',
        content: (
          <div>
            <p className="task-mvp">Fibonacci Number</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Solve the following Question:-

                       <br/><br/>

The Fibonacci numbers, commonly denoted F(n) form a sequence, called the Fibonacci sequence, such that each number is the sum of the two preceding ones, starting from 0 and 1. That is,
<br/>
F(0) = 0, F(1) = 1
<br/>
F(n) = F(n - 1) + F(n - 2), for n &gt; 1.
<br/>
Given n, calculate F(n).

<br/><br/>

Example 1:
<br/>
Input: n = 2
<br/>
Output: 1
<br/>
Explanation: F(2) = F(1) + F(0) = 1 + 0 = 1.
<br/><br/>


Example 2:
<br/>
Input: n = 3
<br/>
Output: 2
<br/>
Explanation: F(3) = F(2) + F(1) = 1 + 1 = 2.

<br/><br/>

Example 3:
<br/>
Input: n = 4
<br/>
Output: 3
<br/>
Explanation: F(4) = F(3) + F(2) = 2 + 1 = 3.

<br/><br/>

Constraints:
<br/>
0 &lt;= n &lt;= 30
<br/><br/>



Watch the hint video if you are having any confusion.


<br/><br/>
Since Recursion is a very important Topic this task will be checked Manually by the mentor. 
<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Permutation (Important)',
        content: (
          <div>
            <p className="task-mvp">Permutation (Important)</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Write a recursive program to find all the permutations of a string and store it in a list or arraylist or vectors.
                       <br/>
Example:-
<br/>
Permutation for abc
<br/>
abc,acb, bac, bca, cab ,cba

<br/><br/>

Write Down the Entire Code for the above Question in your IDE and copy paste the code here.
<br/><br/>


Watch the hint video if you are having any confusion.

<br/><br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 



<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Subsequence (Important)',
        content: (
          <div>
            <p className="task-mvp">Subsequence (Important)</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Write a program to print all the subsequence of an array.
                       <br/>
All subsequence of an array [1,2,3]
<br/>
[1]
<br/>
[1,2]
<br/>
[1,2,3]
<br/>
[1,3]
<br/>
[2]
<br/>
[2,3]
<br/>
[3]

<br/><br/>

Write Down the Entire Code for the above Question in your IDE and copy paste the code here.


<br/><br/>
Watch the hint video if you are having any confusion.

<br/><br/>

Since Recursion is a very important Topic this task will be checked Manually by the mentor. 
<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Merge Sort (Important)',
        content: (
          <div>
            <p className="task-mvp">Merge Sort (Important)</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Write a program to sort the array using merge sort algorithm in Leetcode :-


                       <br/>

<a href="https://leetcode.com/problems/sort-an-array/" target='_blank'>
                            &nbsp;Click here
                          </a> 

                          <br/><br/>
After solving the question on leetcode copy paste your answer here in the dashboard.


<br/><br/>
Watch the hint video if you are having any confusion.


<br/><br/>
Since Recursion is a very important Topic this task will be checked Manually by the mentor. 


<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Tim sort video',
        content: (
          <div>
            <p className="task-mvp">Tim sort video</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Watch this video on tim sort :-


                       <br/>

<a href="https://youtu.be/emeME__917E" target='_blank'>
                            &nbsp;Click here
                          </a> 
                          <br/>
                          <a href="https://youtu.be/6DOhQyqAAvU" target='_blank'>
                            &nbsp;Click here
                          </a> 

                          <br/><br/>

<br/>
<br/>

After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Comparator Videos',
        content: (
          <div>
            <p className="task-mvp">Comparator Videos</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Watch the following videos to understand about comparators :-
                       <br/>

                       <br/>
Java - Watch 2 videos on comparator
<br/>
<a href="https://youtu.be/0xrhqxTOphI" target='_blank'>
                            &nbsp;Click here
                          </a> 
<br/>
<a href="https://youtu.be/c8bghFb_WrA" target='_blank'>
                            &nbsp;Click here
                          </a> 
<br/>
Comparable in JAVA<br/>
<a href="https://www.youtube.com/watch?v=oAp4GYprVHM" target='_blank'>
                            &nbsp;Click here
                          </a> 

                          <br/>

C++ (After 35 minutes)<br/>
<a href="https://www.youtube.com/watch?v=zBhVZzi5RdU" target='_blank'>
                            &nbsp;Click here
                          </a> 

                          <br/><br/>

JavaScript:-


<a href="https://www.youtube.com/watch?v=kxUNQtheCxM" target='_blank'>
                            &nbsp;Click here
                          </a> <br/><br/>
Python :- read this article -<a href="https://medium.com/voice-of-code/sorting-comparator-21d98c192bbb" target='_blank'>
                            &nbsp;Click here
                          </a>  
                          <br/><br/>



After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Question using Comparators',
        content: (
          <div>
            <p className="task-mvp">Question using Comparators</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       Write a Program to create a Class Employee
                       <br/>
It contains two attributes --&gt; Name, Salary
<br/>
Create 5 objects and initialize any random values for Name and Salary to it.
<br/>
Store all these objects in an Array and print the names of the employees sorted in Descending Order using Comparators (Use Salary to sort the elements).



<br/><br/>

Write Down the Entire Code for the above Question in your IDE and copy paste the code here.


<br/><br/>
Since OOPS is a very important Topic this task will be checked Manually by the mentor during the mock interview ::). 


                          <br/><br/>



After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Practice DSA Questions Sheet-2',
        content: (
          <div>
            <p className="task-mvp">Practice DSA Questions Sheet-2</p>
            
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                       <p>
                       
                       I have created an excel sheet which contains some set of questions for practice.

Try to solve 1-2 Questions every day to master the topics already solved.

Link - 
<a href="https://docs.google.com/spreadsheets/d/1PbCBCqc-r3EMAmlhmBfyD9JZqnzCf-1YOJbSI3dr0DY/edit?usp=sharing" target='_blank'>
                            &nbsp;Click here
                          </a>  
                          <br/><br/>



After Solving the question copy paste your code here and click on submit or if you have any doubt then ask your mentor directly on Whatsapp.
<br/>
<br/>

                       </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];

const Linked2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 32) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/11'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 32</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Linked2;
