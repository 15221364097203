import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
     <div className="col-12 rounded-top-4 bg-white border border-secondary">
        <div className="container ">
          <div className="row row-cols-md-4 row-cols-sm-2 text-dark">
            {footerData.map((fitem, fkey) => (
              <div key={fkey} className={`my-2 col-g${fkey + 1}`}>
                <h4>{fitem.h1}</h4>
                <ul>
                  {fitem.children.map((fchild, chikey) => (
                    <li key={chikey}>{fchild.li}</li>
                  ))}
                </ul>
              </div>
            ))}
            
            <div className="container mt-5">
              <div className="row">
                <div className="col-lg-12 col-12 text-dark">
                  Copyright © 2024 All rights reserved.
                  <span className="bg-theme"></span>
                </div>
                <b style={{ textAlign: "center" }}>EduYog</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

export const footerData = [
  {
    h1: "About company",
    children: [
      // { li: <Link className="text-decoration-none" to="/about">About BIDINN</Link> },
      {
        li: (
          <Link className="text-decoration-none text-dark" to="/aboutPage">
            About Us
          </Link>
        ),
      },
     
      {
        li: (
         
          <a href="mailto:eduyogtechno@eduyog.in" className="text-dark">eduyogtechno@eduyog.in</a>

   
        ),
      },
    ],
  },
  {
    h1: "Help & Support",
    children: [
      {
        li: (
          <Link className="text-decoration-none text-dark" to="/faq">
            FAQs
          </Link>
        ),
      },
      {
        li: (
          <Link className="text-decoration-none text-dark" to="/terms">
            Terms & Conditions
          </Link>
        ),
      },
      {
        li: (
          <Link className="text-decoration-none text-dark" to="/refunds">
            {" "}
            Paymment & Cancellation{" "}
          </Link>
        ),
      },
      {
        li: (
          <Link className="text-decoration-none text-dark" to="/contactus">
           Contact Us
          </Link>
        ),
      },
    ],
  },
  {
    h1: "Follow Us",
    children: [
      {
        li: (
          <>
            <a
              className="text-decoration-none text-dark"
              href="https://api.whatsapp.com/send?phone=917295993480"
              target="_blank"
              rel="noopener noreferrer"
            >
              Whatsapp
            </a>
          </>
        ),
      },
      {
        li: (
          <>
            <a
              className="text-decoration-none text-dark"
              href="https://youtube.com/@eduyog?si=7h92_8uTxU1Dw9ag"
              rel="noreferrer noopener"
              target="_blank"
            >
              YouTube
            </a>
          </>
        ),
      },
      {
        li: (
          <>
            <a
              className="text-decoration-none text-dark"
              href="https://www.instagram.com/edu.yog/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Instagram
            </a>
          </>
        ),
      },
      {
        li: (
          <>
            <a
              className="text-decoration-none text-dark"
              href="https://www.linkedin.com/in/edu%E0%A4%AF%E0%A5%8B%E0%A4%97-techno-b697252b4/"
              rel="noreferrer noopener"
              target="_blank"
            >
              linkedin
            </a>
          </>
        ),
      },
    ],
  },
  
];
