import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import { Link } from 'react-router-dom';


const Stack1 = () => {

  const assignments = [
    { id: 1, name: 'Video - Implementation of Stacks', status: 'start'},
    { id: 2, name: 'Progress Check by Mentor', status: 'start'},
    { id: 3, name: 'Video - to use the inbuilt stack', status: 'start'},
    { id: 4, name: 'Min Stack', status: 'start'},
    { id: 5, name: 'Valid Parentheses', status: 'start'},
    { id: 6, name: 'Validate Stack Sequence	', status: 'start'},
    { id: 7, name: 'Remove Adjacent Duplicates	', status: 'start'},
    { id: 8, name: 'Decode string	', status: 'start'},
    { id: 9, name: 'Next Greater Element (Important)', status: 'start'},
    { id: 10, name: 'Stepping into the shoes of an interviewer', status: 'start'},
    { id: 11, name: 'Trapping Rain Water (Important)', status: 'start'},
    { id: 12, name: 'Stock Span', status: 'start'},
    { id: 13, name: 'Maximum area in Histogram (Important)', status: 'start'},
    { id: 14, name: 'Video - Queues From Scratch	', status: 'start'},
    { id: 15, name: 'Implement Queues from scratch	', status: 'start'},
    { id: 16, name: 'Circular Queue	', status: 'start'},
    { id: 17, name: 'Implement Queue Using Stacks	', status: 'start'},
    { id: 18, name: 'Stack Using Queues	', status: 'start'},
    { id: 19, name: 'Non Repeating characters	', status: 'start'},
    { id: 20, name: 'Sliding Window Maxmimum (Important)	', status: 'start'},
    
  ];


  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
      <div className='page1'>
      <div>
        <Sidebar/>
      </div>
      <div>
      <div className="assignment-foj">
      <div className="rig-phw">
        <div className="fle-fok justify-6c5 item-ycb orqly">
          <Link to='/assignment/11'>
          <button className="btn-zle bvadh">Next Assignment</button>
          </Link>
          
        </div>
        <div className="right-c1e">
          <table>
           
            <colgroup>
              {Array(4).fill().map((_, index) => <col key={index} className={`style-${index}EMdC`} />)}
            </colgroup>
            <thead>
              <tr>
                <th className="style-q61Z3">#</th>
                <th>Name</th>
                <th className="style-6q7nt">Status</th>
                <th className="style-grDIy"></th>
              </tr>
            </thead>
            <tbody className="style-iNhB2">
              {assignments.map(({ id, name, status, points }, index) => (
                <tr key={id} className="style-GbKIa">
                  <td className="style-CQJlN">{id}</td>
                  
                  <td>
                    {name}
                  </td>
                  <td className="style-mF9jW">
                  <Link to={`/Stack2/${index + 1}`} className='style-ckn'>{status}</Link>
                  </td>
                  <td className="style-hsgX7">{points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default Stack1;
