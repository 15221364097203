import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import CNavbar from './CNavbar';

const ForgottenPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setLoading(true);

    try {
      const response = await axios.post('http://127.0.0.1:8080/forgotten/forgot', { email });
      setSuccessMessage(response.data);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data);
      } else {
        setError('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="counse" style={{ height: "100vh" }}>
        <CNavbar />
        <div className="container" style={{marginTop:"13%"}}>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="card">
                <div className="card-body">
                  <h1 className="text-center mb-4 display-4 fw-normal">Forgotten Password</h1>
                  {error && <p className="alert alert-danger">{error}</p>}
                  {successMessage && <p className="alert alert-success">{successMessage}</p>}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={loading}
                      />
                    </div>
                    <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                      {loading ? 'Loading...' : 'Reset Password'}
                    </button>
                  </form>
                  <div className="text-center mt-3">
                    <Link to="/signin" className="text-decoration-none">SignIn</Link>
                  </div>
                  <div className="text-center mt-2">
                    <Link to="/signup" className="text-decoration-none">SignUp</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgottenPassword;
