import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import '../component/Page1.css';
const taskDetails = [
    {
      title: 'Test Question - 1',
      content: (
        <div>
          <p className="task-mvp">Test Question - 1</p>
         
                  
                <div className="des-8v9">
                  <p className="title-chv">
                    Task details
                  </p>
                  <div className="ql-6os ql-5xn gkajs">
                      
                    <p> 
                   
<br/>
                    Question 1 

                    <br/><br/>

var number1 = 2;
<br/>
var number2 = 5
<br/><br/>


function multiply(a, b){
<>
<br/>
var answer = a * b; //line 1
<br/>
return answer;<br/>
</>
}
<br/>
var sum = multiply(number1, number2); // line 2
<br/><br/>


console.log(sum) //line 3
<br/><br/>


console.log(answer) //line 4<br/><br/>

Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
Ans:- Output of line 3 is 10 and line 4 is Error<br/><br/>
                    </p>
                  </div>
                </div>
        </div>
      ),
    },
    {
        title: 'Test Question - 2',
        content: (
          <div>
            <p className="task-mvp">Test Question - 2</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                     
                      QUESTION 1
<br/>

<br/>
Find the output 
<br/><br/>


function outerfunction() {
<>
<br/>
console.log(a);
<br/>
var c = 25;
<br/>
<br/>

innerfunction();

<br/><br/>

function innerfunction() {
<>
<br/>
console.log(b);
<br/>
console.log(c);
<br/>
</>
}
<br/>
</>
}

<br/><br/>

var a = 5;
<br/>
var b =6
<br/><br/>


outerfunction();
<br/><br/>


QUESTION 2

<br/><br/>

Find the output

<br/><br/>

function outerfunction() {
<>
<br/>
console.log(a);
<br/>
var a = 25;
<br/><br/>


innerfunction();
<br/><br/>


function innerfunction() {
<>
<br/>
console.log(a);
<br/>
console.log(window.a);
<br/>
console.log(this.a)
<br/>
</>
}
<br/>
</>
}

<br/><br/>

var a = 5;
<br/>
var b =6

<br/><br/>

outerfunction();

<br/><br/>

undefined 25 5 5
<br/>
5 25 5 25
<br/>
25 25 5 25
<br/>
None of the above

<br/><br/>

QUESTION 3

<br/><br/>

Find the output

<br/><br/>

function outerfunction() {

<>
<br/>
var a = 25;
<br/>
console.log(a);

<br/><br/>

return function innerfunction() {
<>
<br/>
console.log(a);
<br/>
console.log(window.a);
<br/>
console.log(this.a)
<br/>
</>
}
<br/>
</>
}


<br/><br/>
var a = 5;
<br/>
var b =6

<br/><br/>

outerfunction();
<br/><br/>
  Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is 5 6 25 , Question 2 is undefined 25 5 5 and Question 3 is 25 25 undefined 5 .<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 3',
        content: (
          <div>
            <p className="task-mvp">Test Question - 3</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                     
                      QUESTION 1

<br/>
<br/>
console.log('a')
<br/>
console.log('b');
<br/>
let a =7;
<br/>
var b =11;
<br/>
console.log('b');
<br/><br/>


QUESTION 2
<br/><br/>


let a = 11;
<br/>
var b = 7;
<br/><br/>


console.log(this.b);
<br/>
console.log(window.b)
<br/>
console.log(window.a)
<br/>
console.log(this.a);
<br/><br/>


QUESSTION 3

<br/><br/>

let a =11;
<br/>
const b
<br/>
b=99;
<br/><br/>
  Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is undefined undefined 11 , Question 2 is undefined 7 undefined undefined and Question 3 is const b needs to be declared with a value.<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 4',
        content: (
          <div>
            <p className="task-mvp">Test Question - 4</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                     <br/>
                      Questions

                      <br/>  <br/>

1. 
<br/>
function x(){
<>  <br/>
let a = 13;
<br/>
function y(){
<>  <br/>
console.log(a);  <br/>
</>
}
<br/>
y()  <br/>
</>
}

<br/>  

x();
<br/>  <br/>




2. <br/> function x(){
<>  <br/>
let a = 13;
<br/>
function y(){
<>  <br/>
console.log(a);  <br/>
</>
}
<br/>
return y;  <br/>
</>
}

<br/>  <br/>

console.log( x());


<br/>  <br/>
3.
<br/>
function x(){
<>  <br/>
let a = 13;
<br/>
function y(){
<>  <br/>
console.log(a);  <br/>
</>
}
<br/>
a= 5;
<br/>
return y;  <br/>
</>
}
<br/> 

const z = x()
<br/>
console.log(z());

<br/>  <br/>

  Please try to understand ths question properly after understand try to solve this question on your if any problem occur contact your trainer <br/><br/> 
  Ans:- Output of Question 1 is 1 , Question 2 is 13 and Question 3 is 5 undefined.<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 5',
        content: (
          <div>
            <p className="task-mvp">Test Question - 5</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                     <br/>
                     Given a student object as shown below can you write code to iterate through object and find the student's name who is of age n.

<br/>
<br/>
Example 1
<br/>
Input
<br/>
studentobj = [
    <br/>
    
'yash': 26,
<br/>
'vaibhav': 24,
<br/>
'rajesh' : 25,
<br/>
]
<br/><br/>


age = 25

<br/><br/>

Output
<br/>
rajesh
<br/><br/>


Explanation
<br/>
Rajesh is the only one with age 25 in the above object.
<br/><br/>


Example 2
<br/>
Input
<br/>
studentobj = [
    <br/>
'yash': 26,
<br/>
'vaibhav': 24,
<br/>
'rajesh' : 25,
]
<br/><br/>


age = 29
<br/>
<br/>

Output
<br/>
-1
<br/><br/>


Explanation
<br/>
No student has the age 29 , thats why function will return -1

<br/><br/>



Assumption
<br/>
There will be only one student, at max ,in student Object whose age will match.
<br/>
2 students of same age will never exist.<br/><br/>
                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
      {
        title: 'Test Question - 6',
        content: (
          <div>
            <p className="task-mvp">Test Question - 6</p>
           
                    
                  <div className="des-8v9">
                    <p className="title-chv">
                      Task details
                    </p>
                    <div className="ql-6os ql-5xn gkajs">
                        
                      <p> 
                      Given a student object as shown below can you write code to iterate through object and find average age of students.
<br/>
<br/>

Input
<br/>
studentobj = [
    <br/>
'yash': 26,
<br/>
'vaibhav': 24,
<br/>
'rajesh' : 25,
<br/>
]

<br/>
<br/>
Output
<br/>
Average age = 25

<br/><br/>

Explanation
<br/>
On adding all the ages we get 75
<br/>
Total number of keys = 3
<br/>
So 75/3 = 25.
<br/><br/>


Can you write a function which takes the object and return average age.

<br/><br/>

function findaverageage(studentObj){
<><br/>
//write your code here.

<br/><br/>

return averageAge;<br/>
</>
}

<br/><br/>

                      </p>
                    </div>
                  </div>
          </div>
        ),
      },
  ];


const Testaj2 = () => {
    const { taskId } = useParams();
    console.log('Task ID:', taskId);    
  const [taskCount, setTaskCount] = useState(parseInt(taskId, 10) || 1);

  const handleNextTask = () => {
    if (taskCount < 6) {
      setTaskCount(taskCount + 1);
    }
  };

  const handlePrevTask = () => {
    if (taskCount > 1) {
      setTaskCount(taskCount - 1);
    }
  };

  const renderTaskContent = () => {
    const task = taskDetails[taskCount - 1];
    return task ? task.content : null;
  };
  

  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
    <div className='page1'>
    <div>
      <Sidebar/>
    </div>
    <div>
    <div className='page11'>
    <div className="section-ogl">
    <div className="section-vzo">
      <div className="tabs-oan">
        <button className="citvr select-yol">
          Description
        </button>
        <button className="citvr">
          Help
        </button>
      </div>
    </div>
    <div className="content-wxz">
      <div className="task-gcw">
        {renderTaskContent()}
      </div>
    </div>
    <div className="switch-lj6">
    <button className="all-hhz" >
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv snipcss0-3-38-39" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FormatListBulletedRoundedIcon">
          <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1z">
          </path>
        </svg>
        <Link to='/assignment/18'>
        <p>All Tasks</p>
        </Link>
      </button>
      <div className="switch-rzi">
      <button className="row-ovd" onClick={handlePrevTask}>
          <img className='img11' src='https://cdn-icons-png.flaticon.com/128/3114/3114883.png' alt='uploading'/>
        </button>
        <p className="tasks-3g6">{taskCount} / 6</p>
        <button className="row-ovd" onClick={handleNextTask}>
        <img className='img11' src='https://cdn-icons-png.flaticon.com/128/545/545682.png' alt='uploading'/>
        </button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  </>
  );
};

export default Testaj2;

