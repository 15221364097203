// Gallery.js
import React, { useState } from "react";
import "./Gallery.css";

const Gallery = ({ images, currentIndex }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div className={`gallery-container ${isZoomed ? 'zoomed' : ''}`} onClick={handleImageClick}>
      {images.length > 0 && (
        <img id="checkimg"
          src={images[currentIndex]}
          alt={`Seminar ${currentIndex + 1}`}
          className={`gallery-image ${isZoomed ? 'zoomed' : ''}`}
        />
      )}
    </div>
  );
};

export default Gallery;
