import React, { useState } from 'react';

const DownloadButton = ({ fileId }) => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: ''
  });

  const { name, phoneNumber, email } = formData;

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDownload = (e) => {
    e.preventDefault(); // Prevent default form submission action

    // Check if form data is filled
    if (name.trim() === '' || phoneNumber.trim() === '' || email.trim() === '') {
      alert('Please fill in all details');
      return;
    }

    // Send form data to the backend
    fetch('https://eduyog.in/api/files/formdata', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        phoneNumber,
        email,
        fileId
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to send form data');
        }
        // Send a GET request to download the file
        return fetch(`https://eduyog.in/api/files/${fileId}`, {
          method: 'GET',
        });
      })
      .then(response => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error('File not found');
        }
        return response.blob(); // Get the response as a Blob
      })
      .then(blob => {
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);
        console.log(url);
        // Create a temporary <a> element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `file_${fileId}.jpg`; // Set the file name
        document.body.appendChild(a);
        a.click(); // Simulate a click event to trigger the download
        document.body.removeChild(a); // Remove the <a> element
        window.URL.revokeObjectURL(url); // Release the object URL

        // Clear form inputs after download
        setFormData({
          name: '',
          phoneNumber: '',
          email: ''
        });
      })
      .catch(error => {
        console.error('Download error:', error);
        // Handle error, e.g., display an error message to the user
      });
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', maxWidth: '400px', margin: 'auto' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Timetable</h2>
      <form onSubmit={handleDownload} style={{ marginBottom: '20px' }}>
        <input type="text" name="name" placeholder="Name" value={name} onChange={handleInputChange} style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }} />
        <input type="tel" name="phoneNumber" placeholder="Phone Number" value={phoneNumber} onChange={handleInputChange} style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }} />
        <input type="email" name="email" placeholder="Email" value={email} onChange={handleInputChange} style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }} />
        <button type="submit" style={{ width: '100%', padding: '10px', borderRadius: '5px', border: 'none', backgroundColor: '#007bff', color: '#fff', cursor: 'pointer' }} disabled={!name || !phoneNumber || !email}>Download Timetable</button>
      </form>
    </div>
  );
};

export default DownloadButton;
