import { Sidebar } from '../Dashboard';
import { PlacementNavbar } from '../Dashboard';
import { Link } from 'react-router-dom';


const Dom1 = () => {

  const assignments = [
    { id: 1, name: 'What on Earth Is Git?', status: 'start'},
    { id: 2, name: 'Adding a new file to GIT', status: 'start'},
    { id: 3, name: 'Linked List - FInd Sum', status: 'start'},
    { id: 4, name: 'Linked List - Find Middle Element', status: 'start'},
    { id: 5, name: 'Linked List - Delete element', status: 'start'},
  ];


  return (
    <>
    <div className='plac1'><PlacementNavbar/></div>
      <div className='page1'>
      <div>
        <Sidebar/>
      </div>
      <div>
      <div className="assignment-foj">
      <div className="rig-phw">
        <div className="fle-fok justify-6c5 item-ycb orqly">
          <Link to='/assignment/15'>
          <button className="btn-zle bvadh">Next Assignment</button>
          </Link>
          
        </div>
        <div className="right-c1e">
          <table>
           
            <colgroup>
              {Array(4).fill().map((_, index) => <col key={index} className={`style-${index}EMdC`} />)}
            </colgroup>
            <thead>
              <tr>
                <th className="style-q61Z3">#</th>
                <th>Name</th>
                <th className="style-6q7nt">Status</th>
                <th className='sh'></th>
              </tr>
            </thead>
            <tbody className="style-iNhB2">
              {assignments.map(({ id, name, status, points }, index) => (
                <tr key={id} className="style-GbKIa">
                  <td className="style-CQJlN">{id}</td>
                  <td>
                   {name}
                  </td>
                  <td className="style-mF9jW">
                  <Link to={`/Dom2/${index + 1}`} className='style-ckn'>{status}</Link>
                  </td>
                  <td className="style-hsgX7">{points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default Dom1;