// YogaDashBoard.js
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Placement.css";
import "./PlacementResponse.css";
import { YogaNavbar } from "./component/YogaNavbar";
import LiveYogaClass from "./component/LiveYogaClass";
import DataWait from "../../Auth/DataWait";
import ReactPlayer from "react-player";
import image1 from "../video/image1.jpg"
import image2 from "../video/image2.jpg"
import image3 from "../video/image3.jpg"

const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

const cardImgStyle = {
  width: '100%',
  height: '300px',
  objectFit: 'cover',
};

const cardTitleStyle = {
  fontSize: '1.25rem',
};

const cardTextStyle = {
  flexGrow: 1,
};

const btnStyle = {
  marginTop: 'auto',
};

const YogaDashBoard = () => {
  return (
    <>
      <YogaNavbar />
      <div className="container-fluid p-0" style={{ backgroundColor: "#f5f5f5" }}>
        <div className="row m-0 mt-5 justify-content-center">
          <div className="col-lg-8 p-0">
            <nav className="mt-5">
              <LiveYogaClass />
            </nav>

            <div className="m-5">
              <div className="card">
                <div className="card-header bg-dark text-white align-item-center">
                  <h5 className="card-title mb-0 text-center">Yoga Session Video For Diet</h5>
                </div>
                <div className="card-body session">
                  <div className="embed-responsive embed-responsive-16by9">
                    <ReactPlayer
                      url="https://www.youtube.com/watch?v=AVpxxDid5tQ&t=1s"
                      controls={true}
                      width="100%"
                      height="40vh"
                      className="rounded shadow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0 justify-content-center mt-5">
          <div className="col-lg-8 text-center">
            <h2 className="mb-4 fw-bold text-dark display-5">Start Your Yoga Journey Today</h2>
            <p className="lead">
              Whether you're a beginner or an experienced practitioner, our yoga classes offer something for everyone. Join our community and experience the benefits of yoga for your mind, body, and soul.
            </p>
            <a href="https://vimeo.com/917467937" alt="uploading" target="_blank">
              <button className="btn btn-primary btn-lg mt-4">Watch Demo Class</button>
            </a>
          </div>
        </div>

        <div className="row m-0 justify-content-center mt-5">
          <div className="col-lg-10">
            <h2 className="text-center mb-5 fw-bold text-dark display-5">Our Featured Yoga Instructors</h2>
            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className="col mb-4">
                <div className="card h-100" style={cardStyle}>
                  <img
                    src={image3}
                    className="card-img-top"
                    alt="Yoga Instructor"
                    style={cardImgStyle}
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={cardTitleStyle}>Suhani Chauhan</h5>
                    <p className="card-text" style={cardTextStyle}>Certified Yoga Instructor</p>
                    <a href="#" className="btn btn-primary" style={btnStyle}>Learn More</a>
                  </div>
                </div>
              </div>
              <div className="col mb-4">
                <div className="card h-100" style={cardStyle}>
                  <img
                    src={image1}
                    className="card-img-top"
                    alt="Yoga Instructor"
                    style={cardImgStyle}
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={cardTitleStyle}>Akanksha Bharti</h5>
                    <p className="card-text" style={cardTextStyle}>Experienced Yoga Teacher</p>
                    <a href="#" className="btn btn-primary" style={btnStyle}>Learn More</a>
                  </div>
                </div>
              </div>
              <div className="col mb-4">
                <div className="card h-100" style={cardStyle}>
                  <img
                    src={image2}
                    className="card-img-top"
                    alt="Yoga Instructor"
                    style={cardImgStyle}
                  />
                  <div className="card-body">
                    <h5 className="card-title" style={cardTitleStyle}>Rithika</h5>
                    <p className="card-text" style={cardTextStyle}>Yoga & Meditation Expert</p>
                    <a href="#" className="btn btn-primary" style={btnStyle}>Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-5 container justify-content-center">
          <div className="m-3">
            <div className="card">
              <div className="card-header bg-dark text-white">
                <h5 className="card-title mb-0">Related Videos</h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <a href="https://youtu.be/sSiA25XlG_A?si=3k6ndgr5dUieAqFP" target="_blank" rel="noopener noreferrer">Yoga for beginners</a>
                </li>
                <li className="list-group-item">
                  <a href="https://youtu.be/Wj0Egd0AcJ8?si=Sk9a2Sii95LKwJJS" target="_blank" rel="noopener noreferrer">10 min full body morning Yoga</a>
                </li>
                <li className="list-group-item">
                  <a href="https://youtu.be/uBoQJkNEx-M?si=KZHMhz5YH1Z9wW2e" target="_blank" rel="noopener noreferrer">Yoga diet plan</a>
                </li>
              </ul>
            </div>
          </div>
          <DataWait />
        </div>

        <div className="row m-0">
          <div className="col-lg-12">
            <div className="alert alert-info text-center mt-3 bg-dark text-light" role="alert">
              Need help or have questions? Contact our support team at <a href="mailto:eduyogtechno@eduyog.in" className="text-white">eduyogtechno@eduyog.in</a>.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YogaDashBoard;
